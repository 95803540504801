import classNames from 'clsx';
import { xray } from 'lib/xray';
import React, { memo, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CloudFile } from 'reactApp/modules/storage/storage.types';
import { CONTENT_LOAD_TIMEOUT } from 'reactApp/ui/ReactViewer/ReactViewer.constants';
import { Spinner } from 'reactApp/ui/Spinner/Spinner';
import { getAttachType } from 'reactApp/utils/downloadDocumentHelpers';
import { sendLoadTime } from 'reactApp/utils/sendLoadTime';

import { useConvertDocumentToPdf } from './hooks/useConvertDocumentToPdf';
import styles from './ViewerPdf.css';

export interface ViewerPdfProps {
    url: string;
    renderError?: () => ReactElement | null;
    fillParent?: boolean;
    file?: CloudFile;
    convert?: boolean;
    radarError?: string;
    onError?: (msg: string) => void;
    onOpen?: () => void;
    onConvertSuccess?: (url: string) => void;
}

export const ViewerPdf = memo<ViewerPdfProps>(
    ({ url, file, convert, onError, onOpen, renderError = () => null, onConvertSuccess, fillParent }) => {
        const ref = useRef<number | undefined>();
        const mountTime = useRef<number>(0);

        const { fileURL, error } = useConvertDocumentToPdf({ file, convert });

        const [errorMsg, setErrorMsg] = useState<string | null>(null);
        const [isLoaded, setLoaded] = useState(false);
        const attachType = useMemo(() => getAttachType(file), [file]);

        const src = convert ? fileURL : url;
        const showSpinner = !isLoaded || (convert && !src);

        const handleError = useCallback(
            (error: string) => {
                setErrorMsg(error);
                setLoaded(true);
            },
            [setErrorMsg, setLoaded]
        );

        const handleIframeError = useCallback(() => {
            handleError('iframe');
        }, [handleError]);

        useEffect(() => {
            if (convert && fileURL) {
                onConvertSuccess?.(fileURL);
            }
        }, [convert, fileURL]);

        useEffect(() => {
            mountTime.current = Date.now();
        }, []);

        useEffect(() => {
            if (error) {
                setErrorMsg(`api_${error.radarName || error.status || error.message}`);
            }
        }, [error]);

        const handleLoad = useCallback(() => {
            if (ref.current) {
                clearTimeout(ref.current);
            }

            if (!isLoaded) {
                sendLoadTime(mountTime.current, `viewer_pdf${convert ? '_ovidius' : ''}${attachType ? `_${attachType}` : ''}_load_time`);
            }

            onOpen?.();
            setLoaded(true);
        }, [ref.current, setLoaded, onOpen]);

        useEffect(() => {
            if (ref.current) {
                clearTimeout(ref.current);
            }

            ref.current = window.setTimeout(() => handleError('timeout'), CONTENT_LOAD_TIMEOUT);

            return () => {
                if (ref.current) {
                    clearTimeout(ref.current);
                }
            };
        }, [url, handleError]);

        useEffect(() => {
            if (errorMsg) {
                xray.send(`viewer_pdf_err${convert ? '_ovidius' : ''}${attachType ? `_${attachType}` : ''}`);
                onError?.(errorMsg);
            }
        }, [errorMsg]);

        if (errorMsg) {
            return renderError();
        }

        return (
            <div
                className={classNames(styles.root, {
                    [styles.root_fillParent]: fillParent,
                })}
            >
                <iframe
                    src={src}
                    className={styles.pdf}
                    allowFullScreen
                    allow="autoplay; fullscreen"
                    frameBorder={0}
                    onError={handleIframeError}
                    onLoad={handleLoad}
                />
                {showSpinner && <Spinner />}
            </div>
        );
    }
);

ViewerPdf.displayName = 'ViewerPdf';

ViewerPdf.whyDidYouRender = true;
