import { useSelector } from 'react-redux';
import { getCreateDescriptions } from 'reactApp/modules/editor/editor.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { DropdownTheme } from 'reactApp/ui/DropdownList/DropdownList';
import { TOOLBAR_CONFIG } from 'reactApp/ui/Toolbar/Toolbar.config';
import { getEditorsItem, getUploadItem, toolbarDropdownItems, toolbarItems } from 'reactApp/ui/Toolbar/Toolbar.data';
import { TToolbarItem } from 'reactApp/ui/Toolbar/Toolbar.types';

export const useAddActions = ({ storage }: { storage: EStorageType }): TToolbarItem[] => {
    const settings = TOOLBAR_CONFIG[storage];

    const availableCreateEditors = useSelector(getCreateDescriptions);

    if (!settings?.add) {
        return [];
    }

    const uploadItem = getUploadItem(storage);

    if (storage === EStorageType.integration) {
        return [uploadItem];
    }

    const list = [...getEditorsItem(availableCreateEditors)];

    if (storage !== EStorageType.alldocuments) {
        list.unshift(toolbarDropdownItems.createFolder);
    }

    return [
        uploadItem,
        {
            ...toolbarItems.create,
            dropdownTheme: DropdownTheme.medium,
            list,
        } as TToolbarItem,
    ];
};
