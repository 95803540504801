import { useSelector } from 'react-redux';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';

export const useQueryParams = () => {
    // max_size - вес самого тяжелого файла
    // total_size - сумма всех файлов
    // afterReload - флаг, по которому открываем в поп-апе сразу оплату
    // isCloudStock - флаг, по которому определяем является ли аттач cloud_stock
    const { max_size, total_size, afterReload, from, isCloudStock } = useSelector(SettingsSelectors.getQueryParams);

    const maxUploadSize = Number(max_size) || 0;
    const totalUploadSize = Number(total_size) || 0;
    const isFromDnD = from === 'dnd';

    return {
        maxUploadSize,
        totalUploadSize,
        isFromDnD,
        afterReload,
        isCloudStock: !!isCloudStock,
    } as const;
};
