import { Icon20MoreVertical } from '@vkontakte/icons';
import { Headline, Spacing } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { memo, MouseEvent, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ACTION_PROMO, IS_ACTION_USER, IS_AUTO_TEST_PROMO } from 'reactApp/appHelpers/configHelpers';
import { isHideQuotaNewbieFeatureEnabled } from 'reactApp/appHelpers/featuresHelpers';
import { canShowUserQuota } from 'reactApp/modules/family/family.selectors';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { useCallbacks } from 'reactApp/ui/Space/SingleSpace/hooks/useCallbacks';
import { useContent } from 'reactApp/ui/Space/SingleSpace/hooks/useContent';
import { useQuotaInfo } from 'reactApp/ui/Space/SingleSpace/hooks/useQuotaInfo';
import { useSendSpaceAnalytics } from 'reactApp/ui/Space/SingleSpace/SingleMenu.analytics';
import { SingleSpaceMenu } from 'reactApp/ui/Space/SingleSpace/SingleSpaceMenu/SingleSpaceMenu';
import { SingleSpaceProgress } from 'reactApp/ui/Space/SingleSpace/SingleSpaceProgress/SingleSpaceProgress';
import { useSpace } from 'reactApp/ui/Space/SingleSpace/SingleSpaceProgress/useSpace';
import { useUserAndFamilySpace } from 'reactApp/ui/Space/SingleSpace/SingleSpaceProgress/useUserAndFamilySpace';
import { Props } from 'reactApp/ui/Space/Space.types';
import { SpaceCountdown } from 'reactApp/ui/Space/SpaceCountdown/SpaceCountdown';
import { SpaceLoader } from 'reactApp/ui/Space/SpaceLoader/SpaceLoader';
import { noopVoid } from 'reactApp/utils/helpers';
import { openSubscriptionsPage } from 'reactApp/utils/openHelpers';

import styles from './SingleSpace.css';

export const SingleSpace = memo<Props>(
    ({ isSubscriptionsPage = false, goToSubscriptions = openSubscriptionsPage, isMobile = false, className }): ReactElement => {
        const showQuotaWithFamily = useSelector(canShowUserQuota);

        const { total, usedTotal, isQuotaLoaded, isFull } = useQuotaInfo(showQuotaWithFamily);

        const isBizUser = useSelector(UserSelectors.isBizUser);
        const isNewbie = useSelector(UserSelectors.isNewbie);

        const countdownPromo = useSelector(PromoSelectors.getPromo(EPromoType.space));

        const isMarketingPromo = IS_ACTION_USER && !isFull && (!!ACTION_PROMO || IS_AUTO_TEST_PROMO);

        const isCountdownPromo = Boolean(countdownPromo) && !isSubscriptionsPage;
        const { warning, buttonText, buttonState, hideButton, isMySubscription, hideMenu, showFamilyButton } = useContent({
            isMarketingPromo,
            isSubscriptionsPage,
        });
        const sendAnalytics = useSendSpaceAnalytics({ isCountdownPromo, isMarketingPromo, skipAnalytics: hideButton || isBizUser });
        const { onPayment, onFamilyClick, updateQuota } = useCallbacks({
            isMobile,
            countdownTariff: (isCountdownPromo && countdownPromo?.data?.productId) || '',
            goToSubscriptions,
            sendAnalytics,
            isMarketingPromo,
            showFamilyButton,
        });

        const wasShown = useRef<boolean>(false);
        const menuRef = useRef<HTMLDivElement | null>(null);
        const [isShowMenu, setShowMenu] = useState<boolean>(false);

        const showMenu = () => {
            if (isBizUser) {
                return;
            }

            setShowMenu(true);
        };

        const closeMenu = (event?: MouseEvent) => {
            event?.stopPropagation();
            setShowMenu(false);
        };

        const onButtonClick = useCallback(
            (event) => {
                event?.stopPropagation();
                closeMenu(event);
                if (showFamilyButton) {
                    onFamilyClick?.();
                } else {
                    onPayment();
                }
            },
            [onPayment, onFamilyClick, showFamilyButton]
        );

        const button = useMemo(
            () => (
                <div className={styles.buttonBlock}>
                    <Spacing size={16} />
                    <div className={styles.buttonWrapper}>
                        <Button
                            {...buttonState}
                            fluid
                            theme="octavius"
                            sizeMode={isMobile ? ButtonSizeMode.big : ButtonSizeMode.small}
                            onClick={isCountdownPromo ? noopVoid : onButtonClick}
                        >
                            <div className={styles.buttonText}>{buttonText}</div>
                        </Button>
                    </div>
                </div>
            ),
            [buttonState, buttonText, isCountdownPromo, isMarketingPromo, isMobile, onButtonClick]
        );

        useEffect(() => {
            if (isQuotaLoaded && !wasShown.current) {
                sendAnalytics('view');
                wasShown.current = true;
            }
        }, [isQuotaLoaded, sendAnalytics]);

        useEffect(() => {
            if (wasShown.current && showQuotaWithFamily) {
                updateQuota();
            }
        }, [showQuotaWithFamily]);

        // tempexp_14979-next-line
        // CLOUDWEB-14979: Показываем скелетом до тех пор пока юзер не принял лс и пока не отработало преименнование файлов (под фичей).
        if (!isQuotaLoaded || (isHideQuotaNewbieFeatureEnabled && isNewbie)) {
            return (
                <>
                    <div className={classNames(styles.spaceBlock, styles.spaceBlock_loading)}>
                        <SpaceLoader withButton={!hideButton} isCompact />
                    </div>
                    <Spacing size={isMobile ? 4 : 8} />
                </>
            );
        }

        return (
            <div className={classNames(className)}>
                <div
                    className={classNames(styles.spaceBlock, {
                        [styles.spaceBlock_overquota]: isFull,
                        [styles.spaceBlock_marketingPromo]: isMarketingPromo,
                        [styles.spaceBlock_mobile]: isMobile,
                    })}
                    onClick={showMenu}
                >
                    <div className={styles.info}>
                        <div className={styles.titleBlock}>
                            <Headline level={isMobile ? '1' : '2'}>
                                Занято {usedTotal?.value} из {total?.value}
                            </Headline>
                            {!hideMenu && (
                                <div
                                    className={classNames(styles.menuIcon, {
                                        [styles.menuIcon_active]: isShowMenu,
                                    })}
                                    ref={menuRef}
                                    onClick={isShowMenu ? closeMenu : showMenu}
                                >
                                    <Icon20MoreVertical />
                                </div>
                            )}
                        </div>
                        <Spacing size={12} />
                        <SingleSpaceProgress
                            isWrap={showQuotaWithFamily}
                            isMobile={isMobile}
                            useSpaceHook={showQuotaWithFamily ? useUserAndFamilySpace : useSpace}
                        />
                        {Boolean(warning) && !isMobile && (
                            <>
                                <Spacing size={12} />
                                <div className={styles.warning}>{warning}</div>
                            </>
                        )}
                    </div>
                    {!hideButton && !isCountdownPromo && button}
                </div>

                {isCountdownPromo && (
                    <>
                        <Spacing size={12} />
                        <div className={styles.promo}>
                            <SpaceCountdown onClick={onButtonClick} button={button} />
                        </div>
                        <Spacing size={4} />
                    </>
                )}

                <Spacing size={isMobile ? 4 : 8} />

                {isShowMenu && (
                    <SingleSpaceMenu
                        isMobile={isMobile}
                        isSubscriptionsPage={isSubscriptionsPage}
                        parentRef={menuRef}
                        onClose={closeMenu}
                        isMySubscription={isMySubscription}
                    />
                )}
            </div>
        );
    }
);

SingleSpace.displayName = 'SingleSpace';
