/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { captureException } from '@sentry/browser';
import features from 'Cloud/Application/Features';
import config from 'Cloud/config';
import $ from 'jquery';
import admanLogger from 'lib/admanLogger';
import AdUnblocker from 'lib/AdUnblocker';
import { logger } from 'lib/logger';
import { xray } from 'lib/xray';
import MrgContext from 'mrg-context';
import {
    DOM_ID_INSIDE_PHONE_DATALIST,
    DOM_ID_PHONE_HEADER_ERRORS_PAGE,
    DOM_ID_RIGHT_COL_BANNER_ERRORS_PAGE,
} from 'reactApp/appHelpers/advertisingConfigHelpers';
import {
    IS_B2B_BIZ_USER,
    IS_MOBILE_BROWSER,
    IS_PHONE_BROWSER,
    IS_PUBLIC,
    IS_PUBLIC_FOLDER,
    IS_TABLET_BROWSER,
    IS_WEBVIEW,
    PUBLIC_SHOVE,
    USER_EMAIL,
} from 'reactApp/appHelpers/configHelpers';
import { advUpdated, EAdvType } from 'reactApp/modules/adv/adv.module';
import { getPublicRootWeblink } from 'reactApp/modules/public/public.selectors';
import { getCurrentRouteId, getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { ViewerSelectors } from 'reactApp/modules/viewer/viewer.selectors';
import { isWelcomePromo } from 'reactApp/modules/welcome/welcome.selectors';
import { store as reduxStore } from 'reactApp/store';
import { ESiteZone } from 'reactApp/ui/AdvBanners/Adv.types';
import { sendGa } from 'reactApp/utils/ga';
import { startAdsUpdateTime } from 'reactApp/utils/helpers';

const IS_ONPREMISE = config.get('ON_PREMISE');
const IS_PUBLIC_FILE = IS_PUBLIC && !IS_PUBLIC_FOLDER;
const AD_UNBLOKER = false; // config.get('AD_UNBLOKER');
const DOM_RIGHT_COL_BANNER_DIRECT = config.get('DOM_RIGHT_COL_BANNER_DIRECT');
const IS_MIMIC_ENABLED = config.get('MIMIC_ENABLED');
const MRG_CTX_TIMEOUT = config.get('MRG_CTX_TIMEOUT');
const ADQ_TIMEOUT = config.get('ADQ_TIMEOUT');

const _SITEID = 182;

let isViewerActive = false;
const dollarsCache = {};
let useRb = true;
let legs;

const places = {
    worm_viewer: config.get('DOM_ID_VIEWER_WORM'),
    worm_nv: config.get('DOM_ID_VIEWER_NEW_WORM'),
    worm: config.get('DOM_ID_WORM'),
    dom404: config.get('DOM_ID_404'),
    roll: config.get('DOM_ID_ROLL'),
    rightcol: config.get('DOM_RIGHT_COL_BANNER'),
    phoneTop: config.get('DOM_ID_PHONE_PUBLIC_HEADER'),
    mobSplash: config.get('DOM_ID_MOBILE_PUBLIC_SPLASH_SCREEN'),
    after_dl: config.get('DOM_ID_AFTER_DATALIST'),
    inside_dl: DOM_ID_INSIDE_PHONE_DATALIST,
    err_page: DOM_ID_RIGHT_COL_BANNER_ERRORS_PAGE,
    err_page_m: DOM_ID_PHONE_HEADER_ERRORS_PAGE,
};

if (typeof window !== 'undefined') {
    legs = $.extend(
        function (place, params) {
            return legs[place] && legs[place](params || {});
        },
        {
            rightcol({ direct } = {}) {
                const el = $(`#${places.rightcol}`);

                el[direct ? 'addClass' : 'removeClass'](DOM_RIGHT_COL_BANNER_DIRECT);
            },
        }
    );

    MrgContext.setup({
        url: `${config.get('DEV_SERVER') ? 'ws' : 'wss'}://${location.hostname}/mimic/v1/ws`,
        timeout: MRG_CTX_TIMEOUT,
        radar: {
            project: 'cloud',
            email: USER_EMAIL,
        },
    });
}

const directDom = {
    cache: {},

    getElementByPlace(place) {
        const domId = places[place];

        return document.querySelector(`#${domId}`);
    },

    extractContent(place, type, container) {
        let fragment = null;

        container = container || this.getElementByPlace(place);

        if (container) {
            if (!type || this.getType(place, container) === type) {
                fragment = document.createDocumentFragment();

                let node;

                while ((node = container.firstChild)) {
                    fragment.appendChild(node);
                }
            }

            this.setType(place, 'unknown', container);
        }

        this.cache[place] = fragment;
    },

    restoreContent(place, container) {
        const cache = this.cache;
        const content = cache[place];

        if (content) {
            container = container || this.getElementByPlace(place);

            if (container) {
                container.innerHTML = '';
                container.appendChild(content);
            }
        }

        cache[place] = null;
    },

    setType(place, type, container) {
        container = container || this.getElementByPlace(place);

        if (container) {
            container['data-type'] = type;
        }
    },

    getType(place, container) {
        container = container || this.getElementByPlace(place);

        return container['data-type'];
    },
};

for (const place in places) {
    if (Object.prototype.hasOwnProperty.call(places, place)) {
        dollarsCache[place] = $(`#${place}_mimic`).text();
    }
}

const getCurrentStorageForRadar = () => {
    const storage = getCurrentStorage(reduxStore.getState());
    return String(storage).replace(/[_/]/g, '-');
};

function onAdvClick() {
    const advParams = this._advParams || {};

    const placeKey = advParams.placeKey || 'unknown'; // worm, worm-viewerm, rightcol

    const slotId = advParams.slotId || 'unknown';
    const slotKey = advParams.slotKey || 'unknown'; // slot id with sz

    const partnerId = advParams.partnerId || 'unknown';
    const statId = advParams.statId || 'unknown';
    const mimicType = advParams.mimicType || 'unknown'; // mail or yandex

    const timeLeft = advParams.time ? Date.now() - advParams.time : 0;

    const canonicalPublicLink = IS_PUBLIC && getPublicRootWeblink(reduxStore.getState()).weblink;

    const STORAGE = getCurrentStorageForRadar();

    let radar;

    if (partnerId) {
        // for mimic
        radar = RADAR.group(`app_mimic_${mimicType}`);

        if (canonicalPublicLink) {
            radar.param('id', canonicalPublicLink);
        }

        const clickLabel = 'click_';

        radar
            .add(`${clickLabel}all`, timeLeft)
            .add(clickLabel + STORAGE, timeLeft)
            .add(`${clickLabel + placeKey}_all`, timeLeft)
            .add(`${clickLabel + placeKey}_${STORAGE}`, timeLeft)
            .add(`${clickLabel}partner-${partnerId}_all`, timeLeft)
            .add(`${clickLabel}partner-${partnerId}_${STORAGE}`, timeLeft)
            .add(`${clickLabel}partner-${partnerId}_stat-${statId}_all`, timeLeft)
            .add(`${clickLabel}partner-${partnerId}_stat-${statId}_${STORAGE}`, timeLeft)
            .send();
    }

    radar = RADAR.group('app_advert');

    if (canonicalPublicLink) {
        radar.param('id', canonicalPublicLink);
    }

    const rbClickLabel = 'rb_click_';

    radar
        .add(`${rbClickLabel}all`, timeLeft)
        .add(rbClickLabel + STORAGE, timeLeft)
        .add(`${rbClickLabel + placeKey}_all`, timeLeft)
        .add(`${rbClickLabel + placeKey}_${STORAGE}`, timeLeft)
        .add(`${rbClickLabel}slot-${slotId}_all`, timeLeft)
        .add(`${rbClickLabel}slot-${slotId}_${STORAGE}`, timeLeft);

    if (slotId !== slotKey) {
        radar.add(`rb_click_slot-${slotKey}_all`, timeLeft).add(`rb_click_slot-${slotKey}_${STORAGE}`, timeLeft);
    }

    radar.send();
}

function addClickCounter(place, advParams) {
    const element = document.querySelector(`#${places[place]}`);

    if (element) {
        if (!element._advParams) {
            element.addEventListener('click', onAdvClick);
        }

        element._advParams = Object.assign(element._advParams || {}, advParams);
    }
}

function isDarkPublic() {
    return (features.isFeature('dark-public-file') || !IS_MOBILE_BROWSER) && IS_PUBLIC_FILE;
}

let adUnblocker;
let isBlockerStyleSheetsDisabled = false;
const noadsButtonsShown = {};

function disableBlockerStyleSheets() {
    if (!isBlockerStyleSheetsDisabled && AD_UNBLOKER) {
        isBlockerStyleSheetsDisabled = true;

        if (!adUnblocker) {
            adUnblocker = new AdUnblocker({
                selectorPattern: config.get('AD_UNBLOKER_SEL_PATTERN'),
            });
        }

        const radar = RADAR.group('app_mimic_mail');

        radar.add('blocker-styles_remove_try', 1);

        adUnblocker.disableBlockerStyleSheets().then(
            function (count) {
                radar.timeEnd('blocker-styles_remove_time');
                radar.add('blocker-styles_remove_count', count);
                radar.send();
            },
            function () {
                radar.timeEnd('blocker-styles_not-found_time');
                radar.send();
            }
        );
    }
}

function noAdsBttnCloseClick(block = '') {
    sendGa(IS_PUBLIC ? 'public' : 'home', 'noadsbtn', 'click');
    sendGa(IS_PUBLIC ? 'public' : 'home', 'abnonoadsbtn', `click-${block}`);
}

function updateDollarsRiver(settings) {
    if (IS_ONPREMISE || IS_B2B_BIZ_USER) {
        return;
    }

    try {
        const bttns = document.querySelectorAll('a[data-name="disableads"]');

        Array.prototype.slice.call(bttns).forEach((bttn) => {
            if (!noadsButtonsShown[bttn.className]) {
                noadsButtonsShown[bttn.className] = true;
                const block = (bttn.href || '').match(/(upline|left|branded)/)[0];
                sendGa(IS_PUBLIC ? 'public' : 'home', 'noadsbtn', 'show');
                sendGa(IS_PUBLIC ? 'public' : 'home', 'abyesnoadsbtn', `show-${block}`);
                bttn.addEventListener('click', () => noAdsBttnCloseClick(block));
            }
        });

        const isToolkitViewerActive = isViewerActive && !IS_PUBLIC_FILE;

        if (PUBLIC_SHOVE && !settings.ignoreShove && !isToolkitViewerActive) {
            return;
        }

        const darkMode = isDarkPublic() || isToolkitViewerActive;

        const { place, onError, onSuccess } = settings;
        const placeKey = place.replace(/_/g, '-');
        const domId = places[place];
        const $container = $(`#${domId}`);
        const slotId = settings.slot;
        const params = settings.params || {};
        const expId = params.exp_id;
        const sz = params.sz;
        const slotKey = slotId + (isFinite(sz) ? `sz${sz}` : '');
        let htmlCode = '';
        const STORAGE = getCurrentStorageForRadar();

        params.q = [slotId];

        if (params.h === null) {
            const dimensions = document.querySelectorAll('.js-public-height');
            const height = dimensions?.[0]?.clientHeight;

            if (!height) {
                return;
            }

            params.h = Math.round(height);

            if (darkMode) {
                params.DT_titleColor = 'fff';
                params.DT_titleHoverColor = 'fff';
                params.DT_textColor = 'f0f0f0';
                params.DT_urlColor = 'fff';
            }
        }

        if (params.w === null) {
            delete params.w;
        }

        if (darkMode) {
            params.DT_top = 'fff';
            params.DT_topHover = 'fff';
            params.DT_bg = '333';
            params.isDarkTheme = 1;
            params.DT_bgHover = '333';
            params.ponymode = 0;
        }

        if (config.get('RB_SLOT_PARAM')) {
            const rbParam = config.get('RB_SLOT_PARAM').split('=');

            params.preview = rbParam[1];
        }

        if (!$container[0] || $container.css('display') === 'none') {
            return;
        }

        const parent = $container.parent();
        if (parent && parent.css('display') === 'none') {
            return;
        }

        const state = reduxStore.getState();
        if (isWelcomePromo(state, getCurrentStorage(state), getCurrentRouteId(state))) {
            $container[0].style.visibility = 'hidden';
            bttns[0].style.visibility = 'hidden';
            return;
        }

        $container[0].style.visibility = 'visible';

        // CLOUDWEB-7013
        const radar = RADAR.group('app_advert');

        if (IS_PUBLIC) {
            radar.param('id', getPublicRootWeblink(reduxStore.getState()).weblink);
        }

        if (useRb) {
            const rbStartLable = 'rb_start_';
            radar
                .timeEnd(`${rbStartLable}all`)
                .timeEnd(rbStartLable + STORAGE)
                .timeEnd(`${rbStartLable + placeKey}_all`)
                .timeEnd(`${rbStartLable + placeKey}_${STORAGE}`)
                .timeEnd(`${rbStartLable}slot-${slotId}_all`)
                .timeEnd(`${rbStartLable}slot-${slotId}_${STORAGE}`);

            if (slotId !== slotKey) {
                radar.timeEnd(`${rbStartLable}slot-${slotKey}_all`).timeEnd(`${rbStartLable}slot-${slotKey}_${STORAGE}`);
            }

            const adqRequestParams = {
                url: '//ad.mail.ru/adq/',
                dataType: 'jsonp',
                data: params,

                success(data) {
                    if (Array.isArray(data) && data.length) {
                        for (const element of data) {
                            htmlCode += element.html;
                        }

                        const rbSuccessLable = 'rb_success_';

                        radar
                            .timeEnd(rbSuccessLable)
                            .timeEnd(rbSuccessLable + STORAGE)
                            .timeEnd(`${rbSuccessLable + placeKey}_all`)
                            .timeEnd(`${rbSuccessLable + placeKey}_${STORAGE}`)
                            .timeEnd(`${rbSuccessLable}slot-${slotId}_all`)
                            .timeEnd(`${rbSuccessLable}slot-${slotId}_${STORAGE}`);

                        if (slotId !== slotKey) {
                            radar.timeEnd(`${rbSuccessLable}slot-${slotKey}_all`).timeEnd(`${rbSuccessLable}slot-${slotKey}_${STORAGE}`);
                        }

                        radar.send();
                        xray.send(['cloud_adv', 'complete', slotId]);

                        directDom.extractContent(place, 'direct', $container[0]);
                        legs(place);

                        try {
                            $container.html(htmlCode);

                            addClickCounter(place, {
                                time: Date.now(),
                                placeKey,
                                slotId,
                                slotKey,
                            });

                            admanLogger.slotInsertComplete({
                                container: $container[0],
                                params: {
                                    slotName: place,
                                    slotId,
                                    expId,
                                    siteZone: sz,
                                },
                                result: {
                                    status: 'render',
                                    slot: {
                                        html: htmlCode,
                                    },
                                },
                            });

                            if (onSuccess) {
                                onSuccess();
                            }
                        } catch (error) {
                            logger.error(error);
                            xray.send(['cloud_adv', 'error_container_html', slotId]);
                        }
                    } else {
                        xray.send(['cloud_adv', 'empty_answer', slotId]);
                    }
                },

                error(jqXHR, textStatus, errorThrown) {
                    const rbErrorLable = 'rb_error_';

                    if (onError) {
                        onError();
                    }

                    radar
                        .timeEnd(`${rbErrorLable}all`)
                        .timeEnd(rbErrorLable + STORAGE)
                        .timeEnd(`${rbErrorLable + placeKey}_all`)
                        .timeEnd(`${rbErrorLable + placeKey}_${STORAGE}`)
                        .timeEnd(`${rbErrorLable}slot-${slotId}_all`)
                        .timeEnd(`${rbErrorLable}slot-${slotId}_${STORAGE}`);

                    if (slotId !== slotKey) {
                        radar.timeEnd(`${rbErrorLable}slot-${slotKey}_all`).timeEnd(`${rbErrorLable}slot-${slotKey}_${STORAGE}`);
                    }

                    if (textStatus === 'timeout') {
                        const rbTimeoutLable = 'rb_timeout_';

                        radar
                            .timeEnd('rb_timeout_all')
                            .timeEnd(rbTimeoutLable + STORAGE)
                            .timeEnd(`${rbTimeoutLable + placeKey}_all`)
                            .timeEnd(`${rbTimeoutLable + placeKey}_${STORAGE}`)
                            .timeEnd(`${rbTimeoutLable}slot-${slotId}_all`)
                            .timeEnd(`${rbTimeoutLable}slot-${slotId}_${STORAGE}`);

                        if (slotId !== slotKey) {
                            radar.timeEnd(`${rbTimeoutLable}slot-${slotKey}_all`).timeEnd(`${rbTimeoutLable}slot-${slotKey}_${STORAGE}`);
                        }

                        useRb = false;
                        updateDollarsRiver(settings);
                    }

                    radar.send();
                    xray.send(['cloud_adv', 'error', slotId]);

                    admanLogger.slotInsertComplete({
                        container: $container[0],
                        params: {
                            slotName: place,
                            slotId,
                            siteZone: sz,
                        },
                        result: {
                            status: 'error',
                            errorThrown,
                        },
                    });
                },
            };

            if (ADQ_TIMEOUT) {
                adqRequestParams.timeout = ADQ_TIMEOUT;
            }

            $.ajax(adqRequestParams);
        } else if (IS_MIMIC_ENABLED) {
            const mimicLabel = 'mimic_';

            radar
                .timeEnd(`${mimicLabel}all`)
                .timeEnd(mimicLabel + STORAGE)
                .timeEnd(`${mimicLabel + placeKey}_all`)
                .timeEnd(`${mimicLabel + placeKey}_${STORAGE}`)
                .timeEnd(`${mimicLabel}slot-${slotId}_all`)
                .timeEnd(`${mimicLabel}slot-${slotId}_${STORAGE}`);

            if (slotId !== slotKey) {
                radar.timeEnd(`${mimicLabel}slot-${slotKey}_all`).timeEnd(`${mimicLabel}slot-${slotKey}_${STORAGE}`);
            }

            radar.send();

            directDom.extractContent(place, 'direct', $container[0]);
            legs(place);
            htmlCode = dollarsCache[settings.place]; // serialize params
            $container.html(htmlCode);

            addClickCounter(place, {
                time: Date.now(),
                placeKey,
                slotId,
                slotKey,
            });

            if (AD_UNBLOKER) {
                disableBlockerStyleSheets();
            }
        }
    } catch (error) {
        logger.error(error);
    }
}

if (!IS_PHONE_BROWSER && !IS_TABLET_BROWSER) {
    reduxStore.subscribe(() => {
        isViewerActive = ViewerSelectors.isViewerActive(reduxStore.getState());
    });
}

export const Rubles = {
    showDirect(id, place, conf, callback) {
        const placeKey = place.replace(/_/g, '-');
        const mimicType = useRb ? 'yandex' : 'mail';
        const radar = RADAR.group(`app_mimic_${mimicType}`);
        const partnerId = id;
        const statId = conf ? conf.stat_id : '';
        const slotId = conf ? conf.slot_id : '';

        const STORAGE = getCurrentStorageForRadar();

        if (IS_PUBLIC) {
            radar.param('id', getPublicRootWeblink(reduxStore.getState()).weblink);
        }

        directDom.setType(place, 'direct');

        MrgContext.insertDirect(id, places[place], conf).then(
            function (status) {
                const regex = /_insert_timeout$/;
                if (regex.test(status)) {
                    directDom.restoreContent(place);
                }
                const statusKey = String(status).replace(/_/g, '-');

                const successLabel = 'success_';

                radar
                    .timeEnd(`${successLabel}all`)
                    .timeEnd(successLabel + STORAGE)
                    .timeEnd(`${successLabel + placeKey}_all`)
                    .timeEnd(`${successLabel + placeKey}_${STORAGE}`)
                    .timeEnd(`${successLabel + statusKey}_all`)
                    .timeEnd(`${successLabel + statusKey}_${STORAGE}`)
                    .timeEnd(`${successLabel + placeKey}_${statusKey}`)
                    .timeEnd(`${successLabel}partner-${partnerId}_all`)
                    .timeEnd(`${successLabel}partner-${partnerId}_${STORAGE}`)
                    .timeEnd(`${successLabel}partner-${partnerId}_stat-${statId}_all`)
                    .timeEnd(`${successLabel}partner-${partnerId}_stat-${statId}_${STORAGE}`)
                    .timeEnd(`${successLabel}partner-${partnerId}_slot-${slotId}_all`)
                    .timeEnd(`${successLabel}partner-${partnerId}_slot-${slotId}_${STORAGE}`)
                    .timeEnd(`${successLabel}partner-${partnerId}_stat-${statId}_slot-${slotId}_all`)
                    .timeEnd(`${successLabel}partner-${partnerId}_stat-${statId}_slot-${slotId}_${STORAGE}`)
                    .send();

                legs(place, {
                    direct: useRb,
                });

                addClickCounter(place, {
                    time: Date.now(),
                    placeKey,
                    mimicType,
                    partnerId,
                    statId,
                });

                // eslint-disable-next-line no-console
                console && console.log(`[${place}] Direct insert success: ${status}`);
                callback && callback(false);
            },
            function (error) {
                // eslint-disable-next-line no-console
                console && console.log(`[${place}] Direct insert error: ${error}`);

                const errorKey = error.replace(/_/g, '-');

                const errorLabel = 'error_';

                radar
                    .timeEnd(`${errorLabel}all`)
                    .timeEnd(errorLabel + STORAGE)
                    .timeEnd(`${errorLabel + placeKey}_all`)
                    .timeEnd(`${errorLabel + placeKey}_${STORAGE}`)
                    .timeEnd(`${errorLabel + errorKey}_all`)
                    .timeEnd(`${errorLabel + errorKey}_${STORAGE}`)
                    .timeEnd(`${errorLabel + placeKey}_${errorKey}`)
                    .timeEnd(`${errorLabel}partner-${partnerId}_all`)
                    .timeEnd(`${errorLabel}partner-${partnerId}_${STORAGE}`)
                    .timeEnd(`${errorLabel}partner-${partnerId}_stat-${statId}_all`)
                    .timeEnd(`${errorLabel}partner-${partnerId}_stat-${statId}_${STORAGE}`)
                    .timeEnd(`${errorLabel}partner-${partnerId}_slot-${slotId}_all`)
                    .timeEnd(`${errorLabel}partner-${partnerId}_slot-${slotId}_${STORAGE}`)
                    .timeEnd(`${errorLabel}partner-${partnerId}_stat-${statId}_slot-${slotId}_all`)
                    .timeEnd(`${errorLabel}partner-${partnerId}_stat-${statId}_slot-${slotId}_${STORAGE}`)
                    .send();

                callback && callback(true);
            }
        );
    },

    update404Content() {
        updateDollarsRiver({
            slot: 828554,
            ignoreShove: true,
            place: 'dom404',
            params: {
                _SITEID,
            },
        });
    },

    updateRollContent({ onError, onSuccess } = {}) {
        try {
            updateDollarsRiver({
                slot: 794675,
                place: 'roll',
                onError,
                onSuccess,
                params: {
                    _SITEID,
                },
            });
        } catch (error) {
            captureException(error);
        }
    },

    runPhoneTopBannerUpdater({ onError } = {}) {
        const featureName = 'mobile-public-ad';

        if (!IS_PUBLIC || !features.isFeature(featureName) || !IS_PHONE_BROWSER) {
            return;
        }
        const webviewSlot = features.getFeatureParam(featureName, 'webviewSlot');
        const defaultSlot = features.getFeatureParam(featureName, 'defaultSlot', 3333);

        if ((IS_WEBVIEW && webviewSlot) || (!IS_WEBVIEW && defaultSlot)) {
            $(directDom.getElementByPlace('phoneTop')).show();

            startAdsUpdateTime(() =>
                updateDollarsRiver({
                    slot: IS_WEBVIEW ? webviewSlot : defaultSlot,
                    place: 'phoneTop',
                    params: {
                        _SITEID,
                    },
                    onSuccess: () => {
                        reduxStore.dispatch(advUpdated(EAdvType.phoneTopBanner));
                    },
                })
            );
        }
    },

    updateWorm() {
        updateDollarsRiver({
            slot: 7231,
            place: 'worm',
            params: {
                sz: IS_PUBLIC ? ESiteZone.folderPublic : ESiteZone.allPrivate,
            },
        });
    },

    updateViewerAttachesWorm(expId) {
        const featureName = 'new-viewer-worm';
        const slot = features.getFeatureParam(featureName, 'slot', 326442);
        const place = features.getFeatureParam(featureName, 'place', 'worm_nv');
        const exp_id = expId || features.getFeatureParam(featureName, 'exp_id', 327);

        updateDollarsRiver({
            slot,
            place,
            params: {
                exp_id,
            },
        });
    },

    updateIndiPublicWorm() {
        updateDollarsRiver({
            slot: 7231, // cloudweb-4753
            place: 'worm',
            params: {
                sz: ESiteZone.indiPublic,
            },
        });
    },

    updateViewerPublicBottomWorm() {
        updateDollarsRiver({
            slot: 7231, // cloudweb-4753
            place: 'worm',
            params: {
                sz: ESiteZone.viewerPublic,
            },
        });
    },

    updateAdvAfterDatalist(width = null, onSuccess) {
        updateDollarsRiver({
            slot: 939728,
            place: 'after_dl',
            params: {
                w: width,
                sz: IS_PUBLIC ? ESiteZone.folderPublic : ESiteZone.allPrivate,
            },
            onSuccess,
        });
    },

    updateAdvPhonePublicInsideDatalist() {
        updateDollarsRiver({
            slot: 988369,
            place: 'inside_dl',
        });
    },

    updateErrorPageRightColAdv(siteZone) {
        startAdsUpdateTime(() =>
            updateDollarsRiver({
                slot: 1683,
                place: 'err_page',
                params: {
                    sz: siteZone,
                },
            })
        );
    },

    updateErrorPagePhoneTopAdv(siteZone) {
        const featureName = 'mobile-public-ad';
        const webviewSlot = features.getFeatureParam(featureName, 'webviewSlot');
        const defaultSlot = features.getFeatureParam(featureName, 'defaultSlot', 3333);

        startAdsUpdateTime(() =>
            updateDollarsRiver({
                slot: IS_WEBVIEW ? webviewSlot : defaultSlot,
                place: 'err_page_m',
                params: {
                    _SITEID,
                    sz: siteZone,
                },
            })
        );
    },

    updateMobSplash() {
        updateDollarsRiver({
            slot: 742247,
            place: 'mobSplash',
            params: {
                _SITEID,
            },
        });
    },

    updateRightCol() {
        updateDollarsRiver({
            slot: 1683,
            place: 'rightcol',
            params: {
                h: null,
            },
        });
    },
};
