import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import { resetFace } from 'reactApp/modules/faces/faces.module';
import { filesFilterAll } from 'reactApp/modules/filesFilter/filesFilter.module';
import { downloadMobileItem } from 'reactApp/modules/modifying/modifying.actions';
import { getSort } from 'reactApp/modules/sort/sort.selectors';
import { ESortOder } from 'reactApp/modules/sort/sort.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { RootState } from 'reactApp/store';
import { renderAuthModalDialog } from 'reactApp/ui/Mobile/AuthModal/AuthModal.helpers';

export const useCallbacks = ({ onClose, id, sendAnalytics, storage, isAppViewer }) => {
    const dispatch = useDispatch();

    const isAnonymous = useSelector(UserSelectors.isAnonymous);

    const sort = useSelector((state: RootState) => getSort(state, { id }, storage));

    const resetFilter = useCallback(() => {
        dispatch(filesFilterAll());
        dispatch(resetFace());
        onClose();
    }, [dispatch, onClose]);

    const onDownload = useCallback(() => {
        if (isAppViewer) {
            window.CloudApp?.downloadFile?.();
        } else {
            dispatch(downloadMobileItem({ id, storage }));
        }
        sendAnalytics?.({ action: 'download-content' });
    }, [dispatch, id, sendAnalytics, storage, isAppViewer]);

    const onUpload = useCallback(() => {
        if (isAnonymous) {
            renderAuthModalDialog();
        }
    }, [isAnonymous]);

    const changeSortOrder = useCallback(() => {
        toolbarActions.sort(`${sort.type}-${sort.order === ESortOder.asc ? ESortOder.desc : ESortOder.asc}`);
        onClose();
    }, [onClose, sort.order, sort.type]);

    return {
        changeSortOrder,
        onUpload,
        onDownload,
        resetFilter,
    };
};
