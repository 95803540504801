import { createElement } from 'react';
import ReactDom from 'react-dom';

import { QuotaCleanerYearFilterPromo } from './QuotaCleanerYearFilterPromo';

export const quotaCleanerYearFilterPromoId = 'quota-cleaner-year-filter';
export const quotaCleanerYearFilterKey = 'quota-cleaner-year-filter-promo';

export const showQuotaCleanerYearFilterPromo = (storeValue: number | null, onHide: () => void, isMobile?: boolean) => {
    const target = document.querySelector(`[data-promo-id="${quotaCleanerYearFilterPromoId}"]`);
    const container = document.querySelector('[data-promo-target="quota-cleaner-toolbar"]');
    const targetYear = document.querySelector('[data-promo-target="quota-target-year"]');

    const targetListeners = [targetYear];

    if (!target || !container) {
        return;
    }

    const yearFilterPromo = createElement(QuotaCleanerYearFilterPromo, { target, targetListeners, storeValue, onHide, isMobile }, null);
    ReactDom.render(yearFilterPromo, container);
};
