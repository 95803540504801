import localState from 'lib/store';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsActiveBookmark, setOptionsConfig } from 'reactApp/modules/ebook/ebook.module';
import { getEbookConfig } from 'reactApp/modules/ebook/ebook.selectors';
import { EbookType } from 'reactApp/modules/ebook/ebook.types';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getFeatureViewerEbookExtraTags } from 'reactApp/modules/features/features.selectors';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { CloudFile } from 'reactApp/modules/storage/storage.types';
import { EEpubPostMessageType, mapPostMessageTypeToRadar } from 'reactApp/ui/ViewerEbook/postMessage.types';
import { sendDwh, sendXray } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

const radarNameMap = {
    epub: {
        RADAR_NAME: 'viewer-ebook',
        RADAR_NAME_IFRAME: 'viewer-ebook-iframe',
    },
    fb2: {
        RADAR_NAME: 'viewer-fb2-book',
        RADAR_NAME_IFRAME: 'viewer-fb2-book-iframe',
    },
    djvu: {
        RADAR_NAME: 'viewer-djvu-book',
        RADAR_NAME_IFRAME: 'viewer-djvu-book-iframe',
    },
};

interface Props {
    postChanelId: string;
    iframeSrc?: string;
    file: CloudFile;
    content?: ArrayBuffer;
    email: string;

    clearRenderTimeout(): void;

    showUIAndSetupTimer(): void;

    setIsRenderBookError(val: boolean): void;
}

export const usePostMessage = ({
    iframeSrc,
    file,
    email,
    clearRenderTimeout,
    postChanelId,
    content,
    showUIAndSetupTimer,
    setIsRenderBookError,
}: Props) => {
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const url = useRef<string>('');

    const dispatch = useDispatch();

    const [isFrameLoaded, setIsFrameLoaded] = useState(false);
    const [isReady, setIsReady] = useState(false);

    const isMobile = useSelector(EnvironmentSelectors.isMobile);
    const extraAllowedTags: ReturnType<typeof getFeatureViewerEbookExtraTags> = useSelector(getFeatureViewerEbookExtraTags);

    const config = useSelector(getEbookConfig);

    const key = useMemo(() => `${email}|ebooksettings`, [email]);

    const ext = file?.ext?.toLowerCase();
    const type = useMemo(() => {
        if (ext === 'epub') {
            return EbookType.epub;
        }
        if (ext === 'djvu' || ext === 'djv') {
            return EbookType.djvu;
        }
        if (ext === 'fb2') {
            return EbookType.fb2;
        }
    }, [ext]);

    const handleBookmark = (isActiveBookmark) => {
        if (!url?.current) {
            return;
        }

        const dwhData = {
            place: file?.storage,
            extension: file?.ext,
            is_bookmarked: Boolean(!isActiveBookmark),
        };

        sendDwh({
            eventCategory: ECategoryGa.viewer,
            action: 'bookmarks',
            dwhData,
        });

        dispatch(
            showSnackbarAction({
                id: 'bookmark',
                closable: true,
                text: isActiveBookmark ? 'Закладка удалена' : 'Закладка добавлена',
                type: SnackbarTypes.success,
            })
        );

        iframeRef?.current?.contentWindow?.postMessage(
            {
                type: EEpubPostMessageType.bookmark,
                chanelId: postChanelId,
            },
            url.current
        );
    };

    useEffect(() => {
        const prev = localState.get(key);

        if (prev) {
            dispatch(setOptionsConfig(prev));
        }
    }, [key]);

    useEffect(() => {
        if (!url?.current) {
            return;
        }

        localState.set(key, config);

        iframeRef?.current?.contentWindow?.postMessage(
            {
                type: EEpubPostMessageType.config,
                chanelId: postChanelId,
                props: config,
            },
            url.current
        );
    }, [config, key, url?.current]);

    const { RADAR_NAME, RADAR_NAME_IFRAME } = radarNameMap[file.ext];

    useEffect(() => {
        if (!isFrameLoaded || !iframeSrc) {
            return;
        }
        const iframeUrl = new URL(iframeSrc);

        url.current = iframeUrl.origin;

        iframeRef?.current?.contentWindow?.postMessage(
            {
                type: EEpubPostMessageType.sender,
                chanelId: postChanelId,
                props: {
                    content,
                    isMobile,
                    email,
                    extraAllowedTags,
                    filePath: file.id,
                    fileHash: 'hash' in file ? file.hash : file.id,
                    type,
                },
            },
            iframeUrl.origin
        );
    }, [
        content,
        isFrameLoaded,
        postChanelId,
        /** iframeSrc должен быть статичным */
        /** extraAllowedTags должен быть статичным */
        /** isMobile должен быть статичным */
    ]);

    useEffect(() => {
        const onMessageHandler = ({ data, origin }) => {
            /**
             * FIXME Брать origin из настроек, пока статично
             */
            if (
                data?.chanelId !== postChanelId ||
                !(
                    ['https://static.mail.corpcloud.devmail.ru', 'https://cloud.imgsmail.ru'].includes(origin) ||
                    /cloud\d*\.(devmail|mini-mail)\.ru/.test(origin)
                )
            ) {
                return;
            }

            switch (data.type) {
                case EEpubPostMessageType.globalError:
                    setIsRenderBookError(true);
                    break;
                case EEpubPostMessageType.error:
                    setIsRenderBookError(true);
                    break;
                case EEpubPostMessageType.ready:
                    clearRenderTimeout();
                    setIsReady(true);
                    showUIAndSetupTimer();
                    break;
                case EEpubPostMessageType.init:
                    setIsFrameLoaded(true);
                    break;
                case EEpubPostMessageType.xray:
                    if (data.xrayData) {
                        sendXray(RADAR_NAME_IFRAME, data.xrayData);
                    }
                    break;
                case EEpubPostMessageType.onShowUI:
                    showUIAndSetupTimer();
                    break;
                case EEpubPostMessageType.activeBookmark:
                    dispatch(setIsActiveBookmark(data.data.value));
                    break;
                case EEpubPostMessageType.locationChanged:
                    storeHelper.setValue('ebooklast', data.data.value);
                    break;
                case EEpubPostMessageType.deleteBookmark:
                    dispatch(
                        showSnackbarAction({
                            id: 'bookmark',
                            closable: true,
                            text: 'Закладка удалена',
                            type: SnackbarTypes.success,
                        })
                    );
                    break;
            }

            if (mapPostMessageTypeToRadar[data.type]) {
                sendXray(RADAR_NAME, {
                    [isMobile ? 'mobile' : 'desktop']: 1,
                    [mapPostMessageTypeToRadar[data.type]]: 1,
                });
            }
        };

        window.addEventListener('message', onMessageHandler);

        return () => window.removeEventListener('message', onMessageHandler);
    }, [isMobile, postChanelId, clearRenderTimeout, showUIAndSetupTimer]);

    return { isFrameLoaded, isReady, iframeRef, theme: config.theme, handleBookmark };
};
