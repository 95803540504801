import { Spacing, Title } from '@vkontakte/vkui';
import classNames from 'clsx';
import { ReactComponent as MarketingPromoLabel } from 'img/marketingPromo/blackFridayLabel2023.svg';
import React, { memo, ReactElement, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isFamilySubsInTariff } from 'reactApp/appHelpers/featuresHelpers';
import { useGoToSubscriptions } from 'reactApp/hooks/useGoToSubscriptions';
import { getAbFastCheckoutLink } from 'reactApp/modules/features/features.selectors';
import { PROMO_TARIFFS_REGEX } from 'reactApp/modules/products/products.helpers';
import { Product, Tariff } from 'reactApp/types/Billing';
import Checkbox from 'reactApp/ui/CheckBox/CheckBox';
import { DiscountLabel } from 'reactApp/ui/DiscountLabel/DiscountLabel';
import { Switch } from 'reactApp/ui/Switch/Switch';
import { getDiscount } from 'reactApp/utils/getDiscount';
import { getPeriodName, getTariffProductsByPeriods, isMonth6Period, isMonthPeriod, isYearPeriod } from 'reactApp/utils/Period';
import { formatPrice, getMonthProductPrice } from 'reactApp/utils/priceHelpers';

import styles from './FastCheckoutSidebar.css';

export const FastCheckoutSidebar = memo(
    ({
        tariff,
        product,
        onProductChange,
        onClickTariffs,
        isMarketingPromo,
    }: {
        tariff: Tariff;
        product: Product;
        onProductChange: (id: string) => void;
        onClickTariffs: () => void;
        isMarketingPromo?: boolean;
    }): ReactElement => {
        const { goToSubscriptions } = useGoToSubscriptions();

        // tempexp_15071-next-line
        const abPromoQuotaLink = useSelector(getAbFastCheckoutLink);

        // tempexp_14791-start
        // в фастчекауте пока не учитываем 2годовые тарифы
        const hasTwoYearProduct = false;

        const isPro = (product?.isProfessional && !product?.isSingleQuota) || (product?.isSingleQuota && product.hasDiskO);

        const { yearProduct, monthProduct } = getTariffProductsByPeriods(tariff?.products, isPro, hasTwoYearProduct);
        // tempexp_14791-end

        const { space, discountPeriod, period, discountPrice, price, hasDiscount, hasTrial, trialPeriod } = product;

        const { roundedDiscount } = getDiscount({ discountPrice: discountPrice || 0, price });

        const isYear = isYearPeriod(period);

        const isActionProduct = isMarketingPromo && PROMO_TARIFFS_REGEX.marketingPromo.test(product?.id);

        const switchChange = useCallback(() => {
            if (monthProduct && yearProduct) {
                onProductChange(isYear ? monthProduct.id : yearProduct.id);
            }
        }, [isYear, monthProduct, onProductChange, yearProduct]);

        const clickTariffs = useCallback(() => {
            onClickTariffs();

            // tempexp_15071-next-line
            goToSubscriptions({ inSameTab: false, isNewTariffsPage: abPromoQuotaLink });
        }, [onClickTariffs, abPromoQuotaLink, goToSubscriptions]);

        const priceBlock = useMemo(() => {
            if (!isActionProduct && hasDiscount && discountPrice && discountPeriod) {
                return (
                    <div className={styles.discountBlock}>
                        <div className={styles.oldPrice}>
                            {formatPrice(price)} ₽/{getPeriodName(period)}
                        </div>
                        <div className={styles.price}>
                            {formatPrice(discountPrice)} ₽/{getPeriodName(discountPeriod)}
                        </div>
                    </div>
                );
            }

            if (isYearPeriod(period) || isMonth6Period(period)) {
                return (
                    <div className={styles.priceBlock}>
                        <div className={styles.priceInfo}>
                            Подписка на {getPeriodName(period)} по цене {formatPrice(discountPrice || price)}&nbsp;₽
                        </div>
                        <div className={styles.price}>{formatPrice(getMonthProductPrice(product))} ₽/месяц</div>
                    </div>
                );
            }

            if (hasTrial && trialPeriod) {
                return (
                    <div className={styles.priceBlock}>
                        <div className={classNames(styles.priceInfo, styles.trialPriceInfo)}>
                            {getPeriodName(trialPeriod)} бесплатно, далее {formatPrice(price)} ₽ в {getPeriodName(period)}
                        </div>
                        <div className={styles.price}>Бесплатно</div>
                    </div>
                );
            }

            if (isMonthPeriod(period)) {
                return (
                    <div className={styles.priceBlock}>
                        <div className={styles.priceInfo}>Подписка на месяц по цене</div>
                        <div className={styles.price}>{formatPrice(price)} ₽/месяц</div>
                    </div>
                );
            }
        }, [discountPeriod, discountPrice, hasDiscount, period, price, product]);

        const yearSwitch = useCallback(
            (isRight: boolean) => (
                <div
                    className={classNames({
                        [styles.switchLabel]: true,
                        [styles.switchLabel_right]: isRight,
                        [styles.switchLabel_active]: isYear,
                        [styles.switchLabelAction_active]: isYear && isActionProduct,
                    })}
                >
                    На год
                </div>
            ),
            [isActionProduct, isYear]
        );

        const monthSwitch = useCallback(
            (isRight: boolean) => (
                <div
                    className={classNames({
                        [styles.switchLabel]: true,
                        [styles.switchLabel_right]: isRight,
                        [styles.switchLabel_active]: !isYear,
                    })}
                >
                    На {getPeriodName(monthProduct?.period)}
                </div>
            ),
            [isYear, monthProduct?.period]
        );

        // tempexp_14791-start
        const switchBlock = useMemo(
            () =>
                tariff?.products?.length > 1 &&
                monthProduct && (
                    <div className={styles.switch} onClick={switchChange}>
                        {isMarketingPromo ? (
                            <>
                                {monthSwitch(false)}
                                <Switch
                                    isAlwaysWhite
                                    name={`tariff_${product.id}`}
                                    checked={isYear}
                                    theme="big"
                                    isAction={isActionProduct}
                                />
                                {yearSwitch(true)}
                                <div className={styles.actionDiscountLabel}>
                                    <MarketingPromoLabel />
                                </div>
                            </>
                        ) : (
                            <>
                                {yearSwitch(false)}
                                <Switch isAlwaysWhite name={`tariff_${product.id}`} checked={!isYear} theme="big" />
                                {monthSwitch(true)}
                            </>
                        )}
                    </div>
                ),
            [isYear, monthProduct, monthSwitch, product.id, switchChange, tariff?.products?.length, yearSwitch]
        );
        // tempexp_14791-end

        const radioBlock = useMemo(
            () =>
                tariff?.products?.length > 1 &&
                yearProduct &&
                monthProduct && (
                    <>
                        <div className={styles.radioBlock} onClick={switchChange}>
                            <div className={styles.radio}>
                                <Checkbox selected={!isYear} mod="round" />
                            </div>
                            <Title level="3" weight="2">
                                {formatPrice(getMonthProductPrice(monthProduct))} ₽ в месяц{' '}
                            </Title>
                        </div>
                        <Spacing size={16} />
                        <div className={styles.radioBlock} onClick={switchChange}>
                            <div className={styles.radio}>
                                <Checkbox selected={isYear} mod="round" />
                            </div>
                            <div>
                                <div className={styles.twoYearTitle}>
                                    <Title level="3" weight="2">
                                        {formatPrice(getMonthProductPrice(yearProduct))} ₽ в месяц{' '}
                                    </Title>
                                    <div className={styles.discountLabel}>
                                        <DiscountLabel tariff={tariff} />
                                    </div>
                                </div>

                                <div className={styles.productInfo}>
                                    купить на {getPeriodName(yearProduct.period)} за {formatPrice(yearProduct.price)} ₽
                                </div>
                            </div>
                        </div>
                        <Spacing size={42} />
                    </>
                ),
            [isYear, monthProduct, switchChange, tariff?.products?.length, yearProduct]
        );

        const featuresList = useMemo(() => {
            const autodeleteText = 'Автоудаление папок';

            if (tariff.isSingleQuota) {
                const base = [
                    'Без рекламы в\u00A0Почте\u00A0и\u00A0Облаке',
                    'Загрузка файлов до 100 ГБ в\u00A0Облако',
                    'Отправка файлов до 100 ГБ в\u00A0письмах',
                ];

                return isFamilySubsInTariff
                    ? [...base, 'Делитесь местом с близкими', autodeleteText]
                    : [...base, autodeleteText, 'Приоритетная поддержка'];
            }

            return ['Никакой рекламы', 'Загрузка файлов до 32 ГБ', autodeleteText, 'Приоритетная поддержка'];
        }, [tariff.isSingleQuota]);

        return (
            <div className={styles.root}>
                <div
                    className={classNames(styles.tariffInfo, {
                        [styles.tariffInfo_action]: isActionProduct,
                    })}
                >
                    {!isActionProduct && hasDiscount && !!roundedDiscount && <div className={styles.label}>Скидка {roundedDiscount}%</div>}
                    <div className={styles.header}>
                        <div className={styles.quota}>+{space.space}</div>
                        <div className={styles.units}>{space.units}</div>
                    </div>

                    {/* tempexp_14791-start */}
                    {hasTwoYearProduct ? (
                        radioBlock
                    ) : (
                        <>
                            {switchBlock}
                            {priceBlock}
                        </>
                    )}
                    {/* tempexp_14791-end */}

                    <ol
                        className={classNames(styles.features, {
                            [styles.features_action]: isActionProduct,
                        })}
                    >
                        {featuresList.map((text, index) => (
                            <li key={index}>{text}</li>
                        ))}
                    </ol>
                </div>
                <div className={styles.tariffsLink} onClick={clickTariffs}>
                    Другие тарифы
                </div>
            </div>
        );
    }
);

FastCheckoutSidebar.displayName = 'FastCheckoutSidebar';
