define(function (require, exports, module) {
	'use strict';

	var apiError = {
		aliases: {
			name_length_exceeded: 'name_too_long',
			quota_exceeded: 'overquota',
			readonly: 'read_only',
			exists: 'file_exists'
		},

		getRawCode: function (anything) {
			if (typeof anything == 'object') {
				var home = anything.home;

				if (typeof home == 'object') {
					return home.error;
				}

				var name = anything.name;

				if (typeof name == 'object') {
					return name.error;
				}

				var body = anything.body;

				if (body != null) {
					return body;
				}
			}

			return anything;
		},

		getCode: function (anything) {
			var code = this.getRawCode(anything);

			if (typeof code == 'string') {
				var alias = this.aliases[code];

				if (alias) {
					return alias;
				}

				return code;
			}

			return 'unknown';
		}
	};

	module.exports = apiError;
});
