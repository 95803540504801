import React, { memo, ReactElement } from 'react';

import styles from './SubscriptionInfoBlock.css';

interface IProps {
    title: string;
}

export const SubscriptionInfoBlock: React.FC<IProps> = memo(
    ({ title, children }): ReactElement => {
        return (
            <div className={styles.root}>
                <div className={styles.title}>{title}</div>
                <div className={styles.info}>{children}</div>
            </div>
        );
    }
);

SubscriptionInfoBlock.displayName = 'SubscriptionInfoBlock';
