import classNames from 'clsx';
import myOfficeIcon from 'img/icons/editor/MyOffice_Logo-T.svg';
import React, { HTMLProps, ReactEventHandler, useEffect, useRef, VFC } from 'react';
import { MyOfficeBusinessMetric, sendMyOfficeMetric } from 'reactApp/ui/ReactViewer/helpers/sendMyOfficeMetric';
import { sendXray } from 'reactApp/utils/ga';

import styles from '../../EditorHeader.css';
import { ReactComponent as AdIcon } from '../Ad.svg';

interface EditorHeaderButtonProps extends Omit<HTMLProps<HTMLDivElement>, 'className' | 'onClick'> {
    radarName: string;
    onClickAd: ReactEventHandler<HTMLDivElement>;
    onClick: ReactEventHandler<HTMLDivElement>;
    showPromoRating?: boolean;
    fileExt?: string;
}

export const EditorHeaderButton: VFC<EditorHeaderButtonProps> = ({
    radarName,
    onClick,
    onClickAd,
    showPromoRating,
    fileExt = '',
    ...htmlDivProps
}) => {
    const buttonTitleRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        sendXray(radarName, { show: 1 });
        sendMyOfficeMetric(MyOfficeBusinessMetric.mention, { i: { [fileExt]: 1 } });
    }, [radarName]);

    return (
        <div
            className={classNames(styles.buttonBlock, styles['buttonBlock_theme-myOffice'])}
            {...htmlDivProps}
            onClick={(e) => {
                /**
                 * На экранах больше 1050 по требованию дизайна клик должен засчитываться только по buttonTitle
                 * На экранах меньше 1050 клик засчитывается по всей области
                 * Это связано с тем, что на экранах 1050 кнопка сворачивается в иконку, а у нас очень кастомная кнопка под промо
                 */
                if (window.innerWidth >= 1050 && e.target !== buttonTitleRef.current) {
                    return;
                }

                sendXray(radarName, { click: 1 });
                sendMyOfficeMetric(MyOfficeBusinessMetric.openPartner, { i: { [fileExt]: 1 } });
                onClick?.(e);
            }}
        >
            <div className={classNames(styles.button, styles.button_size, styles['button_theme-myOffice'])}>
                <div className={styles.icon} title="Установить редакторы">
                    <img height={24} src={myOfficeIcon} alt="Мой Офис" />
                </div>
                <div ref={buttonTitleRef} className={styles.buttonTitle}>
                    Установить редакторы
                </div>
            </div>
            {showPromoRating && (
                <div
                    className={styles.adIcon}
                    onClick={(e) => {
                        onClickAd(e);
                        sendXray(radarName, { 'click-ad': 1 });
                        e.stopPropagation();
                    }}
                >
                    <AdIcon />
                </div>
            )}
        </div>
    );
};
