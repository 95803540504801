import { useIntersectionObserver } from 'reactApp/hooks/useInterSectionObserver';

const mobileThresholds = [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9];
const desktopThreshold = 0.5;

export const useIntersecObsrWithParams = ({
    onIntersecting,
    isMobile,
    headerHeight,
}: {
    onIntersecting: () => void;
    isMobile: boolean;
    headerHeight?: number;
}) =>
    useIntersectionObserver({
        onIntersecting,
        rootMargin: `${headerHeight || 0}px`,
        threshold: isMobile ? mobileThresholds : desktopThreshold,
    });
