import { Icon24DownloadOutline } from '@vkontakte/icons';
import { Spacing } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { memo, ReactElement, useCallback, useEffect, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { DOWNLOAD_DISK_O_PROMO_CONFIG, ENABLE_FULL_RESPONSIVE, IS_BLOCKED } from 'reactApp/appHelpers/configHelpers';
import { abSafeFakeDoorSelector, isAllDocumentsHelpButton, isNewPortalHeader } from 'reactApp/appHelpers/featuresHelpers';
import { chooseVariant } from 'reactApp/appHelpers/featuresHelpers/utils';
import { HelpButton } from 'reactApp/components/HelpButton/HelpButton';
import { BREAKPOINT_MD } from 'reactApp/constants/breakpoints';
import { useMinWidthBreakpoint } from 'reactApp/hooks/responsiveness/useMinWidthBreakpoint';
import { useGoToSubscriptions } from 'reactApp/hooks/useGoToSubscriptions';
import { usePortal } from 'reactApp/hooks/usePortal';
import { ActionPanelSelectors } from 'reactApp/modules/actionpanel/actionpanel.selectors';
import { getFeatureAbMailAttachesAfterClose } from 'reactApp/modules/features/features.selectors';
import { changeHomeHistory } from 'reactApp/modules/home/home.actions';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { historyPush } from 'reactApp/modules/router/router.module';
import { selectStatusPage } from 'reactApp/modules/router/router.selectors';
import { sidebarToggle } from 'reactApp/modules/settings/settings.module';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import { getIdByStorage, getStorage } from 'reactApp/modules/storage/storage.helpers';
import { groupedIds, isRootCurrentFolder } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { getAllowedMimeTypes } from 'reactApp/modules/upload/upload.selectors';
import { useUploadInputHandlers } from 'reactApp/modules/uploading/hooks/useUploadInputHandlers';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { ErrorPage } from 'reactApp/sections/ErrorPage/ErrorPage';
import { useRouteChangeProcessing } from 'reactApp/sections/MainPage/hooks/useRouteChangeProcessing';
import { RootState } from 'reactApp/store';
import { EHeaderColor } from 'reactApp/ui/Header/HeaderWrapper';
import { Space } from 'reactApp/ui/Space/Space';
import { AddToolbar } from 'reactApp/ui/Toolbar/AddToolbar';
import { ToolbarNew } from 'reactApp/ui/Toolbar/Toolbar.new';
import { ToolbarItem } from 'reactApp/ui/Toolbar/ToolbarItem/ToolbarItem';
import { TreeConnected } from 'reactApp/ui/TreeComponent/TreeComponent';
import { ETreeRootIds } from 'reactApp/ui/TreeComponent/TreeComponent.constants';
import { loadFolder } from 'reactApp/ui/TreeComponent/TreeComponent.helpers';
import { TreePromo } from 'reactApp/ui/TreePromo/TreePromo';
import { UploadBlock } from 'reactApp/ui/UploadBlock/UploadBlock';
import { UploadInput } from 'reactApp/ui/UploadInput/UploadInput';
import { ChevronLeftOutlineIcon } from 'reactApp/ui/VKUIIcons';
import { usePHSearchBlock } from 'reactApp/ui/WebSearch/helpers';
import { Worm } from 'reactApp/ui/Worm/Worm';
import { createGaSender, sendGa as sendGaHelper } from 'reactApp/utils/ga';
import { scrollToTop } from 'reactApp/utils/helpers';
import { ESafeFakedoorAnalytics, safeFakedoorAnalytics } from 'reactApp/utils/safeFakedoorGa';

import { useDiskOTreePromo } from './hooks/useDiskOTreePromo';
import styles from './MainPage.css';

const sendGa = createGaSender('main');

interface Props {
    children: ReactElement | null;
    storage?: EStorageType;
    showUploader?: boolean;
    allowDnd?: boolean;
    renderWorm?: boolean;
    showToolbar?: boolean;
    showSpace?: boolean;
    showTree?: boolean;
    showBackHomeButton?: boolean;
}

export const MainPage = memo(
    // eslint-disable-next-line max-lines-per-function
    ({
        children,
        storage,
        showUploader = true,
        allowDnd = true,
        renderWorm = false,
        showToolbar = true,
        showSpace = true,
        showTree = true,
        showBackHomeButton = false,
    }: Props): ReactElement | null => {
        const dispatch = useDispatch();
        const location = useLocation();
        const params = useParams();
        const [largeLBreakpointHit] = useMinWidthBreakpoint(BREAKPOINT_MD);

        const isNewbie = useSelector(UserSelectors.isNewbie);
        const isAnonymous = useSelector(UserSelectors.isAnonymous);
        const isFrozen = useSelector(UserSelectors.isFrozen);
        const statusPage = useSelector(selectStatusPage);
        const isSidebarOpened = useSelector(SettingsSelectors.isSidebarOpened);
        const allowedMimeTypes = useSelector(getAllowedMimeTypes)?.join(',');
        const isActionPanelOpened = useSelector(ActionPanelSelectors.isActionPanelOpened);

        // tempexp-15946-start
        const abMailAttachesAfterClose = useSelector(getFeatureAbMailAttachesAfterClose);
        const gotoForNewbie = isNewbie && abMailAttachesAfterClose !== 'b';
        // tempexp-15946-end

        const uploadPortalTarget = usePortal('uploadAreaRoot');
        const helpButtonPortalTarget = usePortal('helpButtonRoot');

        const { subscribeToInput, unsubscribeOfInput, processDrop } = useUploadInputHandlers();
        const leftBlockWrapperRef = useRef<HTMLDivElement | null>(null);

        const { showDiskOTreePromo, handleDiskOTreePromoShow, handleDiskOTreePromoClick, handleDiskOTreePromoClose } = useDiskOTreePromo();

        const { isHome, isReactPage, isAttaches, isIntegration, isAllDocuments, isDocuments } = getStorage(storage as EStorageType);

        const closeSidebarHandler = useCallback(() => dispatch(sidebarToggle(false)), [dispatch]);

        usePHSearchBlock();

        useEffect(() => {
            closeSidebarHandler();
        }, [largeLBreakpointHit, closeSidebarHandler]);

        const handleMenuClick = useCallback(
            ({ id, storage, href, level }) => {
                const domainBizFoldersSection = id === ETreeRootIds.domain && level === 0;

                if (domainBizFoldersSection) {
                    return;
                }

                if (
                    chooseVariant(
                        abSafeFakeDoorSelector,
                        { control: false, variant2: id === ETreeRootIds.safe },
                        { skipCondition: { variant1: true } }
                    )
                ) {
                    safeFakedoorAnalytics(ESafeFakedoorAnalytics.CLICK, { entity: 'chapter' });

                    openPopupHelper({
                        popupName: popupNames.SAFE_FAKEDOOR,
                    });

                    return;
                }

                const { isReactPage, isStart, isHome, isIntegration } = getStorage(storage);

                if (isReactPage || isStart) {
                    if (isHome || isIntegration) {
                        // для storage === integration критически важно не терять гет параметры при навигации
                        dispatch(changeHomeHistory({ id, search: location.search }));
                    } else {
                        dispatch(historyPush({ id }));
                    }
                    return;
                }

                const homeItemId = (storage === EStorageType.home && id) || '';
                const fullPath = `/${storage}${homeItemId}${location.search ?? ''}`;

                window.location = href || fullPath;
            },
            [location]
        );

        const { goToSubscriptions } = useGoToSubscriptions();

        const handleOnMenuExpand = useCallback(({ id, storage, dispatch }) => {
            loadFolder({ id, storage, dispatch });
        }, []);

        useEffect(() => {
            scrollToTop();

            sendGa('show');
            sendGaHelper('lm', 'show');
        }, []);

        useRouteChangeProcessing({ storage, path: location.pathname, search: location.search });

        useEffect(() => {
            if (isReactPage && !isHome && !isIntegration && !isAttaches && !isAllDocuments && (isAnonymous || isFrozen || gotoForNewbie)) {
                open(isAnonymous ? '/promo' : '/home', '_self');
            }
        }, [isFrozen, isAnonymous, storage, isReactPage, isHome, isAttaches, isIntegration, isAllDocuments, gotoForNewbie]);

        useEffect(() => {
            const classes = classNames(styles.container, {
                [styles.responsive]: ENABLE_FULL_RESPONSIVE,
            }).split(' ');
            uploadPortalTarget.classList.remove(...classes);
            if (isActionPanelOpened) {
                uploadPortalTarget.classList.add(...classes);
            }
        }, [uploadPortalTarget, isActionPanelOpened]);

        const leftBlock = useMemo(
            () => (
                <div
                    className={classNames(styles.leftBlock, {
                        [styles.responsive]: ENABLE_FULL_RESPONSIVE,
                    })}
                >
                    {showToolbar && (
                        <div
                            className={classNames(styles.toolbar, {
                                [styles.responsive]: ENABLE_FULL_RESPONSIVE,
                                [styles.toolbar_new_portal_header]: isNewPortalHeader.client,
                            })}
                        >
                            {ENABLE_FULL_RESPONSIVE && (
                                <ToolbarItem
                                    id="toolbar-collapse-item"
                                    text="Свернуть"
                                    icon={<ChevronLeftOutlineIcon />}
                                    onClick={closeSidebarHandler}
                                />
                            )}
                            <AddToolbar />
                        </div>
                    )}
                    {showBackHomeButton && (
                        <div className={styles.backButtonBlock}>
                            <RouterLink to={getIdByStorage(EStorageType.home)} reloadDocument>
                                <ToolbarItem className={styles.backButton} id="back" text="Вернуться" icon={<ChevronLeftOutlineIcon />} />
                            </RouterLink>
                        </div>
                    )}
                    {showSpace && (
                        <Space
                            goToSubscriptions={goToSubscriptions}
                            className={classNames({ [styles.spacePromo_new_portal_header]: isNewPortalHeader.client })}
                        />
                    )}
                    {showDiskOTreePromo && DOWNLOAD_DISK_O_PROMO_CONFIG?.treePromoLink && (
                        <TreePromo
                            text="Скачать Облако для ПК"
                            icon={<Icon24DownloadOutline />}
                            href={DOWNLOAD_DISK_O_PROMO_CONFIG?.treePromoLink}
                            onShow={handleDiskOTreePromoShow}
                            onClick={handleDiskOTreePromoClick}
                            onClose={handleDiskOTreePromoClose}
                        />
                    )}
                    {/* если не отображаем ничего из вышеперечисленного, то хотя бы добавим отступ */}
                    {!showToolbar && !showSpace && !showDiskOTreePromo && <Spacing size={24} />}
                    {showTree && (
                        <div
                            className={classNames(styles.treeWrapper, {
                                [styles.responsive]: ENABLE_FULL_RESPONSIVE,
                                [styles.treeWrapper_new_portal_header]: isNewPortalHeader.client,
                            })}
                        >
                            <TreeConnected
                                onClick={handleMenuClick}
                                onExpand={handleOnMenuExpand}
                                gaSuffix="sp"
                                search={location?.search}
                            />
                        </div>
                    )}
                </div>
            ),
            [
                location?.search,
                goToSubscriptions,
                storage,
                handleMenuClick,
                showDiskOTreePromo,
                handleDiskOTreePromoShow,
                handleDiskOTreePromoClick,
                handleDiskOTreePromoClose,
                closeSidebarHandler,
            ]
        );

        const handleLeftBlockWrapperOverlayClick = useCallback(
            (evt) => {
                if (ENABLE_FULL_RESPONSIVE && leftBlockWrapperRef.current === evt.target) {
                    closeSidebarHandler();
                }
            },
            [closeSidebarHandler]
        );

        const items = useSelector((state: RootState) => groupedIds(state, storage as EStorageType, false)) as string[];
        const isRootFolder = useSelector((state: RootState) => isRootCurrentFolder(state, storage));

        const hideToolbar = (items.length === 0 && isHome && isRootFolder) || (isDocuments && !params.documentType);

        if (isFrozen) {
            return null;
        }

        if (statusPage) {
            return <ErrorPage status={statusPage} />;
        }

        return (
            <div
                className={classNames(styles.root, {
                    [styles.root_new_portal_header]: isNewPortalHeader.client,
                })}
            >
                {!IS_BLOCKED && (
                    <div
                        ref={leftBlockWrapperRef}
                        onClick={handleLeftBlockWrapperOverlayClick}
                        className={classNames(styles.leftBlockWrapper, {
                            [styles.responsive]: ENABLE_FULL_RESPONSIVE,
                            [styles.visible]: isSidebarOpened,
                        })}
                    >
                        {leftBlock}
                    </div>
                )}
                {!IS_BLOCKED && (
                    <div
                        className={classNames(styles.rightBlock, {
                            [styles.responsive]: ENABLE_FULL_RESPONSIVE,
                            [styles.rightBlock_new_portal_header]: isNewPortalHeader.client,
                        })}
                    >
                        <div className={styles.rightBlockContent}>
                            {renderWorm && (
                                <div className={styles.wormWrapper}>
                                    <Worm />
                                </div>
                            )}
                            {isNewPortalHeader.client && !hideToolbar && <ToolbarNew color={EHeaderColor.WHITE} />}
                            {isNewPortalHeader.client ? <div className={styles.rightBlockContentWrapper}>{children}</div> : children}
                        </div>
                    </div>
                )}

                {!IS_BLOCKED &&
                    createPortal(
                        <UploadBlock
                            showDropArea={showUploader}
                            allowDnd={allowDnd}
                            subscribeToInput={subscribeToInput}
                            unsubscribeOfInput={unsubscribeOfInput}
                            processDrop={processDrop}
                            gaCategory="fast"
                            containerElement={uploadPortalTarget}
                        />,
                        uploadPortalTarget
                    )}

                {isAllDocuments && isAllDocumentsHelpButton && createPortal(<HelpButton storage={storage} />, helpButtonPortalTarget)}

                <UploadInput allowedMimeTypes={allowedMimeTypes} />
            </div>
        );
    }
);

MainPage.displayName = 'MainPage';

MainPage.whyDidYouRender = true;
