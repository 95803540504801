import { createSelector } from '@reduxjs/toolkit';
import { ACTION_PROMO } from 'reactApp/appHelpers/configHelpers';
import { favorableTariffs, trialTariffsList } from 'reactApp/appHelpers/featuresHelpers';
import { getBaseUserQuota } from 'reactApp/modules/family/family.selectors';
import { getQuotaLandingTariffsConfig } from 'reactApp/modules/features/features.selectors';
import { PaidInfoSelectors } from 'reactApp/modules/paidInfo/paidInfo.selectors';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { QuotaLandingSelectors } from 'reactApp/modules/quotaLanding/quotaLanding.selector';
import { normalizeMobileTariffList } from 'reactApp/sections/MobileSubscriptionsPage/MobileSubscriptionsPage.helpers';
import { ITariffsMobile } from 'reactApp/sections/MobileSubscriptionsPage/MobileSubscriptionsPage.types';
import { RootState } from 'reactApp/store';
import { Product, Tariff } from 'reactApp/types/Billing';
import { ITariffsByTab } from 'reactApp/ui/TariffSectionNew/TariffsSection.types';
import { getDiscount } from 'reactApp/utils/getDiscount';
import { getMonthes, getTariffProductsByPeriods, isMonth6Period, isYear2Period } from 'reactApp/utils/Period';

import { baseYearProduct } from './TariffsPlans.helpers';

// tempexp_15246-start
export const isTwoYearsPromoAb = createSelector(ProductsSelectors.getTwoYearsTariffs, (tariffs) =>
    Boolean(!ACTION_PROMO && tariffs && tariffs?.length > 0)
);
// tempexp_15246-end

export const getMaxTariffDiscount = createSelector(
    [
        (state: RootState) => ProductsSelectors.getTariffsForQuotaLanding(state),
        (state, actionName: string) => ProductsSelectors.getPromoTariffs(state, actionName, true),
        (state, actionName) => actionName,
    ],
    (nonSpecialTariffs, promoTariffs, actionName) => {
        const tariffs: Tariff[] = actionName ? promoTariffs : nonSpecialTariffs;
        const discounts = tariffs.map((tariffItem) => {
            const { monthProduct, yearProduct } = getTariffProductsByPeriods(
                tariffItem.products,
                false,
                // tempexp_15246-next-line
                tariffItem.products.some((item) => isYear2Period(item.period))
            );

            if (!(monthProduct && yearProduct)) {
                return 0;
            }

            const yearPriceByMonth = getMonthes(yearProduct.period) * monthProduct.price;
            const { discount } = getDiscount({
                discountPrice: yearProduct?.discountPrice || yearProduct.price,
                price: yearPriceByMonth,
            });

            return discount;
        });

        return discounts.length && Math.max(...discounts);
    }
);

export const getTariffsList = createSelector(
    [
        (state: RootState) => ProductsSelectors.getTariffsForQuotaLanding(state),
        (state, actionName: string) => ProductsSelectors.getPromoTariffs(state, actionName, true),
        (state) => QuotaLandingSelectors.getAbLandingFeature(state),
        (state, actionName) => actionName,
        (state, actionName, isMobile) => isMobile,
        (state, actionName, isMobile, activeTariffPeriod) => activeTariffPeriod,
        (state) => getQuotaLandingTariffsConfig(state),
        (state) => getBaseUserQuota(state),
        (state) => ProductsSelectors.getNormalizedProductsBySpace(state, trialTariffsList),
        (state) => PaidInfoSelectors.isFreeUserWithoutTrials(state),
    ],
    (
        nonSpecialTariffs,
        promoTariffs,
        isNewLanding,
        actionName,
        isMobile,
        activeTariffPeriod,
        config,
        baseQuota,
        trialsList,
        isFreeUserWithoutTrials
    ): ITariffsByTab[] | ITariffsMobile[] => {
        const tariffs: Tariff[] = actionName ? promoTariffs : nonSpecialTariffs;

        const list = tariffs.reduce((acc: ITariffsByTab[], tariffItem) => {
            const products = getTariffProductsByPeriods(
                tariffItem.products,
                tariffItem.products.some((item) => isMonth6Period(item.period)),
                false,
                // tempexp_15246-next-line
                tariffItem.products.some((item) => isYear2Period(item.period))
            );

            const trial = isFreeUserWithoutTrials ? trialsList[tariffItem.space.original] : undefined;

            if (products.yearProduct) {
                const recommendedId = isNewLanding ? config.recommendedIdNew : config.recommendedId;

                acc.push({
                    space: tariffItem.space,
                    tariffId: tariffItem.id,
                    // tempexp_16373-next-line
                    isRecommended: tariffItem.id.includes(favorableTariffs.length ? config.recommendedIdFavorable : recommendedId),
                    products: {
                        yearProduct: trial?.yearProduct || products.yearProduct,
                        monthProduct: trial?.monthProduct || products.monthProduct,
                    },
                });
            }

            return acc;
        }, []);

        // tempexp_16373-next-line
        if (!favorableTariffs.length) {
            list.unshift({
                tariffId: 'base',
                space: { ...baseQuota },
                isRecommended: false,
                isBase: true,
                products: {
                    yearProduct: {
                        ...baseYearProduct,
                        space: baseQuota,
                    },
                    monthProduct: undefined,
                },
            });
        }

        if (isMobile) {
            const mobileTariffList = list.reduce((acc, item) => {
                // не показываем базовый тариф на таче
                if (item.isBase) {
                    return acc;
                }

                acc[item.space.original] = [item.products.yearProduct, item.products.monthProduct, item.products.twoYearProduct];
                return acc;
            }, <Record<number, [Product, Product | undefined, Product | undefined]>>{});

            return normalizeMobileTariffList(mobileTariffList, Boolean(actionName), activeTariffPeriod);
        }

        return list;
    }
);
