import React, { useRef, VFC } from 'react';
import { useSelector } from 'react-redux';
import { IPropsWithPopup } from 'reactApp/modules/popup/popup.helpers';
import { sortQuotaPromoBySpace } from 'reactApp/modules/promoTariffs/promoTariffs.selectors';
import {
    getBasicSubscription,
    getSubscriptionsLoadingState,
    sortSubscriptionsBySpace,
} from 'reactApp/modules/subscriptions/subscriptions.selectors';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';
import { Placeholder } from 'reactApp/ui/Placeholder/Placeholder';

import { SubscriptionListComponent } from './SubscriptionList/SubscriptionListComponent';
import styles from './SubscriptionsModal.css';
import { SubscriptionList } from './SubscriptionsModal.types';

export const SubscriptionsModal: VFC<IPropsWithPopup> = ({ onClose }) => {
    const scrollRef = useRef<HTMLDivElement>(null);

    const { isLoaded } = useSelector(getSubscriptionsLoadingState);

    const basicSubscription = useSelector(getBasicSubscription);
    const subscriptions = useSelector(sortSubscriptionsBySpace);
    const promoSubscriptions = useSelector(sortQuotaPromoBySpace);
    const subscriptionList = [basicSubscription, ...subscriptions, ...promoSubscriptions] as SubscriptionList;

    return (
        <MobileDialog
            id="mobile-subscriptions-dialog"
            onClose={onClose}
            closeOnDimmerClick
            mod="confirm"
            topmost
            innerClassNames={{ title: styles.root }}
            title="Мои подписки"
            scrollRef={scrollRef}
        >
            {isLoaded ? (
                <SubscriptionListComponent ref={scrollRef} subscriptionList={subscriptionList} onClose={onClose} />
            ) : (
                <div className={styles.loadingBlock}>
                    <Placeholder />
                </div>
            )}
        </MobileDialog>
    );
};
