export const MAX_TREE_LEVEL_LM = 7;

export enum ETreeNodeMode {
    selectDlg = 'selectDlg',
    albumsDlg = 'albumbsDlg',
    selectCheck = 'selectCheck',
}

export enum ETreeRootIds {
    home = '/',
    gallery = '/gallery',
    favorites = '/favorites',
    feed = '/feed',
    domain = '/domain',
    incoming = '/incoming',
    sharedIncoming = '/shared/incoming',
    sharedLinks = '/shared/links',
    sharedAutodelete = '/shared/autodelete',
    attaches = '/attaches',
    recommend = '/recommend',
    trashbin = '/trashbin',
    start = '/start',
    promocodes = '/promocodes',
    documents = '/documents',
    albums = '/albums',
    alldocuments = '/alldocuments',
    alldocumentsDocument = '/alldocuments/document',
    alldocumentsSpreadsheet = '/alldocuments/spreadsheet',
    alldocumentsPresentation = '/alldocuments/presentation',
    alldocumentsPdf = '/alldocuments/pdf',
    alldocumentsTrashbin = '/alldocuments/trashbin',
    family = '/family',
    safe = '/safe',
}
