import { Space } from '@mail/cross-sizes-utils';
import { normalizeData } from 'reactApp/modules/products/products.helpers';

export enum EProductPeriod {
    year = '1y',
    month = '1m',
}

export type IProductList = ReturnType<typeof normalizeData>;

export interface IProductsState {
    isLoading: boolean;
    selectedGroupName: string;
    hasError: boolean;
    list: IProductList;
    isLoaded: boolean;
}

export interface IProduct {
    id: string;
    turbo: boolean;
    price: number;
    period: string;
    available: boolean;
    isPromo: boolean;
    hasTrial: boolean;
    trialPeriod?: string;
    isProfessional: boolean;
    isDisko: boolean;
    isPrepaid: boolean;
    hasDiscount: boolean;
    discountPeriod?: string;
    discountPrice?: number;
    space: Space;
    isForceDiscountTrial?: boolean;
    isTransit?: boolean;
    gift?: {
        space: Space;
        period: string;
        id: string;
    };
}
