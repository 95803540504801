// tempexp_15952-full-file
import { bytesToNDigits } from '@mail/cross-sizes-utils';
import React, { memo, ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSendSpaceMenuAnalytics } from 'reactApp/hooks/UseSendSpaceMenuAnalytics';
import { historyPush } from 'reactApp/modules/router/router.module';
import { widgetTitles } from 'reactApp/modules/userQuotaCleaner/helpers/constants';
import { goToCleanerAndShowQuotaCleaner, loadUserQuotaCleanerList } from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.actions';
import { getFilteredLimitedGroups } from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.selectors';
import { QuotaCleanerGroup } from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.types';
import { UserQuotaGroupId } from 'reactApp/types/QuotaCleaner';
import { ClearBlock, ClearItem } from 'reactApp/ui/WelcomePromo/WelcomeNewPromo/ClearBlock';
import { TrialBlock } from 'reactApp/ui/WelcomePromo/WelcomeNewPromo/TrialBlock';
import { useWelcomePromoLogic } from 'reactApp/ui/WelcomePromo/WelcomePromo.hooks';

import styles from '../WelcomeNewPromo/WelcomeNewPromo.css';

interface Props {
    space?: string;
    trialId?: string | null;
    items: ClearItem[];

    onSubscriptionsClick(): void;

    onClearClick(): void;
}

export const WelcomeOverquotaPromoComponent = memo(({ onSubscriptionsClick, onClearClick, items = [], trialId }: Props): ReactElement => {
    return (
        <div className={styles.root}>
            <div className={styles.textBlock}>
                <div className={styles.header}>Ваше хранилище почти заполнено</div>
                <div className={styles.description}>
                    Чтобы{' '}
                    <span className={styles.link} onClick={onClearClick}>
                        освободить место
                    </span>{' '}
                    в хранилище, можно очистить ящик от тяжёлых
                    <br />
                    писем. Или увеличить хранилище, подключив один из{' '}
                    <span className={styles.link} onClick={onSubscriptionsClick}>
                        платных тарифов.
                    </span>
                </div>
            </div>
            <div className={styles.actionBlock}>
                <div className={styles.wrapper}>
                    <ClearBlock items={items} onClearClick={onClearClick} />
                    {Boolean(trialId) && <TrialBlock trialId={trialId ?? ''} forQuota />}
                </div>
            </div>
        </div>
    );
});

WelcomeOverquotaPromoComponent.displayName = 'WelcomeOverquotaPromoComponent';

const MAX_CLEAR_ITEMS = 4;

const neededGroupsIds = [
    UserQuotaGroupId.Read,
    UserQuotaGroupId.LargeInbox,
    UserQuotaGroupId.LargeSent,
    UserQuotaGroupId.OldInbox,
    UserQuotaGroupId.MailBin,
    UserQuotaGroupId.MailSpam,
    UserQuotaGroupId.Newsletters,
    UserQuotaGroupId.OldSent,
    UserQuotaGroupId.WithAttaches,
];

export const WelcomeOverquotaPromo = memo((): ReactElement => {
    const dispatch = useDispatch();
    const { trialId, handleSubscriptionsClick } = useWelcomePromoLogic();
    const groups = useSelector(getFilteredLimitedGroups);
    const sendAnalytics = useSendSpaceMenuAnalytics(false);

    const onClearItemClick = useCallback(
        (group: QuotaCleanerGroup) => {
            dispatch(
                goToCleanerAndShowQuotaCleaner({
                    groupId: group.groupId,
                    size: group.size,
                })
            );
        },
        [dispatch]
    );

    const items = useMemo(
        () =>
            groups
                .filter((group) => neededGroupsIds.includes(group.groupId))
                .filter((_, i) => i < MAX_CLEAR_ITEMS)
                .map((group) => ({
                    size: bytesToNDigits(group.size, 3).value,
                    title: widgetTitles[group.groupId],
                    onClick: () => onClearItemClick(group),
                })),
        [groups, onClearItemClick]
    );

    const handleClearClick = useCallback(() => {
        sendAnalytics('clear');
        dispatch(
            historyPush({
                id: '/promo/quota',
                search: '',
                hash: 'cleaner',
            })
        );
    }, []);

    useEffect(() => {
        dispatch(loadUserQuotaCleanerList());
    }, [dispatch]);

    return (
        <WelcomeOverquotaPromoComponent
            onSubscriptionsClick={handleSubscriptionsClick}
            onClearClick={handleClearClick}
            trialId={trialId}
            items={items}
        />
    );
});

WelcomeOverquotaPromo.displayName = 'WelcomeOverquotaPromo';
