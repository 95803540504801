import classNames from 'clsx';
import React, { memo, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { noopVoid } from 'reactApp/utils/helpers';

import styles from './Link.css';
import { getRouterLinkUrl } from './Link.helpers';
import { IProps } from './Link.types';

export const Link = memo(
    ({
        children,
        id,
        href,
        storage,
        handleGoTo = noopVoid,
        handleClick = noopVoid,
        noLink = false,
        search = '',
        className = '',
    }: IProps): ReactElement => {
        const currentStorage = useSelector(getCurrentStorage);

        const isRouterLink = storage && getStorage(storage).isReactPage && getStorage(currentStorage).isReactPage;
        const isRouterLinkPublic = currentStorage === EStorageType.public || currentStorage === EStorageType.stock;
        const routerLinkUrl =
            id && storage
                ? getRouterLinkUrl(id, currentStorage === EStorageType.integration ? EStorageType.integration : storage, href, search)
                : undefined;

        return (
            <>
                {!noLink && (isRouterLink || isRouterLinkPublic) && routerLinkUrl ? (
                    <RouterLink to={routerLinkUrl} className={classNames(styles.root, className)} onClick={handleClick}>
                        {children}
                    </RouterLink>
                ) : (
                    <div onClick={handleGoTo} className={classNames(styles.root, className)}>
                        {children}
                    </div>
                )}
            </>
        );
    }
);

Link.displayName = 'Link';
