import { PayloadAction } from '@reduxjs/toolkit';
import _ from 'Cloud/Application/_';
import app from 'Cloud/Application/app';
import config from 'Cloud/config';
import { addYears } from 'date-fns';
import { path, pathOr } from 'ramda';
import { getFileInfoApiCall } from 'reactApp/api/FileInfoAPICall';
import { IS_PUBLIC_ALBUM, IS_REACT_PAGE } from 'reactApp/appHelpers/configHelpers';
import { EFrom } from 'reactApp/components/SharingWindow/Sharing.types';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { albumsLoadMoreRequest } from 'reactApp/modules/albums/albums.actions';
import { allDocumentsMoreRequest } from 'reactApp/modules/allDocuments/allDocuments.module';
import { attachesLoadMoreRequest } from 'reactApp/modules/attaches/attaches.actions';
import { requestMoreFilesWithFaceStart } from 'reactApp/modules/faces/faces.module';
import { hasMoreToLoad } from 'reactApp/modules/faces/faces.selectors';
import { feedLoadMoreRequest } from 'reactApp/modules/feed/feed.module';
import { getFolderParams } from 'reactApp/modules/file/utils';
import { galleryLoadMoreRequest } from 'reactApp/modules/gallery/gallery.module';
import { loadHomeFolderRequest, loadMoreHomeRequest } from 'reactApp/modules/home/home.actions';
import { getHomeItemById } from 'reactApp/modules/home/home.selectors';
import { HomeFolder } from 'reactApp/modules/home/home.types';
import {
    addToFavorites,
    checkFolderForUpdate,
    cloneRequest,
    copyRequest,
    createFolderAction,
    createFolderSuccess,
    createNewAutoDeleteFolder,
    createNewItem,
    deleteWeblinkExpiresRequest,
    downloadMobileItem,
    editPublicCopy,
    inlineEditFailure,
    inlineEditSuccess,
    loadMoreRequest,
    makeNewDocumentSuccess,
    modifyingStop,
    mountRequest,
    moveRequest,
    openPublishDialog,
    publishAutoDeleteFolder,
    publishRequest,
    publishWeblink,
    removeFromFavorites,
    removeRequest,
    renameFileRequest,
    toggleWeblinkDomesticRequest,
    toggleWeblinkDownloadableRequest,
    toggleWeblinkEditableRequest,
    toggleWeblinkUploadRequest,
    unmountRequest,
    unPublishRequest,
    unPublishWeblink,
    updateWeblinkAutoDeleteRequest,
    updateWeblinkCountDownloadsRequest,
    updateWeblinkExpiresRequest,
} from 'reactApp/modules/modifying/modifying.actions';
import { EInlineEditPlace, EModifyReason, IInlineEditSuccess, TItem } from 'reactApp/modules/modifying/modifying.types';
import { handeEditPublicCopy, handleCloneItems } from 'reactApp/modules/modifying/sagas/clone.saga';
import { handleCreateNewItem } from 'reactApp/modules/modifying/sagas/createNewItem.saga';
import { loadDocumentsMoreRequest } from 'reactApp/modules/personalDocuments/personalDocuments.module';
import { loadMorePublicFolderRequest } from 'reactApp/modules/public/public.actions';
import { getCurrentRouteId, getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { searchLoadMoreRequest } from 'reactApp/modules/search/search.module';
import { scrollToHomeItemAction } from 'reactApp/modules/selections/selections.actions';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { digestRequest } from 'reactApp/modules/start/start.module';
import { loadStockMoreRequest } from 'reactApp/modules/stock/stock.module';
import { getLoadMoreLimit, getStorage } from 'reactApp/modules/storage/storage.helpers';
import { scrollToItemAction } from 'reactApp/modules/storage/storage.module';
import { getCurrentFolder, getStorageCurrentFolder } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { trashbinLoadMoreRequest } from 'reactApp/modules/trashbin/trashbin.module';
import { IInputFile } from 'reactApp/modules/uploadList/uploadList.model';
import { updateUploadFilesAction } from 'reactApp/modules/uploadList/uploadList.module';
import { getFolder, getSuccessInputFiles } from 'reactApp/modules/uploadList/uploadList.selectors';
import { loadUserQuotaGroupMore } from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.actions';
import { Visibly } from 'reactApp/modules/visibly/Visibly';
import { getParams as getAttachesParams } from 'reactApp/sections/AttachesPage/helpers';
import { store } from 'reactApp/store';
import { sendGa, sendXray } from 'reactApp/utils/ga';
import { channel } from 'redux-saga';
import { call, cancel, put, select, take, takeEvery, throttle } from 'redux-saga/effects';

import { isModifyingInProgress } from './modifying.selectors';
import { handleCopyItems } from './sagas/copy.saga';
import { createNewFolder, handleCreateFolderSuccess, handleCreateNewFolder } from './sagas/createFolder.saga';
import { handleDownloadMobile } from './sagas/download.saga';
import { handleAddToFavoritesItems, handleRemoveFromFavoritesItems } from './sagas/favorites.saga';
import { handleMountRequest, handleUnmountRequest } from './sagas/mount.saga';
import { handleMoveItems } from './sagas/move.saga';
import {
    deleteExpires,
    handleOpenPublishDialog,
    handlePublishRequest,
    handleUnPublishRequest,
    openPublishDialogSaga,
    toggleDomestic,
    toggleDownloadable,
    toggleEditable,
    toggleUpload,
    updateAutoDelete,
    updateCount,
    updateExpires,
} from './sagas/publish.saga';
import { handleRemoveItems } from './sagas/remove.saga';
import { handleRenameFile } from './sagas/rename.saga';

const IS_PUBLIC = config.get('PUBLIC');

// CLOUDWEB-4301 & CLOUDWEB-4465:
const visibly = new Visibly();
const CHECK_REVISIONS_THROTTLE_TIME = 15000;
const UPDATE_TIME_PRIVATE = 2 * 60 * 1000;
const UPDATE_TIME_PUBLIC = 10 * 60 * 1000;

visibly.init({
    updateTimeMs: IS_PUBLIC ? UPDATE_TIME_PUBLIC : UPDATE_TIME_PRIVATE,
    onVisibleTimer: (id) => {
        const state = store.getState();
        const currentRouteId = getCurrentRouteId(state);
        store.dispatch(checkFolderForUpdate(id ?? currentRouteId));
    },
});

function* handleMakeNewDocumentSuccess() {
    try {
        const storageHelper = getStorage(yield select(getCurrentStorage));

        if (storageHelper.isStart) {
            yield put(digestRequest());
        }
    } catch (error) {
        yield cancel();
    }
}

function* checkFolderById(folderId: string | undefined, storage: EStorageType) {
    const { isTrashBin, isPublic, isStart, isReactPage } = getStorage(storage);

    if ((!isPublic && !isReactPage) || IS_PUBLIC_ALBUM) {
        return;
    }

    let currentFolder: null | HomeFolder = null;
    if (folderId) {
        currentFolder = IS_REACT_PAGE ? yield select(getHomeItemById, folderId) : (app.folder(folderId) as HomeFolder);
    } else if (isTrashBin) {
        currentFolder = _.tree('home').get('/');
    } else {
        currentFolder = IS_REACT_PAGE ? yield select(getHomeItemById, ROOT_FOLDER_ID) : yield select(getCurrentFolder);
    }

    if (!currentFolder) {
        return;
    }

    const oldRev = currentFolder.rev;
    const params = getFolderParams(currentFolder, isPublic);

    if (!params) {
        return;
    }

    const newItem = yield getFileInfoApiCall(params);
    // значение rev в ответе v4 соответствует значению grev в ответе v2
    // grev это то, что для файла приходит как rev
    // а rev для папки - это lrev - ревизия которая меняется при изменении листинга папки
    // rev для файла - это ревизия, которая меняется при изменении файла
    // rev для папки - это рекурсивная ревизия, которая меняется при изменении содержания папки
    // Итого: В хомяке и паблике уже новое апи v4, мы сохранили его rev (которое grev в старом),
    // потому тут из старого для сравнения берем сначала grev
    const newRev = newItem.grev || newItem.rev;

    if (newRev && oldRev === newRev) {
        return;
    }

    // если новая версия папки
    if (IS_REACT_PAGE) {
        if (isStart) {
            yield put(digestRequest());
        } else {
            yield put(loadHomeFolderRequest({ id: params.home, isFolder: true, force: true }));
        }
    } else {
        app.updateFolder(newItem, true);
    }
}

function* handleCheckFolderForUpdate(action: ReturnType<typeof checkFolderForUpdate>) {
    const folderId = action.payload;
    const isModifyingProgress = yield select(isModifyingInProgress);
    const storage = yield select(getCurrentStorage);

    if (isModifyingProgress) {
        return;
    }

    try {
        yield checkFolderById(folderId, storage);

        if (folderId !== ROOT_FOLDER_ID) {
            yield checkFolderById(ROOT_FOLDER_ID, EStorageType.home);
        }
    } catch (error) {
        yield cancel();
    }
}

function* handleCreateNewAutoDeleteFolder() {
    try {
        const createChannel = channel();

        yield createNewFolder({
            storage: EStorageType.home,
            parentFolderId: ROOT_FOLDER_ID,
            onCreateFolder: (folderName) => createChannel.put(folderName),
            onClose: () => createChannel.close(),
        });

        const folderName = yield take(createChannel);
        const item = yield select(getHomeItemById, folderName);

        createChannel.close();

        sendGa('folderCreate', 'autodelete');
        yield put(publishAutoDeleteFolder({ item }));
    } catch (error) {
        yield cancel();
    }
}

function* handlePublishAutoDeleteFolder(action: ReturnType<typeof publishAutoDeleteFolder>) {
    const { item } = action.payload;

    if (!item) {
        return;
    }

    yield openPublishDialogSaga({
        item,
        itemStorage: EStorageType.home,
        gaSuffix: 'folderCreate',
        from: EFrom.WEBLINK,
    });

    const itemWithWeblink = yield select(getHomeItemById, item.id);
    const expires = addYears(new Date(), 1).valueOf();

    yield put(updateWeblinkAutoDeleteRequest({ item: itemWithWeblink, expires }));
}

export function* handlePublish({ cloudPath, name, type, publish }: { cloudPath: string; name: string; type: TItem; publish: boolean }) {
    // может быть загружено несколько одинаковых файлов, нужно обновить паблик у всех
    let inputFiles = ((yield select(getSuccessInputFiles, { cloudPath })) as IInputFile[]) || [];

    if (type === 'folder') {
        const folder = (yield select(getFolder, { localPath: `/${name}` })) as IInputFile;

        if (folder) {
            inputFiles = [folder];
        }
    }

    if (!inputFiles?.length) {
        return;
    }

    let i = 0;
    while (i < inputFiles.length) {
        yield put(
            updateUploadFilesAction({
                cloudPath: inputFiles[i].cloudPath,
                descriptorId: inputFiles[i].descriptorId,
                publish,
                extension: inputFiles[i].extension,
                folderCloudPath: inputFiles[i].folderCloudPath,
                localPath: inputFiles[i].localPath,
                name: inputFiles[i].name,
            })
        );
        i++;
    }
}

function* publish(action) {
    const { payload } = action;

    yield call(handlePublish, { cloudPath: payload.id, name: payload.name, type: payload.type, publish: true });
}

function* unPublish(action) {
    const { payload } = action;

    yield call(handlePublish, { cloudPath: payload.id, name: payload.name, type: payload.type, publish: false });
}

function* handleModifyingStop(action) {
    const { reason, storage: storageTemp, id: idTemp } = action.payload ?? {};

    if (!reason) {
        return;
    }

    if (reason === EModifyReason.move || reason === EModifyReason.remove) {
        const id = idTemp ? idTemp : yield select(getCurrentRouteId);
        const storage = storageTemp ? storageTemp : yield select(getCurrentStorage);

        yield put(loadMoreRequest({ id, storage, loadOnlyIfLessThanLimit: true }));
    }
}

// eslint-disable-next-line complexity
function* handleLoadMore(action) {
    const { id, storage, loadOnlyIfLessThanLimit } = action.payload;
    const {
        isHome,
        isPublic,
        isTrashBin,
        isSearch,
        isAttaches,
        isFeed,
        isGallery,
        isDocuments,
        isQuotaCleaner,
        isStock,
        isAlbums,
        isAllDocuments,
    } = getStorage(storage);

    const folder = yield select(getStorageCurrentFolder, storage);

    const offset = action.payload.offset ?? path(['count', 'loaded'], folder) ?? 0;

    if (loadOnlyIfLessThanLimit) {
        const apiLimit = getLoadMoreLimit(storage);
        if (offset > apiLimit) {
            return;
        }
    }

    if (isPublic && (yield select(hasMoreToLoad))) {
        yield put(requestMoreFilesWithFaceStart({}));
        return;
    }

    if (folder && 'hasMoreToLoad' in folder && folder.hasMoreToLoad) {
        if (isHome) {
            yield put(loadMoreHomeRequest({ offset, id }));
        } else if (isPublic) {
            yield put(loadMorePublicFolderRequest({ offset, id }));
        } else if (isSearch) {
            yield put(searchLoadMoreRequest());
        } else if (isTrashBin) {
            yield put(
                trashbinLoadMoreRequest({
                    id,
                    tree: 'tree' in folder ? folder.tree : undefined,
                    nextChunkRev: pathOr(0, ['nextChunkRev'], folder),
                })
            );
        } else if (isAttaches) {
            const params = yield select(SettingsSelectors.getQueryParams);
            const attachesParams = getAttachesParams({ params }) ?? {};
            yield put(
                attachesLoadMoreRequest({
                    after: pathOr('', ['after'], folder),
                    ...attachesParams,
                })
            );
        } else if (isFeed) {
            yield put(feedLoadMoreRequest());
        } else if (isGallery) {
            yield put(galleryLoadMoreRequest());
        } else if (isDocuments) {
            yield put(loadDocumentsMoreRequest());
        } else if (isAllDocuments) {
            yield put(allDocumentsMoreRequest());
        } else if (isAlbums) {
            yield put(albumsLoadMoreRequest());
        } else if (isQuotaCleaner) {
            yield put(loadUserQuotaGroupMore({ offset }));
        } else if (isStock) {
            yield put(loadStockMoreRequest({ offset, id }));
        }
    }
}

function* handleInlineEditSuccess(action: PayloadAction<IInlineEditSuccess>) {
    const { id, place } = action.payload;

    if (place === EInlineEditPlace.breadcrumbs) {
        yield put(scrollToHomeItemAction({ itemId: id }));
    } else {
        // tempexp-14737-next-line
        yield put(scrollToItemAction(id));
    }

    sendXray(['rename', 'inline', place]);
}

function* handleInlineEditFailure(action: PayloadAction<string | null>) {
    const error = action?.payload;
    if (error) {
        yield put(
            showSnackbarAction({
                id: 'rename-breadcrumb-fail',
                closable: true,
                text: error,
                type: SnackbarTypes.failure,
            })
        );
    }
}

export function* watchModifying() {
    yield takeEvery(updateWeblinkExpiresRequest.toString(), updateExpires);
    yield takeEvery(deleteWeblinkExpiresRequest.toString(), deleteExpires);
    yield takeEvery(updateWeblinkAutoDeleteRequest.toString(), updateAutoDelete);
    yield takeEvery(toggleWeblinkDomesticRequest.toString(), toggleDomestic);
    yield takeEvery(toggleWeblinkUploadRequest.toString(), toggleUpload);
    yield takeEvery(toggleWeblinkEditableRequest.toString(), toggleEditable);
    yield takeEvery(toggleWeblinkDownloadableRequest.toString(), toggleDownloadable);
    yield takeEvery(updateWeblinkCountDownloadsRequest.toString(), updateCount);
    yield takeEvery(renameFileRequest.toString(), handleRenameFile);
    yield takeEvery(makeNewDocumentSuccess.toString(), handleMakeNewDocumentSuccess);
    yield takeEvery(createNewAutoDeleteFolder.toString(), handleCreateNewAutoDeleteFolder);
    yield takeEvery(publishAutoDeleteFolder.toString(), handlePublishAutoDeleteFolder);
    yield takeEvery(publishWeblink.toString(), publish);
    yield takeEvery(unPublishWeblink.toString(), unPublish);
    yield takeEvery(copyRequest.toString(), handleCopyItems);
    yield takeEvery(moveRequest.toString(), handleMoveItems);
    yield takeEvery(removeRequest.toString(), handleRemoveItems);
    yield throttle(CHECK_REVISIONS_THROTTLE_TIME, checkFolderForUpdate.toString(), handleCheckFolderForUpdate);
    yield takeEvery(modifyingStop.toString(), handleModifyingStop);
    yield takeEvery(loadMoreRequest.toString(), handleLoadMore);
    yield takeEvery(publishRequest.toString(), handlePublishRequest);
    yield takeEvery(unPublishRequest.toString(), handleUnPublishRequest);
    yield takeEvery(openPublishDialog.toString(), handleOpenPublishDialog);
    yield takeEvery(unmountRequest.toString(), handleUnmountRequest);
    yield takeEvery(mountRequest.toString(), handleMountRequest);
    yield takeEvery(addToFavorites.toString(), handleAddToFavoritesItems);
    yield takeEvery(removeFromFavorites.toString(), handleRemoveFromFavoritesItems);
    yield takeEvery(cloneRequest.toString(), handleCloneItems);
    yield takeEvery(editPublicCopy.toString(), handeEditPublicCopy);
    yield takeEvery(downloadMobileItem.toString(), handleDownloadMobile);
    yield takeEvery(createFolderAction.toString(), handleCreateNewFolder);
    yield takeEvery(createFolderSuccess.toString(), handleCreateFolderSuccess);
    yield takeEvery(createNewItem.toString(), handleCreateNewItem);
    yield takeEvery(inlineEditSuccess.toString(), handleInlineEditSuccess);
    yield takeEvery(inlineEditFailure.toString(), handleInlineEditFailure);
}
