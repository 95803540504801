import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { historyPush } from 'reactApp/modules/router/router.module';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { showWelcome } from 'reactApp/modules/welcome/welcome.module';
import { openPromoQuotaPage, openSubscriptionsPage } from 'reactApp/utils/openHelpers';

interface IArgs {
    query?: string;
    anchor?: string;
    inSameTab?: boolean;
    isNewTariffsPage?: boolean;
}

export const useGoToSubscriptions = ({ searchSuffix = '' } = {}) => {
    const dispatch = useDispatch();
    const isNewbie = useSelector(UserSelectors.isNewbie);

    const goToSubscriptions = useCallback(
        ({ query = '', anchor = '', inSameTab = true, isNewTariffsPage = false }: IArgs = {}) => {
            if (isNewbie) {
                dispatch(showWelcome());
                return;
            }

            if (inSameTab) {
                let search = searchSuffix;
                if (query) {
                    search = (search ? `${search}&` : '?') + query;
                }
                dispatch(historyPush({ id: isNewTariffsPage ? '/promo/quota' : '/subscriptions', search, hash: anchor }));
            } else {
                if (isNewTariffsPage) {
                    openPromoQuotaPage({ query, inSameTab, anchor });
                    return;
                }

                openSubscriptionsPage({ query, inSameTab, anchor });
            }
        },
        [isNewbie]
    );

    return { goToSubscriptions };
};
