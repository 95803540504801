import { Switch } from '@vkontakte/vkui';
import classNames from 'clsx';
import { ReactComponent as ShareIcon } from 'img/icons/editor/shareIcon.svg';
import React, { FC } from 'react';
import { EditorItem } from 'reactApp/modules/editor/editor.types';
import { HeaderButton } from 'reactApp/ui/EditorHeader/WhiteEditorHeader/components/HeaderButton/HeaderButton';
import buttonStyles from 'reactApp/ui/EditorHeader/WhiteEditorHeader/components/HeaderButton/HeaderButton.css';

import styles from './ShareButton.css';

interface Props {
    file: EditorItem | null;
    isCoEditSwitchButtonEnable: boolean;
    buttonTextRef: React.RefObject<HTMLDivElement>;
    handlePublish: (e?: React.MouseEvent<HTMLDivElement> | React.ChangeEvent<HTMLInputElement>) => void;
}

export const ShareButton: FC<Props> = ({ handlePublish, isCoEditSwitchButtonEnable, file, buttonTextRef }) => {
    return (
        <div onClick={handlePublish} data-qa-id="share" className={classNames({ [styles.sharingButton]: isCoEditSwitchButtonEnable })}>
            {isCoEditSwitchButtonEnable ? (
                <>
                    <span
                        className={classNames(buttonStyles.buttonTitle, {
                            [styles.sharingButtonTitle]: isCoEditSwitchButtonEnable,
                        })}
                    >
                        Совместная работа
                    </span>
                    <Switch checked={!!file?.weblink} />
                </>
            ) : (
                <HeaderButton
                    hint="Настроить доступ"
                    title="Настроить доступ"
                    icon={<ShareIcon />}
                    onClick={handlePublish}
                    textRef={buttonTextRef}
                />
            )}
        </div>
    );
};
