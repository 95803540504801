import { differenceInDays } from 'date-fns';
import cloudIcon from 'img/icons/cloud-colored.svg';
import { ascend, descend, find, path, propEq, sortWith, values } from 'ramda';
import { getBaseUserQuota } from 'reactApp/modules/family/family.selectors';
import { getFeatureSaveSubscriptionTransit } from 'reactApp/modules/features/features.selectors';
import { isYearSubscription } from 'reactApp/modules/subscriptions/subscriptions.helpers';
import {
    EStatus,
    ESubscriptionsTypes,
    isFutureSubscription,
    isMobileSubscription,
    ISubscriptionsState,
} from 'reactApp/modules/subscriptions/subscriptions.types';
import { RootState } from 'reactApp/store';
import { LoadingState } from 'reactApp/types/commonStates';
import { createSelector } from 'reselect';

export const getSubscriptionsRoot = (state: RootState) => state.subscriptions;
export const getSubscriptions = createSelector(getSubscriptionsRoot, (subscriptions) => subscriptions.list);
export const getSubscriptionsRaw = createSelector(getSubscriptionsRoot, (subscriptions) => subscriptions.rawList);
export const getSubscriptionsList = createSelector(getSubscriptions, (list) => values(list));

export const getSubscriptionsById = createSelector(
    getSubscriptions,
    (_: RootState, id: string | number) => id,
    (subscriptions, id) => path([id], subscriptions)
);

export const getBasicSubscription = createSelector(getBaseUserQuota, (baseQuota) => ({
    id: 'base',
    space: baseQuota,
    icon: cloudIcon,
    base: true,
    type: ESubscriptionsTypes.base,
}));

export const getTrialSubscription = createSelector(
    getSubscriptions,
    (subscriptions) => subscriptions && Object.values(subscriptions).find((item) => item.isTrial)
);

export const getAutorenewalTrialSubscription = createSelector(getSubscriptions, (subscriptions) => {
    const trials = subscriptions && Object.values(subscriptions).filter((subs) => subs.isTrial && subs.autorenewal);

    return trials.length && (trials.find((trial) => trial.isDisko) || trials[0]);
});

export const sortSubscriptionsBySpace = createSelector(getSubscriptions, (subscriptions) =>
    sortWith([ascend(path(['space', 'original'])), ascend(path(['id']))], values(subscriptions)).filter(
        (subscription) =>
            // если будущая подписка в статусе FINISHED, не показываем ее
            !isFutureSubscription(subscription) || (isFutureSubscription(subscription) && subscription.status !== EStatus.FINISHED)
    )
);

export const getComboSubscription = createSelector(
    getSubscriptions,
    (subscriptions) => subscriptions && find(propEq('type', 'reward'), values(subscriptions))
);

export const getProfessionalSubscription = createSelector(
    getSubscriptions,
    (subscriptions) => subscriptions && Object.values(subscriptions).find((item) => item.isProfessional)
);

export const getActiveSubscriptionId = createSelector(getSubscriptionsRoot, (subscriptions) => subscriptions.activeSubscriptionId);

export const checkSubscriptionWithProduct = createSelector(
    getSubscriptionsList,
    (_: RootState, productId: string) => productId,
    (list, productId) => list.some((subscription) => subscription.productId === productId)
);

export const hasMailSubscription = createSelector(getSubscriptionsList, (list) => list.some((subscription) => subscription.isMail));

export const hasFutureTransitSubscription = createSelector(getSubscriptionsList, (list) =>
    list.some((subscription) => isFutureSubscription(subscription) && subscription.isTransit)
);

export const getMailSubscription = createSelector(getSubscriptionsList, (list) => list.find((subscription) => subscription.isMail));

export const getSubscriptionByProductId = createSelector(
    getSubscriptionsList,
    (_: RootState, productId: string) => productId,
    (list, productId) => list.sort(descend(path(['start']))).find((subscription) => subscription.productId === productId)
);

export const getSubscriptionsLoadingState = createSelector(
    getSubscriptionsRoot,
    (state: ISubscriptionsState): LoadingState => ({
        isLoading: state.isLoading,
        isLoaded: state.isLoaded,
    })
);

export const getMobileSubscriptionForTransit = createSelector(
    sortSubscriptionsBySpace,
    getFeatureSaveSubscriptionTransit,
    (list, transitFeature) => {
        const { daysCount } = transitFeature || {};

        if (!daysCount) {
            return;
        }

        return list
            .reverse()
            .find((subs) => isMobileSubscription(subs) && differenceInDays(subs.expires, Date.now()) < daysCount && isYearSubscription);
    }
);
