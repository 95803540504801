import { Spacing } from '@vkontakte/vkui';
import classNames from 'clsx';
import { xray } from 'lib/xray';
import React, { memo, ReactElement, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ACTION_PROMO, IS_MOBILE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { isDarkTariffsPages, isTwoYearsTariffsInWebview } from 'reactApp/appHelpers/featuresHelpers';
import { useDarkTheme } from 'reactApp/hooks/useDarkTheme';
import { loadQuotaTariffsLandingRequest, sendShowLandingAnalyticsAction } from 'reactApp/modules/quotaLanding/quotaLanding.module';
import { QuotaLandingSelectors } from 'reactApp/modules/quotaLanding/quotaLanding.selector';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import {
    useAddCardModal,
    useRemoveCardModal,
    useSubscriptionsModal,
} from 'reactApp/sections/MobileSubscriptionsPage/MobileSubscriptionsPage.hooks';
import { Container } from 'reactApp/sections/QuotaLanding/Container/Container';
import { useProductFromQuery } from 'reactApp/sections/QuotaLanding/hooks/useProductFromQuery';
import { sendQuotaGa } from 'reactApp/sections/QuotaLanding/QuotaLanding.helpers';
import { TariffsList } from 'reactApp/sections/QuotaLanding/TariffsList/TairiffsList';
import { Button } from 'reactApp/ui/Button/Button';
import { ButtonLink } from 'reactApp/ui/ButtonLink/ButtonLink';
import Content from 'reactApp/ui/Content/Content';
import { renderSubscriptionsModal } from 'reactApp/ui/Mobile/SubscriptionsModal/SubscriptionsModal.helpers';
import { ACTIONS_DEFS } from 'reactApp/ui/TariffsSection/TariffSection.constants';
import opener from 'reactApp/utils/opener';

import { Header } from './Header/Header';
import styles from './MobileQuotaTariffsLanding.css';

const HELP_LINK = 'https://help.mail.ru/cloud_web/size/increase';

export const MobileQuotaTariffsLanding = memo((): ReactElement => {
    const dispatch = useDispatch();

    const { isLoading, hasError, isLoaded } = useSelector(QuotaLandingSelectors.getState);
    const isAnonymous = useSelector(UserSelectors.isAnonymous);
    const isSocialUser = useSelector(UserSelectors.isSocialUser);
    const isUserWithBilling = useSelector(UserSelectors.isUserWithBilling);

    useProductFromQuery({ forceMobile: true });
    useRemoveCardModal();
    useAddCardModal();
    useSubscriptionsModal();

    useDarkTheme(isDarkTariffsPages);

    const onSubscriptionsClick = useCallback(() => {
        sendQuotaGa({ action: 'subscriptions', label: 'click' });
        renderSubscriptionsModal();
    }, []);

    const onCancelInfo = useCallback(() => {
        sendQuotaGa({ action: 'cancel-info', label: 'click' });
    }, []);

    useEffect(() => {
        dispatch(loadQuotaTariffsLandingRequest());
    }, []);

    const retry = useCallback(() => {
        dispatch(loadQuotaTariffsLandingRequest());
    }, [dispatch]);

    useEffect(() => {
        if (isLoaded) {
            dispatch(sendShowLandingAnalyticsAction());
        }
    }, [isLoaded]);

    // если пользователю недоступен билинг(кроме соц пользователей)
    // если аноним
    // если не тач
    // редирект к хомяк
    if ((!isUserWithBilling && !isSocialUser) || isAnonymous || !IS_MOBILE_BROWSER) {
        xray.send('mobile_quota_tariffs_to_home');
        opener('/', true);
    }

    return (
        <Content
            buttonText="Попробовать ещё раз"
            retry={retry}
            isLoading={isLoading}
            hasError={hasError}
            isPage
            pageName="quota_landing_tariffs"
        >
            <div
                className={classNames(styles.root, {
                    [styles.root_dark]: isDarkTariffsPages,
                    [styles.root_action]: Boolean(ACTION_PROMO),
                })}
            >
                <Spacing size={32} />
                <Header />
                <Spacing size={32} />
                <TariffsList
                    isMobilePayAvailable
                    isMobilePayFromGetParam={false}
                    isAnonymous={isAnonymous}
                    isTariffsPage
                    isTwoYearTariffsAvailable={isTwoYearsTariffsInWebview}
                    isPhone
                />
                <Spacing size={20} />
                <Container isNarrow>
                    <Button className={styles.button} theme="vk" onClick={onSubscriptionsClick} qaId="subscriptions">
                        Мои подписки
                    </Button>
                    {ACTION_PROMO && (
                        <>
                            <Spacing size={12} />
                            <Button
                                className={styles.button}
                                theme="vk"
                                href={ACTIONS_DEFS['marketingPromo'].conditions}
                                target="_blank"
                                qaId="action"
                            >
                                Полные условия акции
                            </Button>
                        </>
                    )}
                    <Spacing size={20} />
                    <ButtonLink
                        className={styles.link}
                        href={HELP_LINK}
                        target="_blank"
                        rel="noopener"
                        hoverUnderline={false}
                        onClick={onCancelInfo}
                    >
                        Как отменить подписку?
                    </ButtonLink>
                </Container>
                <Spacing size={40} />
            </div>
        </Content>
    );
});

MobileQuotaTariffsLanding.displayName = 'MobileQuotaTariffsLanding';
