/* eslint-disable complexity */
import { IS_AUTO_TEST_HIDE } from 'reactApp/appHelpers/configHelpers';
import { SPACE_LIST } from 'reactApp/constants/spaceList';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { store } from 'reactApp/store';
import { put } from 'redux-saga/effects';
import { select } from 'typed-redux-saga';

export const quotaTipId = (quota: number) => `quota-banner_${quota}`;

export function* initQuotaPromo() {
    const storage = yield* select(getCurrentStorage);
    const { isHome } = getStorage(storage);
    const isPhone = yield* select(EnvironmentSelectors.isPhone);
    const isAnonymous = yield* select(UserSelectors.isAnonymous);

    if (isAnonymous || isPhone) {
        return;
    }

    if (!isPhone && (!isHome || IS_AUTO_TEST_HIDE)) {
        return;
    }

    const { isOverQuota, userTotalSpace, remaining } = yield select(UserQuotaSelectors.getUserQuotaState);

    const bytesFree = remaining?.original;
    const bytesTotal = userTotalSpace?.original;

    if (!bytesFree || !bytesTotal || isOverQuota) {
        return;
    }

    if (bytesFree >= SPACE_LIST.gb_16) {
        return;
    }

    let quota;

    if (bytesFree < SPACE_LIST.gb_16 && bytesTotal >= SPACE_LIST.gb_64) {
        quota = 16;
    }

    if (bytesFree < SPACE_LIST.gb_8 && bytesTotal >= SPACE_LIST.gb_32) {
        quota = 8;
    }

    if (bytesFree < SPACE_LIST.gb_4) {
        quota = 4;
    }

    if (bytesFree < SPACE_LIST.gb_2) {
        quota = 2;
    }

    const storeValue = storeHelper.getValue(quotaTipId(quota));
    if (!quota || (storeValue && Date.now() < storeValue)) {
        return;
    }

    yield put(
        addPromoToShowQueue({
            type: EPromoType.quota,
            promoId: quotaTipId(quota),
            onShow: () => store.dispatch(promoShown(EPromoType.quota)),
            onClose: () => store.dispatch(removePromo(EPromoType.quota)),
            quota,
        })
    );
}

export function* showQuotaPopup() {
    const quotaPromo = yield* select(PromoSelectors.getPromo(EPromoType.quota));

    if (!quotaPromo) {
        return;
    }

    openPopupHelper({
        popupName: popupNames.QUOTA_POPUP,
        data: {
            onShow: quotaPromo.onShow,
            onClose: quotaPromo.onClose,
            quota: quotaPromo.quota,
        },
    });
}
