import { parse } from 'qs';
import { ROOT_FOLDER_ID, STORAGE_REGEXP } from 'reactApp/constants/magicIdentificators';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { IContext, ICtx } from 'server/types/context/IContext';
import { IRequest } from 'server/types/context/IRequest';

import { getContext } from './context/getContext';

export interface IRequestParams extends IRequest {
    id: string;
    uri: string;
    uriFull: string;
    userAgentString: string;
    queryString: string;
    cookie: Record<string, string>;
    queryParams: Partial<Record<EQueryParams, string>>;
    httpHeaders: Record<string, string>;
    rbSlotParam: string;
}

export enum EQueryParams {
    // Открытие промо после привязки Сферума
    bindSpherum = 'bindSpherum',
    // Превью для RB
    preview = 'preview',
    // Открытие в приложении облака
    webview = 'webview',
    // Открытие в приложении облака для просмтора файлов в webview
    viewerOnly = 'viewerOnly',
    from = 'from',
    'from-page' = 'from-page',
    'from-promo' = 'from-promo',
    // Фичи, которые можно включить через хендлер query
    features = 'features',
    // Хост для подмены статики. Не доступно для прода
    static_host = 'STATIC_HOST',
    action = 'action',
    // Релогин
    'x-email' = 'x-email',

    // Параметры с бывшего лендинга tariffs
    web_product = 'web_product',
    mt_link_id = 'mt_link_id',

    // Параметр подставляется в урл если открыть файл и у него будет публичная ссылка
    weblink = 'weblink',

    // Ссылка для резервной авторизации
    emergencylink = 'emergencylink',

    // Параметр для поднятия окна оплаты с выбранным тарифом
    tariffId = 'tariffId',

    // Параметр для поднятия окна оплаты с выбранным продуктом
    productId = 'productId',

    // Id сториза, который открывается вместе с action=show-stories
    id = 'id',

    // Параметр для вебвью Майтима
    wv = 'wv',

    // backUrl в attaches
    back_url = 'back_url',

    // Параметры из инвайт-ссылки
    token = 'token',
    owner = 'owner',
    recipient = 'recipient',
    owner_email = 'owner_email',
    folder_name = 'folder_name',

    // Параметры для аттачей
    type = 'type',
    folderId = 'folderId',

    // Параметр для открытия шторки с подписками юзера
    my_subscriptions = 'my_subscriptions',

    // Параметр, в котором передаётся ориджин страницы, открывшей Облако window.open'ом. Валидируется из
    // онлайнконфовой ручки integration-parent-origin-whitelist, используется для отправки postMessage
    parentOrigin = 'parent_origin',
    // Идентификатор клиента, который интегрировал себе Облако. Используется для аналитики
    integrationClient = 'integration_client',
    integrationUid = 'integration_uid',
    integrationChannel = 'integration_channel',
    // Просмотрщик в вэбвью https://jira.vk.team/browse/CLOUDWEB-15881
    is_app_viewer = 'is_app_viewer',
}

const getCookie = (httpCookie: string): Record<string, string> => {
    if (!httpCookie) {
        return {};
    }

    return httpCookie?.split(';').reduce((res, cookie: string) => {
        const parts = cookie.split('=');
        const key = parts.shift()?.trim() || '';

        try {
            res[key] = decodeURIComponent(parts.join('='));
            return res;
        } catch (_) {
            return '';
        }
    }, {});
};

const getQueryParams = (queryString: string): Partial<Record<EQueryParams, string>> => {
    if (!queryString) {
        return {};
    }

    try {
        return parse(queryString, { ignoreQueryPrefix: true });
    } catch (_) {
        return {};
    }
};

const getHttpHeaders = (httpHeaders = ''): Record<string, string> => {
    try {
        return JSON.parse(httpHeaders);
    } catch (_) {
        return {};
    }
};

const parseAttachId = (uri: string) => {
    const uriDecoded = decodeURIComponent(uri);
    if (!uriDecoded.match(/\/attaches\/*$/)) {
        if (uriDecoded.match(/(\/)attaches(\/)(\d+)(\/|;)(\d+);(\d+)/)) {
            return uriDecoded.replace(/(\/)attaches(\/)(\d+)(\/|;)(\d+);(\d+)/, '$3;$5;$6').replace(/^\//, '');
        }

        // eslint-disable-next-line no-useless-escape
        if (uriDecoded.match(/(\/)attaches(\/)([A-Za-z0-9]+)(?:[\/|;|%3B])/)) {
            return uriDecoded.replace(/(\/)attaches(\/)([A-Za-z0-9]+)\/(\/public\/)?([A-Za-z0-9]+)/, '$3;$4$5').replace(/^\//, '');
        }
    }

    return '';
};

const getRequestId = (request: IRequest) => {
    const storage = request.storage;

    if (storage === EStorageType.home) {
        return request.home;
    }

    if (storage === EStorageType.public) {
        return request.weblink;
    }

    if (storage === EStorageType.stock) {
        return request.stock;
    }

    if (storage === EStorageType.attaches) {
        return parseAttachId(request.uri.replace(/\?.+$/g, ''));
    }

    return ROOT_FOLDER_ID;
};

export const getStorageFromUri = (uri: string) => {
    const customStorage = uri.match(STORAGE_REGEXP);

    return customStorage?.[1];
};

export const getRequestParams = (ctx: ICtx, json: IContext): IRequestParams => {
    const uri = json.request.uri;
    const httpCookie = getContext(ctx, 'HTTP_COOKIE') || '';
    const queryString = uri.replace(/^.+\?/g, '');
    const userAgentString = getContext(ctx, 'HTTP_USER_AGENT') || '';
    const httpHeaders = getHttpHeaders(getContext(ctx, 'HTTP_HEADERS'));

    const cookie = getCookie(httpCookie);
    const queryParams = getQueryParams(queryString);
    const id = getRequestId(json.request);
    const preview = queryParams.preview;
    const rbSlotParam = preview ? `preview=${preview}` : '';

    return {
        ...json.request,
        id: id?.replace(/\/+$/g, '') || ROOT_FOLDER_ID,
        storage: (getStorageFromUri(uri) || json.request.storage) as EStorageType,
        cookie,
        queryParams,
        userAgentString,
        uriFull: `https://${json.request.host}${uri}`,
        uri: uri.replace(/\?.*$/g, ''),
        httpHeaders,
        queryString,
        rbSlotParam,
    };
};
