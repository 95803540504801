import { PayloadAction } from '@reduxjs/toolkit';
import { downloadAsJpeg } from 'Cloud/Application/DownloadAsJpeg';
import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import { IDownloadItem } from 'reactApp/modules/modifying/modifying.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { SelectionsSelectors } from 'reactApp/modules/selections/selections.selectors';
import { getItemById } from 'reactApp/modules/storage/storage.selectors';
import { renderDownloadMobileDropdown } from 'reactApp/ui/Mobile/DownloadDropdown/DownloadDropdown.helpers';
import { select } from 'redux-saga/effects';

export function* handleDownloadMobile(action: PayloadAction<IDownloadItem>) {
    const { id = '' } = action.payload || {};
    const selected = yield select(SelectionsSelectors.getSelectedIdxs);
    const itemId = id || (selected.length === 1 && selected[0]) || '';
    const item = yield select(getItemById, itemId);
    const currentStorage = yield select(getCurrentStorage);
    const storage = action.payload?.storage || currentStorage;
    const ext = item?.ext;

    if (ext && downloadAsJpeg.isAvailable({ ext, storage })) {
        yield renderDownloadMobileDropdown({ id: itemId, storage });

        return;
    }

    toolbarActions.download({ id, storage });
}
