import classNames from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { IS_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { isNewPortalHeader } from 'reactApp/appHelpers/featuresHelpers';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { setIsFromAttaches } from 'reactApp/modules/attaches/attaches.actions';
import { AttachesSelectors } from 'reactApp/modules/attaches/attaches.selectors';
import {
    getFeatureAbMailAttachesNewEmptyAfterClose,
    getFeatureAbMailAttachesNewOverquotaEmptyAfterClose,
    getFeatureShowStories,
} from 'reactApp/modules/features/features.selectors';
import { feedLoadRequest } from 'reactApp/modules/feed/feed.module';
import { getCurrentFolderHome, getDomainFoldersFilterActive, isDefaultRootContent } from 'reactApp/modules/home/home.selectors';
import { checkUpdateLicenseRequest } from 'reactApp/modules/profile/profile.module';
import { getStoryListRequest } from 'reactApp/modules/stories/stories.module';
import { getStorySummaryCovers } from 'reactApp/modules/stories/stories.selectors';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { AutoDeleteFolderInformer } from 'reactApp/ui/AutoDeleteFolderInformer/AutoDeleteFolderInformer';
import { BreadcrumbsContainer } from 'reactApp/ui/BreadcrumbsContainer/BreadcrumbsContainer';
import { DataList } from 'reactApp/ui/Datalist/DataList';
import BizPageTabMenu from 'reactApp/ui/DataListItemRow/components/BizPageTabMenu/BizPageTabMenu';
import { StoriesWidget } from 'reactApp/ui/StoriesWidget/StoriesWidget';
import { WelcomeNewPromo } from 'reactApp/ui/WelcomePromo/WelcomeNewPromo/WelcomeNewPromo';
import { WelcomeOverquotaPromo } from 'reactApp/ui/WelcomePromo/WelcomeNewPromo/WelcomeOverquotaPromo';
import { WelcomePromo } from 'reactApp/ui/WelcomePromo/WelcomePromo';
import { scrollToTop } from 'reactApp/utils/helpers';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './HomePage.css';

const AB_MAX_USED_SPACE_PERCENT = 80;

// eslint-disable-next-line complexity, max-lines-per-function
export const HomePage: React.VFC<{ initialItemId?: string }> = ({ initialItemId = '' }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const [showInformer, setShowInformer] = useState(true);

    const item = useSelector(getCurrentFolderHome);
    const { id, isFolder } = item || {};
    const isDefaultContent = useSelector(isDefaultRootContent);
    const isShowStoryEnabled = useSelector(getFeatureShowStories);
    const storeStories = useSelector(getStorySummaryCovers);
    const domainFoldersFilterActive = useSelector(getDomainFoldersFilterActive);
    // tempexp_15949-next-line
    const isAbMailAttachesNewEmpty = useSelector(getFeatureAbMailAttachesNewEmptyAfterClose) === 'b';
    // tempexp_15952-next-line
    const isAbMailAttachesNewOverquotaEmptyAfterClose = useSelector(getFeatureAbMailAttachesNewOverquotaEmptyAfterClose) === 'b';
    const { usedPercent } = useSelector(UserQuotaSelectors.getUserQuotaState);
    const { isLoaded } = useSelector(UserQuotaSelectors.getLoadingState);
    const isFromAttaches = useSelector(AttachesSelectors.getIsFromAttaches);

    const userHasStories = storeStories?.length > 0;
    const isRoot = isFolder && id === ROOT_FOLDER_ID;
    const showWidgets = isRoot && isShowStoryEnabled;
    const showBizPageTabMenu = !domainFoldersFilterActive && item?.isFolder && item?.childs.length > 0;

    useEffect(() => {
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'home' });
        sendPaymentGa({
            eventCategory: ECategoryGa.entered,
            action: 'page-entered',
            source: isFromAttaches ? 'from-mail' : 'home',
            source_to: 'home',
        });

        return () => {
            if (isFromAttaches) {
                dispatch(setIsFromAttaches(false));
            }
        };
    }, [isFromAttaches]);

    useEffect(() => {
        if (isDefaultContent) {
            dispatch(checkUpdateLicenseRequest());
        }
    }, [isDefaultContent]);

    useEffect(() => {
        // TODO: routing
        // Без preventPush в историю браузера добавляется лишние айтемы с тем же адресом.
        // Но с ним баг гораздо хуже - при подьеме из внутренней папки через кнопку Бек браузера,
        // урл в строке не соответствует содержимому даталиста
        // if (preventPush) {
        //     console.log('dispatch change hist from home: revert preventPush ', location.pathname, location);
        //     dispatch(preventHistoryPush(false));
        //     return;
        // }
    }, [location.pathname]);

    useEffect(() => {
        if (showWidgets) {
            dispatch(getStoryListRequest());
            dispatch(feedLoadRequest());
        }
    }, [dispatch, showWidgets]);

    useEffect(() => {
        scrollToTop();
    }, [id]);

    const hideInformer = useCallback(() => {
        setShowInformer(false);
    }, []);

    // tempexp_15949-start
    if (isDefaultContent && !IS_BIZ_USER) {
        if (isAbMailAttachesNewEmpty && usedPercent < AB_MAX_USED_SPACE_PERCENT) {
            return (
                <div className={classNames(styles.promo, { [styles.promo_new_portal_header]: isNewPortalHeader.client })}>
                    <WelcomeNewPromo />
                </div>
            );
        }

        // tempexp_15952-start
        if (isAbMailAttachesNewOverquotaEmptyAfterClose && usedPercent >= AB_MAX_USED_SPACE_PERCENT) {
            return (
                <div className={classNames(styles.promo, { [styles.promo_new_portal_header]: isNewPortalHeader.client })}>
                    <WelcomeOverquotaPromo />
                </div>
            );
        }

        if (isAbMailAttachesNewOverquotaEmptyAfterClose && !isLoaded) {
            return null;
        }
        // tempexp_15952-end
    }
    // tempexp_15949-end

    if (isDefaultContent) {
        return (
            <div className={classNames(styles.promo, { [styles.promo_new_portal_header]: isNewPortalHeader.client })}>
                <WelcomePromo />
            </div>
        );
    }

    return (
        <div className={styles.root}>
            {showWidgets && userHasStories && !isNewPortalHeader.client && <StoriesWidget />}
            {!isNewPortalHeader.client && (
                <BreadcrumbsContainer
                    className={classNames({
                        [styles.breadcrumbs_new_portal_header]: isNewPortalHeader.client && item?.childs.length === 0,
                    })}
                />
            )}
            {showBizPageTabMenu && <BizPageTabMenu />}
            {showInformer && item && isFolder && (
                <AutoDeleteFolderInformer
                    className={classNames({ [styles.autoDeleteInformer_new_portal_header]: isNewPortalHeader.client })}
                    onClose={hideInformer}
                    item={item}
                />
            )}
            <DataList initialItemId={initialItemId} />
        </div>
    );
};
