/* eslint-disable max-lines-per-function */
import classNames from 'clsx';
import hotkeys from 'hotkeys-js';
import debounce from 'lodash.debounce';
import React, { memo, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { ENABLE_FULL_RESPONSIVE, IS_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { useHotKeyScope } from 'reactApp/hooks/useHotkeyScope';
import { getAlbumsBreadcrumbs } from 'reactApp/modules/albums/albums.selector';
import { hideDialog, showDialog } from 'reactApp/modules/dialog/dialog.module';
import { loadHomeFolderRequest } from 'reactApp/modules/home/home.actions';
import { getDomainFoldersFilterActive, getRootFolderList } from 'reactApp/modules/home/home.selectors';
import { HomeFolder } from 'reactApp/modules/home/home.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { SelectionsSelectors } from 'reactApp/modules/selections/selections.selectors';
import { getBreadCrumbs, getCurrentFolder, getStorageSelectedItems } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { RootState } from 'reactApp/store';
import { Button } from 'reactApp/ui/Button/Button';
import Content from 'reactApp/ui/Content/Content';
import { renderAlertToPublicDialog } from 'reactApp/ui/NoWarningDialog/NoWarningDialog.helpers';
import { TreeComponent } from 'reactApp/ui/TreeComponent/TreeComponent';
import { ETreeNodeMode, ETreeRootIds } from 'reactApp/ui/TreeComponent/TreeComponent.constants';
import { treeRootConfig } from 'reactApp/ui/TreeComponent/TreeComponent.data';
import { getFoldersTree, loadFolder } from 'reactApp/ui/TreeComponent/TreeComponent.helpers';
import { noop } from 'reactApp/utils/helpers';

import styles from './SelectFolderDialog.css';
import { DIALOG_COMPONENTS_TYPES, getMainTitle, getSubjectTitle } from './SelectFolderDialog.helpers';
import { EActionId, Props, State } from './SelectFolderDialog.types';

type ProcessSelectedItemProps = Partial<Pick<State, 'isSharedOrMounted' | 'isThisOrParentPublic'>> & {
    id: string;
    scrollToIt?: boolean;
    storage?: EStorageType;
};

const HOT_KEY_SCOPE_NAME = 'SelectFolderDialog';

export const SelectFolderDialog = memo(
    ({
        selectedId,
        items,
        onActionClick = () => Promise.resolve(''),
        onClose = noop,
        onShowDialog = noop,
        onHideDialog = noop,
        requestLoadFoldersTree = noop,
        onSelect = () => Promise.resolve({ disableAction: false }),
        onMakeNewFolder = () => Promise.resolve(''),
        closeOnAction = false,
        alertApproveTitle = '',
        actionPart = '',
        rootFolderList,
        autoCreateSelectedId,
        actionId,
        selectedItems,
        homeList,
        isPhone,
        selectButtonText,
        onClosePopup,
        waiterText,
        title,
        fullTitle,
        onExpand,
        disableSelectSameId = true,
        domainFoldersFilterActive,
    }: Props): ReactElement | null => {
        const contentRef = useRef<HTMLDivElement | null>(null);
        const scrollIntoViewRef = useRef<HTMLDivElement | null>();

        const [disableAction, setDisableAction] = useState(true);
        const [isInAction, setIsInAction] = useState(false);
        const [isSharedOrMounted, setIsSharedOrMounted] = useState(false);
        const [isThisOrParentPublic, setIsThisOrParentPublic] = useState(false);
        const [showOnClosePopup, setShowOnClosePopup] = useState(false);
        const [selectedIdState, setSelectedIdState] = useState(selectedId || items?.[0]?.id);
        const [shouldAutoCreate, setShouldAutoCreate] = useState(
            autoCreateSelectedId && !items?.[0].children?.find((item) => item.id === selectedIdState)
        );

        const selectedNodeId = selectedIdState ? `home${selectedIdState}` : '';

        const initialId = useMemo(() => selectedIdState && selectedId === selectedIdState, [selectedId]);

        useHotKeyScope(HOT_KEY_SCOPE_NAME);

        const scrollCurrentIntoView = useCallback(() => {
            scrollIntoViewRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, []);

        const debouncedScrollIntoView = useMemo(() => debounce(scrollCurrentIntoView, 250), [scrollCurrentIntoView]);

        const processSelectedItem = useCallback<(data: ProcessSelectedItemProps) => void>(
            ({ id, storage = EStorageType.home, isSharedOrMounted = false, isThisOrParentPublic = false, scrollToIt = false }) => {
                setSelectedIdState(id);
                setIsSharedOrMounted(isSharedOrMounted);
                setIsThisOrParentPublic(isThisOrParentPublic);

                if (scrollToIt) {
                    debouncedScrollIntoView();
                }

                if (disableSelectSameId && selectedItems.some((item) => item.id === id) && !domainFoldersFilterActive) {
                    setDisableAction(true);
                    return;
                }

                onSelect({ id, storage }).then(({ disableAction }) => setDisableAction(disableAction));
            },
            [selectedItems, disableSelectSameId, onSelect, debouncedScrollIntoView, domainFoldersFilterActive]
        );

        const doActionClick = useCallback(() => {
            onActionClick({ items: selectedItems, destination: selectedIdState });

            if (closeOnAction) {
                onClose();
                return;
            }

            setIsInAction(true);
        }, [setIsInAction, closeOnAction, onClose, onActionClick, selectedItems, selectedIdState]);

        const handleActionClick = useCallback(() => {
            const needShowAlert = [EActionId.COPY, EActionId.MOVE, EActionId.SELECT_FOLDER_PATH_TO_UPLOAD].includes(actionId);
            const destinationIsMountedSharedOrDomainFolder = Boolean(homeList.find(({ id }) => id === selectedIdState));
            const isDestinationPublicFolder = destinationIsMountedSharedOrDomainFolder || isSharedOrMounted || isThisOrParentPublic;

            if (disableAction) {
                return;
            }

            if (isDestinationPublicFolder && needShowAlert) {
                renderAlertToPublicDialog({
                    title: alertApproveTitle,
                    subjectTitle: getSubjectTitle(selectedItems),
                    onActionClick: doActionClick,
                    actionPart,
                });

                return;
            }

            doActionClick();
            setDisableAction(true);
        }, [disableAction, setDisableAction, doActionClick, actionId, selectedIdState, homeList, isSharedOrMounted, isThisOrParentPublic]);

        useEffect(() => {
            onShowDialog(actionId);

            // Если на других страницах не загружен хомяк - надо серверное дерево положить в стор
            const needLoadHome = !rootFolderList || (rootFolderList.count?.all && !rootFolderList.childs?.length);
            if (items?.[0]?.id === ETreeRootIds.home && needLoadHome) {
                requestLoadFoldersTree();
            }

            return () => {
                onHideDialog(actionId);
            };
        }, []);

        useEffect(() => {
            if (initialId && shouldAutoCreate) {
                setShouldAutoCreate(false);

                onMakeNewFolder({
                    id: ROOT_FOLDER_ID,
                    autoCreateFolderId: selectedIdState.replace(/\//, ''),
                    storage: EStorageType.home,
                }).then((newFolderId) => {
                    if (newFolderId) {
                        processSelectedItem({
                            id: newFolderId,
                            scrollToIt: true,
                        });
                    }
                });
            }
        }, [initialId, selectedIdState, onMakeNewFolder, shouldAutoCreate, processSelectedItem]);

        useEffect(() => {
            if (initialId) {
                setDisableAction(false);
            }
        }, [initialId]);

        useEffect(() => {
            hotkeys('enter', HOT_KEY_SCOPE_NAME, () => {
                if (!disableAction) {
                    handleActionClick();
                }
            });

            return () => {
                hotkeys.unbind('enter', HOT_KEY_SCOPE_NAME);
            };
        }, [disableAction, handleActionClick]);

        const handleScrollIntoViewRef = useCallback((ref) => {
            if (!ref) {
                return;
            }

            scrollIntoViewRef.current = ref;
        }, []);

        const handleNewFolderClick = useCallback(async () => {
            const newFolderId = await onMakeNewFolder({ id: selectedIdState, storage: EStorageType.home });
            if (newFolderId) {
                processSelectedItem({ id: newFolderId, isSharedOrMounted, isThisOrParentPublic, scrollToIt: true });
            }
        }, [onMakeNewFolder, selectedIdState, processSelectedItem, isSharedOrMounted, isThisOrParentPublic]);

        const handleClose = useCallback(() => {
            onClose();
            setShowOnClosePopup(true);
        }, [setShowOnClosePopup, onClose]);

        const footer = (
            <div
                className={classNames(styles.buttonWrapper, {
                    [styles.buttonWrapper_mobile]: !!isPhone,
                    [styles.buttonWrapperResponsive]: ENABLE_FULL_RESPONSIVE,
                })}
            >
                <Button theme="octavius" primary onClick={handleActionClick} middle disabled={disableAction} data-name="action">
                    {selectButtonText}
                </Button>
                <Button theme="octavius" onClick={handleNewFolderClick} middle data-name="create">
                    Создать папку
                </Button>
            </div>
        );

        const handleClosePopup = useCallback(() => {
            setShowOnClosePopup(false);
        }, [setShowOnClosePopup]);

        if (showOnClosePopup && onClosePopup) {
            return onClosePopup({ onClosePopup: handleClosePopup });
        }

        const Dialog = DIALOG_COMPONENTS_TYPES[isPhone ? 'mobile' : 'desktop'];

        return (
            <Dialog
                open
                onClose={handleClose}
                footer={footer}
                closeOnDimmerClick={!!isPhone}
                id={`select-folder-dialog${actionId ? `-${actionId}` : ''}`}
                scrollRef={isPhone ? contentRef : undefined}
                title={getMainTitle({ selectedItems, isInAction, waiterText, title, fullTitle, isPhone })}
                className={classNames({
                    [styles.root_dialog]: ENABLE_FULL_RESPONSIVE,
                })}
            >
                <Content
                    wrapClass={classNames(styles.root, { [styles.root_mobile]: !!isPhone })}
                    isModal
                    isLoading={false}
                    hasError={false}
                    scrolling
                >
                    <div
                        className={classNames(styles.content, {
                            [styles.contentColumn]: IS_BIZ_USER,
                            [styles.content_responsive]: ENABLE_FULL_RESPONSIVE,
                        })}
                        ref={contentRef}
                    >
                        <TreeComponent
                            items={items}
                            selectedId={selectedNodeId}
                            treeNodeMod={ETreeNodeMode.selectDlg}
                            onClick={processSelectedItem}
                            onExpand={onExpand}
                            gaCategory="selectFolder"
                            handleScrollIntoViewRef={handleScrollIntoViewRef}
                        />
                    </div>
                </Content>
            </Dialog>
        );
    }
);

SelectFolderDialog.displayName = 'SelectFolderDialog';

const mapStateToProps = (state: RootState, props: Props & { skipRoFolders?: boolean; disableSelectDirectParentOfSelected?: boolean }) => {
    const domainFoldersFilterActive = getDomainFoldersFilterActive(state);
    /*
        ищем items в treeRootConfig.find((item) => item.id === ETreeRootIds.home), если не находим берем items из treeRootConfig по индексу.
        индекс 0 соответствует home, индекс 1 domain
    */
    const items = IS_BIZ_USER
        ? [
              treeRootConfig.find((item) => item.id === ETreeRootIds.home) || treeRootConfig[0],
              treeRootConfig.find((item) => item.id === ETreeRootIds.domain) || treeRootConfig[2],
          ]
        : [treeRootConfig.find((item) => item.id === ETreeRootIds.home) || treeRootConfig[0]];

    const tree = getFoldersTree(state, items, props.dialogMod, IS_BIZ_USER ? true : props.skipRoFolders);

    const selectedItems = (
        props.selectedItems?.length > 0
            ? props.selectedItems
            : getStorageSelectedItems(state, SelectionsSelectors.getStorageSelected(state))
    ).map((item) => ({ ...item }));

    if (selectedItems.length === 0) {
        const id = getCurrentFolder(state)?.id;
        if (id) {
            selectedItems.push({
                id,
                isFolder: true,
            });
        }
    }
    const handleOnSelect = ({ id, storage }) =>
        new Promise<{ disableAction: boolean }>(function (resolve) {
            if (props.onSelect) {
                return props.onSelect({ id, storage }).then(({ disableAction }) => {
                    if (domainFoldersFilterActive && id === ROOT_FOLDER_ID) {
                        resolve({ disableAction: true });
                    }
                    resolve({ disableAction });
                });
            }

            const currentStorage = getCurrentStorage(state);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const breadCrubmbs = currentStorage === EStorageType.albums ? getAlbumsBreadcrumbs(state) : getBreadCrumbs(state, id);
            const breadCrubmbsIdxs = breadCrubmbs.map((item) => item.id);

            const disableAction = domainFoldersFilterActive
                ? false
                : selectedItems.some(
                      (item) => breadCrubmbsIdxs.includes(item.id) || (props.disableSelectDirectParentOfSelected && id === item.parent)
                  );

            resolve({ disableAction });
        });

    return {
        items: tree,
        selectedItems,
        onSelect: handleOnSelect,
        rootFolderList: getRootFolderList(state) as HomeFolder | undefined,
        homeList: Object.values(state.home.list).filter(
            (item) => item.kind === 'mounted' || item.kind === 'domain-folder' || item.kind === 'shared'
        ),
        domainFoldersFilterActive,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onExpand: (data) => loadFolder({ ...data, dispatch }),
    onShowDialog: (id) => dispatch(showDialog(id)),
    onHideDialog: (id) => dispatch(hideDialog(id)),
    requestLoadFoldersTree: () => dispatch(loadHomeFolderRequest({ id: ROOT_FOLDER_ID, isFolder: true })),
});

export const SelectFolderConnected = connect(mapStateToProps, mapDispatchToProps)(SelectFolderDialog);
