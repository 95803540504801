import { bytesToNDigits } from '@mail/cross-sizes-utils';
import { Text } from '@vkontakte/vkui';
import classNames from 'clsx';
import { path } from 'ramda';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
    isDmrMidasUrlEnabled,
    isHidePayMethodsMenuTouch,
    tariffBuyCustomDescription,
    tariffBuyCustomTitle,
} from 'reactApp/appHelpers/featuresHelpers';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import { BillingActions } from 'reactApp/modules/billing/billing.module';
import { BillingSelectors } from 'reactApp/modules/billing/billing.selectors';
import { getPayMethod } from 'reactApp/modules/buySubscription/buySubscription.helpers';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { Product } from 'reactApp/types/Billing';
import { SpecialTariffProps } from 'reactApp/types/SpecialTariffProps';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { DMR } from 'reactApp/ui/DMRFrame/DMR';
import { PaymentFooter } from 'reactApp/ui/PaymentFooter/PaymentFooter';
import { noop } from 'reactApp/utils/helpers';
import { EPaymentGa, sendPaymentGa } from 'reactApp/utils/paymentGa';
import { getPeriodName } from 'reactApp/utils/Period';
import { formatPrice } from 'reactApp/utils/priceHelpers';
import { sendTmrGoal } from 'reactApp/utils/tmr';
import { AnyAction } from 'redux';

import styles from './TariffBuy.css';

interface Props extends SpecialTariffProps {
    link: string | null;
    isLoading: boolean;
    hasError: boolean;
    isMobile: boolean;
    hasTrial: boolean;
    reset();
    buy(...args);
    id: Product['id'];
    product?: Product;
    onSuccess();
    onClose();
    onError();
    userTotalSpace: Product['space'];
    source?: string;
    isQuotaCleaner?: boolean;
    closable?: boolean;
    title?: string;
    payMethod?: string;
    isMidas?: boolean;
}

interface State {
    isOpen: boolean;
    isSuccessPayment: boolean;
    frameHeight: number;
}

const mapStateToProps = (state, props): any => {
    const { isMobile } = props;

    const product = props.product || ProductsSelectors.getProductById(state, props.id) || {};
    const { space, period, price, hasTrial } = product;

    const { total: userTotalSpace } = UserSelectors.getCloudSpace(state);

    const { isLoading, hasError } = BillingSelectors.getLoadingState(state);
    const buyLink = BillingSelectors.getPaymentLink(state);

    const isQuickPayment = (isMobile && isHidePayMethodsMenuTouch) || hasTrial;

    return {
        product,
        link: buyLink,
        space,
        period,
        isLoading,
        hasError,
        price,
        userTotalSpace,
        hasTrial,
        payMethod: isQuickPayment ? undefined : getPayMethod(isMobile),
        isMidas: isDmrMidasUrlEnabled && !isMobile && !hasTrial,
    };
};

const mapDispatchToProps = (dispatch, props) => ({
    buy: (id: string, source, isMidas, payMethod): AnyAction => {
        const { isMobile } = props;

        const { action = null, description = null } = getQueryParams();
        const descriptionFromQuery =
            action === 'request-payment' && tariffBuyCustomDescription?.[description] ? tariffBuyCustomDescription[description] : undefined;

        return dispatch(
            BillingActions.buySubscription({
                id,
                isMobile,
                payMethod,
                source,
                xraySource: 'tariff-modal',
                description: descriptionFromQuery,
                isMidas,
            })
        );
    },
    reset: (): AnyAction => dispatch(BillingActions.resetLink()),
});

class TariffBuy extends PureComponent<Props, State> {
    public state = {
        isOpen: true,
        isSuccessPayment: false,
        frameHeight: 0,
    };

    public static defaultProps = {
        onSuccess: noop,
        onClose: noop,
        onError: noop,
        closable: true,
    };

    public componentDidMount(): void {
        const { id, buy, source, isMidas, payMethod } = this.props;
        buy(id, source, isMidas, payMethod);
        this.sendPayment('view');
    }

    public componentDidUpdate(prevProps: Props): void {
        const { hasError, onError } = this.props;
        if (!prevProps.hasError && hasError) {
            onError();
        }
    }

    sendPayment = (label) => {
        const { id } = this.props;

        sendPaymentGa({ action: EPaymentGa.payment, label: `${label}_v2`, tariff: id });
    };

    private handleOnClose = (): void => {
        const { onClose } = this.props;

        onClose();

        this.setState({
            isOpen: false,
        });
    };

    private handleOnCancelDialog = (): void => {
        this.handleOnClose();
        this.sendPayment('close');
    };

    private handleDmrFormSend = (): void => {
        this.sendPayment('click');
    };

    private handleOnSuccess = (): void => {
        const { onSuccess, hasTrial } = this.props;

        sendTmrGoal({ goal: hasTrial ? 'trial-success' : 'pay-success' });

        this.setState({ isSuccessPayment: true }, () => {
            onSuccess();
        });
    };

    private handleError = (): void => {
        this.props.onError();
    };

    private handleResize = (data): void => {
        const newHeight = path(['height'], data) as number;
        const { frameHeight } = this.state;
        if (newHeight && frameHeight !== newHeight) {
            this.setState({ frameHeight: newHeight });
        }
    };

    private getHeader() {
        const { isSuccessPayment } = this.state;

        const { space, period, userTotalSpace, isMobile, hasTrial, price, product, title } = this.props;
        const { hasDiscount, discountPrice, discountPeriod, trialPeriod, isForceDiscountTrial } = product || {};

        const { title: titleFromQuery = null, action = null } = getQueryParams();

        if (action === 'request-payment' && tariffBuyCustomTitle?.[titleFromQuery]) {
            return tariffBuyCustomTitle?.[titleFromQuery];
        }

        if (title) {
            return title;
        }

        if (isSuccessPayment) {
            return 'Оплата прошла успешно';
        }

        if (isMobile && space) {
            if (isForceDiscountTrial) {
                return `Оплата тарифа ${space.value}`;
            }

            const priceToDisplay = hasDiscount ? discountPrice : price;
            const periodToDisplay = hasDiscount && discountPeriod ? discountPeriod : period;

            const priceText = priceToDisplay ? ` за ${formatPrice(priceToDisplay)} ₽` : '';

            return hasTrial
                ? `Введите данные карты для получения ${space.value}${trialPeriod ? ` на ${getPeriodName(trialPeriod, false, true)}` : ''}`
                : `Оплата тарифа\n${space.value} на ${getPeriodName(periodToDisplay, false, true)}${priceText}`;
        }

        if (userTotalSpace && space) {
            const newSpace = bytesToNDigits(userTotalSpace.original + space.original, 3).value;
            return `Увеличение объёма до ${newSpace}`;
        }

        return 'Подписка на Облако';
    }

    private getDescription() {
        const { product } = this.props;

        if (product?.isForceDiscountTrial && product?.trialPeriod && product?.discountPeriod) {
            return (
                <div className={styles.description}>
                    {getPeriodName(product.trialPeriod, true)} бесплатно, затем {formatPrice(product.discountPrice)}&nbsp;₽ за&nbsp;
                    {getPeriodName(product.discountPeriod)}
                </div>
            );
        }

        return null;
    }

    public render() {
        const { isSuccessPayment, isOpen, frameHeight } = this.state;

        if (!isOpen) {
            return null;
        }

        const {
            isLoading,
            reset,
            product,
            hasError,
            link,
            isMobile,
            id,
            hasTrial,
            buy,
            source,
            isQuotaCleaner,
            closable,
            payMethod,
            isMidas,
        } = this.props;

        return (
            <Dialog
                id="tariff-buy"
                open
                size="larger"
                header={this.getHeader()}
                onCancel={this.handleOnCancelDialog}
                mod={isMobile ? 'mobile' : 'frame'}
                closeOnDimmerClick={false}
                closeOnEscape={false}
                dimmer={!isMobile}
                showCloseIcon
                disableScrollOnBody={!isQuotaCleaner}
                closable={closable}
                disableDarkTheme
                className={classNames({
                    [styles.midasModal]: isMidas,
                    [styles.mobileModal]: isMobile,
                })}
            >
                {this.getDescription()}
                <div
                    className={classNames(styles.root, {
                        [styles.root_mobile]: isMobile,
                        [styles.root_trial]: hasTrial,
                    })}
                >
                    {hasTrial && (
                        <Text className={styles.note} data-qa-id="note-check-payment">
                            Для проверки карты мы спишем и сразу же вернём&nbsp;1&nbsp;₽.
                        </Text>
                    )}
                    <div style={frameHeight ? { height: frameHeight } : {}} className={classNames(styles.dmrContainer)}>
                        <DMR
                            buySubscription={(productId) => buy(productId, source, isMidas, payMethod)}
                            resetSubscription={reset}
                            subscription={product}
                            onCancel={this.handleOnClose}
                            onSuccess={this.handleOnSuccess}
                            onFormSend={this.handleDmrFormSend}
                            isLoading={isLoading}
                            hasError={hasError}
                            onError={this.handleError}
                            onResize={this.handleResize}
                            checkoutLink={link}
                            className={styles.dmr}
                            disableLoaderCentering
                            eventCategory={isMidas ? 'midasBuyModal' : 'newBuyModal'}
                            size={isMobile ? 'medium' : 'larger'}
                            skin="vkpay"
                            payMethod={payMethod}
                            isMidas={isMidas}
                        />
                    </div>
                    {!isMidas && (
                        <div
                            className={classNames(styles.footer, {
                                [styles.footer_success]: isMobile && isSuccessPayment,
                            })}
                        >
                            <PaymentFooter productId={id} isMobile={isMobile} product={this.props.product} />
                        </div>
                    )}
                </div>
            </Dialog>
        );
    }
}

export default TariffBuy;

export const TariffBuyConnected = connect(mapStateToProps, mapDispatchToProps)(TariffBuy);
