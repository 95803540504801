import { useSelector } from 'react-redux';
import { getIntegrationQueryString } from 'reactApp/modules/integration/integration.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { ETreeRootIds } from 'reactApp/ui/TreeComponent/TreeComponent.constants';

export const useHref = (storage: EStorageType, useRouterLink: boolean, isBiz: boolean) => {
    const { isAllDocuments, isIntegration } = getStorage(storage);
    const integrationQueryString = useSelector(getIntegrationQueryString);

    if (isBiz) {
        return '/';
    }

    if (!useRouterLink) {
        return '/';
    }

    if (isAllDocuments) {
        return ETreeRootIds.alldocumentsDocument;
    }
    if (isIntegration) {
        return `/${EStorageType.integration}${integrationQueryString}`;
    }

    return '/home';
};
