import classNames from 'clsx';
import CloudIcon from 'img/portal-menu/cloud.svg';
import CloudDocumentsIcon from 'img/portal-menu/cloud-documents.svg';
import CloudWhiteIcon from 'img/portal-menu/cloud-white.svg';
import { ReactComponent as VKIcon } from 'img/portal-menu/vk.svg';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { ENABLE_FULL_RESPONSIVE, IS_CLOUD_LOCKED } from 'reactApp/appHelpers/configHelpers';

import styles from './Logo.css';

export enum ELogoSize {
    m = 'm',
    s = 's',
}

interface IProps {
    isBiz: boolean;
    href?: string;
    isPhone: boolean;
    size?: ELogoSize;
    bizLogo?: string;
    bizBrandName?: string;
    white?: boolean;
    useRouterLink?: boolean;
    isDocuments?: boolean;
    withEndorsement?: boolean;
    errorPage?: boolean;
}

export const LogoComponent: React.FC<IProps> = ({
    href = '/',
    isDocuments,
    isPhone,
    isBiz,
    size = ELogoSize.m,
    bizLogo,
    bizBrandName,
    white,
    useRouterLink,
    withEndorsement = true,
    errorPage = false,
}): ReactElement => {
    const whiteLogo = white || isPhone;
    const logoSize = isPhone ? ELogoSize.s : size;

    const icon = (
        /* @ts-ignore */
        <img className={styles.logo} src={whiteLogo ? CloudWhiteIcon : isDocuments ? CloudDocumentsIcon : CloudIcon} importance="high" />
    );

    const logoLinkProps = {
        title: 'Облако Mail.ru',
        className: styles.cloud,
        children: icon,
    };

    return (
        <div
            className={classNames({
                [styles.root]: true,
                [styles[`size_${logoSize}`]]: !!logoSize,
                [styles.root_white]: whiteLogo,
                [styles.root_my_team]: ENABLE_FULL_RESPONSIVE,
                [styles.bizLocked]: ENABLE_FULL_RESPONSIVE && IS_CLOUD_LOCKED,
            })}
        >
            {isBiz ? (
                <a href={href} className={classNames({ [styles.biz]: true, [styles.hidden_logo]: errorPage })} title={bizBrandName}>
                    {/* @ts-ignore */}
                    <img className={styles.logo} src={bizLogo} height={32} width={164} importance="high" />
                </a>
            ) : (
                <>
                    {useRouterLink ? <Link to={href} {...logoLinkProps} /> : <a href={href} {...logoLinkProps} />}
                    {withEndorsement && (
                        <a href="https://trk.mail.ru/c/o90pw9" className={styles.vk} target="_blank" title="Экосистема VK" rel="noreferrer">
                            <VKIcon className={styles.endorsment} />
                        </a>
                    )}
                </>
            )}
        </div>
    );
};
