import { Middleware } from '@reduxjs/toolkit';
import { toggleActionPanel } from 'reactApp/modules/actionpanel/actionpanel.actions';
import { getBizFilteredItems } from 'reactApp/modules/bizCategories/bizCategories.helpers';
import { SelectionsSelectors } from 'reactApp/modules/selections/selections.selectors';
import { CloudItem } from 'reactApp/modules/storage/storage.types';
import { AnyAction } from 'redux';

export const actionPanelMiddleware: Middleware =
    ({ getState, dispatch }) =>
    (next) =>
    (action: AnyAction) => {
        const eligibleModals = ['file-history-copy', 'file-history-rewrite'];
        const closingActions = [
            'home/changeHistory',
            'modifying/createNewItem',
            'modifying/removeRequest',
            'router/changeHistory',
            'showDialog/show',
            'uploader/setUploadFrom',
            'uploaderList/setHasInputAction',
        ];

        let shouldBizFilterClosePanel = false;
        if (action.type === 'bizCategories/setCurrentCategory') {
            const selectedItems = SelectionsSelectors.getSelectedItems(getState()) as CloudItem[];
            const filteredBizItems = getBizFilteredItems(selectedItems, action.payload);
            shouldBizFilterClosePanel = selectedItems.length === 1 && selectedItems[0] !== filteredBizItems[0];
        }

        const shouldClosePanel =
            shouldBizFilterClosePanel || (closingActions.includes(action.type) && !eligibleModals.includes(action.payload));

        if (shouldClosePanel) {
            dispatch(toggleActionPanel({ open: false }));
        }

        return next(action);
    };
