import { noopVoid } from 'reactApp/utils/helpers';

class FullScreenProcessor {
    private isFullScreenState = false;
    private isBrowserFullScreen = false;

    public init() {
        window.addEventListener('webkitfullscreenchange mozfullscreenchange fullscreenchange', this.handleFullscreenChange);
        window.matchMedia('(display-mode: fullscreen)')?.addEventListener?.('change', this.handleChange);
    }

    public switchFullscreen = (onSuccessEnter = noopVoid, onSuccessStop = noopVoid, onEnterError = noopVoid, onExitError = noopVoid) => {
        if (this.isFullScreenState || this.isBrowserFullScreen) {
            document
                .exitFullscreen()
                ?.then(() => {
                    this.isFullScreenState = false;
                    onSuccessStop();
                })
                ?.catch(onExitError);
        } else {
            document.documentElement
                .requestFullscreen?.()
                ?.then(() => {
                    this.isFullScreenState = true;
                    onSuccessEnter();
                })
                ?.catch(onEnterError);
        }
    };

    public isFullScreen = () => this.isFullScreenState || this.isBrowserFullScreen;

    public isFullScreenBrowserOnly = () => this.isBrowserFullScreen && !this.isFullScreenState;

    private handleChange = (event) => {
        this.isBrowserFullScreen = event.matches;
        if (!this.isBrowserFullScreen) {
            this.isFullScreenState = false;
        }
    };

    private handleFullscreenChange = (event) => {
        // eslint-disable-next-line compat/compat
        this.isFullScreenState = document.fullscreenElement === event.target;
    };
}

export const fullScreenProcessor = new FullScreenProcessor();
