import { PayloadAction } from '@reduxjs/toolkit';
import config from 'Cloud/config';
import { logger } from 'lib/logger';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getFolderPath, getSlicedHome, isFolder, removeTailSlash } from 'reactApp/modules/file/utils';
import { getHomeItemById } from 'reactApp/modules/home/home.selectors';
import { historyPush, routerStart } from 'reactApp/modules/router/router.module';
import { getCurrentRouteId, getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { IHistoryPush } from 'reactApp/modules/router/router.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { checkViewerAtIdChange } from 'reactApp/modules/viewer/viewer.module';
import { getEncodePath, getPathName, getStorageAwareFolderPath } from 'reactApp/utils/urlHelper';
import { put, select } from 'redux-saga/effects';

const SERVER_HOME = config.get('serverSideFolders')?.folder?.home || config.get('serverSideFolders')?.folder?.path;

export function* changeHistorySaga(action: PayloadAction<IHistoryPush>) {
    const { id, search } = action.payload;
    const isPhone = yield select(EnvironmentSelectors.isPhone);

    let idFixed;
    try {
        idFixed = decodeURIComponent(removeTailSlash(getSlicedHome(id)));
    } catch (error) {
        idFixed = getSlicedHome(id);
        logger.error(error);
    }
    const routeId = yield select(getCurrentRouteId);

    if (routeId !== ROOT_FOLDER_ID && routeId !== idFixed && routeId.toLowerCase() === idFixed.toLowerCase()) {
        idFixed = routeId;
    }

    const item = yield select(getHomeItemById, idFixed);
    const storage = (yield select(getCurrentStorage)) === EStorageType.integration ? EStorageType.integration : EStorageType.home;
    const isDirectPath = !item; // при первом заходе на страницу
    let isHomeFile = false;
    let folderPath;

    if (isDirectPath) {
        const slicedPath = decodeURIComponent(removeTailSlash(getSlicedHome(getPathName())));
        const isFolderItem = SERVER_HOME === slicedPath;
        folderPath = decodeURIComponent(getFolderPath(id, isFolderItem));

        isHomeFile = folderPath === ROOT_FOLDER_ID && !isFolderItem;

        if (id === '/') {
            yield put(historyPush({ id: '/', search })); // если переходим на корень хомяка и меню схлопнуто
        }
    } else {
        folderPath = getFolderPath(item.home, isFolder(item));
        const pathname = getStorageAwareFolderPath(storage, getEncodePath(item.home));

        yield put(historyPush({ id: pathname, search }));
    }

    if (item && !isPhone) {
        // TODO: routing: перенести все в общую ротуинг сагу CLOUDWEB-13172
        yield put(checkViewerAtIdChange({ id: item.id, storage: EStorageType.home }));
    }

    // при переходе стрелками браузера в id содержится home и становится сложно отличить от первого захода на страницу
    if (folderPath === ROOT_FOLDER_ID && isDirectPath && !isHomeFile) {
        folderPath = getSlicedHome(id) || ROOT_FOLDER_ID;
    }

    yield put(
        routerStart({
            id: folderPath,
            params: {},
            storage,
            url: folderPath,
            __isFolder: true,
            __parent: '/',
        })
    );
}
