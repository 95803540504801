import { useSelector } from 'react-redux';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { EProductPeriod } from 'reactApp/modules/products/products.types';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { RootState } from 'reactApp/store';
import { Tariff } from 'reactApp/types/Billing';

export const useTariffs = ({ isPro, isOneTariff }: { isPro: boolean; isOneTariff: boolean }): Tariff[] => {
    const userSpace = useSelector(UserQuotaSelectors.getTotal);

    const defaultTariffs = useSelector((state: RootState) => ProductsSelectors.getYearAvailableTariffs(state, false));

    const { tariff: proTariff } = useSelector((state: RootState) =>
        ProductsSelectors.getProductAndTariffByQuota(state, userSpace?.original, EProductPeriod.year, isPro)
    );

    const discountTariff = useSelector((state: RootState) => ProductsSelectors.getQuotaDiscountTariff(state, userSpace?.original));

    if (isPro && proTariff) {
        return [proTariff];
    }

    if (isOneTariff && discountTariff) {
        return [discountTariff];
    }

    return defaultTariffs.slice(0, 3);
};
