import { equals } from 'ramda';
import { BindDeleteAPICall } from 'reactApp/api/billing/bind/BindDeleteAPICall';
import { BindListAPICall } from 'reactApp/api/billing/bind/BindListAPICall';
import { CardActions, normalizeCardData } from 'reactApp/modules/creditCard/creditCard.module';
import { CardSelectors } from 'reactApp/modules/creditCard/creditCard.selectors';
import { ECardNotifications, IApiLoadResponse } from 'reactApp/modules/creditCard/creditCard.types';
import { CreditCardDmrLinkActions } from 'reactApp/modules/creditCardDmrLink/creditCardDmrLink.module';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { renderCardModal } from 'reactApp/ui/AddCardModal/AddCardModal.helpers';
import { noopVoid } from 'reactApp/utils/helpers';
import ping from 'reactApp/utils/ping';
import { channel } from 'redux-saga';
import { call, cancel, put, select, take, takeEvery } from 'redux-saga/effects';

const getCardApiCall = () => new BindListAPICall().makeRequest();

interface IRemoveCardParams {
    bind_id?: string;
    source?: string;
}

function* loadCard() {
    try {
        const { data } = yield call(new BindListAPICall().makeRequest);
        yield put(CardActions.loadCardSuccess(data as IApiLoadResponse));
    } catch (error) {
        yield put(CardActions.hasError());
        yield cancel();
    }
}

function* removeCard(action) {
    const id = yield select(CardSelectors.getCardId);
    const { onSuccess = noopVoid, source } = action.payload;

    const params: IRemoveCardParams = {
        bind_id: id,
    };

    if (source) {
        params.source = source;
    }

    try {
        yield call(() => new BindDeleteAPICall().makeRequest(params));
        yield put(CardActions.removeCardSuccess());

        onSuccess();

        yield put(
            showSnackbarAction({
                type: SnackbarTypes.success,
                text: 'Карта успешно удалена',
                closable: true,
                id: ECardNotifications.removeSuccess,
            })
        );
    } catch (error: any) {
        yield put(CardActions.hasError());

        const { status } = error;

        yield put(
            showSnackbarAction({
                type: SnackbarTypes.failure,
                text: status === 406 ? 'Нет привязанных карт' : 'Не удалось удалить карту',
                closable: true,
                id: ECardNotifications.removeFailure,
            })
        );

        yield cancel();
    }
}

export function* updateCard(action) {
    const { card } = yield select(CardSelectors.getCard);
    const { showNotifications, onSuccess = noopVoid } = action.payload || {};

    try {
        const { data } = yield ping({
            request: getCardApiCall,
            check: ({ data }) => !equals(normalizeCardData(data.card), card),
        });

        yield put(CardActions.updateCardSuccess(data as IApiLoadResponse));

        if (showNotifications) {
            yield put(
                showSnackbarAction({
                    type: SnackbarTypes.success,
                    text: 'Карта обновлена',
                    closable: true,
                    id: ECardNotifications.updateSuccess,
                })
            );
        }

        onSuccess();
    } catch (error) {
        yield put(CardActions.hasError());

        if (showNotifications) {
            yield put(
                showSnackbarAction({
                    type: SnackbarTypes.failure,
                    text: 'Не удалось обновить карту',
                    closable: true,
                    id: ECardNotifications.updateFailure,
                })
            );
        }

        yield cancel();
    }
}

export function* showCardModal(action) {
    const cardChannel = channel();

    const { showNotifications, isSilent, isMobile, onCancel, onSuccess } = action.payload || {};

    yield renderCardModal({
        onSuccess: () => {
            onSuccess?.();
            cardChannel.put(true);
        },
        onClose: () => cardChannel.close(),
        isMobile,
        onCancel,
    });

    const isSuccess = yield take(cardChannel);

    if (isSuccess) {
        yield put(CardActions.updateCard({ showNotifications, isSilent }));
    }

    yield put(CreditCardDmrLinkActions.resetLink());
}

export function* watchCreditCard() {
    yield takeEvery(CardActions.loadCard.toString(), loadCard);
    yield takeEvery(CardActions.removeCard.toString(), removeCard);
    yield takeEvery(CardActions.updateCard.toString(), updateCard);
    yield takeEvery(CardActions.showModal.toString(), showCardModal);
}
