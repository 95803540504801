import React, { FC } from 'react';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { Loader } from 'reactApp/ui/Loader/Loader';

import styles from './CreateCopyOfNonEditableFileModal.css';
import { ContentType, CreateCopyOfNonEditableFileModalProps } from './types';

export const openCreateCopyOfNoneditableFileModal = (data: Omit<CreateCopyOfNonEditableFileModalProps, 'popupName'>) =>
    openPopupHelper({ popupName: popupNames.CREATE_COPY_OF_NONEDITABLEFILE, data });

const getContentType = (isSharedFile: boolean, fromSharePopup: boolean): ContentType => {
    if (fromSharePopup) {
        return ContentType.sharedPopup;
    }

    if (isSharedFile) {
        return ContentType.sharedHome;
    }

    return ContentType.home;
};

const CONTENT_COMPONENT_MAP: Record<ContentType, FC<{ convertExtension: string }>> = {
    [EStorageType.home]: ({ convertExtension }) => (
        <>
            Копия файла сохранится в формате <b>{convertExtension}</b>, и вы сможeте её отредактировать.
        </>
    ),
    sharedHome: ({ convertExtension }) => (
        <>
            Копия сохранится в формате <b>{convertExtension}</b>. Вы сможете отредактировать её и поделиться новой версией с&nbsp;другими
            людьми. Исходный файл будет по&#8209;прежнему доступен по ссылке.
        </>
    ),
    sharedPopup: ({ convertExtension }) => (
        <>
            Этот файл нельзя редактировать, но его копию в&nbsp;формате <b>{convertExtension}</b> — можно. Нажмите на кнопку ниже, чтобы
            создать её и дать другим доступ на&nbsp;редактирование.
        </>
    ),
};

export const ContentComponent: FC<{
    convertExtension: string;
    isSharedFile: boolean;
    fromSharePopup: boolean;
    isLoading: boolean;
}> = ({ fromSharePopup, isSharedFile, convertExtension, isLoading }) => {
    const contentType = getContentType(isSharedFile, fromSharePopup);
    const Component = CONTENT_COMPONENT_MAP[contentType];

    return isLoading ? (
        <div className={styles.loading}>
            <Loader centered isModal />
        </div>
    ) : (
        <div className={styles.text}>
            <Component convertExtension={convertExtension} />
        </div>
    );
};
