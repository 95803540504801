import { createAction } from '@reduxjs/toolkit';
import { ApiFolderResponse } from 'reactApp/modules/home/home.types';
import {
    AddFileSuccessAction,
    DeleteWeblinkExpiresRequest,
    EditPublicCopyItem,
    IAddToFavoritesRequest,
    IAddToFavoritesSuccess,
    ICloneToCloudRequest,
    ICopyItems,
    ICreateFolderAction,
    ICreateFolderSuccess,
    ICreateNewItem,
    IDownloadItem,
    IInlineEditSuccess,
    ILoadMoreRequest,
    IModifyingStopData,
    IMountRequest,
    IMoveItems,
    IOpenPublishDialog,
    IPublishRequest,
    IRemoveFromFavoritesRequest,
    IRemoveFromFavoritesSuccess,
    IRemoveItems,
    IRenameItem,
    IRenameItemSuccess,
    IShareFolderSuccess,
    IUnmountRequest,
    IUnPublishRequest,
    IUnshareFolderSuccess,
    MountActionSuccess,
    MoveItemsSuccessAction,
    PublishAutoDeleteFolderAction,
    RemoveFileSuccessAction,
    ResetWeblinkCountDownloads,
    ToggleWeblinkAccessRights,
    ToggleWeblinkDomestic,
    ToggleWeblinkDomesticRequest,
    ToggleWeblinkDownloadable,
    ToggleWeblinkDownloadableRequest,
    ToggleWeblinkEditableRequest,
    ToggleWeblinkUploadRequest,
    UnmountActionSuccess,
    UnpublishWeblinkAction,
    UpdateItemAction,
    UpdateWeblinkAction,
    UpdateWeblinkAutoDelete,
    UpdateWeblinkAutoDeleteRequest,
    UpdateWeblinkCountDownloads,
    UpdateWeblinkCountDownloadsRequest,
    UpdateWeblinkExpires,
    UpdateWeblinkExpiresRequest,
} from 'reactApp/modules/modifying/modifying.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';

export const addFilesSuccess = createAction<AddFileSuccessAction>('modifying/addFilesSuccess');
export const removeFileSuccess = createAction<RemoveFileSuccessAction>('modifying/removeFileSuccess');
export const renameFileRequest = createAction<IRenameItem>('modifying/renameFileRequest');
export const updateItem = createAction<UpdateItemAction>('modifying/updateItem');
export const updateFolder = createAction<ApiFolderResponse>('modifying/updateFolder');
export const publishWeblink = createAction<UpdateWeblinkAction>('weblinks/publish');
export const unPublishWeblink = createAction<UnpublishWeblinkAction>('weblinks/unPublish');
export const publishAutoDeleteFolder = createAction<PublishAutoDeleteFolderAction>('modifying/publishAutoDeleteFolder');
export const mountSuccess = createAction<MountActionSuccess>('modifying/mount/success');
export const unmountSuccess = createAction<UnmountActionSuccess>('modifying/unmount/success');
export const toggleWeblinkAccessRights = createAction<ToggleWeblinkAccessRights>('modifying/toggleWeblinkAccessRights');
export const toggleWeblinkDownloadable = createAction<ToggleWeblinkDownloadable>('modifying/toggleWeblinkDownloadable');
export const updateWeblinkCountDownloads = createAction<UpdateWeblinkCountDownloads>('modifying/updateWeblinkCountDownloads');
export const resetWeblinkCountDownloads = createAction<ResetWeblinkCountDownloads>('modifying/resetWeblinkCountDownloads');
export const updateWeblinkExpiresRequest = createAction<UpdateWeblinkExpiresRequest>('modifying/updateWeblinkExpiresRequest');
export const deleteWeblinkExpiresRequest = createAction<DeleteWeblinkExpiresRequest>('modifying/deleteWeblinkExpiresRequest');
export const updateWeblinkExpires = createAction<UpdateWeblinkExpires>('modifying/updateWeblinkExpires');
export const updateWeblinkAutoDeleteRequest = createAction<UpdateWeblinkAutoDeleteRequest>('modifying/updateWeblinkAutoDeleteRequest');
export const updateWeblinkAutoDelete = createAction<UpdateWeblinkAutoDelete>('modifying/updateWeblinkAutoDelete');
export const toggleWeblinkDomesticRequest = createAction<ToggleWeblinkDomesticRequest>('modifying/toggleWeblinkDomesticRequest');
export const toggleWeblinkDomestic = createAction<ToggleWeblinkDomestic>('modifying/toggleWeblinkDomestic');
export const toggleWeblinkEditableRequest = createAction<ToggleWeblinkEditableRequest>('modifying/toggleWeblinkEditableRequest');
export const toggleWeblinkDownloadableRequest = createAction<ToggleWeblinkDownloadableRequest>(
    'modifying/toggleWeblinkDownloadableRequest'
);
export const updateWeblinkCountDownloadsRequest = createAction<UpdateWeblinkCountDownloadsRequest>(
    'modifying/updateWeblinkCountDownloadsRequest'
);
export const toggleWeblinkUploadRequest = createAction<ToggleWeblinkUploadRequest>('modifying/toggleWeblinkUploadRequest');
export const createFolderAction = createAction<ICreateFolderAction>('modifying/createFolderAction');
export const createFolderSuccess = createAction<ICreateFolderSuccess>('modifying/createFolderSuccess');
export const checkFolderForUpdate = createAction<string | undefined>('modifying/checkFolderForUpdate');
export const createNewAutoDeleteFolder = createAction('modifying/createNewAutoDeleteFolder');
export const makeNewDocumentSuccess = createAction('modifying/makeNewDocumentSuccess');
export const makeNewDocumentError = createAction('modifying/makeNewDocumentError');
export const copyRequest = createAction<ICopyItems>('modifying/copyRequest');
export const moveRequest = createAction<IMoveItems>('modifying/moveRequest');
export const moveItemsSuccess = createAction<MoveItemsSuccessAction>('modifying/moveItemsSuccess');
export const removeRequest = createAction<IRemoveItems>('modifying/removeRequest');
export const renameItemSuccess = createAction<IRenameItemSuccess>('modifying/renameItemSuccess');
export const modifyingStart = createAction('modifying/modifyingStart');
export const modifyingStop = createAction<IModifyingStopData | undefined>('modifying/modifyingStop');
export const cloneToCloudSuccess = createAction<{ idx: string[]; home: string[]; storageFrom: EStorageType }>(
    'modifying/cloneToCloudSuccess'
);
export const cloneToCloudFailure = createAction('modifying/cloneToCloudFailure');
export const loadMoreRequest = createAction<ILoadMoreRequest>('modifying/loadMoreRequest');
export const publishRequest = createAction<IPublishRequest>('modifying/publishRequest');
export const unPublishRequest = createAction<IUnPublishRequest>('modifying/unPublishRequest');
export const unmountRequest = createAction<IUnmountRequest>('modifying/unmountRequest');
export const mountRequest = createAction<IMountRequest>('modifying/mountRequest');
export const shareFolderSuccess = createAction<IShareFolderSuccess>('modifying/shareFolderSuccess');
export const unshareFolderSuccess = createAction<IUnshareFolderSuccess>('modifying/unshareFolderSuccess');

export const addToFavorites = createAction<IAddToFavoritesRequest>('modifying/addToFavorites');
export const addToFavoritesSuccess = createAction<IAddToFavoritesSuccess>('modifying/addToFavoritesSuccess');
export const removeFromFavorites = createAction<IRemoveFromFavoritesRequest>('modifying/removeFromFavorites');
export const removeFromFavoritesSuccess = createAction<IRemoveFromFavoritesSuccess>('modifying/removeFromFavoritesSuccess');

export const openPublishDialog = createAction<IOpenPublishDialog>('modifying/openPublishDialog');

export const cloneRequest = createAction<ICloneToCloudRequest>('modifying/cloneRequest');
export const editPublicCopy = createAction<EditPublicCopyItem>('modifying/editPublicCopy');
export const downloadMobileItem = createAction<IDownloadItem | undefined>('modifying/downloadMobileItem');

export const createNewItem = createAction<ICreateNewItem>('modifying/createNewItem');

// tempexp-14737-next-line
export const inlineEditStart = createAction<string | undefined>('modifying/inlineEditStart');
export const inlineEditStop = createAction('modifying/inlineEditStop');
export const inlineEditSuccess = createAction<IInlineEditSuccess>('modifying/inlineEditSuccess');
export const inlineEditFailure = createAction<string | null>('modifying/inlineEditFailure');
