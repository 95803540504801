import { Icon20CheckAlt } from '@vkontakte/icons';
import { Caption, Spacing } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { memo, ReactElement, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { ACTION_PROMO, BASEQUOTA } from 'reactApp/appHelpers/configHelpers';
import { isCallToActionTariffButtonTouch } from 'reactApp/appHelpers/featuresHelpers';
import { getFeatureABSubNewCard } from 'reactApp/modules/features/features.selectors';
import { TABS_MAP } from 'reactApp/sections/MobileSubscriptionsPage/MobileSubscriptionsPage.constants';
import { TariffInfoBlock } from 'reactApp/sections/QuotaLanding/TariffsPlans/TariffPlansCard/TariffPlansCard';
import { Button } from 'reactApp/ui/Button/Button';
import { ChevronDownIcon } from 'reactApp/ui/VKUIIcons';
import { getPeriodName, isYear2Period } from 'reactApp/utils/Period';
import { formatPrice } from 'reactApp/utils/priceHelpers';

import styles from './TariffCard.css';
import { getButtonText, getDiscountText, getFeatureList, getYearPriceWithMonthProduct } from './TariffCard.helpers';
import { IProps } from './TariffCard.types';

export const TariffCard = memo(function TariffCard(props: IProps): ReactElement | null {
    // tempexp_13600-next-line countSnapshotsBySpace, openAbCard
    const {
        countSnapshotsBySpace,
        openAbCard,
        space,
        products,
        onClick,
        fullPrice,
        oldPrice,
        fullPricePeriod,
        economy,
        tariffTypeLabel,
        savingLable,
        hasOldPriceYear,
        isOldCard = false,
        giftSpace,
        infoText,
        theme,
        isSingleQuota,
        alwaysExpand,
        showPriceByMonth,
        skipBenefitsFeature,
        setCarouselLoaded,
    } = props;
    const isOneProduct = products.length === 1;

    // tempexp_13600-start
    const isAbExpNewCards13600 = useSelector(getFeatureABSubNewCard) && !isOldCard;
    const [openSpoiler, setOpenSpoiler] = useState(openAbCard || alwaysExpand);
    const features = getFeatureList(countSnapshotsBySpace, isSingleQuota, skipBenefitsFeature);

    const expandCard = openSpoiler || alwaysExpand;

    const location = useLocation();
    const isAction = Boolean(ACTION_PROMO) && (!location.hash || location.hash === TABS_MAP.special);

    // tempexp_13600-end

    const onButtonClick = useCallback(
        (product) => () => {
            onClick({ id: product.id, period: product.period, price: product.discountPrice || product.price });
        },
        [onClick]
    );

    useEffect(() => {
        /** Это сделано именно здесь для того, чтобы дожидаться рендера тарифов со всем контентом внутри
         * для корректного скролла по якорю из ссылки на лендинг единой квоты
         * В родительских элементах useEffect срабатывает слишком рано, до формирования листа тарифов */
        setCarouselLoaded?.(true);
    }, []);

    return (
        <div
            className={classNames({
                [styles.root]: true,
                [styles[`root_space_${space.space}`]]: Boolean(space.space),
                [styles[`root_theme_${theme}`]]: Boolean(theme),
                [styles[`root_old_price_year`]]: hasOldPriceYear,
                // tempexp_13600-start
                [styles.root_ab_new_cards]: isAbExpNewCards13600,
                // tempexp_13600-end
                [styles.root_action]: isAction,
            })}
        >
            {/* tempexp_13600-next-line */}
            {isAbExpNewCards13600 && !isSingleQuota && <div className={styles.bgicon_ab_new_cards} />}
            {/* tempexp_13600-next-line !isAbExpNewCards13600 скрываем на эксперимент лейбл */}
            {!ACTION_PROMO && (
                <div className={styles.lables}>
                    {Boolean(savingLable) && <div className={styles.savingLable}>{savingLable}</div>}
                    {Boolean(economy) && <div className={styles.economy}>Экономия {formatPrice(economy, 'ru')} ₽</div>}
                    {Boolean(tariffTypeLabel) && <div className={styles.economy}>{tariffTypeLabel}</div>}
                </div>
            )}

            {/* tempexp_13600-next-line isAbExpNewCards13600 В эксперименте показываем новый вариант заголовка*/}
            {isAbExpNewCards13600 && !alwaysExpand ? (
                <div
                    onClick={() => setOpenSpoiler(!openSpoiler)}
                    className={classNames(styles.title, styles.title_ab_new_cards, {
                        [styles.title_ab_new_cards_opened]: expandCard,
                    })}
                >
                    + {space.space} {space.units}
                    <ChevronDownIcon width={30} height={30} />
                </div>
            ) : (
                <div
                    className={classNames(styles.title, {
                        [styles.title_withGift]: Boolean(giftSpace),
                    })}
                >
                    <div className={styles.space}>{space.space}</div>
                    <div className={styles.unit}>{space.units}</div>
                    {giftSpace && (
                        <div className={styles.gift}>
                            <div className={styles.plus}>+</div>
                            <div className={styles.space}>{giftSpace.space}</div>
                            <div className={styles.unit}>* {giftSpace.units}</div>
                        </div>
                    )}
                </div>
            )}

            {isAbExpNewCards13600 && expandCard && isSingleQuota && <TariffInfoBlock isMobile newQuota={BASEQUOTA + space.original} />}

            {/* tempexp_13600-start Добавляем список фичей */}
            {isAbExpNewCards13600 && expandCard && (
                <div className={styles.features_ab_new_cards}>
                    {features.map((value, index) => (
                        <div
                            key={index}
                            className={classNames(styles.features_li, {
                                [styles[`features_li_space_${space.space}`]]: !!space.space,
                            })}
                        >
                            <Icon20CheckAlt />
                            <div>{value}</div>
                        </div>
                    ))}
                </div>
            )}
            {/* tempexp_13600-end */}

            {Boolean(fullPrice) && Boolean(fullPricePeriod) && fullPrice && fullPricePeriod && (
                <div className={styles.discount}>{getDiscountText(fullPrice, fullPricePeriod)}</div>
            )}

            {infoText && <div className={styles.infoText}>{infoText}</div>}

            <div className={styles.buttons}>
                {products.map((product) => (
                    <div className={styles.buttonWrapper} key={product.id}>
                        <Button
                            className={classNames(styles.button, {
                                // tempexp_13600-next-line Делаем кнопку более белой
                                [styles.button_ab_new_cards]: isAbExpNewCards13600,
                                [styles.button_column]: isSingleQuota,
                                [styles.button_action]: isAction,
                            })}
                            theme="octavius"
                            primary={product.primary}
                            onClick={onButtonClick(product)}
                        >
                            {/* tempexp_15661-start */}
                            {getButtonText(
                                product.discountPrice && product.discountPeriod ? product.discountPrice : product.price,
                                product.discountPrice && product.discountPeriod ? product.discountPeriod : product.period,
                                isOneProduct,
                                product,
                                showPriceByMonth,
                                isCallToActionTariffButtonTouch
                            )}
                            {/* tempexp_15661-end */}
                            {oldPrice &&
                                hasOldPriceYear &&
                                !product?.hasTrial &&
                                product.period === '1y' &&
                                (showPriceByMonth ? (
                                    <>
                                        <Spacing size={2} />
                                        <Caption level="2">при покупке на год</Caption>
                                    </>
                                ) : (
                                    <span className={styles.oldPrice}>
                                        {getButtonText(oldPrice, product.period, isOneProduct, product)}
                                    </span>
                                ))}
                            {isSingleQuota &&
                                product.period === '1y' &&
                                !product.discountPrice &&
                                !product?.hasTrial &&
                                (showPriceByMonth ? (
                                    <>
                                        <Spacing size={2} />
                                        <Spacing size={2} />
                                        {/* tempexp_15661-next-line */}
                                        <Caption level="2">при {isCallToActionTariffButtonTouch ? 'оплате' : 'покупке'} на год</Caption>
                                    </>
                                ) : (
                                    <>
                                        <Spacing size={2} />
                                        <Caption level="2" className={styles.yearPrice}>
                                            {getYearPriceWithMonthProduct(products)}
                                        </Caption>
                                    </>
                                ))}
                            {/* tempexp_15246-next-line */}
                            {isYear2Period(product.period) && (
                                <>
                                    <Spacing size={2} />
                                    {/* tempexp_15661-next-line */}
                                    <Caption level="2">при {isCallToActionTariffButtonTouch ? 'оплате' : 'покупке'} на 2 года</Caption>
                                </>
                            )}
                            {product.hasTrial && product.trialPeriod && (
                                <>
                                    <Spacing size={2} />
                                    <Caption level="2">
                                        далее {formatPrice(product.price, 'ru')} ₽ в {getPeriodName(product.period)}
                                    </Caption>
                                </>
                            )}
                        </Button>
                        {product.saving && (
                            <div
                                className={classNames(styles.saving, {
                                    [styles.saving_action]: isAction,
                                })}
                            >
                                -{product.saving}%
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
});
