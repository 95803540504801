import { ReactComponent as ArrowIcon } from 'img/arrow.svg';
import React, { memo, ReactElement, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { defaultQuotaValueMockForQA, isHideQuotaNewbieFeatureEnabled } from 'reactApp/appHelpers/featuresHelpers';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { DataListItemCreateNew } from 'reactApp/ui/DataListItemCreateNew/DataListItemCreateNew';
import { CreateNewMod } from 'reactApp/ui/DataListItemCreateNew/DataListItemCreateNew.data';
import { TrialBanner } from 'reactApp/ui/WelcomePromo/TrialBanner/TrialBanner';
import { useWelcomePromoLogic } from 'reactApp/ui/WelcomePromo/WelcomePromo.hooks';

import styles from './WelcomePromo.css';

export const WelcomePromo = memo((): ReactElement => {
    const userTotalSpace = useSelector(UserQuotaSelectors.getTotal);
    const isNewbie = useSelector(UserSelectors.isNewbie);

    const { trialId, handleSubscriptionsClick, showSubscriptions } = useWelcomePromoLogic();

    // tempexp_14979-start
    const getPromoStartText = useMemo(() => {
        if (!isHideQuotaNewbieFeatureEnabled) {
            return (
                <>
                    Теперь у вас есть <span className={styles.quota}>{userTotalSpace?.value}</span>.{' '}
                </>
            );
        }

        if (isHideQuotaNewbieFeatureEnabled && !isNewbie) {
            // Тестироващикам необходимо будет протестировать разную дефолтную квоту до начала эксперимента. поэтому мокаем.
            const space = defaultQuotaValueMockForQA || userTotalSpace?.space;
            const spaceText = defaultQuotaValueMockForQA ? `${defaultQuotaValueMockForQA} ГБ` : userTotalSpace?.value;

            if (space === 8) {
                return (
                    <>
                        Теперь у вас есть <span className={styles.quota}>{spaceText}</span>.{' '}
                    </>
                );
            }

            return (
                <>
                    Мы увеличили бесплатное место до <span className={styles.quota}>{spaceText}</span>.{' '}
                </>
            );
        }
        return null;
    }, [isNewbie, userTotalSpace?.value, userTotalSpace?.space]);
    // tempexp_14979-end

    return (
        <div className={styles.root}>
            <div className={styles.textBlock}>
                <div className={styles.header}>Добро пожаловать в Облако!</div>
                <div className={styles.description}>
                    {/** tempexp_14979-next-line */}
                    {getPromoStartText}Загружайте фото, делитесь файлами
                    <br /> и работайте вместе с другими пользователями.{' '}
                    {showSubscriptions && (
                        <span className={styles.tariffs} onClick={handleSubscriptionsClick}>
                            Тарифы <ArrowIcon height={7} width={14} />
                        </span>
                    )}
                </div>
            </div>
            <div className={styles.actionBlock}>
                <div className={styles.createBlock}>
                    <DataListItemCreateNew isThumb mod={CreateNewMod.firstFolder} />
                    <DataListItemCreateNew isThumb mod={CreateNewMod.newDocument} />
                </div>
                {Boolean(trialId) && <TrialBanner trialId={trialId ?? ''} />}
            </div>
        </div>
    );
});

WelcomePromo.displayName = 'WelcomePromo';
