import classNames from 'clsx';
import React, { ReactElement } from 'react';
import { Product } from 'reactApp/types/Billing';
import { CloudStockAnalytics, sendCloudStockAnalytics } from 'reactApp/ui/MailPopup/MailPopup.analytics';
import styles from 'reactApp/ui/MailPopup/MailPopup.css';
import { getMonthes, getPeriodName } from 'reactApp/utils/Period';
import { formatPrice } from 'reactApp/utils/priceHelpers';

interface Content {
    title: ReactElement;
    description: ReactElement;
    buttonText: string;
    buttonQaId: string;
    extraButtonText?: string;
    extraButtonQaId?: string;
}

// eslint-disable-next-line max-lines-per-function, complexity
export const useContent = ({
    isFrozen,
    tariff,
    canUpload,
    isFromDnD,
    isUserFreeTb,
    isEnoughSpace,
    isCloudStock,
    showPayment,
}: {
    isFrozen: boolean;
    tariff: Product | undefined;
    canUpload: boolean;
    isFromDnD: boolean;
    isUserFreeTb: boolean;
    isEnoughSpace: boolean;
    isCloudStock: boolean;
    showPayment: boolean;
}): Content => {
    let title = (
        <>
            Вы не можете загружать
            <br />
            {isFromDnD ? '' : 'в письма '}файлы больше <span className={styles.limit}>2 ГБ</span>
        </>
    );

    let buttonText = 'Загрузить ссылкой';
    let buttonQaId = 'upload-button';
    let description = (
        <>
            Но их можно добавлять из Облака!
            <br />
            Хотите загрузить файл ссылкой?
        </>
    );

    if (isFrozen) {
        return {
            title,
            buttonText: 'Разморозить',
            buttonQaId: 'unfreeze-button',
            description: (
                <>
                    Ваше Облако заморожено. Чтобы добавлять
                    <br />
                    {isFromDnD ? 'файлы в Облако' : 'в письма файлы из Облака'}, перейдите и<br />
                    активируйте свой аккаунт
                </>
            ),
        };
    }

    if (tariff && isCloudStock) {
        let priceText = `за ${formatPrice(tariff.price)} ₽/${getPeriodName(tariff.period)}`;

        if (tariff.hasTrial) {
            priceText = 'бесплатно';
        }

        if (tariff.hasDiscount && tariff.discountPeriod && tariff.discountPrice) {
            priceText = `за ${formatPrice(tariff.discountPrice / getMonthes(tariff.discountPeriod))} ₽/месяц`;
        }

        const tariffBlock = (
            <>
                <span
                    className={classNames({
                        [styles.tariff]: true,
                        [styles[`tariff_${tariff.space.space}`]]: tariff.space.space,
                    })}
                >
                    {!isUserFreeTb && tariff.space.value} {priceText}
                </span>
                {tariff.hasDiscount && ` или ${tariff.discountPrice}  ₽/первый год`}
            </>
        );

        const title = showPayment ? (
            <>
                В вашем Облаке недостаточно
                <br />
                места для загрузки файла
            </>
        ) : (
            <>Нехвает места для файла</>
        );

        const description = showPayment ? (
            <>
                Для загрузки, увеличьте ваше хранилище, подключив
                <br />
                тариф &nbsp;&nbsp;{tariffBlock}. Бонусом вы получите
                <br />
                отключение рекламы в Почте и Облаке, и другие
                <br />
                преимущества.
            </>
        ) : (
            <>
                Ваше{' '}
                <a href="/home" onClick={() => sendCloudStockAnalytics(CloudStockAnalytics.HOME_OPEN)} target="_blank">
                    Облако
                </a>{' '}
                заполнено, вы не можете прикреплять
                <br />
                вложения, если их общий объём превышает 25 МБ.
                <br />
                <br />
                Чтобы хватило места, можно подключить тариф
                <br />
                {tariffBlock} или{' '}
                <a href="/promo/quota" onClick={() => sendCloudStockAnalytics(CloudStockAnalytics.CLEANER_OPEN)} target="_blank">
                    очистите
                </a>{' '}
                Облако.
            </>
        );

        return {
            title,
            description,
            buttonQaId: 'cloud-stock-tariff',
            buttonText: 'Подключить тариф',
            extraButtonQaId: 'cloud-stock-quota-cleaner',
            extraButtonText: 'Освободить место',
        };
    }

    if (!canUpload && tariff) {
        buttonText = 'Загрузить с Облаком';
        buttonQaId = 'paid-button';

        let priceText = `за ${formatPrice(tariff.price)} ₽/${getPeriodName(tariff.period)}`;

        if (tariff.hasTrial) {
            priceText = 'бесплатно';
        }

        if (tariff.hasDiscount && tariff.discountPeriod && tariff.discountPrice) {
            priceText = `за ${formatPrice(tariff.discountPrice / getMonthes(tariff.discountPeriod))} ₽/месяц`;
        }

        const tariffBlock = (
            <>
                <span
                    className={classNames({
                        [styles.tariff]: true,
                        [styles[`tariff_${tariff.space.space}`]]: tariff.space.space,
                    })}
                >
                    {!isUserFreeTb && tariff.space.value} {priceText}
                </span>
                {tariff.hasDiscount && ` или ${tariff.discountPrice}  ₽/первый год`}
            </>
        );

        // если предлагаем триал
        if (tariff.hasTrial) {
            description = isFromDnD ? (
                <>
                    Эта функция доступна только на платном тарифе.
                    <br />
                    Подключите&nbsp;&nbsp;{tariffBlock}, чтобы снять ограничение. Бонусом вы получите отключение рекламы
                    в&nbsp;Почте&nbsp;и&nbsp;Облаке!
                </>
            ) : (
                <>
                    Но их можно добавлять с помощью Облака! Мы даём вам {tariffBlock} на месяц для загрузки больших файлов в&nbsp;Почту.
                    Бонусом вы получите отключение рекламы в&nbsp;Почте&nbsp;и&nbsp;Облаке!
                </>
            );
            // если пользователю в облаке не хватает места и он не бесплатный терабайтник
        } else if (!isEnoughSpace && !isUserFreeTb) {
            title = (
                <>
                    В вашем Облаке недостаточно
                    <br />
                    места для загрузки файла
                </>
            );
            description = (
                <>
                    Для загрузки файла, увеличьте ваше Облако,
                    <br />
                    подключив тариф&nbsp;&nbsp;{tariffBlock}. Бонусом вы получите отключение рекламы в&nbsp;Почте&nbsp;и&nbsp;Облаке!
                </>
            );
            buttonText = 'Подключить и загрузить';
            // если пользователю хватает места в облаке, но загружаемые файлы превышают лимит на загруку одного файла
            // или пользователь бесплатный терабайтник
        } else {
            description = isFromDnD ? (
                <>
                    Эта функция есть только на платном тарифе. Подключите
                    <br />
                    {tariffBlock}, чтобы снять ограничение
                    <br />и получить доступ ко всем возможностям единого хранилища – например, отключению рекламы
                    в&nbsp;Почте&nbsp;и&nbsp;Облаке!
                </>
            ) : (
                <>
                    Но их можно добавить ссылкой с помощью Облака!
                    <br />
                    Для загрузки файла более 2 ГБ, купите тариф {tariffBlock}. Бонусом вы получите отключение рекламы
                    в&nbsp;Почте&nbsp;и&nbsp;Облаке!
                </>
            );
        }
    }

    return {
        title,
        buttonText,
        description,
        buttonQaId,
    };
};
