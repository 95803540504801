import theme from '@paradigm/tokens/cloud';
import classNames from 'clsx';
import { ReactComponent as DoneIcon } from 'mrg-icons/src/mailru/status/done.svg';
import React, { VFC } from 'react';

import styles from './TariffFeatureList.css';
import { ITariffFeatureListProps } from './TariffFeatureList.types';

export const TariffFeatureList: VFC<ITariffFeatureListProps> = ({ dataQa, items, showIcon = false, large = false, accent = false }) => (
    <ul className={styles.featureList} data-qa={dataQa}>
        {items.map((item) => (
            <li
                key={item.key}
                className={classNames(styles.featureListItem, {
                    [styles.featureListItem_large]: large,
                    [styles.featureListItem_accent]: accent,
                    [styles.featureListItem_pointer]: !!item.onClick,
                })}
                onClick={item.onClick}
            >
                {showIcon && <div className={styles.featureListItemIcon}>{item.icon || <DoneIcon fill={theme.colorPrimary.normal} />}</div>}
                <div data-qa={`${dataQa}_text`}>{item.text}</div>
            </li>
        ))}
    </ul>
);
