import classNames from 'clsx';
import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import { BillingBuyTemplates } from 'reactApp/modules/buySubscription/buySubscription.types';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { AddCardModal } from 'reactApp/ui/AddCardModal/AddCardModal';
import { Loader } from 'reactApp/ui/Loader/Loader';
import { TabMenu } from 'reactApp/ui/Menu/TabMenu';
import { ETabMenuMod } from 'reactApp/ui/Menu/TabMenu.types';
import { TabMenuItem } from 'reactApp/ui/Menu/TabMenuItem';
import { renderSubscriptionsModal } from 'reactApp/ui/Mobile/SubscriptionsModal/SubscriptionsModal.helpers';
import { Toolbar } from 'reactApp/ui/Mobile/Toolbar/Toolbar';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';
import { EQueryParams } from 'server/helpers/getRequestParams';

import { Discounts } from './Discounts/Discounts';
import { useCardHandlers } from './hooks/useCardHandlers';
import { useDiscounts } from './hooks/useDiscounts';
import { useOnPageLoad } from './hooks/useOnPageLoad';
import { TABS, TABS_MAP } from './MobileSubscriptionsPage.constants';
import styles from './MobileSubscriptionsPage.css';
import { getActiveTab, getAvailableHash } from './MobileSubscriptionsPage.helpers';
import {
    useLoading,
    useLoadProductsOrLogin,
    useMarketingPromo,
    useProductFromQuery,
    useRemoveCardModal,
    useTariffs,
} from './MobileSubscriptionsPage.hooks';
import { TabValue } from './MobileSubscriptionsPage.types';
import { Tariffs } from './Tariffs/Tariffs';

export const MobileSubscriptionsPage = memo(() => {
    const tariffs = useTariffs();
    const discounts = useDiscounts();
    const location = useLocation();
    const { marketingPromo } = useMarketingPromo();
    const hash = getAvailableHash(location.hash, marketingPromo);
    const isLoading = useLoading();
    const { showAddCardModal, onSuccessAddCard, onCloseAddCard } = useCardHandlers();
    const [activeTab, setActiveTab] = useState<TabValue>(() => getActiveTab(hash));

    const isWebview = useSelector(EnvironmentSelectors.isWebview);
    const activeTabWithHash = `#${activeTab}`;
    const showTariff = TABS_MAP.usual === activeTabWithHash || TABS_MAP.special === activeTabWithHash;
    const showDiscounts = TABS_MAP.discounts === activeTabWithHash;

    const isPromoActionPage = Boolean(marketingPromo) && showTariff;

    useEffect(() => {
        const params = getQueryParams();

        if (params[EQueryParams.my_subscriptions]) {
            renderSubscriptionsModal();
        }
    }, []);

    useLoadProductsOrLogin();
    useProductFromQuery();
    useOnPageLoad();
    useRemoveCardModal();

    useEffect(() => {
        setActiveTab(getActiveTab(hash));
    }, [hash]);

    useEffect(() => {
        if (showDiscounts) {
            sendDwh({ eventCategory: ECategoryGa.payment, action: 'action-open', dwhData: { source: 'touch' } });
        }
    }, [showDiscounts]);

    if (isLoading) {
        return (
            <div className={styles.root}>
                <Loader centered />
            </div>
        );
    }

    return (
        <div
            className={classNames(styles.root, {
                [styles.root_actionPromo]: isPromoActionPage,
            })}
        >
            {!isWebview && <Toolbar logoWithEndorsment={false} disableActions showMenu showSubscriptions />}
            {showAddCardModal && (
                <AddCardModal
                    size="tiny"
                    isMobile
                    onSuccess={onSuccessAddCard}
                    onClose={onCloseAddCard}
                    checkoutSkin={BillingBuyTemplates.CLOUD_B2C_MOBILE}
                />
            )}
            <div className={styles.content}>
                <div
                    className={styles.title}
                    dangerouslySetInnerHTML={{
                        __html: isPromoActionPage ? marketingPromo?.mobileTitle || marketingPromo?.title || 'Объём Облака' : 'Объём Облака',
                    }}
                />
                {isPromoActionPage && Boolean(marketingPromo?.text) && <div className={styles.description}>{marketingPromo?.text}</div>}
                <TabMenu isMobile mod={ETabMenuMod.mobileSubscription}>
                    <div className={styles.tabs}>
                        {TABS.map(({ name, value, match }) => (
                            <TabMenuItem
                                key={value}
                                name={name}
                                value={value}
                                active={match.includes(activeTab)}
                                onClick={setActiveTab}
                                mod={ETabMenuMod.mobileSubscription}
                            />
                        ))}
                    </div>
                </TabMenu>
                {showTariff && <Tariffs marketingPromo={marketingPromo} tariffs={tariffs} />}
                {showDiscounts && <Discounts marketingPromo={marketingPromo} discounts={discounts} />}
            </div>
        </div>
    );
});

MobileSubscriptionsPage.displayName = 'MobileSubscriptionsPage';
