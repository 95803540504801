import { path } from 'ramda';
import React, { memo, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNewPortalHeader } from 'reactApp/appHelpers/featuresHelpers';
import { QuotaCleanerPlug } from 'reactApp/components/QuotaCleanerPlug/QuotaCleanerPlug';
/* tempexp_14812-next-line */
import { FeatureSelector } from 'reactApp/modules/features/components/FeatureSelector';
/* tempexp_14812-next-line */
import { getFeatureNewEmptyStatesTouch } from 'reactApp/modules/features/features.selectors';
import { isMediaFolderAndGalleryEnabled as isMedia } from 'reactApp/modules/file/utils';
import { isDefaultRootContent } from 'reactApp/modules/home/home.selectors';
import { getCurrentStorage, getParams } from 'reactApp/modules/router/router.selectors';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { scrollToItemAction } from 'reactApp/modules/storage/storage.module';
import {
    getSelectedItem,
    getStorageCurrentFolder,
    groupedIds,
    hasLoadMore,
    isCurrentFolderLoading,
    isEmptyFolder,
} from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { getParams as getAttachesParams } from 'reactApp/sections/AttachesPage/helpers';
import { RootState } from 'reactApp/store';
import { useSendDwh } from 'reactApp/ui/Datalist/DataList.hooks';
import { onScroll } from 'reactApp/ui/Datalist/UserActions/onScrollAction';
/* tempexp_14812-next-line */
import { Empty } from 'reactApp/ui/Empty/Empty';
import { ErrorRetry } from 'reactApp/ui/ErrorRetry/ErrorRetry';
import { Loader } from 'reactApp/ui/Loader/Loader';
import { MOBILE_ROW_HEIGHT } from 'reactApp/ui/Mobile/Datalist/Datalist.constants';
import { useInsertAdvDividerToDatalist } from 'reactApp/ui/Mobile/Datalist/Datalist.hooks';
import { EmptyFolder } from 'reactApp/ui/Mobile/Datalist/EmptyFolder/EmptyFolder';
import { EmptySearch } from 'reactApp/ui/SearchMessage/EmptySearch/EmptySearch';
import { ErrorSearch } from 'reactApp/ui/SearchMessage/ErrorSearch/ErrorSearch';
import { VirtualList } from 'reactApp/ui/VirtualList/VirtualList';
import { VirtualList as VirtualListNew } from 'reactApp/ui/VirtualList/VirtualList.new';
import { IVirtualList } from 'reactApp/ui/VirtualList/VirtualList.types';
import { ESearchOptionType } from 'reactApp/ui/WebSearch/WebSearch.data';

import styles from './Datalist.css';
import { DatalistItem } from './DatalistItem/DatalistItem';

interface Props {
    customScrollElement?: HTMLDivElement | null;
}
// eslint-disable-next-line max-lines-per-function
export const Datalist = memo(({ customScrollElement }: Props): ReactElement => {
    const dispatch = useDispatch();
    const storage = useSelector(getCurrentStorage);
    const folder = useSelector((state: RootState) => getStorageCurrentFolder(state, storage));
    const list = useSelector((state: RootState) => groupedIds(state, storage));
    const isEmpty = useSelector(isEmptyFolder);
    const viewMode = useSelector((state) => SettingsSelectors.getViewByStorage(state, storage, isMedia(state, folder)));
    const { isSearch, isIntegration, isQuotaCleaner } = getStorage(storage);
    const listRef = useRef<IVirtualList | null>(null);
    const isDefaultContent = useSelector(isDefaultRootContent);
    const routerParams = useSelector(getParams);
    const attachesParams = getAttachesParams({ params: useSelector(SettingsSelectors.getQueryParams) });
    const selectedItem = useSelector(getSelectedItem);

    const { modifiedList, showAdv, dividerHeight } = useInsertAdvDividerToDatalist(list, viewMode, listRef);

    const renderList = showAdv ? modifiedList : list;

    const hasMoreToLoad = useSelector((state: RootState) => hasLoadMore(state, storage, folder));
    const isLoading = useSelector(isCurrentFolderLoading);
    const isError = Boolean(path(['error'], folder));
    const isLoaded = folder?.isLoaded;

    const loadMore = useCallback(() => {
        if (isLoading) {
            return;
        }

        onScroll({ storage, items: list, folderId: folder?.id, hasMoreToLoad, dispatch });
    }, [dispatch, folder?.id, hasMoreToLoad, isLoading, list, storage]);

    const renderItem = useCallback((id: string): ReactElement => <DatalistItem id={id} />, []);

    const { onItemsRendered } = useSendDwh({
        storage,
        isSearchActive: isSearch,
        isLoaded,
        isError,
        items: list,
    });

    useEffect(() => {
        if (isLoaded && selectedItem && listRef?.current) {
            const selectedIndex = renderList.indexOf(selectedItem);
            if (selectedIndex >= 0) {
                listRef.current?.scrollToItem(selectedIndex);
            }
            dispatch(scrollToItemAction(''));
        }
    }, [selectedItem, isLoaded, renderList]);

    /**
     * FIXME: Для определения позиции для вставки рекламы в даталисте нужно знать кол-во колонок,
     * которое приходит из рефки listRef. При смене вьюмода рефка не успевает обновится и мы получаем предыдущие значение кол-ва колонок.
     * Поэтому необходим дополнительный перерендер при смене вьюмода.
     */
    const [, updateColumnCount] = useState(false);
    useEffect(() => {
        updateColumnCount((prev) => !prev);
    }, [viewMode]);

    if (isLoading && storage !== EStorageType.search) {
        return <Loader centered />;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (folder?.error) {
        if (isSearch) {
            return <ErrorSearch isMobile />;
        }

        return (
            <div className={styles.error} data-qa-datalist={storage}>
                <ErrorRetry />
            </div>
        );
    }

    if (isEmpty || isDefaultContent) {
        if (isSearch) {
            return (
                <EmptySearch
                    searchType={routerParams?.searchType as ESearchOptionType}
                    searchText={routerParams?.query || attachesParams?.query}
                    isMobile
                />
            );
        }

        if (isIntegration) {
            return <Empty type="folder" storage={storage} canUpload={false} />;
        }

        if (isQuotaCleaner) {
            return (
                <div className={styles.emptyFolder}>
                    <QuotaCleanerPlug />;
                </div>
            );
        }

        return (
            <div className={styles.emptyFolder}>
                {/* tempexp_14812-next-line */}
                <FeatureSelector
                    selector={getFeatureNewEmptyStatesTouch}
                    control={<EmptyFolder />}
                    variant1={<Empty.New storage={storage} isMobile />}
                />
            </div>
        );
    }

    return (
        <div className={styles.root} data-qa-datalist={storage} data-qa-view={viewMode}>
            {isNewPortalHeader.client ? (
                <VirtualListNew
                    renderItem={renderItem}
                    onItemsRendered={onItemsRendered}
                    hasMoreToLoad={hasMoreToLoad}
                    viewMode={viewMode}
                    list={renderList}
                    loadOnScroll={loadMore}
                    dividerHeight={dividerHeight}
                    rowHeight={MOBILE_ROW_HEIGHT}
                    isLoading={false}
                    ref={listRef}
                    customScrollElement={customScrollElement}
                />
            ) : (
                <VirtualList
                    renderItem={renderItem}
                    onItemsRendered={onItemsRendered}
                    hasMoreToLoad={hasMoreToLoad}
                    viewMode={viewMode}
                    list={renderList}
                    loadOnScroll={loadMore}
                    dividerHeight={dividerHeight}
                    rowHeight={MOBILE_ROW_HEIGHT}
                    isLoading={false}
                    ref={listRef}
                    customScrollElement={customScrollElement}
                />
            )}
        </div>
    );
});

Datalist.displayName = 'Datalist';
