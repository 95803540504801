import React, { ReactElement, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { IS_PUBLIC_ALBUM } from 'reactApp/appHelpers/configHelpers';
import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import { useActionsConfig } from 'reactApp/hooks/useActionsConfig';
import { getStorageCurrentFolder } from 'reactApp/modules/storage/storage.selectors';
import { EActions, EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { RootState } from 'reactApp/store';
import { renderAuthModalDialog } from 'reactApp/ui/Mobile/AuthModal/AuthModal.helpers';
import { FacesCounter } from 'reactApp/ui/Mobile/FacesCounter/FacesCounter';
import { UploadInput } from 'reactApp/ui/Mobile/UploadInput/UploadInput';
import { AddIcon, DownloadIcon, FolderAddIcon, LinkIcon, RestoreIcon, TrashbinIcon, UploadIcon } from 'reactApp/ui/VKUIIcons';

import styles from './ActionsCarousel.css';

interface IActionItem {
    id: EActions;
    text?: string;
    onClick?: () => void;
    icon?: ReactElement;
    active?: boolean;
    component?: ReactElement;
}

export const useItems = ({ storage }: { storage: EStorageType }) => {
    const isAnonymous = useSelector(UserSelectors.isAnonymous);
    const currentFolder = useSelector((state: RootState) => getStorageCurrentFolder(state, storage));

    const onAuth = useCallback(() => {
        if (isAnonymous) {
            renderAuthModalDialog();
        }
    }, [isAnonymous]);

    const actionItems: Partial<Record<EActions, IActionItem>> = {
        [EActions.createFolder]: {
            id: EActions.createFolder,
            text: 'Создать папку',
            onClick: () => toolbarActions.create('folder', 'carousel'),
            icon: <FolderAddIcon />,
        },
        [EActions.facesFilter]: {
            id: EActions.facesFilter,
            component: <FacesCounter />,
        },
        [EActions.clone]: {
            id: EActions.clone,
            text: 'Сохранить в Облако',
            icon: <UploadIcon />,
            onClick: () => toolbarActions.clone({ source: 'carousel' }),
        },
        [EActions.publish]: {
            id: EActions.publish,
            text: 'Поделиться',
            onClick: () => toolbarActions.publish('carousel'),
            icon: <LinkIcon />,
            active: Boolean(currentFolder && 'weblink' in currentFolder && currentFolder?.weblink),
        },
        [EActions.download]: {
            id: EActions.download,
            text: 'Скачать',
            icon: <DownloadIcon />,
            onClick: toolbarActions.downloadMobileItem,
        },
        [EActions.restoreAll]: {
            id: EActions.restoreAll,
            text: 'Восстановить всё',
            icon: <RestoreIcon />,
            onClick: toolbarActions.restoreAll,
        },
        [EActions.clear]: {
            id: EActions.clear,
            text: 'Очистить корзину',
            icon: <TrashbinIcon />,
            onClick: toolbarActions.clear,
        },
        [EActions.upload]: {
            id: EActions.upload,
            text: 'Загрузить файлы',
            icon: <AddIcon />,
            onClick: onAuth,
            component: isAnonymous ? undefined : (
                <div className={styles.upload}>
                    <UploadInput inputName="public-carousel" />
                </div>
            ),
        },
    };

    const actions = useActionsConfig({ useOnlyCurrentFolder: true, isMobile: true, skipUpload: IS_PUBLIC_ALBUM });

    return Object.keys(actions)
        .map((id) => actionItems[id])
        .filter((action) => action);
};
