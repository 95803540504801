import { BuySubscriptionActions } from 'reactApp/modules/buySubscription/buySubscription.module';
import { BuyNotifications } from 'reactApp/modules/buySubscription/buySubscription.types';
import { checkCaptcha } from 'reactApp/modules/captcha/captcha.saga';
import { showCardModal, updateCard } from 'reactApp/modules/creditCard/creditCard.saga';
import { CardSelectors } from 'reactApp/modules/creditCard/creditCard.selectors';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { loggerSaga } from 'reactApp/modules/logger/logger.saga';
import { closePopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { updateSubscriptionsRequest } from 'reactApp/modules/subscriptions/subscriptions.module';
import { getSubscriptionsById } from 'reactApp/modules/subscriptions/subscriptions.selectors';
import { renderCancelAutorenewMailSubsDialog } from 'reactApp/sections/QuotaLanding/CancelAutorenewDialog/CancelAutorenewDialog.helpers';
import { ESubscriptionModalAction } from 'reactApp/sections/SubscriptionsPage/ui/SubscriptionModal/SubscriptionModal.analytics';
import { store } from 'reactApp/store';
import { renderPollPopup } from 'reactApp/ui/PollPopup/PollPopup.helpers';
import { sendGa } from 'reactApp/utils/ga';
import { channel } from 'redux-saga';
import { call, put, putResolve, select, take, takeEvery } from 'redux-saga/effects';

function* openCancelAutorenewMailSubsDialog(action: ReturnType<typeof BuySubscriptionActions.openCancelAutorenewMailSubsDialog>) {
    const { subscription } = action.payload;

    const cancelChannel = channel();

    yield renderCancelAutorenewMailSubsDialog({
        onSuccess: () => cancelChannel.put(true),
        onClose: () => cancelChannel.close(),
        subscription,
    });

    yield take(cancelChannel);

    yield closePopupHelper(popupNames.CANCEL_MAIL_SUBSCRIPTION);

    try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        yield put(BuySubscriptionActions.cancelAutorenew(subscription.id));
        yield put(updateSubscriptionsRequest());
    } catch (error) {
        yield put(
            showSnackbarAction({
                text: 'Не удалось отключить автоплатёж',
                type: SnackbarTypes.failure,
                id: BuyNotifications.cancelAutoRenewFailure,
                closable: true,
            })
        );

        yield loggerSaga({ error });
    }
}

function* addAutoRenew(action: ReturnType<typeof BuySubscriptionActions.addAutorenewToSubscription>) {
    const { subscription } = action.payload;

    const { card } = yield select(CardSelectors.getCard);

    if (!card) {
        yield showCardModal({ isSilent: true });
        yield updateCard({
            showNotifications: true,
        });
    }

    try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        yield put(BuySubscriptionActions.addAutorenew(subscription.id));
        yield put(updateSubscriptionsRequest());
        yield put(
            showSnackbarAction({
                text: 'Автопродление включено',
                type: SnackbarTypes.success,
                id: BuyNotifications.autoRenewSuccess,
                closable: true,
            })
        );
        sendGa('billing', 'autorenew-cancel-success');
    } catch (error) {
        yield put(
            showSnackbarAction({
                text: 'Не удалось подключить автоплатёж',
                type: SnackbarTypes.failure,
                id: BuyNotifications.autoRenewFailure,
                closable: true,
            })
        );
        yield loggerSaga({ error });
    }
}

function* cancelAutorenew(action: ReturnType<typeof BuySubscriptionActions.cancelAutorenewSubscription>) {
    const { subscriptionId, captchaId, captchaValue, sendAnalytics } = action.payload;

    const subscription = yield select(getSubscriptionsById, subscriptionId);

    if (captchaId) {
        yield call(checkCaptcha, { id: captchaId, value: captchaValue || '', sendAnalytics });

        yield closePopupHelper(popupNames.SUBSCRIPTION_CANCEL_WITH_CAPTCHA);
    }

    try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        yield putResolve(yield call(BuySubscriptionActions.cancelAutorenew, subscriptionId));

        // закрываем окно с информацией о подписке для триалов,
        // тк после отмены автоплатежа такая подписка удаляется из списка подписок
        if (subscription?.isTrial && !subscription?.isPrepaid) {
            closePopupHelper(popupNames.SUBSCRIPTION_MODAL);
        }

        yield put(
            showSnackbarAction({
                text: 'Подписка отменена',
                type: SnackbarTypes.success,
                id: BuyNotifications.cancelAutoRenewSuccess,
                closable: true,
                buttonText: 'Восстановить',
                onButtonClick: () => {
                    sendAnalytics?.(ESubscriptionModalAction.cancelAutopaymentRestore);
                    store.dispatch(BuySubscriptionActions.addAutorenewToSubscription({ subscription }));
                },
            })
        );

        yield put(updateSubscriptionsRequest());
    } catch (error) {
        yield put(
            showSnackbarAction({
                text: 'Не удалось отключить автоплатёж',
                type: SnackbarTypes.failure,
                id: BuyNotifications.cancelAutoRenewFailure,
                closable: true,
            })
        );
        yield loggerSaga({ error });
    }

    const isPhone = yield select(EnvironmentSelectors.isPhone);

    yield renderPollPopup({ isPhone });
}

export function* watchBuySubscription() {
    yield takeEvery(BuySubscriptionActions.openCancelAutorenewMailSubsDialog.toString(), openCancelAutorenewMailSubsDialog);
    yield takeEvery(BuySubscriptionActions.addAutorenewToSubscription.toString(), addAutoRenew);
    yield takeEvery(BuySubscriptionActions.cancelAutorenewSubscription.toString(), cancelAutorenew);
}
