import config from 'Cloud/config';
import type { Editor, EditorState } from 'reactApp/modules/editor/editor.types';
import { isEditPublicCopy } from 'reactApp/modules/features/features.helpers';
import { isMountedOrSharedFolder } from 'reactApp/modules/storage/storage.selectors';
import { CloudFile, EStorageType } from 'reactApp/modules/storage/storage.types';

/**
 * Есть стойкое ощущение, что всё, что здесь было написано и после вынесено в отдельные методы уже
 * запрограмированно в модулях compose-*
 * Нужно бы разобраться с этим и отрефачить, дабы уменьшить энтропию
 */

const ITEM_WITHOUT_HASH_MAX_SIZE = config.get('ITEM_WITHOUT_HASH_MAX_SIZE');

export const isValidEditableItem = (item: CloudFile | null | undefined): boolean => {
    if (!item) {
        return false;
    }

    const { size, storage } = item;

    if (typeof size !== 'number' || !Number.isFinite(size) || size <= 0 || size <= ITEM_WITHOUT_HASH_MAX_SIZE) {
        // для файлов без размера или бесконечного размера или вдруг файлов размером
        // меньше 21 байта просмотр недоступен
        return false;
    }

    if (isEditPublicCopy && storage === EStorageType.public) {
        return true;
    }

    const { home, feed, attaches, viewerAttaches, favorites, search } = EStorageType;
    const notInEditableStorage = [home, feed, attaches, viewerAttaches, favorites, search].every(
        (targetStorage) => targetStorage !== storage
    );
    return !notInEditableStorage;
};

export const getAvailableEditorsForItem = (
    item: CloudFile,
    editor: EditorState,
    folderState: ReturnType<typeof isMountedOrSharedFolder>
): Editor[] => {
    const { extensions, availableEditors, sharedExtensions } = editor;
    const ext = item?.ext?.toLocaleLowerCase();
    const hasSharedEditor = editor.hasSharedEditor && sharedExtensions[ext];
    const isSharedFolder = hasSharedEditor || folderState.is;
    const editors = extensions[ext]?.editors || [];

    return editors.reduce<Editor[]>((acc, id) => {
        let editor = availableEditors[id] as Editor;
        const isDefault = hasSharedEditor && isSharedFolder ? editor.default === 'shared-folder' : !!editor.default;

        if (!hasSharedEditor || !editor['x-in-shared-folder'] || isSharedFolder) {
            editor = Object.assign(
                {},
                editor,
                {
                    icon: editor.icon ? editor.icon[item.ext] : undefined,
                    icon_big: editor?.icon_big?.[item.ext],
                },
                {
                    name: editor.id,
                    text: editor.title,
                }
            );

            if (isDefault) {
                // default is always first
                acc.unshift(editor);
            } else {
                acc.push(editor);
            }
        }
        return acc;
    }, []);
};
