/** tempexp_14729-full-file */
import classNames from 'clsx';
import React, { FC, ReactElement, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getSearchContentStatus } from 'reactApp/modules/search/search.selectors';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { STORAGE_CONFIG } from 'reactApp/modules/storage/storage.config';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { Checkbox } from 'reactApp/ui/DataListItemCommonComponents/Checkbox/Checkbox';
import { Destination, SizeType } from 'reactApp/ui/DataListItemCommonComponents/Destination/Destination';
import { Download } from 'reactApp/ui/DataListItemCommonComponents/Download/Download';
import { Favorite } from 'reactApp/ui/DataListItemCommonComponents/Favorite/Favorite';
import { Progress } from 'reactApp/ui/DataListItemCommonComponents/Progress/Progress';
import { Publish } from 'reactApp/ui/DataListItemCommonComponents/Publish/Publish';
import { Tip } from 'reactApp/ui/Tip/Tip';
import { ESearchOptionSource } from 'reactApp/ui/WebSearch/WebSearch.data';
import { WebSearchSource } from 'reactApp/ui/WebSearch/WebSearchSource/WebSearchSource';
import { noop } from 'reactApp/utils/helpers';

import { DataListItemThumbProps as DataListItemThumbBaseProps } from '../DataListItemThumb.types';
import { Date } from './components/Date/Date';
import { ItemName } from './components/ItemName/ItemName';
import { Pic } from './components/Pic/Pic';
import styles from './DataListItemThumb.css';

type DataListItemThumbProps = DataListItemThumbBaseProps & {
    className?: string;
};

// eslint-disable-next-line max-lines-per-function
export const DataListItemThumb: FC<DataListItemThumbProps> = (props) => {
    const {
        className,
        denyDoAction,
        name,
        ext,
        mtime,
        isPopup,
        storage,
        isSelected,
        onCheckboxClick,
        onDownload,
        isFolder,
        size,
        id,
        forceCheckbox,
        isPublicDownloaded,
        isActive,
        tipData,
    } = props;
    const config = STORAGE_CONFIG[storage];
    const { webSearchContentEnabled } = useSelector(getSearchContentStatus);

    const [pictureLoaded, setPictureLoaded] = useState<boolean>(false);
    const handlePictureLoad = useCallback(() => {
        setPictureLoaded(true);
    }, []);

    const hasThumb = !props.isFolder && props.thumbUrl?.src && pictureLoaded;
    const isFavoritesEnable = config.favorites && 'favoritesAvailable' in props && props.favoritesAvailable;
    const isRenameAvailable = 'renameAvailable' in props && props.renameAvailable;

    const bottomActions = useMemo(() => {
        const content: ReactElement[] = [];

        if (!denyDoAction && isFavoritesEnable) {
            content.push(
                <Favorite
                    key={0}
                    className={classNames(styles.favorite, {
                        [styles.favorite_active]: 'isInFavorites' in props && Boolean(props.isInFavorites),
                    })}
                    viewType={EViewMode.thumbs}
                    isFavorite={'isInFavorites' in props && Boolean(props.isInFavorites)}
                    onClick={'onFavorites' in props ? props.onFavorites : noop}
                />
            );
        }

        switch (storage) {
            case EStorageType.home:
            case EStorageType.sharedLinks:
            case EStorageType.sharedAutodelete:
            case EStorageType.favorites:
            case EStorageType.search:
            case EStorageType.feed:
            case EStorageType.documents:
            case EStorageType.gallery:
                if (storage === EStorageType.home && denyDoAction && typeof props.progress === 'number') {
                    content.push(<Progress key={1} onClick={props.onCancelUpload} progress={props.progress} />);

                    break;
                }

                content.push(
                    <Publish
                        className={classNames(styles.publish, {
                            [styles.publish_active]: props.isPublic,
                        })}
                        key={2}
                        viewType={EViewMode.thumbs}
                        isShared={props.isPublic}
                        onClick={props.onPublish}
                    />
                );

                break;
        }

        return <div className={styles.actions}>{content}</div>;
    }, [denyDoAction, isFavoritesEnable, props, storage]);

    const floatingActions = useMemo(() => {
        const content: ReactElement[] = [
            <Checkbox
                key={0}
                className={styles.check}
                viewType={EViewMode.thumbs}
                onCheckboxClick={onCheckboxClick}
                isSelected={isSelected}
                isDisabled={denyDoAction}
            />,
        ];

        if (!denyDoAction && !forceCheckbox && !isPopup && (storage !== EStorageType.public || !isPublicDownloaded)) {
            content.push(
                <div key={1} className={styles.download}>
                    <Download
                        onClick={onDownload}
                        ext={isFolder ? 'zip' : ext}
                        size={size}
                        id={id}
                        storage={storage}
                        viewType={EViewMode.thumbs}
                        className={styles.download}
                        isLarge={false}
                    />
                </div>
            );
        }

        return <div className={styles.floating_actions}>{content}</div>;
    }, [
        denyDoAction,
        ext,
        forceCheckbox,
        id,
        isFolder,
        isPopup,
        isPublicDownloaded,
        isSelected,
        onCheckboxClick,
        onDownload,
        size,
        storage,
        webSearchContentEnabled,
    ]);

    return (
        <div
            className={classNames(styles.root, className, {
                [styles.selected]: isSelected,
                [styles.active]: isActive,
            })}
        >
            {floatingActions}
            <div
                className={classNames(styles.picture, {
                    [styles.picture_thumb]: hasThumb,
                    [styles.picture_icon]: !hasThumb,
                })}
            >
                <Pic
                    isFolder={props.isFolder}
                    isPublic={props.isPublic}
                    thumbUrl={props.thumbUrl}
                    kind={props.kind}
                    isVirus={'isVirus' in props && Boolean(props.isVirus)}
                    isAutoDelete={'isAutoDelete' in props && props.isAutoDelete}
                    ext={props.ext || ''}
                    isLoading={denyDoAction}
                    onLoad={handlePictureLoad}
                    hasLoaded={pictureLoaded}
                />
                {storage === EStorageType.attaches && (
                    <Destination
                        className={styles.destination}
                        size={SizeType.s}
                        author={props.owner}
                        openAttachLetter={props.openAttachLetter}
                        isAttaches
                    />
                )}
            </div>
            <div className={styles.details}>
                <ItemName name={name} ext={ext} id={id} isRenameAvailable={isRenameAvailable} />
                {!denyDoAction &&
                    !isPopup &&
                    (webSearchContentEnabled && 'srchSrc' in props && props.srchSrc === ESearchOptionSource.content && props.query ? (
                        <WebSearchSource className={styles.source} query={props.query} />
                    ) : (
                        <Date
                            date={mtime}
                            expires={'publicExpires' in props ? props.publicExpires : undefined}
                            isAutoDelete={'isAutoDelete' in props && props.isAutoDelete}
                        />
                    ))}
            </div>
            {!isPopup && bottomActions}
            {!isPopup && (
                <div className={styles.pinLeft}>
                    {!!tipData && (
                        <Tip
                            hideTip={tipData[0].onHideClick}
                            onShow={tipData[0].onShow}
                            position="top center"
                            text={tipData[0].getTipContent?.(isFolder)}
                            tips={tipData}
                            id={tipData[0].id}
                            show
                            showBubble={false}
                            className={styles.pinLeftTooltip}
                        />
                    )}
                </div>
            )}
        </div>
    );
};
