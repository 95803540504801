import { HomeFile } from 'reactApp/modules/home/home.types';
import { IPopupHelperProps } from 'reactApp/modules/popup/popup.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';

export type CreateCopyOfNonEditableFileModalProps = Pick<IPopupHelperProps, 'onClose'> & {
    file: HomeFile;
    editingNoneEditableFormats: { storage: EStorageType; ext: string };
    fromSharePopup?: boolean;
};

export const enum ContentType {
    home = 'home',
    sharedHome = 'sharedHome',
    sharedPopup = 'sharedPopup',
}
