import downloadAppModal from 'img/downloadAppModal/1.png';
import React, { memo, useCallback, useEffect, useRef } from 'react';
import { IPropsWithPopup } from 'reactApp/modules/popup/popup.helpers';
import { sendDwh } from 'reactApp/utils/ga';

import { MobileDialog } from '../MobileDialog/MobileDialog';
import styles from './DownloadAppModal.css';
import { DownloadAppModalData } from './DownloadAppModal.types';

const BUTTON_HREF = 'https://trk.mail.ru/c/emn3i6';

export const DownloadAppModal = memo<IPropsWithPopup<DownloadAppModalData>>(
    ({ onClose, description, title, buttonText, buttonLink, imgUrl }) => {
        const ref = useRef<HTMLDivElement>(null);

        useEffect(() => {
            sendDwh({
                eventCategory: 'ads-touch',
                action: 'view',
            });
        }, []);

        const handleClick = useCallback(() => {
            sendDwh({
                eventCategory: 'ads-touch',
                action: 'click',
            });
            onClose();
        }, [onClose]);

        const handleClose = useCallback(() => {
            sendDwh({
                eventCategory: 'ads-touch',
                action: 'close',
            });
            onClose();
        }, [onClose]);

        return (
            <MobileDialog id="download-app-modal" onClose={handleClose} mod="base" topmost closeOnDimmerClick scrollRef={ref}>
                <div className={styles.root} ref={ref}>
                    <div className={styles.imageWrapper}>
                        <img className={styles.image} src={imgUrl || downloadAppModal} />
                    </div>
                    <div className={styles.content}>
                        <div className={styles.title}>{title}</div>
                        <div className={styles.text}>{description}</div>
                        <a
                            href={`${buttonLink || BUTTON_HREF}?mt_deeplink=${window.location.href}`}
                            onClick={handleClick}
                            className={styles.button}
                        >
                            {buttonText}
                        </a>
                    </div>
                </div>
            </MobileDialog>
        );
    }
);

DownloadAppModal.displayName = 'DownloadAppModal';
