import { xray } from 'lib/xray';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    quotaCleanerYearFilterKey,
    quotaCleanerYearFilterPromoId,
    showQuotaCleanerYearFilterPromo,
} from 'reactApp/components/QuotaCleanerYearFilterPromo/quotaCleanerYearFilterPromo.toolkit';
import { QuotaLandingSelectors } from 'reactApp/modules/quotaLanding/quotaLanding.selector';
import { resetSelect } from 'reactApp/modules/selections/selections.actions';
import { UserStorageActions } from 'reactApp/modules/user/userStorage';
import { getUserQuotaGroup, toggleYearFilterOpen } from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.actions';
import {
    getCurrentGroupId,
    getIsToggledYearFilterOpen,
    getYearCurrentGroupConfig,
} from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.selectors';
import { QuotaCleanerYears } from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.types';
import { getSize } from 'reactApp/utils/sizeHelper';

export const useYearFilter = () => {
    const dispatch = useDispatch();
    const yearConfig = useSelector(getYearCurrentGroupConfig);
    const groupId = useSelector(getCurrentGroupId);
    const isToggledYearFilterOpen = useSelector(getIsToggledYearFilterOpen);
    const isMobile = useSelector(QuotaLandingSelectors.isMobile);

    const toggleIsDropdownListOpen = useCallback(
        (value?: boolean) => {
            dispatch(toggleYearFilterOpen({ value }));
        },
        [dispatch]
    );

    const setCurrentYear = useCallback(
        (year: string | QuotaCleanerYears) => {
            if (!yearConfig || !groupId) {
                return;
            }

            dispatch(resetSelect());
            dispatch(getUserQuotaGroup({ groupId, year }));
            xray.send(`quota-cln-set-year${isMobile ? '-mob' : ''}`, { i: groupId });
        },
        [dispatch, yearConfig, groupId]
    );

    const list = useMemo(() => {
        if (!yearConfig || !yearConfig.config || !groupId) {
            return [];
        }

        const { config, currentYear, all } = yearConfig;
        const years = Object.keys(config);
        // фильтр имеет смысл при > 1 варианте
        if (years.length < 2) {
            return [];
        }

        return [QuotaCleanerYears.All, ...years].map((year) => {
            const isAll = year === QuotaCleanerYears.All;
            const { size } = isAll ? all : config[year];
            const allYear = isMobile ? 'Все письма' : 'Посмотреть все письма';
            const yearString = isAll ? allYear : year;

            return {
                id: year,
                year,
                yearString,
                size,
                sizeString: getSize(size),
                isCurrent: year === currentYear,
            };
        });
    }, [yearConfig, groupId]);

    const onYearFilterPromoHide = useCallback(() => {
        dispatch(UserStorageActions.set(quotaCleanerYearFilterKey, new Date().getTime()));
    }, [dispatch]);

    const yearFilterPromoStoreValue = useMemo(
        () => dispatch(UserStorageActions.get(quotaCleanerYearFilterKey) as unknown) as number | null,
        [dispatch]
    );

    useEffect(() => {
        showQuotaCleanerYearFilterPromo(yearFilterPromoStoreValue, onYearFilterPromoHide, isMobile);
    }, []);
    return {
        list,
        setCurrentYear,
        isDropdownListOpen: isToggledYearFilterOpen,
        toggleIsDropdownListOpen,
        promoId: quotaCleanerYearFilterPromoId,
    };
};
