import { MAX_PARALLEL_UPLOAD_COUNT } from 'reactApp/modules/features/features.helpers';
import { removeFileSuccess } from 'reactApp/modules/modifying/modifying.actions';
import { joinPath } from 'reactApp/modules/uploading/helpers/fs/fs.helpers';
import { getConnectionTypeString, sendGaUploaderNew } from 'reactApp/modules/uploading/helpers/uploading.helpers';
import { UploadingPacketConfig } from 'reactApp/modules/uploading/serviceClasses/UploadingPacketConfig';
import { uploadingService } from 'reactApp/modules/uploading/serviceClasses/UploadingService';
import { RETRY_TIMEOUT_CONNECTION_ERROR } from 'reactApp/modules/uploading/uploading.constants';
import { processUploadQueue, startFileUploading } from 'reactApp/modules/uploading/uploading.module';
import { EConflictResolution, EUploadingState } from 'reactApp/modules/uploading/uploading.types';
import { sendDwh } from 'reactApp/utils/ga';
import { delay, put } from 'redux-saga/effects';

function* processPacketsUploadEnd() {
    const removeFromDatalistIdxs = new Set<string>();

    const packets = uploadingService.getAllPackets();

    if (!packets.length) {
        return;
    }

    uploadingService._isActive = false;

    packets.forEach((packet: UploadingPacketConfig) => {
        if (packet.packetUploadEndProcessed) {
            return;
        }

        packet.packetUploadEndProcessed = true;

        packet.finishTime = Date.now();

        const totalTime = Math.round(packet.finishTime - packet.startTime);
        const speed = Math.round((packet.size - packet.prevUploadedBytes) / (totalTime / 1000));

        sendDwh({
            eventCategory: 'uploader',
            action: 'packet-finished',
            label: 'metrics',
            dwhData: {
                totalTime,
                uploadedBytes: packet.size,
                prevUploadedBytes: packet.prevUploadedBytes,
                fileCount: packet.fileCount,
                folderCount: packet.folderCount,
                canceledCount: packet.cancelCount,
                hasError: packet.hasError,
                source: packet.storage,
                group: MAX_PARALLEL_UPLOAD_COUNT.toString(),
                description: getConnectionTypeString(),
                diskReadTime: packet.diskReadTime,
                hashCalcTime: packet.hashCalcTime,
                putTime: packet.putTime || 0,
                pollTime: packet.pollTime || 0,
                addTime: packet.addTime || 0,
                speed,
            },
        });

        sendGaUploaderNew('packet', 'speed', speed);

        const descriptors = uploadingService.getPacketDescriptors(packet.packetId);
        descriptors.forEach((descriptor) => {
            if (descriptor.conflictResolution === EConflictResolution.skip) {
                return;
            }
            const canceled = descriptor.getTopMostCanceledDescriptor();
            if (!canceled) {
                return;
            }
            const id = canceled.cloudPath || joinPath(packet.workingDirectory, canceled.localPath);

            if (id) {
                removeFromDatalistIdxs.add(id);
            }
        });
    });

    if (removeFromDatalistIdxs.size > 0) {
        yield put(removeFileSuccess({ ids: Array.from(removeFromDatalistIdxs) }));
    }
}

export function* handleProcessUploadQueue() {
    if (!window.navigator.onLine) {
        yield delay(RETRY_TIMEOUT_CONNECTION_ERROR);
        yield put(processUploadQueue());
        return;
    }

    if (uploadingService.isUserCanceled) {
        yield processPacketsUploadEnd();
        return;
    }

    const descriptors = uploadingService.getDescriptorsForUpload();

    if (!descriptors.length) {
        if (uploadingService.uploadingQueue.length <= 0) {
            // Новых дескрипторов для загрузки нет, и сейчас ничего не загружается - значит все уже загружено
            yield processPacketsUploadEnd();
        }

        return;
    }

    for (const descriptor of descriptors) {
        if (descriptor) {
            uploadingService._isActive = true;

            if (!window.navigator.onLine) {
                yield delay(RETRY_TIMEOUT_CONNECTION_ERROR);
                yield put(processUploadQueue());
                return;
            }

            if (descriptor.hasCanceledParent()) {
                continue;
            }

            if (descriptor.state === EUploadingState.STATE_SUSPENDED) {
                yield delay(RETRY_TIMEOUT_CONNECTION_ERROR);
            }

            uploadingService.uploadingQueue.push(descriptor.id);
            yield put(startFileUploading(descriptor));
        }
    }
}
