import { Space } from '@mail/cross-sizes-utils';
import { ReactElement } from 'react';
import { EpubPromoType } from 'reactApp/modules/promo/sagas/epub.saga';
import { Product, Tariff } from 'reactApp/types/Billing';

export enum EPromoType {
    bindingSpherum = 'bindingSpherum',
    topBanner = 'topBanner',
    whatsNew = 'whatsNew',
    faceFilter = 'faceFilter',
    mobileFaceFilter = 'mobileFaceFilter',
    mobilePublicBanner = 'mobilePublicBanner',
    mobileSearchBanner = 'mobileSearchBanner',
    mobileSearchHistoryBanner = 'mobileSearchHistoryBanner',
    secondClick = 'secondClick',
    space = 'space',
    banner = 'banner',
    overQuota = 'overQuota',
    quota = 'quota',
    downloadApp = 'downloadApp',
    expiredDeviceSubscription = 'expiredDeviceSubscription',
    publicUpload = 'publicUpload',
    csatServey = 'csatServey',
    mobileSaveSubs = 'mobileSaveSubs',
    saveSubsPopup = 'saveSubsPopup',
    saveSubsTopBanner = 'saveSubsTopBanner',
    newFeaturesDesktop = 'newFeaturesDesktop',
    newFeaturesMobile = 'newFeaturesMobile',
    diskOAsidePromo = 'diskOAsidePromo',
    safetyAsidePromo = 'safetyAsidePromo',
    // tempexp_SRCH-70119-next-line
    searchTooltip = 'searchTooltip',
    searchFilterTooltipPromo = 'searchFilterTooltipPromo',
    defaultQuotaAsidePromo = 'defaultQuotaAsidePromo',
    autosave = 'autosave',
    epubPromo = 'epubPromo',
    suggestionsLaw = 'suggestionsLaw',
    familyAsidePromo = 'familyAsidePromo',
}

export interface IBaseData {
    promoId: string;

    onShow();

    onClose();
}

export interface IWhatsNewData extends IBaseData {
    onClick();

    onSecondary();

    title: string;
    text: string;
    imageUrl: string;
    date?: number;
    primaryButtonText: string;
    closeByPrimaryButton?: boolean;
    primaryButtonHref?: string;
    secondaryButtonText?: string;
    secondaryButtonHref?: string;
}

type IPromoDataWhatsNew = {
    type: EPromoType.whatsNew;
} & IWhatsNewData;

type IPromoSaveSubs = {
    type: EPromoType.mobileSaveSubs | EPromoType.saveSubsPopup | EPromoType.saveSubsTopBanner;
    productId?: string;
    oldSubscriptionSpace: Space;
    source: string;
    isTransit: boolean;
    onClick?();
} & IBaseData;

type IPromoFaceFilter = {
    type: EPromoType.faceFilter;
} & IBaseData;

type IPromoMobileFaceFilter = {
    type: EPromoType.mobileFaceFilter;
} & IBaseData;

type IPromoMobileSearchBanner = {
    type: EPromoType.mobileSearchBanner;
} & IBaseData;

type IPromoMobileSearchHistoryBanner = {
    type: EPromoType.mobileSearchHistoryBanner;
} & IBaseData;

type IPromoMobilePublicBanner = {
    type: EPromoType.mobilePublicBanner;
} & IBaseData;

export type IPromoSecondClick = {
    type: EPromoType.secondClick;
    onHide();
} & IBaseData;

export interface ISpacePromoConfig {
    id: string;
    icon: string;
    title: string;
    text: string;
    button: string;
    tariffId: string;
    theme: string;
}

export type IPromoBanner = {
    type: EPromoType.banner;
    config: ISpacePromoConfig;
    product: Product;
} & IBaseData;

export type IPromoOverQuota = {
    type: EPromoType.overQuota;
    tariff: Tariff;
    product: Product;
} & IBaseData;

export type IPromoQuota = {
    type: EPromoType.quota;
    quota: number;
} & IBaseData;

export type IPromoDownloadApp = {
    type: EPromoType.downloadApp;
} & IBaseData;

export type IPromoExpiredDeviceSubscription = {
    type: EPromoType.expiredDeviceSubscription;
    product: Tariff;
} & IBaseData;

export interface IPromoSpaceData {
    id: string;
    text: string;
    img: ReactElement;
    productId: string;
}

export type IPromoSpace = {
    type: EPromoType.space;
    data: IPromoSpaceData;
    endDate: number;
} & IBaseData;

export type IPromoCSAT = {
    type: EPromoType.csatServey;
} & IBaseData;

export type IPromoPublicUpload = {
    type: EPromoType.publicUpload;
} & IBaseData;

type TSearchTooltip = {
    type: EPromoType.searchTooltip;
} & IBaseData;

type TSearchFilterTooltip = {
    type: EPromoType.searchFilterTooltipPromo;
} & IBaseData;

type TPromoNewFeatures = {
    type: EPromoType.newFeaturesDesktop;
} & IBaseData;

type TPromoNewFeaturesMobile = {
    type: EPromoType.newFeaturesMobile;
} & IBaseData;

export interface IDiskOAsidePromoModal extends IBaseData {
    type: EPromoType.diskOAsidePromo;
    onClick: () => void;
}

export interface ISafetyAsidePromoModal extends IBaseData {
    type: EPromoType.safetyAsidePromo;
    onClick: () => void;
}

export interface IBindingSpherumPromoModal extends IBaseData {
    type: EPromoType.bindingSpherum;
}

// tempexp_14979-start
export interface IDefaultQuotaAsidePromoModal extends IBaseData {
    type: EPromoType.defaultQuotaAsidePromo;
}

// tempexp_14979-end

export interface IAutosaveAsidePromoModal extends IBaseData {
    type: EPromoType.autosave;
}

export type IPromoEpub = {
    type: EPromoType.epubPromo;
    tipType: EpubPromoType[];
    onMarkShown(type: EpubPromoType): void;
} & IBaseData;

export type IPromoSuggestionsLaw = {
    type: EPromoType.suggestionsLaw;
} & IBaseData;

export interface IFamilyAsidePromoModal extends IBaseData {
    type: EPromoType.familyAsidePromo;
    onClick: () => void;
}

export type IPromoData =
    | IPromoDataWhatsNew
    | IPromoFaceFilter
    | IPromoMobileFaceFilter
    | IPromoMobileSearchBanner
    | IPromoMobileSearchHistoryBanner
    | IPromoMobilePublicBanner
    | IPromoSecondClick
    | IPromoBanner
    | IPromoOverQuota
    | IPromoQuota
    | IPromoDownloadApp
    | IPromoExpiredDeviceSubscription
    | IPromoSpace
    | IPromoCSAT
    | IPromoSaveSubs
    | IPromoPublicUpload
    | TPromoNewFeatures
    | TPromoNewFeaturesMobile
    | TSearchTooltip
    | TSearchFilterTooltip
    | IDiskOAsidePromoModal
    | ISafetyAsidePromoModal
    // tempexp_14979-next-line
    | IDefaultQuotaAsidePromoModal
    | IAutosaveAsidePromoModal
    | IPromoEpub
    | IFamilyAsidePromoModal
    | IBindingSpherumPromoModal
    | IPromoSuggestionsLaw;

export type IGenericPromoData<T extends EPromoType> = {
    type: T;
} & IPromoData;

export function isNeedsPromo<T extends EPromoType>(promoType: T, promo?: IPromoData): promo is IGenericPromoData<T> {
    return promo?.type === promoType;
}

export interface IPromoState {
    queue: IPromoData[];
    currentPromo?: IPromoData;
    lastPromoShownTimestamp?: Date;
}
