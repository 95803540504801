import { Icon24ClockOutline } from '@vkontakte/icons';
import classNames from 'clsx';
import { xray } from 'lib/xray';
import React, { ReactElement, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isModifiedSharing } from 'reactApp/appHelpers/appHelpers';
import { SharingDropdown } from 'reactApp/components/SharingWindow/Dropdown/SharingDropdown';
import { EXPIRES_OPTIONS, QA_VALUE, TOOLTIP_OFFSET } from 'reactApp/components/SharingWindow/Sharing.constants';
import { sendGa } from 'reactApp/components/SharingWindow/Sharing.helpers';
import { ExpiryPeriod } from 'reactApp/components/SharingWindow/Sharing.types';
import styles from 'reactApp/components/SharingWindow/Weblink/SharingWeblink.css';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { deleteWeblinkExpiresRequest, updateWeblinkExpiresRequest } from 'reactApp/modules/modifying/modifying.actions';
import { Dropdown } from 'reactApp/ui/Dropdown/Dropdown';
import { DropdownMenu } from 'reactApp/ui/Dropdown/DropdownMenu';
import { tooltipPlacements } from 'reactApp/ui/Tooltip/Tooltip.constants';
import { formatExpiresDropdown } from 'reactApp/utils/formatDate';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

export const useExpires = (item) => {
    const modifiedSharing = isModifiedSharing(item);
    const dispatch = useDispatch();
    const isPhone = useSelector(EnvironmentSelectors.isPhone);
    const expires = item?.weblinkExpires;

    const renderExpiresDropdown = useCallback(
        (onClick): ReactElement => {
            const list = Object.values(EXPIRES_OPTIONS).map((item) => ({
                id: item.id,
                text: item.text,
                check: !expires && item.id === ExpiryPeriod.Unlimited,
                qaValue: item.id,
            }));

            // После установки TTL, он показывается в выпадающем списке 1м элементом
            if (expires) {
                list.unshift({
                    text: `До ${formatExpiresDropdown(expires)}`,
                    id: ExpiryPeriod.Expiry,
                    check: true,
                    qaValue: ExpiryPeriod.Expiry,
                });
            }

            return <DropdownMenu list={list} theme="octavius" onClick={onClick} className={styles.dropdownMenu} />;
        },
        [expires]
    );

    const handleUpdateExpires = useCallback(
        (id): void => {
            const expires = EXPIRES_OPTIONS[id];

            if (id === ExpiryPeriod.Expiry) {
                return;
            }

            if (modifiedSharing) {
                xray.send('sharing-modified_expires');
            }

            sendGa('expires');
            sendPaymentGa({
                eventCategory: ECategoryGa.public,
                action: 'edit-ttl',
                time_ttl: id,
                public_id: item?.weblink,
            });

            if (expires.id === ExpiryPeriod.Unlimited) {
                dispatch(deleteWeblinkExpiresRequest({ item }));

                return;
            }

            dispatch(updateWeblinkExpiresRequest({ item, expires: expires.getValue() }));
        },
        [dispatch, item]
    );

    return useCallback((): ReactElement => {
        const item = EXPIRES_OPTIONS[ExpiryPeriod.Unlimited];
        const text = expires ? `До ${formatExpiresDropdown(expires)}` : item?.text;

        return (
            <div
                className={classNames(styles.rightItem, { [styles.rightItemModified]: modifiedSharing })}
                data-qa-item={QA_VALUE.expiresItem}
            >
                {modifiedSharing && <Icon24ClockOutline className={styles.leftIcon} />}
                <span className={styles.rightTitle}>Ссылка действует</span>
                <div className={styles.dropdown}>
                    <Dropdown
                        withinDialog
                        theme="octavius"
                        qaValue={item.id}
                        as={SharingDropdown}
                        value={isPhone || modifiedSharing ? text : text.toLowerCase()}
                        content={renderExpiresDropdown}
                        onClickItem={handleUpdateExpires}
                        placement={tooltipPlacements.BOTTOM_LEFT}
                        qaValueContent={QA_VALUE.expiresDropdownContent}
                        tooltipOffset={TOOLTIP_OFFSET}
                        animatedTooltip
                        description="Ссылка действует"
                        tooltipClassName={isPhone ? styles.tooltip : undefined}
                        isPhone={isPhone}
                    />
                </div>
            </div>
        );
    }, [expires, handleUpdateExpires, isPhone, renderExpiresDropdown]);
};
