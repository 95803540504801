import { IS_MY_TEAM } from 'reactApp/appHelpers/configHelpers';
import { vkTeamsBridge } from 'reactApp/modules/vkTeam/vkTeam.module';
import opener from 'reactApp/utils/opener';

interface openPageProps {
    inSameTab?: boolean;
    anchor?: string;
    query?: string;
}

export const openSubscriptionsPage = ({ inSameTab = false, anchor = '', query = '' }: openPageProps = {}): Window | undefined | null => {
    const url = `/subscriptions/${query ? `?${query}` : ''}${anchor ? `#${anchor}` : ''}`;

    if (!inSameTab) {
        const tab = window.open(url);

        if (tab) {
            tab.focus();
        }

        return tab;
    }

    window.location.assign(url);
};

export const openPromoQuotaPage = ({ inSameTab = false, anchor = '', query = '' }: openPageProps = {}) => {
    opener(`/promo/quota/${query ? `?${query}` : ''}${anchor ? `#${anchor}` : ''}`, inSameTab);
};

export const openLink = async (link: string, target = '_self') => {
    if (!IS_MY_TEAM) {
        window.open(link, target);
    } else {
        try {
            const bridge = await vkTeamsBridge;
            await bridge.send('OpenLink', { url: link });
        } catch (error) {
            console.error(error);
        }
    }
};
