import { Icon20Users3Outline } from '@vkontakte/icons';
import classNames from 'clsx';
import React, { memo, ReactElement, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ENABLE_FULL_RESPONSIVE } from 'reactApp/appHelpers/configHelpers';
import { BREAKPOINT_SM } from 'reactApp/constants/breakpoints';
import { contactsSlice } from 'reactApp/modules/contacts/contacts.module';
import { isFolderOwner } from 'reactApp/modules/storage/storage.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { DropdownItemAction } from 'reactApp/ui/DropdownItemAction/DropdownItemAction';
import { DropdownFont, DropdownList, DropdownTheme, ListItem } from 'reactApp/ui/DropdownList/DropdownList';
import { EDropdownPosition } from 'reactApp/ui/Toolbar/Toolbar.types';
import styles from 'reactApp/ui/Toolbar/ToolbarItem/ToolbarItem.css';
import { findDropdownPosition } from 'reactApp/ui/Toolbar/ToolbarItem/ToolbarItem.utils';
import { useWindowWidth } from 'reactApp/ui/VirtualList/VirtualList.hooks';
import { ChevronDownIcon } from 'reactApp/ui/VKUIIcons';
import { sendGa } from 'reactApp/utils/ga';

import { AuthorItem } from './AuthorItem';
import { useList } from './useList';

const GA_ID = 'authorFilter';

export const FilterAuthorsDropdown = memo(({ parentRef }: { parentRef?: RefObject<HTMLElement> }): ReactElement => {
    const dispatch = useDispatch();
    const windowWidth = useWindowWidth();

    const isOwn = useSelector(isFolderOwner);
    const isAnonymous = useSelector(UserSelectors.isAnonymous);
    const list = useList();

    const rootRef = useRef<HTMLDivElement>(null);

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const closeDropdown = useCallback(() => {
        setIsOpen(false);
    }, []);

    const onClick = useCallback(() => {
        if (!isOpen) {
            sendGa(GA_ID, 'item-click', 'authors');
        }

        setIsOpen(!isOpen);
    }, [isOpen]);

    const renderDropdownItem = useCallback(
        ({ id, text, active }: ListItem) => {
            if (!isOwn) {
                return <DropdownItemAction text={text} withStatus active={active} />;
            }

            return <AuthorItem key={id} id={id} withAvatar={id !== 'all'} text={text} checked={active} email={id} />;
        },
        [isOwn]
    );

    useEffect(() => {
        if (!isAnonymous) {
            dispatch(contactsSlice.actions.listRequest());
        }
    }, [isAnonymous]);

    return (
        <div
            className={classNames(styles.root, styles.root_withDropdown, {
                [styles.root_active]: isOpen,
                [styles.rootResponsive]: ENABLE_FULL_RESPONSIVE,
            })}
            data-name="author"
            ref={rootRef}
            onClick={onClick}
        >
            <div
                className={classNames(styles.text, {
                    [styles.textResponsive]: ENABLE_FULL_RESPONSIVE,
                })}
            >
                Авторы
            </div>
            {ENABLE_FULL_RESPONSIVE && windowWidth <= BREAKPOINT_SM && <Icon20Users3Outline />}
            <div className={styles.dropdownIcon}>
                <ChevronDownIcon />
            </div>
            {isOpen && (
                <DropdownList
                    list={list}
                    renderItem={renderDropdownItem}
                    close={closeDropdown}
                    calcPosition={findDropdownPosition({ ref: rootRef, parentRef, position: EDropdownPosition.right })}
                    theme={DropdownTheme.small}
                    parentRef={rootRef}
                    gaId={GA_ID}
                    closeOnResize
                    closeOnScroll
                    closeOnItemClick={!isOwn}
                    font={DropdownFont.mailSans}
                    animated
                    doNotGaSendId
                />
            )}
        </div>
    );
});

FilterAuthorsDropdown.displayName = 'FilterAuthorsDropdown2';
