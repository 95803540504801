/* eslint-disable max-lines, complexity */
import { SelectableGroup } from '@cloud/react-selectable';
import classNames from 'clsx';
import { isNil, path } from 'ramda';
import React, { memo, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IS_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { abSafeFakeDoorSelector, isNewPortalHeader } from 'reactApp/appHelpers/featuresHelpers';
import { chooseVariant } from 'reactApp/appHelpers/featuresHelpers/utils';
import { QuotaCleanerPlug } from 'reactApp/components/QuotaCleanerPlug/QuotaCleanerPlug';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { useHotKeyScope } from 'reactApp/hooks/useHotkeyScope';
import { usePortal } from 'reactApp/hooks/usePortal';
import { getAllDocumentsCurrentType } from 'reactApp/modules/allDocuments/allDocuments.selectors';
import { EAllDocumentsType } from 'reactApp/modules/allDocuments/allDocuments.types';
import { getBizCurrentCategory } from 'reactApp/modules/bizCategories/bizCategories.selectors';
import { BizCategoryType } from 'reactApp/modules/bizCategories/bizCategories.types';
import { isDialogVisible } from 'reactApp/modules/dialog/dialog.selectors';
import { getDownloadEbookClick } from 'reactApp/modules/ebook/ebook.selectors';
/* tempexp_14812-next-line */
import { FeatureSelector } from 'reactApp/modules/features/components/FeatureSelector';
/* tempexp_14812-next-line */
import { chooseVariant as chooseVariantOld } from 'reactApp/modules/features/features.helpers';
/* tempexp_14812-next-line */
import {
    getFeatureEditingNonEditableFormatsData,
    getFeatureHideCreateUploadInRoFolders,
    getFeatureNewEmptyStates,
    getFeatureShowStories,
} from 'reactApp/modules/features/features.selectors';
import { getFeedCategory } from 'reactApp/modules/feed/feed.selectors';
import { isMediaFolderAndGalleryEnabled, isVirusItem } from 'reactApp/modules/file/utils';
import { getGalleryCategory } from 'reactApp/modules/gallery/gallery.selectors';
import {
    getCurrentFolderHome,
    getDomainFoldersFilterActive,
    isReadOnly,
    isThisOrParentMounted,
} from 'reactApp/modules/home/home.selectors';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { EpubPromoType } from 'reactApp/modules/promo/sagas/epub.saga';
import { isPublicDownloadableEnabled, isPublicUploadEnabled } from 'reactApp/modules/public/public.selectors';
import { getCurrentStorage, getParams } from 'reactApp/modules/router/router.selectors';
import { getSearchRequestParams } from 'reactApp/modules/search/search.selectors';
import { SelectionsSelectors } from 'reactApp/modules/selections/selections.selectors';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import {
    getParentMountedOrSharedItems,
    getSelectedItem,
    getStorageCurrentFolder,
    getStorageItemById,
    groupedIds,
    hasLoadMore,
} from 'reactApp/modules/storage/storage.selectors';
import { CloudItem, EStorageType } from 'reactApp/modules/storage/storage.types';
import { getStorySummaryCovers } from 'reactApp/modules/stories/stories.selectors';
import { selectFilesInProgress } from 'reactApp/modules/uploadList/uploadList.selectors';
import { SelectFromCloudEmpty } from 'reactApp/sections/AlbumsPage/SelectFromCloudPopup/components/SelectFromCloudEmpty/SelectFromCloudEmpty';
import { getParams as getAttachesParams } from 'reactApp/sections/AttachesPage/helpers';
import { RootState } from 'reactApp/store';
import { WrapDataListItem } from 'reactApp/ui/Datalist/Item/DataListItem';
import { useHandleCallback } from 'reactApp/ui/Datalist/UserActions/handleCallback';
import { useHandleDragAndDropCallback } from 'reactApp/ui/Datalist/UserActions/handleDragAndDropCallback';
import { useHandleMouseCallback } from 'reactApp/ui/Datalist/UserActions/handleMouseCallback';
import { hotkeysHandlers } from 'reactApp/ui/Datalist/UserActions/hotkeys';
import { onScroll } from 'reactApp/ui/Datalist/UserActions/onScrollAction';
import { DataListDragItem } from 'reactApp/ui/DataListDragItem/DataListDragItem';
import { getThumbUrl } from 'reactApp/ui/DataListItem/DataListItem.helpers';
import { ID_NEW_ITEM } from 'reactApp/ui/DataListItemCreateNew/DataListItemCreateNew';
import { Empty } from 'reactApp/ui/Empty/Empty';
import { EmptyFolder } from 'reactApp/ui/EmptyFolder/EmptyFolder';
import { Loader } from 'reactApp/ui/Loader/Loader';
import { EmptySearch } from 'reactApp/ui/SearchMessage/EmptySearch/EmptySearch';
import { ErrorSearch } from 'reactApp/ui/SearchMessage/ErrorSearch/ErrorSearch';
import { VirtualList } from 'reactApp/ui/VirtualList/VirtualList';
import { VirtualList as VirtualListNew } from 'reactApp/ui/VirtualList/VirtualList.new';
import { IVirtualList } from 'reactApp/ui/VirtualList/VirtualList.types';
import { ESearchOptionType } from 'reactApp/ui/WebSearch/WebSearch.data';
import { generateUuidV4 } from 'reactApp/utils/helpers';
import { getPublicUrl } from 'reactApp/utils/urlHelper';

import { BreadcrumbsContainer } from '../BreadcrumbsContainer/BreadcrumbsContainer';
import { ErrorRetry } from '../ErrorRetry/ErrorRetry';
import { HOT_KEY_SCOPE_NAME, ROW_HEIGHT } from './DataList.constants';
import styles from './DataList.css';
import { getShowPromoTip } from './DataList.helpers';
import {
    useAutoSwitchFromGallery,
    useGaSelectSend,
    useGaShowGallery,
    useHandleMouse,
    useHandlerOnDocumentClick,
    useHandleStopDrag,
    useLoadToItem,
    useLogHandler,
    useScrollToItem,
    useSendDwh,
    useSendGaStat,
    useSetActiveIndex,
    useSetInitialViewerId,
} from './DataList.hooks';
import { Props } from './DataList.types';
import { DataListItemPlug } from './DataListItemPlug/DataListItemPlug';
import { ExtraBlocks } from './ExtraBlocks/ExtraBlocks';
import { UploadProvocation } from './UploadProvocation/UploadProvocation';

export const DATA_LIST_BREADCRUMBS_ID = generateUuidV4();
export const DATA_LIST_EXTRABLOCKS_ID = generateUuidV4();
export const DATA_LIST_STORIES_WIDGET_ID = generateUuidV4();
export const SAFE_FAKEDOOR_ID = generateUuidV4();

export const DataList = memo(
    // eslint-disable-next-line max-lines-per-function
    ({
        storage,
        viewMode: currentViewMode,
        disableCenteredLoader = false,
        limitItemsNumber = 0,
        disableTips = false,
        gaSuffix = '',
        initialItemId = '',
        disableLoadOnScroll = false,
        isPopup = false,
        itemsList,
        customScrollElement,
        onItemSelectCallback,
        withinDialog,
        isFastPage,
        storiesWidget,
        albumShowSelected = false,
        isLastFiles,
        selectableClassName,
    }: Props): ReactElement => {
        const startTime = Date.now();
        const dragParams = useRef<{
            dragItem: null | CloudItem;
            startX: number;
            startY: number;
            isDragging: boolean;
        }>({
            dragItem: null,
            startX: 0,
            startY: 0,
            isDragging: false,
        });
        const dispatch = useDispatch();
        const isDragging = useSelector(SelectionsSelectors.isDragging);
        const [dragPosX, setDragPosX] = useState(0);
        const [dragPosY, setDragPosY] = useState(0);
        const [tipShown, setTipShown] = useState(false);
        const [dragOverItem, setDragOverItem] = useState<CloudItem | null>(null);
        const [selectingIdxs, setSelectingIdxs] = useState<string[]>([]);
        const [initialViewerId, setInitialViewerId] = useState<string>(initialItemId);
        const prevSelectHandler = useRef(null);
        const registerItem = useRef<null | any>(null);
        const unregisterItem = useRef<null | any>(null);
        const listRef = useRef<IVirtualList | null>(null);
        const itemCreateNewRef = useRef<HTMLDivElement | null>(null);
        const routerParams = useSelector(getParams);
        const currentAllDocsType = useSelector(getAllDocumentsCurrentType);
        const currentStorage = useSelector(getCurrentStorage) as EStorageType;
        const domainFoldersFilterActive = useSelector(getDomainFoldersFilterActive);
        const bizCurrentCategory = useSelector(getBizCurrentCategory);
        const emptyBizCategory = !bizCurrentCategory || bizCurrentCategory === BizCategoryType.all;
        const hideCreateUploadInRoFolders = useSelector(getFeatureHideCreateUploadInRoFolders);
        const featureFormatsData = useSelector(getFeatureEditingNonEditableFormatsData);

        storage = storage || currentStorage;
        const {
            isHome,
            isPublic,
            isSharedLinks,
            isSearch,
            isAttaches,
            isSharedAutodelete,
            isAlbums,
            isQuotaCleaner,
            isAllDocuments,
            isFeed,
            isGallery,
            isStock,
        } = getStorage(storage);

        const folder = useSelector((state: RootState) => getStorageCurrentFolder(state, storage as EStorageType));

        const isReadOnlyFolder = useSelector((state: RootState) => isReadOnly(state, folder?.id));
        const hideActionInReadOnlyFolder = IS_BIZ_USER && isReadOnlyFolder && hideCreateUploadInRoFolders;

        const attachesParams = getAttachesParams({ params: useSelector(SettingsSelectors.getQueryParams) });
        let items = useSelector((state: RootState) => groupedIds(state, storage as EStorageType, isLastFiles)) as string[];

        const storeStories = useSelector(getStorySummaryCovers);
        const userHasStories = storeStories?.length > 0;
        const isShowStoryEnabled = useSelector(getFeatureShowStories);
        const item = useSelector(getCurrentFolderHome);
        const { id, isFolder } = item || {};
        const isRoot = isFolder && id === ROOT_FOLDER_ID;
        const showWidgets = isRoot && isShowStoryEnabled && isHome;

        const { type: searchRequestType, query: searchRequestQuery } = useSelector(getSearchRequestParams);

        if (itemsList?.length) {
            items = itemsList;
        }

        const showNewItemInAllDocs =
            isAllDocuments &&
            currentAllDocsType &&
            [EAllDocumentsType.document, EAllDocumentsType.spreadsheet].includes(currentAllDocsType);

        const hasExtraBlocksItem = !isQuotaCleaner && !isPopup && isNewPortalHeader.client;
        const hasBreadCrumbsItem = (isHome || isPublic || isStock) && isNewPortalHeader.client;
        const hasStoriesWidgetItem = showWidgets && userHasStories && isHome && isNewPortalHeader.client;

        const hasAddedItem = chooseVariantOld(getFeatureNewEmptyStates, {
            control: Boolean(
                ((isHome || showNewItemInAllDocs) &&
                    !domainFoldersFilterActive &&
                    (folder?.childs?.length || folder?.id === ROOT_FOLDER_ID)) ||
                    isSharedLinks ||
                    isSharedAutodelete
            ),
            /* tempexp_14812-next-line */
            variant1: Boolean(
                ((isHome || showNewItemInAllDocs) &&
                    !domainFoldersFilterActive &&
                    (folder?.childs?.length || folder?.id === ROOT_FOLDER_ID)) ||
                    isSharedAutodelete
            ),
        })();

        const storageViewMode = useSelector((state) =>
            SettingsSelectors.getViewByStorage(state, storage, isMediaFolderAndGalleryEnabled(state, folder, storage))
        );
        const viewMode = currentViewMode || storageViewMode;

        if (limitItemsNumber > 0) {
            items = items.slice(0, limitItemsNumber);
        }

        if (hasAddedItem && !hideActionInReadOnlyFolder && viewMode !== EViewMode.gallery) {
            if (
                chooseVariant(
                    abSafeFakeDoorSelector,
                    {
                        control: false,
                        variant1: storage === EStorageType.home && isRoot,
                    },
                    {
                        skipCondition: {
                            variant2: true,
                        },
                    }
                )
            ) {
                items = [ID_NEW_ITEM, SAFE_FAKEDOOR_ID, ...items];
            } else {
                items = [ID_NEW_ITEM, ...items];
            }
        }

        const isMounted = useSelector((state: RootState) => isThisOrParentMounted(state, folder?.id));

        const selectedIdxs = useSelector(SelectionsSelectors.getSelectedIdxs);
        const lastSelectedId = useSelector(SelectionsSelectors.getLastSelected);
        const selectedRange = useSelector(SelectionsSelectors.getSelectedRange);
        const selectedCount = selectedIdxs.length;
        const selectedItems = useSelector((state: RootState) =>
            selectedIdxs.map((id) => getStorageItemById(state, storage as EStorageType, id)).filter((item) => !!item)
        );
        const filesInProgress = useSelector(selectFilesInProgress);

        const folderId = folder?.id || '';
        const isLoading = folder?.isLoading;
        const isLoaded = folder?.isLoaded;
        const error = path(['error'], folder);
        const hasMoreToLoad = useSelector((state: RootState) => hasLoadMore(state, storage, folder));

        const isFavoritesEnabled = (isHome || isSearch || isAlbums) && !isMounted;
        const draggingPortalTarget = usePortal('draggingElement');
        const isSearchActive = isSearch || (isAttaches && attachesParams?.query);
        const isModalOpen: boolean = useSelector(isDialogVisible);
        const isPublicUpload = useSelector(isPublicUploadEnabled);
        const isPublicDownloaded = useSelector(isPublicDownloadableEnabled);
        const galleryCategory = useSelector(getGalleryCategory);
        const feedCategory = useSelector(getFeedCategory);
        const cloudItems = useSelector((state: RootState) =>
            items.map((id) => getStorageItemById(state, storage as EStorageType, id)).filter((item) => !!item)
        ) as CloudItem[];
        const listOfBannedToFavoriteItems = useSelector((state: RootState) => getParentMountedOrSharedItems(state, cloudItems));

        const secondClickPromo = useSelector(PromoSelectors.pullPromo(EPromoType.secondClick));
        const epubPromo = useSelector(PromoSelectors.pullPromo(EPromoType.epubPromo));
        const isDownloadEbookClick = useSelector(getDownloadEbookClick);

        const promo = isDownloadEbookClick && epubPromo?.tipType?.includes(EpubPromoType.downloadList) ? epubPromo : secondClickPromo;

        const tipData = getShowPromoTip({
            selectedCount,
            isDragging,
            disableTips,
            promo,
            selectedItems,
        });

        useEffect(() => {
            if (promo && tipData && !tipShown) {
                promo.onShow();
                setTipShown(true);
            }
            if (promo && !tipData && tipShown && 'onHide' in promo) {
                promo.onHide();
            }
            // Если после показа тултипа
            // сняли выделение с элемента или кликнули по нему, то считаем, что его закрыли (без клика по крестику)
            if (tipShown && !tipData && promo) {
                setTipShown(false);
                promo.onClose();
            }
        }, [promo, tipData, tipShown]);

        const { handleStartDrag, handleStopDrag, handleDraggingOver } = useHandleDragAndDropCallback({
            dragParams,
            selectedIdxs,
            dispatch,
            storage,
            setDragOverItem,
            setDragPosX,
            setDragPosY,
            prevSelectHandler,
            dragOverItem,
            selectedItems,
            isDragging,
        });

        const { handleMouseMove, handleMouseUp, handleMouseDownOnItem, handleMouseLeave } = useHandleMouseCallback({
            dragParams,
            storage,
            setDragOverItem,
            setDragPosX,
            setDragPosY,
            isDragging,
            handleStartDrag,
        });

        const loadOnScroll = useCallback(() => {
            onScroll({
                storage,
                items: items.filter(
                    (o) =>
                        ![
                            ID_NEW_ITEM,
                            SAFE_FAKEDOOR_ID,
                            DATA_LIST_BREADCRUMBS_ID,
                            DATA_LIST_EXTRABLOCKS_ID,
                            DATA_LIST_STORIES_WIDGET_ID,
                        ].includes(o)
                ),
                folderId,
                hasMoreToLoad,
                dispatch,
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [disableLoadOnScroll, folderId, items.length, dispatch, hasAddedItem, hasMoreToLoad]);

        const [activeIndex, isElementLoaded, setActiveIndex] = useLoadToItem(items, loadOnScroll, isLoading);

        const selectedItem = useSelector(getSelectedItem);

        const { type: attachType, folderId: attachFolderId } = useSelector(SettingsSelectors.getQueryParams);

        useScrollToItem({
            activeIndex,
            dispatch,
            listRef,
            isElementLoaded: isElementLoaded && !isLoading && isLoaded,
        });

        useSetActiveIndex({ selectedItem, items, setActiveIndex, storage });

        useSetInitialViewerId({
            isLoaded,
            loadOnScroll,
            initialViewerId,
            setInitialViewerId,
            items,
            storage,
            dispatch,
        });

        useHandleStopDrag({ isDragging, handleStopDrag, selectedItems });

        useHandleMouse({ handleMouseMove, handleMouseUp });

        useAutoSwitchFromGallery({ viewMode, folder, storage });

        const {
            handleOnDocumentClick,
            handleOnSelectGroupClick,
            handleBeginSelection,
            handleOnContextMenu,
            handleOnContextMenuItem,
            handleOnSelect,
            handleSelectboxSelection,
            handleSelecting,
            handleOnClick,
            handleClickCreateNew,
        } = useHandleCallback({
            isModalOpen,
            setActiveIndex,
            dispatch,
            selectedItem,
            prevSelectHandler,
            storage,
            gaSuffix,
            items,
            selectedIdxs,
            selectingIdxs,
            setSelectingIdxs,
            routerParams,
            itemCreateNewRef,
            viewMode,
            isPopup,
            onItemSelectCallback,
            attachType,
            attachFolderId,
            currentAllDocsType,
            featureFormatsData,
        });

        useHandlerOnDocumentClick({ handleOnDocumentClick });

        useLogHandler({ items, viewMode, startTime, folder });

        useGaSelectSend({ selectedCount });

        useGaShowGallery({ viewMode });

        useSendGaStat({ folder });

        useHotKeyScope(HOT_KEY_SCOPE_NAME);

        const { onItemsRendered } = useSendDwh({
            storage,
            isSearchActive,
            isLoaded,
            isError: Boolean(error),
            items,
            isPopup,
        });

        useEffect(
            (): (() => void) | undefined =>
                hotkeysHandlers({
                    folder,
                    selectedIdxs,
                    storage,
                    dispatch,
                    items,
                    handleOnClick,
                    viewMode,
                    isPublicUpload,
                    selectedItems,
                    hasAddedItem,
                    lastSelectedId,
                    listRef,
                    selectedRange,
                    filesInProgress,
                }),
            [
                items,
                selectedItems,
                storage,
                isPublicUpload,
                lastSelectedId,
                selectedRange,
                selectedIdxs,
                hasAddedItem,
                filesInProgress,
                folder,
                dispatch,
                handleOnClick,
                viewMode,
            ]
        );

        const renderItem = (
            id: string,
            options: { index: number; thumbType?: string },
            { onItemSizeChange }: { onItemSizeChange?: (size: number) => void } = {}
        ): ReactElement => (
            <WrapDataListItem
                id={id}
                optionIndex={options.index}
                view={viewMode}
                handleClickCreateNew={handleClickCreateNew}
                isSharedLinks={isSharedLinks}
                selectedIdxs={selectedIdxs}
                selectingIdxs={selectingIdxs}
                activeIndex={activeIndex}
                isDragging={isDragging}
                dragOverItemId={dragOverItem?.id}
                onSelect={handleOnSelect}
                onClick={handleOnClick}
                onContextMenu={handleOnContextMenuItem}
                selectedCount={selectedCount}
                storage={storage || EStorageType.home}
                tipData={selectedIdxs[0] === id ? tipData : null}
                isFavoritesEnabled={isFavoritesEnabled}
                listOfBannedToFavoriteItems={listOfBannedToFavoriteItems}
                onDraggingOver={handleDraggingOver}
                onMouseLeave={handleMouseLeave}
                register={registerItem.current}
                unregister={unregisterItem.current}
                onMouseDown={handleMouseDownOnItem}
                rootRef={itemCreateNewRef}
                thumbType={options.thumbType}
                isPopup={isPopup}
                isPublicDownloaded={isPublicDownloaded}
                currentAllDocsType={currentAllDocsType}
                albumShowSelected={albumShowSelected}
                itemsAmount={items.length}
                isSharedAutodelete={isSharedAutodelete}
                onItemSizeChange={onItemSizeChange}
            />
        );

        const categoryTypeForEmpty = useMemo(() => {
            if (isFeed) {
                return feedCategory;
            }

            if (isGallery) {
                return galleryCategory;
            }

            return attachType;
        }, [attachType, feedCategory, galleryCategory, isFeed, isGallery]);

        if (error) {
            if (isSearchActive && isSearch) {
                return <ErrorSearch data-qa-datalist={storage} isPopup={isPopup} />;
            }

            return (
                <div className={styles.root} data-qa-datalist={storage}>
                    <ErrorRetry centered={!isPublic && !disableCenteredLoader} isPopup={isPopup} />
                </div>
            );
        }

        if (((isNil(isLoading) || isLoading) && !isLoaded && !items.length) || !isElementLoaded) {
            if (isQuotaCleaner) {
                return <DataListItemPlug />;
            }

            return (
                <div className={styles.root} data-qa-datalist={storage}>
                    <Loader centered={!isPublic && !disableCenteredLoader} />
                </div>
            );
        }

        if (!items?.length || (items.length === 1 && items[isNewPortalHeader.client ? 1 : 0] === ID_NEW_ITEM && bizCurrentCategory)) {
            const searchType = isPopup && searchRequestType ? searchRequestType : routerParams?.searchType;
            const searchQuery = isPopup && searchRequestQuery ? searchRequestQuery : routerParams?.query || attachesParams?.query;

            if (
                !isPublic &&
                !isQuotaCleaner &&
                folder?.id &&
                // Не выводим для корня и разделов, только для папки
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                folder.parent &&
                !isReadOnlyFolder &&
                emptyBizCategory
            ) {
                return (
                    <div onContextMenu={handleOnContextMenu} className={styles.root} data-qa-datalist={storage}>
                        {isNewPortalHeader.client && storage === EStorageType.home && (
                            <BreadcrumbsContainer className={styles.emptyStateBreadcrumbs_new_portal_header} />
                        )}
                        <EmptyFolder folder={folder} />
                    </div>
                );
            }

            if (isQuotaCleaner) {
                return <QuotaCleanerPlug />;
            }

            if (isSearchActive) {
                return (
                    <EmptySearch
                        data-qa-datalist={storage}
                        onContextMenu={handleOnContextMenu}
                        searchType={searchType as ESearchOptionType}
                        searchText={searchQuery}
                    />
                );
            }

            if (isGallery && isPopup) {
                return <SelectFromCloudEmpty />;
            }

            const showUploaderpromoForAllDocs =
                isAllDocuments && (currentAllDocsType === EAllDocumentsType.presentation || currentAllDocsType === EAllDocumentsType.pdf);

            return (
                <div
                    onContextMenu={handleOnContextMenu}
                    /* tempexp_14812-next-line */
                    className={chooseVariantOld(getFeatureNewEmptyStates, {
                        control: styles.root,
                        variant1: classNames(styles.root, styles.emptyRoot),
                    })()}
                    data-qa-datalist={storage}
                >
                    {isNewPortalHeader.client && isPublic && (
                        <BreadcrumbsContainer className={styles.emptyStateBreadcrumbs_new_portal_header} />
                    )}
                    {/* tempexp_14812-next-line */}
                    <FeatureSelector
                        selector={(state) =>
                            getFeatureNewEmptyStates(state) &&
                            !!storage &&
                            [
                                EStorageType.sharedLinks,
                                EStorageType.sharedIncoming,
                                EStorageType.feed,
                                EStorageType.favorites,
                                EStorageType.gallery,
                                EStorageType.albums,
                                EStorageType.attaches,
                                EStorageType.trashbin,
                                EStorageType.sharedAutodelete,
                            ].includes(storage)
                        }
                        control={
                            <Empty
                                searchType={galleryCategory || null}
                                type={storage === EStorageType.gallery ? 'gallery' : 'folder'}
                                storage={storage}
                                canUpload={isHome && !isReadOnlyFolder && !domainFoldersFilterActive}
                                weblink={getPublicUrl(folder)}
                                id={folder?.id}
                                canCoshare
                                currentAllDocsType={currentAllDocsType}
                                isNewTrashbin={currentStorage === EStorageType.alldocuments && storage === EStorageType.trashbin}
                            />
                        }
                        variant1={
                            <>
                                <Empty.New storage={storage} categoryType={categoryTypeForEmpty} />
                                {(isFeed || (isGallery && !isPopup)) && <UploadProvocation maxHeight={152} />}
                            </>
                        }
                    />
                    {showUploaderpromoForAllDocs && <ExtraBlocks itemsCount={items.length} />}
                </div>
            );
        }

        if (hasExtraBlocksItem) {
            items = [...items, DATA_LIST_EXTRABLOCKS_ID];
        }

        if (hasBreadCrumbsItem) {
            items = [DATA_LIST_BREADCRUMBS_ID, ...items];
        }

        if (hasStoriesWidgetItem) {
            items = [DATA_LIST_STORIES_WIDGET_ID, ...items];
        }

        return (
            <>
                <SelectableGroup
                    tolerance={20}
                    onSelection={handleSelecting}
                    onBeginSelection={handleBeginSelection}
                    preventDefault={false}
                    onClick={handleOnSelectGroupClick}
                    onEndSelection={handleSelectboxSelection}
                    selectboxClassName={styles.selectbox}
                    disableClickOnItem
                    className={classNames(styles.selectable, selectableClassName, {
                        [styles.selectable_withinDialog]: Boolean(withinDialog),
                        [styles.selectable_fastpage]: Boolean(isFastPage),
                        [styles.selectable_stories]: Boolean(storiesWidget),
                        [styles.selectable_new_portal_header]: isNewPortalHeader.client,
                    })}
                >
                    {({ register, unregister }) => {
                        registerItem.current = register;
                        unregisterItem.current = unregister;

                        return (
                            <div
                                className={classNames(styles.root, {
                                    [styles.root_new_portal_header]: isNewPortalHeader.client,
                                })}
                                onContextMenu={handleOnContextMenu}
                                onMouseLeave={handleMouseLeave}
                                data-qa-datalist={storage}
                                data-qa-view={viewMode}
                            >
                                {isNewPortalHeader.client ? (
                                    <VirtualListNew
                                        hasMoreToLoad={disableLoadOnScroll ? false : hasMoreToLoad}
                                        list={items}
                                        viewMode={viewMode}
                                        renderItem={renderItem}
                                        rowHeight={ROW_HEIGHT}
                                        isLoading={isLoading}
                                        loadOnScroll={loadOnScroll}
                                        onItemsRendered={onItemsRendered}
                                        ref={listRef}
                                        customScrollElement={customScrollElement}
                                        disableScroll={storiesWidget}
                                    />
                                ) : (
                                    <VirtualList
                                        hasMoreToLoad={disableLoadOnScroll ? false : hasMoreToLoad}
                                        list={items}
                                        viewMode={viewMode}
                                        renderItem={renderItem}
                                        rowHeight={ROW_HEIGHT}
                                        isLoading={isLoading}
                                        loadOnScroll={loadOnScroll}
                                        onItemsRendered={onItemsRendered}
                                        ref={listRef}
                                        customScrollElement={customScrollElement}
                                    />
                                )}

                                {isDragging &&
                                    selectedCount > 0 &&
                                    selectedItems[0] &&
                                    !!dragPosX &&
                                    !!dragPosY &&
                                    createPortal(
                                        <DataListDragItem
                                            dragPosX={dragPosX}
                                            dragPosY={dragPosY}
                                            selectedCount={selectedCount}
                                            item={selectedItems[0]}
                                            thumb={getThumbUrl(selectedItems[0], isVirusItem(selectedItems[0]))}
                                        />,
                                        draggingPortalTarget
                                    )}
                            </div>
                        );
                    }}
                </SelectableGroup>
                {!isQuotaCleaner && !isPopup && !isNewPortalHeader.client && <ExtraBlocks itemsCount={items.length} />}
            </>
        );
    }
);

DataList.displayName = 'DataList';

DataList.whyDidYouRender = true;
