import classNames from 'clsx';
import React, { FC } from 'react';
import { Tip } from 'reactApp/ui/Tip/Tip';

import { BasicProps } from '../../DataListItemRow.types';
import styles from './ItemTip.css';

interface ItemTipProps {
    className?: string;
    isFolder: boolean;
    tipData: BasicProps['tipData'];
}

export const ItemTip: FC<ItemTipProps> = ({ className, isFolder, tipData }) => {
    return !tipData ? null : (
        <div className={classNames(styles.root, className)}>
            <Tip
                hideTip={tipData[0].onHideClick}
                onShow={tipData[0].onShow}
                position="top center"
                text={tipData[0].getTipContent?.(isFolder)}
                tips={tipData}
                id={tipData[0].id}
                show
                showBubble={false}
                className={styles.pinTooltip}
            />
        </div>
    );
};
