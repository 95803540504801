import { Icon24PenOutline } from '@vkontakte/icons';
import { Switch } from '@vkontakte/vkui';
import config from 'Cloud/config';
import classNames from 'clsx';
import { xray } from 'lib/xray';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { renderBizPaidEditorDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog.helpers';
import { openCreateCopyOfNoneditableFileModal } from 'reactApp/components/CreateCopyOfNoneditableFileModal/helper';
import { QA_VALUE } from 'reactApp/components/SharingWindow/Sharing.constants';
import { sendGa } from 'reactApp/components/SharingWindow/Sharing.helpers';
import { AccessStatus } from 'reactApp/components/SharingWindow/Sharing.types';
import styles from 'reactApp/components/SharingWindow/Weblink/SharingWeblink.css';
import { getEditorsFor, getIsFileForCoEditing } from 'reactApp/modules/editor/editor.selectors';
import { EditorItem } from 'reactApp/modules/editor/editor.types';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getFeatureEditingNoneditableFormats } from 'reactApp/modules/features/features.selectors';
import { HomeFile } from 'reactApp/modules/home/home.types';
import { toggleWeblinkEditableRequest, toggleWeblinkUploadRequest } from 'reactApp/modules/modifying/modifying.actions';
import { PublishItem } from 'reactApp/modules/modifying/modifying.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { snackbarController } from 'reactApp/modules/snackbar/snackbar.controller';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { getItemById, isMountedOrSharedFolder } from 'reactApp/modules/storage/storage.selectors';
import { RootState } from 'reactApp/store';
import { AccessRights } from 'reactApp/types/Tree';
import { Hint } from 'reactApp/ui/Hint/Hint';
import { noop } from 'reactApp/utils/helpers';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

const IS_FREE_B2B_BIZ_EDITORS_USER = config.get('FREE_B2B_BIZ_EDITORS_USER');

const CO_EDIT_TEXT = {
    switchOnTitle: 'Совместное редактирование включено',
    switchOnHint: 'Включить',
    switchOnSnackbar: 'Доступ на редактирование открыт',
    switchOffTitle: 'Совместное редактирование выключено',
    switchOffHint: 'Выключить',
    switchOffSnackbar: 'Доступ на редактирование закрыт',
} as const;

const getAccessRights = (isReadOnly: boolean | undefined, isPublicReadWrite: boolean | AccessStatus): boolean | AccessStatus => {
    return (isPublicReadWrite && !isReadOnly) || AccessStatus.ReadOnly;
};

/** CLOUDWEB-15038 Хук предназначен для изменения прав доступа к файлу, с просмотра на редактирование*/
export const useCoEditCondition = (isSharedOrMouted: ReturnType<typeof isMountedOrSharedFolder>, item: PublishItem, onClose) => {
    const dispatch = useDispatch();

    const isPhone = EnvironmentSelectors.isPhone();
    const isPublicReadWrite = item?.weblinkAccessRights === AccessRights.rw;
    const isPublic = Boolean(item?.weblink);
    const isEditable = Boolean(useSelector((state: RootState) => getEditorsFor(state, item))?.length);

    const isFileForCoEditing = useSelector((state: RootState) => getIsFileForCoEditing(state, item));

    const storage = useSelector(getCurrentStorage);
    const editingNoneEditableFormats = useSelector((state: RootState) =>
        getFeatureEditingNoneditableFormats(state, { file: item as HomeFile, storage })
    );
    const convertExt = editingNoneEditableFormats?.ext || '';
    const convertedStorage = editingNoneEditableFormats?.storage || storage;
    const isConvertFileForCoEditing = useSelector((state: RootState) => getIsFileForCoEditing(state, { ...item, ext: convertExt }));
    const isDocumentForEdit =
        (item?.kind === 'document' && IS_FREE_B2B_BIZ_EDITORS_USER) || isFileForCoEditing || isConvertFileForCoEditing;
    const isReadOnlyItem = item?.isReadOnly;

    const parent = useSelector((state: RootState) => getItemById(state, item?.parent || ''));
    const isReadOnly = !isReadOnlyItem && isSharedOrMouted.isMounted ? (parent as EditorItem)?.isReadOnly : isReadOnlyItem;

    const accessRights = getAccessRights(isReadOnly, isPublicReadWrite);

    const [isCoEditOpened, setIsCoEditOpened] = useState<boolean>(isPublicReadWrite);

    useEffect(() => {
        const itmeAccessRights = item?.weblinkAccessRights === AccessRights.rw;
        if (itmeAccessRights !== isCoEditOpened) {
            setIsCoEditOpened(itmeAccessRights);
        }
    }, [item]);

    const handleSnackbar = useCallback(() => {
        snackbarController.showSnackbar({
            id: 'coedit-switch',
            closable: true,
            text: isCoEditOpened ? CO_EDIT_TEXT.switchOffSnackbar : CO_EDIT_TEXT.switchOnSnackbar,
            type: SnackbarTypes.success,
        });
    }, [isCoEditOpened]);

    const handleAccessRightsChange = useCallback((): void => {
        if (!isPublic) {
            return;
        }

        if (isConvertFileForCoEditing) {
            onClose();
            openCreateCopyOfNoneditableFileModal({
                file: item as HomeFile,
                editingNoneEditableFormats: { storage: convertedStorage, ext: convertExt },
                fromSharePopup: true,
            });
            return;
        }

        setIsCoEditOpened(!isCoEditOpened);

        handleSnackbar();

        xray.send('sharing-modified_access-rights');
        sendGa(isCoEditOpened ? 'ta-access-rights' : 'access-rights');
        sendPaymentGa({
            eventCategory: ECategoryGa.public,
            action: 'edit-type-access',
            public_id: item?.weblink,
        });

        if (isDocumentForEdit && isCoEditOpened) {
            if (IS_FREE_B2B_BIZ_EDITORS_USER && !isEditable && isReadOnly) {
                renderBizPaidEditorDialog();
                return;
            }
            dispatch(toggleWeblinkEditableRequest({ item }));
            return;
        }
        dispatch(toggleWeblinkUploadRequest({ item }));
    }, [isCoEditOpened, isDocumentForEdit, accessRights, dispatch, isEditable, isPublic, isReadOnly, item, isConvertFileForCoEditing]);

    const renderAccessRights = useCallback((): ReactElement => {
        return (
            <div className={classNames(styles.rightItem, styles.rightItemModified)} data-qa-item={QA_VALUE.accessItem}>
                <Icon24PenOutline className={styles.leftIcon} />
                <span className={styles.rightTitle}>{isCoEditOpened ? CO_EDIT_TEXT.switchOnTitle : CO_EDIT_TEXT.switchOffTitle}</span>
                <Hint text={isCoEditOpened ? CO_EDIT_TEXT.switchOffHint : CO_EDIT_TEXT.switchOnHint} isPopupView={true}>
                    <div className={styles.rightAction}>
                        <Switch name="Имя" data-qa-id="coEditSwitch" checked={isCoEditOpened} onChange={handleAccessRightsChange} />
                    </div>
                </Hint>
            </div>
        );
    }, [accessRights, handleAccessRightsChange, isFileForCoEditing, isPhone]);

    return !isDocumentForEdit ? noop : renderAccessRights;
};
