import classNames from 'clsx';
import { ReactComponent as CloneIcon } from 'img/icons/add2Cloud.svg';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { IS_MY_TEAM, IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import { getIsViewerFileVerifiedByKaspersky } from 'reactApp/modules/file/utils';
import { Button } from 'reactApp/ui/Button/Button';
import { ProtectedTooltip } from 'reactApp/ui/Mobile/ProtectedTooltip/ProtectedTooltip';
import { NameComponent } from 'reactApp/ui/Name/Name';
import { tooltipPlacements } from 'reactApp/ui/Tooltip/Tooltip.constants';
import { ViewerShareButton } from 'reactApp/ui/ViewerShareButton/ViewerShareButton';
import { DownloadAppButton } from 'reactApp/ui/ViewerToolbar/DownloadAppButton';
import styles from 'reactApp/ui/ViewerToolbar/ViewerToolbar.css';
import type { IFile } from 'reactApp/ui/ViewerToolbar/ViewerToolbar.types';
import { DownloadIcon } from 'reactApp/ui/VKUIIcons';

interface IProps {
    file: IFile;
    isPreviewableItem?: boolean;
    canClone: boolean;
    socialList: any[];
    date: string;
    expires: string;
    handleOnClone: () => void;
    handleOnDownload: () => void;
    handleOnSocial: (id: string) => void;
}

export const ViewerToolbarMobile: FC<IProps> = ({
    file,
    canClone,
    date,
    expires,
    handleOnClone,
    handleOnDownload,
    handleOnSocial,
    socialList,
    isPreviewableItem,
}) => {
    const isViewerFileVerifiedByKaspersky = useSelector(getIsViewerFileVerifiedByKaspersky);

    return (
        <div className={classNames(styles.root, styles.root_mobile)}>
            <div className={styles.content}>
                <div className={styles.info}>
                    <div className={styles.name}>
                        <NameComponent name={file.name} extension={file.extension} />
                        {isViewerFileVerifiedByKaspersky && <ProtectedTooltip />}
                    </div>
                    <div className={styles.meta}>
                        {file.size}
                        {date ? `, ${date}` : ''}
                    </div>
                </div>
                <div className={styles.download}>
                    {canClone && (
                        <Button onClick={handleOnClone} color="black" size="big" data-name="clone">
                            <CloneIcon width={24} height={24} viewBox="12 12 24 24" />
                        </Button>
                    )}
                    <Button onClick={handleOnDownload} color="black" size="big" data-name="download">
                        <DownloadIcon />
                    </Button>
                </div>
            </div>
            {expires && <div className={styles.expires}>Доступ по ссылке открыт до {expires}</div>}
            <div className={styles.actions}>
                {IS_WEBVIEW ? (
                    <ViewerShareButton onSocial={handleOnSocial} socialList={socialList} placement={tooltipPlacements.TOP_LEFT} />
                ) : (
                    !IS_MY_TEAM && (
                        <div className={styles.downloadAppBttnWrapper}>
                            <DownloadAppButton file={file} isPreviewableItem={isPreviewableItem} />
                        </div>
                    )
                )}
            </div>
        </div>
    );
};
