import classNames from 'clsx';
import format from 'date-fns/format';
import { ReactComponent as LogoCloudColor24 } from 'img/icons/logo_cloud_color_24.svg';
import React, { memo, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { APP_TITLE, APP_WHITE_LOGO, IS_B2B_BIZ_USER, IS_MY_TEAM } from 'reactApp/appHelpers/configHelpers';
import { getIsViewerFileVerifiedByKaspersky } from 'reactApp/modules/file/utils';
import { Hint } from 'reactApp/ui/Hint/Hint';
import { NameComponent } from 'reactApp/ui/Name/Name';
import { ProtectedHint } from 'reactApp/ui/ProtectedHint/ProtectedHint';
import opener from 'reactApp/utils/opener';

import styles from './AttachInfo.css';

interface Props {
    name: string;
    author: string;
    fileName: string;
    fileExt: string;
    size: string;
    timestamp: number;
    isTemporary: boolean;
    onClick();
}

const handleCloudLogoClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();

    opener('/home', true);
};

export const AttachInfo = memo(({ name, author, timestamp, onClick, fileName, fileExt, size, isTemporary }: Props): ReactElement | null => {
    const fileTitle = `${fileName}.${fileExt}`;
    const hintText = IS_B2B_BIZ_USER ? APP_TITLE : 'Облако Mail.ru';

    const isViewerFileVerifiedByKaspersky = useSelector(getIsViewerFileVerifiedByKaspersky);

    return (
        <div
            className={classNames(styles.root, { [styles.rootClickable]: !isTemporary && !IS_MY_TEAM })}
            onClick={IS_MY_TEAM ? undefined : onClick}
        >
            <Hint theme="dark" text={hintText}>
                {IS_B2B_BIZ_USER ? (
                    <img className={styles.logoB2B} src={APP_WHITE_LOGO} />
                ) : (
                    <div className={styles.iconWrapper} onClick={handleCloudLogoClick}>
                        <LogoCloudColor24 className={styles.logo} />
                    </div>
                )}
            </Hint>
            <div className={styles.divider} />
            <div className={styles.attachBlock}>
                <div className={styles.attachTopBlock}>
                    <div className={styles.author}>{Boolean(author) && author}</div>
                    <div className={styles.attachTime}>{Boolean(timestamp) && format(timestamp, 'dd.MM.yy, HH:mm')}</div>
                </div>
                <div className={styles.attachBottomBlock}>
                    {name && (
                        <Hint text={name} theme="dark" showDelay>
                            <div className={styles.attachName}>{name}</div>
                        </Hint>
                    )}
                    {fileTitle && (
                        <Hint text={fileTitle} theme="dark" showDelay>
                            <div className={classNames({ [styles.attachItemPrefix]: name })}>
                                <NameComponent name={fileName} extension={fileExt} truncate />
                            </div>
                        </Hint>
                    )}
                    {size && (
                        <div data-qa-id="attach-info-size" className={classNames({ [styles.attachItemPrefix]: fileTitle })}>
                            {size}
                        </div>
                    )}
                    {isViewerFileVerifiedByKaspersky && <ProtectedHint text="Файл проверен антивирусом Касперского" />}
                </div>
            </div>
        </div>
    );
});

AttachInfo.displayName = 'AttachInfo';

AttachInfo.whyDidYouRender = true;
