import { bytesToNDigits } from '@mail/cross-sizes-utils';
import React, { useCallback, useEffect, useRef, useState, VFC } from 'react';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { downloadArchiveItemRequest, getArchiveItemPreviewRequest } from 'reactApp/modules/viewer/viewer.module';
import { IArchiveItem } from 'reactApp/modules/viewer/viewer.types';
import { animationClasses, SWIPER_SPEED } from 'reactApp/ui/Mobile/MobileViewer/MobileViewer.constants';
import { ItemViewerContent } from 'reactApp/ui/Mobile/MobileViewer/MobileViewerArchive/ItemViewerContent';
import { ViewerToolbar } from 'reactApp/ui/Mobile/MobileViewer/ViewerToolbar/ViewerToolbar';
import { OptionsModalArchive } from 'reactApp/ui/Mobile/OptionsModal/OptionsModalArchive';
import { SwipingDownComponent } from 'reactApp/ui/SwipingDownComponent/SwipingDownComponent';
import { Zoom } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import styles from './ItemViewer.css';

interface IItemViewerProps {
    item: IArchiveItem;
    archiveId: string;
    storage: EStorageType;

    sendAnalytics(data: any): void;

    onClose();
}

export const ItemViewer: VFC<IItemViewerProps> = ({ item, archiveId, storage, onClose, sendAnalytics }) => {
    const dispatch = useDispatch();
    const [showOptions, setShowOptions] = useState(false);
    const [showToolbar, setShowToolbar] = useState<boolean>(true);
    const timerId = useRef<number | undefined>(undefined);

    const size = (item.size && bytesToNDigits(item.size, 3).value) || '';

    const sendArchiveDwh = useCallback(
        (action, data?) => {
            sendAnalytics({
                action,
                extension: item.ext,
                is_archive: true,
                size_files: item.size,
                type_content: item.kind,
                ...(data ? data : {}),
            });
        },
        [sendAnalytics, item]
    );

    const handleDownload = useCallback(() => {
        dispatch(
            downloadArchiveItemRequest({
                storage,
                archiveId,
                itemId: item.path,
            })
        );
        sendArchiveDwh('download-content');
    }, [sendAnalytics, sendArchiveDwh]);

    const toggleToolbar = useCallback(() => {
        if (!timerId.current) {
            const id = window.setTimeout(() => {
                setShowToolbar((prevValue) => !prevValue);
                timerId.current = undefined;
            }, 500);
            timerId.current = id;
        }
    }, [timerId, setShowToolbar]);

    const handleOnDoubleClick = useCallback(() => {
        window.clearTimeout(timerId.current);
        timerId.current = undefined;
    }, [timerId]);

    useEffect(() => {
        sendArchiveDwh('open');
        sendArchiveDwh('open-content', {
            is_full_render: item.previewUrl !== null,
            have_face: false,
            is_edit: false,
        });
        return () => {
            sendArchiveDwh('close', {
                is_full_render: item.previewUrl !== null,
                have_face: false,
                is_edit: false,
            });
        };
    }, []);

    useEffect(() => {
        if (!item.previewUrl && item.previewUrl !== null && !item.isEncrypted) {
            dispatch(
                getArchiveItemPreviewRequest({
                    storage,
                    archiveId,
                    itemId: item.path,
                })
            );
        }
    }, [archiveId, dispatch, item.isEncrypted, item.path, item.previewUrl, storage]);

    return (
        <div className={styles.wrapper}>
            <SwipingDownComponent onClose={onClose}>
                <div className={styles.root}>
                    {/* Swiper тут используется только для зума. Можно было бы использовать зум верхнего свайпера, но мешается SwipingDownComponent,
                который нужен для закрытия просмотрщика в архиве свайпом вниз, и который не пропускает события зума выше.
                Можно было бы использовать самй верхний SwipingDownComponent в MobileViewer, но он закрывает весь контент, в том числе и даталист архива,
                а закрывать надо только текущую картинку.
                Можно потом использовать этот свайпер для навигации внутри архива  */}
                    <Swiper
                        onClick={toggleToolbar}
                        onDoubleClick={handleOnDoubleClick}
                        resizeObserver={false}
                        simulateTouch={false}
                        navigation={false}
                        pagination={false}
                        modules={[Zoom]}
                        zoom
                    >
                        <SwiperSlide zoom>
                            <ItemViewerContent item={item} />
                        </SwiperSlide>
                    </Swiper>
                    <CSSTransition in={showToolbar} timeout={SWIPER_SPEED} unmountOnExit exit enter classNames={animationClasses}>
                        <ViewerToolbar
                            onBack={onClose}
                            ext={item.ext}
                            nameWithoutExt={item?.nameWithoutExt || ''}
                            subTitle={size}
                            onClickOption={() => setShowOptions(true)}
                        />
                    </CSSTransition>
                    {showOptions && (
                        <OptionsModalArchive
                            item={item}
                            storage={storage}
                            handleDownload={handleDownload}
                            onClose={() => setShowOptions(false)}
                        />
                    )}
                </div>
            </SwipingDownComponent>
        </div>
    );
};
