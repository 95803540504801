import { useSelector } from 'react-redux';
import { ENABLE_FULL_RESPONSIVE } from 'reactApp/appHelpers/configHelpers';
import { attachesCloneLimit } from 'reactApp/appHelpers/featuresHelpers';
import { BREAKPOINT_SM } from 'reactApp/constants/breakpoints';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { useActionsConfig } from 'reactApp/hooks/useActionsConfig.new';
import { getCurrentAlbum } from 'reactApp/modules/albums/albums.selector';
import { getFilesWithSelectedFace } from 'reactApp/modules/faces/faces.selectors';
import { isPublicUploadEnabled } from 'reactApp/modules/public/public.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import {
    getCurrentTotalCount,
    getItemsTotalCount,
    getStorageCurrentFolder,
    getStorageSelectedItems,
    groupedIds,
    isFolderWithAuthor,
} from 'reactApp/modules/storage/storage.selectors';
import { CloudItem, EActions, EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { getIsLetterGroup } from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.selectors';
import { RootState } from 'reactApp/store';
import { ListItem } from 'reactApp/ui/DropdownList/DropdownList';
import {
    actionsOrder,
    actionsOrder_EbookReader,
    actionsOrder_PdfEdit,
    moreListOrder,
    TOOLBAR_CONFIG,
} from 'reactApp/ui/Toolbar/Toolbar.config.new';
import { getCloneItem, getDownloadItem, getSelectItem, toolbarDropdownItems, toolbarItems } from 'reactApp/ui/Toolbar/Toolbar.data.new';
import { ToolbarContextEnum, TToolbarItem } from 'reactApp/ui/Toolbar/Toolbar.types';
import { useWindowWidth } from 'reactApp/ui/VirtualList/VirtualList.hooks';

const getPriority = (item) => (typeof item == 'number' ? item : undefined);

const checkAttachesCloneLimit = (storage: EStorageType, selectedCount: number) =>
    storage !== EStorageType.attaches || !attachesCloneLimit || selectedCount <= attachesCloneLimit;

// eslint-disable-next-line max-lines-per-function, complexity
export const useActions = ({
    storage,
    toolbarType,
    downloadButtonRef,
    isEbookReader,
}: {
    storage: EStorageType;
    toolbarType?: ToolbarContextEnum;
    downloadButtonRef?: React.RefObject<HTMLDivElement>;
    isEbookReader?: boolean;
}): TToolbarItem[] => {
    const settings = TOOLBAR_CONFIG[storage];

    const items = useSelector((state: RootState) => groupedIds(state, storage as EStorageType, false)) as string[];
    const selectedItems: CloudItem[] = useSelector((state: RootState) => getStorageSelectedItems(state, storage)) as CloudItem[];
    const filesWithFaces = useSelector(getFilesWithSelectedFace);
    const quotaCleanerIsLetterGroup = useSelector((state: RootState) => getIsLetterGroup(state));
    const currentFolder = useSelector((state: RootState) => getStorageCurrentFolder(state, storage));

    const { isHome, isQuotaCleaner, isPublic, isStock, isAlbums, isDocuments, isFavorites } = getStorage(storage);
    const isRootHome = isHome && currentFolder?.id === ROOT_FOLDER_ID;
    const totalItemsCount = useSelector((state: RootState) => getCurrentTotalCount(state, undefined, undefined));
    const selectedCount = selectedItems.length;
    const allSelected = !!selectedCount && selectedCount === totalItemsCount;
    const currentAlbum = useSelector(getCurrentAlbum);
    const isPublicUpload = useSelector(isPublicUploadEnabled);
    const folderWithAuthors = useSelector(isFolderWithAuthor);
    const isAnonymous = useSelector(UserSelectors.isAnonymous);
    const facesIsOff = !filesWithFaces.length;

    const itemsAllCount = useSelector(getItemsTotalCount);

    const showAuthorsFilter = settings?.authorSort && (folderWithAuthors || (isPublic && isPublicUpload)) && facesIsOff && !isAnonymous;

    const availableActions = useActionsConfig();

    const windowWidth = useWindowWidth();

    if (!settings || toolbarType === ToolbarContextEnum.pageContentContext) {
        return [];
    }

    let filterHandler = (action: EActions) => availableActions[action] && Boolean(toolbarItems[action]) && Boolean(settings[action]);

    const hasActionInEmptyState = (action: EActions) => {
        let available = [EActions.selectAll];
        if (isAlbums) {
            available = [EActions.createAlbum];
        }

        if (isDocuments) {
            return false;
        }

        if (isHome && !isRootHome) {
            available = [...available, EActions.publish, EActions.share];
        }

        return available.includes(action);
    };

    if (items.length === 0 && !currentAlbum && !showAuthorsFilter) {
        filterHandler = hasActionInEmptyState;
    }

    const actionsOrderList = isEbookReader
        ? actionsOrder_EbookReader
        : storage === EStorageType.pdfEdit
        ? actionsOrder_PdfEdit
        : actionsOrder;

    const actions: TToolbarItem[] = actionsOrderList
        .filter(filterHandler)
        .map((action) => {
            let item: TToolbarItem = toolbarItems[action];

            if (!isFavorites && [EActions.addToFavorites, EActions.removeFromFavorites].includes(action)) {
                return null;
            }

            if (action === EActions.download) {
                const isDownloadOff = isQuotaCleaner && quotaCleanerIsLetterGroup;
                if (isDownloadOff) {
                    return null;
                }

                item = getDownloadItem(
                    selectedItems,
                    filesWithFaces,
                    currentFolder as CloudItem,
                    isRootHome,
                    downloadButtonRef,
                    isEbookReader
                );
            } else if (action === EActions.selectAll) {
                item = getSelectItem({
                    allSelected,
                    itemsAllCount,
                    itemsCount: selectedCount,
                    disabled: items.length === 0 && !showAuthorsFilter,
                });
            } else if (action === EActions.clone) {
                if (!checkAttachesCloneLimit(storage, selectedCount)) {
                    return null;
                }
                item = getCloneItem(isPublic || isStock);
            }

            if (
                ENABLE_FULL_RESPONSIVE &&
                windowWidth <= BREAKPOINT_SM &&
                (action === EActions.remove || action === EActions.publish || action === EActions.share)
            ) {
                return null;
            }

            return {
                ...item,
                priority: getPriority(settings[action]),
            };
        })
        .filter((el) => !!el) as TToolbarItem[];

    let moreListOrderFiltered = moreListOrder.filter((action) => availableActions[action] && Boolean(toolbarDropdownItems[action]));
    if (isFavorites) {
        moreListOrderFiltered = moreListOrderFiltered.filter(
            (o) => isFavorites && ![EActions.addToFavorites, EActions.removeFromFavorites].includes(o)
        );
    }

    const moreList: ListItem[] = moreListOrderFiltered.map((action) => toolbarDropdownItems[action]);

    if (moreList.length) {
        if (ENABLE_FULL_RESPONSIVE && windowWidth <= BREAKPOINT_SM) {
            if (availableActions[EActions.publish]) {
                moreList.push(toolbarItems.publish as ListItem);
            }

            if (availableActions[EActions.share]) {
                moreList.push(toolbarItems.share as ListItem);
            }
        }

        if (moreList.length > 1) {
            const removeActionIndex = moreList.findIndex((o) => o.id === EActions.remove);

            moreList[removeActionIndex - 1] = { ...moreList[removeActionIndex - 1], divider: true };
        }

        actions.push({
            ...toolbarItems[EActions.more],
            list: moreList,
        });
    }

    return actions;
};
