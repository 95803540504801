import classNames from 'clsx';
import { ReactComponent as CloudIcon } from 'img/icons/cloud-colored.svg';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { IS_BIZ_USER, IS_BLOCKED, IS_FROZEN, IS_ONPREMISE } from 'reactApp/appHelpers/configHelpers';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { goToRegisterPage } from 'reactApp/modules/ph/ph.helpers';
import { selectStatusPage } from 'reactApp/modules/router/router.selectors';
import { SelectionsSelectors } from 'reactApp/modules/selections/selections.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { isWelcomePromoShow } from 'reactApp/modules/welcome/welcome.selectors';
import styles from 'reactApp/ui/Header/Header.css';
import { WebSearch } from 'reactApp/ui/Header/WebSearch/WebSearch';
import { Toolbar } from 'reactApp/ui/Toolbar/Toolbar';
import { EToolbarItemMod } from 'reactApp/ui/Toolbar/Toolbar.types';
import { ToolbarItem } from 'reactApp/ui/Toolbar/ToolbarItem/ToolbarItem';
import { storages } from 'server/constants/storages';

import { EHeaderColor } from './HeaderWrapper';

interface Props {
    isSearchExpanded: boolean;
    toggleSearch: (state: boolean) => void;
    storage: EStorageType;
    searchOnlyExpanded: boolean;
    isResponsiveEnable: boolean;
    isEbookReader?: boolean;
    color?: EHeaderColor;
    onBookmark?(isActiveBookmark: boolean): void;
}

export const RightSide = memo(
    ({ isSearchExpanded, toggleSearch, storage, searchOnlyExpanded, isResponsiveEnable, isEbookReader, color, onBookmark }: Props) => {
        const { isAttaches, isStart } = getStorage(storage);
        const isSelectingMode = useSelector(SelectionsSelectors.isAnyIndexSelected);
        const isAnonymous = useSelector(UserSelectors.isAnonymous);
        const isPhone = useSelector(EnvironmentSelectors.isPhone);
        const isWelcomePromo = useSelector(isWelcomePromoShow);
        const isErrorPage = useSelector(selectStatusPage);

        const searchAvailable =
            !isEbookReader && Boolean(storages[storage]?.isSearchAvailable) && !IS_FROZEN && !IS_BLOCKED && !isErrorPage;
        const showRegisterUrl = isAnonymous && !isPhone && !IS_ONPREMISE && !isErrorPage;
        const showToolbarOnStartPage = IS_BIZ_USER && isStart ? true : !isStart;
        const showToolbar = (!isSearchExpanded || isSelectingMode) && showToolbarOnStartPage && !isWelcomePromo && !isErrorPage;

        return (
            <div
                className={classNames({
                    [styles.rightSide]: true,
                    [styles.rightSideMyTeam]: isResponsiveEnable,
                })}
            >
                {showToolbar && (
                    <div
                        className={classNames(styles.toolbar, {
                            [styles.toolbarNoMargin]: isResponsiveEnable,
                        })}
                    >
                        <Toolbar
                            color={color}
                            isResponsiveEnable={isResponsiveEnable}
                            isEbookReader={isEbookReader}
                            onBookmark={onBookmark}
                        />
                    </div>
                )}
                {((!isSelectingMode && searchAvailable) || isStart) && (
                    <WebSearch
                        onlyExpanded={searchOnlyExpanded}
                        dropdownAvailable={!isAttaches}
                        toggleSearch={toggleSearch}
                        isExpanded={isSearchExpanded}
                        storage={storage}
                    />
                )}
                {showRegisterUrl && (
                    <ToolbarItem
                        id="cloud"
                        text="Завести Облако"
                        collapse={isResponsiveEnable}
                        icon={<CloudIcon />}
                        onClick={goToRegisterPage}
                        mod={EToolbarItemMod.blue}
                    />
                )}
            </div>
        );
    }
);

RightSide.displayName = 'RightSide';
