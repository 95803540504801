import { xray } from 'lib/xray';
import { QuotaLandingSelectors } from 'reactApp/modules/quotaLanding/quotaLanding.selector';
import { startSelecting } from 'reactApp/modules/selections/selections.actions';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { getDeleteSnackbarText } from 'reactApp/modules/userQuotaCleaner/helpers/getDeleteSnackbarText';
import {
    deleteUserQuotaCleanerItemsSuccess,
    reloadGroupAfterDelete,
    setIsItemsDeleteProcess,
    setNeedsGroupReload,
} from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.actions';
import { getCurrentGroupTotalCount } from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.selectors';
import { put, select } from 'redux-saga/effects';

export function* deleteItemsSuccess(action: ReturnType<typeof deleteUserQuotaCleanerItemsSuccess>) {
    const {
        payload: { count, groupType, groupId, ids, itemIds, size, isMsgRemove, onDeleteSuccess },
    } = action;
    const isMobile = yield select(QuotaLandingSelectors.isMobile);
    yield put(setIsItemsDeleteProcess({ value: false }));

    onDeleteSuccess?.();
    const totalCount = yield select(getCurrentGroupTotalCount);

    if (totalCount) {
        yield put(
            showSnackbarAction({
                id: 'quotaCleanerDeleteSuccess',
                type: SnackbarTypes.success,
                text: getDeleteSnackbarText(count, groupType, groupId),
                closable: true,
            })
        );
    } else {
        yield put(
            showSnackbarAction({
                id: 'quotaCleanerDeleteAllSuccess',
                type: SnackbarTypes.success,
                text: 'Хранилище очищено',
                closable: true,
            })
        );
    }

    xray.send(`quota-cln-delete-${isMsgRemove ? 'msg-' : ''}scc${isMobile ? '-mob' : ''}`, { i: groupId });

    yield put(setNeedsGroupReload({ value: true }));
    yield put(reloadGroupAfterDelete({ ids, itemIds, groupId, size }));
    if (isMobile) {
        yield put(startSelecting());
    }
}
