import { editor } from 'Cloud/Application/Editor';
import { isReadOnly } from 'reactApp/appHelpers/appHelpers';
import { IS_PUBLIC } from 'reactApp/appHelpers/configHelpers';
import { CloudItem } from 'reactApp/modules/storage/storage.types';

export const openEditor = (item: CloudItem, editorId = '') => editor?.openEditor(item, editorId);

export const isFileForOpenInEditor = (item) => {
    if (IS_PUBLIC || isReadOnly(item) || item.isFile || item.isReadOnly) {
        return false;
    }

    const editors = editor?.getEditorsFor(item);

    return editors?.some((editor) => ['R7'].includes(editor.id));
};
