import { useEffect } from 'react';
import { VK_UI_DARK_THEME } from 'reactApp/constants';
import { setPortalMenuTheme } from 'reactApp/modules/ph/ph.helpers';
import styles from 'server/components/Page/Page@desktop.css';

export const setDarkTheme = (useDark: boolean) => {
    const containsDarkTheme = document.body.classList.contains(VK_UI_DARK_THEME);

    if (!useDark) {
        if (containsDarkTheme) {
            const page = document.querySelector(`.${styles.root_theme_dark}`);

            document.body.classList.remove(VK_UI_DARK_THEME);
            page?.classList.remove(styles.root_theme_dark);

            return () => {
                // restore previous state
                document.body.classList.add(VK_UI_DARK_THEME);
                page?.classList.add(styles.root_theme_dark);
            };
        }
        return;
    }

    document.body.classList.add(VK_UI_DARK_THEME);

    const page = document.querySelector(`.${styles.root}`);

    if (page && !page.classList.contains(styles.root_theme_dark)) {
        page.classList.add(styles.root_theme_dark);
    }

    setPortalMenuTheme('dark');

    return () => {
        document.body.classList.remove(VK_UI_DARK_THEME);
        page?.classList.remove(styles.root_theme_dark);
        setPortalMenuTheme('default');
    };
};

export const useDarkTheme = (useDark: boolean) => {
    useEffect(() => {
        return setDarkTheme(useDark);
    }, [useDark]);
};
