import classNames from 'clsx';
import { ReactComponent as CloseIcon } from 'mrg-icons/src/mailru/actions/close_big.svg';
import React, { createRef, PureComponent, ReactElement, ReactNode } from 'react';
import { noop } from 'reactApp/utils/helpers';
import { scrollLock, scrollUnlock } from 'reactApp/utils/scrollLock';

import styles from './Screen.css';

export interface Props {
    closable: boolean;
    children: ReactNode;
    withLogo: boolean;
    vkLogo: boolean;
    onClose();
}

export class Screen extends PureComponent<Props> {
    private rootRef = createRef<HTMLDivElement>();

    public static defaultProps = {
        closable: true,
        withLogo: true,
        vkLogo: false,
        onClose: noop,
    };

    public componentDidMount(): void {
        scrollLock(this.rootRef.current, { allowTouchMove: true });
    }

    public componentWillUnmount(): void {
        scrollUnlock(this.rootRef.current);
    }

    private handleClose = (): void => {
        this.props.onClose();
    };

    public render(): ReactElement {
        const { closable, children, withLogo, vkLogo } = this.props;

        return (
            <div
                className={classNames({
                    [styles.root]: true,
                    [styles.logo]: withLogo,
                    [styles.logo_vk]: vkLogo,
                })}
                ref={this.rootRef}
            >
                {closable && (
                    <div className={styles.closeIcon} onClick={this.handleClose}>
                        <CloseIcon width={16} height={16} />
                    </div>
                )}
                <div className={styles.content}>{children}</div>
            </div>
        );
    }
}
