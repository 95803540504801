/* eslint-disable max-lines-per-function */
import classNames from 'clsx';
import React, { forwardRef, memo, MutableRefObject, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { VariableSizeList as List } from 'react-window';
import { ENABLE_FULL_RESPONSIVE } from 'reactApp/appHelpers/configHelpers';
import { BREAKPOINT_SM } from 'reactApp/constants/breakpoints';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { isMobileGalleryViewMode } from 'reactApp/sections/MobileGalleryPage/MobileGalleryPage.helpers';

import { DIVIDER_HEIGHT, INCOMING_ITEM_HEIGHT_RESPONSIVE } from './VirtualList.constants';
import styles from './VirtualList.css';
import { listChildrenMap } from './VirtualList.data';
import { getActiveRowIndex, getIndexes, getListSizes, scrollIntoView } from './VirtualList.helpers';
import { useWindowResizer, useWindowScroller, useWindowWidth } from './VirtualList.hooks';
import { EAligment, IProps } from './VirtualList.types';
import { VirtualListLoader } from './VirtualListLoader';

const VirtualListBase = forwardRef(function VirtualList(props: IProps, ref) {
    const {
        customScrollElement,
        itemHeight,
        itemWidth,
        rowHeight,
        list,
        renderItem,
        viewMode,
        dividerHeight,
        dividerSize,
        onItemsRendered,
        className = '',
    } = props;
    const [rootRef, containerWidth] = useWindowResizer();
    const [scrollerRef, outerRef] = useWindowScroller(customScrollElement);
    const [activeIndex, setActiveIndex] = useState<number>(-1);
    const activeRef = useRef<HTMLDivElement | null>(null) as MutableRefObject<HTMLDivElement | null>;
    const aligment = useRef<string>(EAligment.CENTER);
    const isPhone = useSelector(EnvironmentSelectors.isPhone);
    const windowWidth = useWindowWidth();
    const currentStorage = useSelector(getCurrentStorage);

    const { columnCount, lineHeight, width, margin, height } = getListSizes({
        containerWidth,
        itemHeight,
        itemWidth,
        rowHeight,
        viewMode,
        isPhone,
    });

    const handleRef = useCallback((ref) => {
        activeRef.current = ref;
    }, []);

    const style = useMemo(() => ({ height: '100%', overflowX: 'hidden' } as const), []);

    const indexes = getIndexes(list, columnCount, viewMode);
    const itemActiveIndex = activeIndex !== -1 ? getActiveRowIndex({ index: activeIndex, viewMode, columnCount, indexes }) : -1;

    const getItemSize = useCallback(
        (index: number) => {
            const gridIndex = indexes[index]?.start;
            const isGrid =
                viewMode === EViewMode.thumbs ||
                isMobileGalleryViewMode(viewMode) ||
                viewMode === EViewMode.squares ||
                viewMode === EViewMode.squares180;

            const listItem = list[isGrid ? gridIndex : index];
            const isDivider = typeof listItem === 'object' && listItem.divider;

            if (isDivider) {
                return dividerHeight ? dividerHeight : DIVIDER_HEIGHT;
            }

            if (viewMode === EViewMode.list && windowWidth <= BREAKPOINT_SM && ENABLE_FULL_RESPONSIVE) {
                return INCOMING_ITEM_HEIGHT_RESPONSIVE;
            }

            return lineHeight;
        },
        [viewMode, indexes, lineHeight, list, dividerHeight, currentStorage, windowWidth]
    );

    const handleItemsRendered = useCallback(
        ({ visibleStartIndex, visibleStopIndex }: { visibleStartIndex: number; visibleStopIndex: number }) => {
            const start = indexes[visibleStartIndex]?.start;
            const stop = indexes[visibleStopIndex]?.end;

            onItemsRendered?.({ start, stop });
        },
        [indexes, onItemsRendered]
    );

    const itemData = {
        renderItem,
        list,
        itemActiveIndex,
        handleRef,
        width,
        margin,
        height,
        indexes,
        dividerSize,
    };

    useImperativeHandle(ref, () => ({
        scrollToItem: (index: number, aligmentOption = EAligment.CENTER) => {
            setActiveIndex(index);
            aligment.current = aligmentOption;
        },
        getColumnsCount: () => columnCount,
    }));

    useEffect(() => {
        scrollerRef.current?.resetAfterIndex(0);
    }, [lineHeight, scrollerRef, list]);

    useEffect(() => {
        if (itemActiveIndex === -1) {
            return;
        }

        const scrollerAlignment = aligment.current === EAligment.CENTER ? 'center' : 'smart';
        scrollerRef.current?.scrollToItem(itemActiveIndex, scrollerAlignment);
        // Надо, чтобы VariableSizeList успел проскроллить и нарисовать элементы
        setTimeout(
            () =>
                aligment.current === EAligment.CENTER
                    ? activeRef.current?.scrollIntoView({ block: 'center' })
                    : scrollIntoView(activeRef.current),
            100
        );
    }, [scrollerRef, columnCount, activeIndex]);

    const isGrid = viewMode === EViewMode.thumbs || viewMode === EViewMode.squares || viewMode === EViewMode.squares180;

    return (
        <div
            ref={rootRef}
            className={classNames(className, {
                [styles.root]: true,
                [styles.root_grid]: isGrid,
            })}
        >
            <List
                ref={scrollerRef}
                itemSize={getItemSize}
                itemCount={indexes.length}
                height={window.innerHeight}
                itemData={itemData}
                width={containerWidth}
                outerRef={outerRef}
                style={style}
                overscanCount={15}
                onItemsRendered={handleItemsRendered}
            >
                {listChildrenMap[viewMode || EViewMode.thumbs]}
            </List>
            {props.hasMoreToLoad && <VirtualListLoader loadOnScroll={props.loadOnScroll} isLoading={props.isLoading} />}
        </div>
    );
});

export const VirtualList = memo(VirtualListBase);
