import FeedbackLoader from '@mail/feedback-loader';
import { Xray } from '@mail/xray';
import config from 'Cloud/config';

const DEFAULT_STYLES = {
    bottom: '37px',
    zIndex: '3000',
};

const CORSAPI_HOST = config.get('CORS_API_HOST');

export interface ICSATCreatorParams {
    clientId?: string;
    email?: string;
    project: string;
    xray?: Xray;
    feedbacks: Array<{
        surveyId: number;
        delay: number;
        permille: number;
        feature: string;
    }>;
    apiBaseUrl?: string;
    forceShowSurveyId?: string;
    filterFeature: (feature: string) => boolean;
}

export function createVKCSATLoader({
    xray,
    email,
    project,
    feedbacks,
    apiBaseUrl = `https://${CORSAPI_HOST}/api/v1/`,
    filterFeature,
    forceShowSurveyId,
    clientId = FeedbackLoader.getClientId(),
}: ICSATCreatorParams) {
    return new FeedbackLoader(feedbacks, {
        forceShowSurveyId,
        surveyLoaderOptions: {
            clientId,
            email,
            radar: xray?.send.bind(xray),
            apiBaseUrl,
            checkFeature: filterFeature,
            frameLoaderOptions: {
                project,
                theme: 'vk-com',
                styles: Object.assign({}, DEFAULT_STYLES),
            },
        },
    });
}
