import { ReactComponent as ChevronIcon } from '@vkontakte/icons/src/svg/16/chevron_outline_16.svg';
import classNames from 'clsx';
import React, { useState } from 'react';
import { IS_ONPREMISE } from 'reactApp/appHelpers/configHelpers';
import { PublishItem } from 'reactApp/modules/modifying/modifying.types';

import { DownloadsCountDropdown } from '../DownloadsCountDropdown/DownloadsCountDropdown';
import { ExpiriesDwopdown } from '../ExpiriesDwopdown/ExpiriesDwopdown';
import styles from './AdditionalSettings.css';

interface Props {
    item?: PublishItem;
    isWeblinkOpened: boolean;
    showExpires: boolean;
    isDownloadable?: boolean;
    isPhone?: boolean;
}

const AdditionalSettings: React.FC<Props> = ({ item, isWeblinkOpened, showExpires, isDownloadable, isPhone }) => {
    const [isOpened, setIsOpened] = useState(false);

    return (
        <>
            {isWeblinkOpened && (
                <>
                    <div
                        className={classNames(styles.toggler, { [styles.toggler_opened]: isOpened, [styles.toggler_phone]: isPhone })}
                        onClick={() => setIsOpened(!isOpened)}
                    >
                        Дополнительные настройки
                        <ChevronIcon />
                    </div>
                    <div className={classNames(styles.content, { [styles.content_opened]: isOpened })}>
                        {showExpires && <ExpiriesDwopdown item={item} />}
                        {IS_ONPREMISE && <DownloadsCountDropdown item={item} isDownloadable={isDownloadable} />}
                    </div>
                </>
            )}
        </>
    );
};

export default AdditionalSettings;
