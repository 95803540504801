// TODO: CLOUDWEB-14876 - заменить на новый компонент empty-state'а после аб.
import { Button, Spacing, Text } from '@vkontakte/vkui';
import emptyImg from 'img/empty-state/album.png';
import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { createAlbumRequest } from 'reactApp/modules/albums/albums.actions';
import { sendEmptyStatesAnalitics } from 'reactApp/utils/emptyStatesGa';

import styles from './AlbumsEmpty.css';

export const AlbumsEmpty = memo(() => {
    const dispatch = useDispatch();

    const handleAlbumCreate = useCallback(() => {
        sendEmptyStatesAnalitics({ action: 'album-click' });

        dispatch(createAlbumRequest());
    }, [dispatch]);

    return (
        <div className={styles.root}>
            <img className={styles.image} src={emptyImg} alt="Альбомы для фото и видео" />
            <Spacing size={24} />
            <Text className={styles.title}>Альбомы для фото и видео</Text>
            <Spacing size={12} />
            <Text className={styles.text}>Соберите тематическую коллекцию и поделитесь ссылкой на неё с близкими</Text>
            <Spacing size={24} />
            <Button className={styles.button} mode="secondary" appearance="neutral" size="m" onClick={handleAlbumCreate}>
                Создать альбом
            </Button>
        </div>
    );
});

AlbumsEmpty.displayName = 'AlbumsEmpty';
