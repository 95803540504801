import classNames from 'clsx';
import { ReactComponent as SettingsIcon } from 'img/icons/documents/settings.svg';
import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNewPortalHeader } from 'reactApp/appHelpers/featuresHelpers';
import { loadDocumentsSectionRequest } from 'reactApp/modules/personalDocuments/personalDocuments.module';
import {
    getDocumentsList,
    getDocumentsRecognitionStatus,
    getSectionLoadedState,
} from 'reactApp/modules/personalDocuments/personalDocuments.selectors';
import { ERecognitionStatus } from 'reactApp/modules/personalDocuments/personalDocuments.types';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { checkUpdateLicenseRequest } from 'reactApp/modules/profile/profile.module';
import { BreadcrumbsContainer } from 'reactApp/ui/BreadcrumbsContainer/BreadcrumbsContainer';
import { EInformerColor } from 'reactApp/ui/Informer/Informer.types';
import { InlineInformer } from 'reactApp/ui/Informer/InlineInformer/InlineInformer';
import { Loader } from 'reactApp/ui/Loader/Loader';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import { DocumentsInformer } from './DocumentsInformer/DocumentsInformer';
import { Item } from './Item/Item';
import { sendDocumentsGa } from './PersonalDocuments.helpers';
import styles from './PersonalDocumentsMainPage.css';
import { SmsBlock } from './SmsBlock/SmsBlock';

export const PersonalDocumentsMainPage = memo(function PersonalDocuments() {
    const dispatch = useDispatch();
    const items = useSelector(getDocumentsList);
    const isLoaded = useSelector(getSectionLoadedState);
    const documentsRecognitionStatus = useSelector(getDocumentsRecognitionStatus);

    const onSettingsClick = useCallback(() => openPopupHelper({ popupName: popupNames.RECOGNITION_POPUP }), []);

    useEffect(() => {
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'documents' });
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'page-entered', source: 'documents' });
    }, []);

    useEffect(() => {
        dispatch(loadDocumentsSectionRequest());
        dispatch(checkUpdateLicenseRequest());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!isLoaded) {
        return <Loader centered />;
    }

    return (
        <div
            className={classNames(styles.root, {
                [styles.root_new_portal_header]: isNewPortalHeader.client,
            })}
        >
            <div className={styles.title}>
                <BreadcrumbsContainer />

                {documentsRecognitionStatus !== ERecognitionStatus.DISABLED && (
                    <div className={styles.info}>
                        {documentsRecognitionStatus === ERecognitionStatus.PROCESSED && (
                            <div className={styles.informer}>
                                <InlineInformer color={EInformerColor.blue}>
                                    На основе ваших фотографий в Облаке — это безопасно и надёжно!
                                </InlineInformer>
                            </div>
                        )}

                        <div className={styles.settings} onClick={onSettingsClick}>
                            <SettingsIcon />
                        </div>
                    </div>
                )}
            </div>

            <DocumentsInformer sendGa={sendDocumentsGa} />

            <div className={styles.content}>
                {items.map((item) => (
                    <Item key={item.id} {...item} />
                ))}
            </div>

            <div className={styles.smsBlockWrapper}>
                <SmsBlock />
            </div>
        </div>
    );
});
