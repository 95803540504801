import { Icon24UsersOutline } from '@vkontakte/icons';
import classNames from 'clsx';
import { xray } from 'lib/xray';
import React, { ReactElement, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isModifiedSharing } from 'reactApp/appHelpers/appHelpers';
import { SharingDropdown } from 'reactApp/components/SharingWindow/Dropdown/SharingDropdown';
import { DOMAIN_ACCESS_OPTIONS, QA_VALUE, TOOLTIP_OFFSET } from 'reactApp/components/SharingWindow/Sharing.constants';
import { sendGa } from 'reactApp/components/SharingWindow/Sharing.helpers';
import { DomainAccess } from 'reactApp/components/SharingWindow/Sharing.types';
import styles from 'reactApp/components/SharingWindow/Weblink/SharingWeblink.css';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { toggleWeblinkDomesticRequest } from 'reactApp/modules/modifying/modifying.actions';
import { newSendSearchRadarAnalytics } from 'reactApp/modules/search/search.analytics';
import { getSearchRequestParams } from 'reactApp/modules/search/search.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { Dropdown } from 'reactApp/ui/Dropdown/Dropdown';
import { DropdownMenu } from 'reactApp/ui/Dropdown/DropdownMenu';
import { tooltipPlacements } from 'reactApp/ui/Tooltip/Tooltip.constants';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

export const useDomestic = (item, { storage }) => {
    const modifiedSharing = isModifiedSharing(item);
    const dispatch = useDispatch();
    const { xPageId, xReqId, query } = useSelector(getSearchRequestParams);
    const isPhone = EnvironmentSelectors.isPhone();
    const domainAccess = item?.weblinkDomestic ? DomainAccess.DOMESTIC : DomainAccess.EXTERNAL;
    const isFolder = item?.isFolder;

    const renderDomesticDropdown = useCallback(
        (onClick): ReactElement => {
            const list = Object.values(DOMAIN_ACCESS_OPTIONS).map((item) => ({
                id: item.id,
                text: item.text,
                check: item.id === domainAccess,
                qaValue: item.id,
            }));

            return <DropdownMenu list={list} theme="octavius" onClick={onClick} className={styles.dropdownMenu} />;
        },
        [domainAccess]
    );

    const handleUpdateDomestic = useCallback(
        (id): void => {
            if (id !== domainAccess) {
                if (modifiedSharing) {
                    xray.send('sharing-modified_domestic');
                }
                sendGa('domestic');
                sendPaymentGa({
                    eventCategory: ECategoryGa.public,
                    action: 'edit-access',
                    type_for: id,
                    public_id: item?.weblink,
                });

                if (storage === EStorageType.search) {
                    newSendSearchRadarAnalytics({
                        eventCategory: ECategoryGa.publicSearch,
                        action: 'edit-access',
                        searchParams: {
                            search_phrase: query,
                            page_id: xPageId,
                            req_id: xReqId,
                        },
                        items: [
                            {
                                file_name: 'nameWithoutExt' in item && item.nameWithoutExt,
                                type: item.kind,
                                pos: item.pos,
                                file_id: item.id,
                                public_id: ('weblink' in item && item.weblink) || '',
                                placement: item.srchSrc,
                            },
                        ],
                    });
                }

                dispatch(toggleWeblinkDomesticRequest({ item }));
            }
        },
        [dispatch, domainAccess, item]
    );

    return useCallback((): ReactElement => {
        const item = DOMAIN_ACCESS_OPTIONS[domainAccess];

        return (
            <div
                className={classNames(styles.rightItem, { [styles.rightItemModified]: modifiedSharing })}
                data-qa-item={QA_VALUE.domesticItem}
            >
                {modifiedSharing && <Icon24UsersOutline className={styles.leftIcon} />}
                <span className={styles.rightTitle}>{isFolder ? 'Папка доступна' : 'Файл доступен'}</span>
                <div className={styles.dropdown}>
                    <Dropdown
                        withinDialog
                        theme="octavius"
                        qaValue={item.id}
                        as={SharingDropdown}
                        value={isPhone || modifiedSharing ? item.text : item.text.toLowerCase()}
                        content={renderDomesticDropdown}
                        onClickItem={handleUpdateDomestic}
                        placement={tooltipPlacements.BOTTOM_LEFT}
                        qaValueContent={QA_VALUE.domesticDropdownContent}
                        tooltipOffset={TOOLTIP_OFFSET}
                        animatedTooltip
                        tooltipClassName={isPhone ? styles.tooltip : undefined}
                        isPhone={isPhone}
                    />
                </div>
            </div>
        );
    }, [domainAccess, handleUpdateDomestic, isFolder, isPhone, renderDomesticDropdown]);
};
