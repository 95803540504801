import { autoUpdate, flip, offset, useFloating } from '@floating-ui/react';
import { Icon20Cancel } from '@vkontakte/icons';
import { Text } from '@vkontakte/vkui';
import classNames from 'clsx';
import { ReactComponent as ArrowAside } from 'img/tooltip/arrow-aside.svg';
import { ReactComponent as ArrowCenter } from 'img/tooltip/arrow-center.svg';
import throttle from 'lodash.throttle';
import React, { memo, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { calculateOffset, getFlipFallbackPlacements } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.helpers';
import { ETooltipPlacement, ETooltipSize, ITooltipProps } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';

import styles from './FloatingTooltip.css';

const ARROW_CENTER_PLACEMENTS = [ETooltipPlacement.top, ETooltipPlacement.bottom, ETooltipPlacement.left, ETooltipPlacement.right];

export const FloatingTooltip = memo<ITooltipProps>(
    // eslint-disable-next-line max-lines-per-function
    ({
        children,
        className,
        target,
        title,
        text,
        buttonText,
        img,
        appearance,
        size,
        placement,
        closable = true,
        hideArrow = false,
        closeOnOutsideClick = true,
        closeOnScroll = false,
        withinModal = false,
        qaId,
        overAll = false,
        isAlternativeButton = false,
        onClick,
        onShow,
        onClose,
        onTooltipClick,
    }) => {
        const [isOpen, setIsOpen] = useState(true);

        const {
            x,
            y,
            strategy,
            refs,
            placement: finalPlacement,
        } = useFloating({
            open: isOpen,
            onOpenChange: setIsOpen,
            placement,
            middleware: [
                offset(calculateOffset),
                flip({
                    fallbackPlacements: getFlipFallbackPlacements(placement),
                }),
            ],
            whileElementsMounted: autoUpdate,
        });

        const onDocumentClick = useCallback(
            ({ target }: MouseEvent) => {
                if (refs?.floating && refs.floating?.current && target instanceof HTMLElement && !refs.floating.current.contains(target)) {
                    setIsOpen(false);
                    onClose?.();
                }
            },
            [onClose, refs?.floating]
        );

        const throttledScroll = throttle(() => {
            setIsOpen(false);
            onClose?.();
        }, 100);

        const handleClick = useCallback(() => {
            onClick?.();
        }, [onClick]);

        const handleClose = useCallback(() => {
            onClose?.();
            setIsOpen(false);
        }, [onClose, setIsOpen]);

        useLayoutEffect(() => {
            if (target) {
                refs.setReference('current' in target ? target.current : target);
            }
        }, [refs, target]);

        useEffect(() => {
            onShow?.();

            if (closeOnOutsideClick) {
                document.addEventListener('click', onDocumentClick, { capture: true });
            }

            if (closeOnScroll) {
                document.addEventListener('scroll', throttledScroll);
            }

            return () => {
                document.removeEventListener('click', onDocumentClick, { capture: true });
                document.removeEventListener('scroll', throttledScroll);
            };
        }, []);

        const showImage = Boolean(img) && (!size || size !== ETooltipSize.small);

        if (!isOpen || !refs.reference) {
            return null;
        }

        return (
            <div
                className={classNames(styles.root, className, {
                    [styles.root_closable]: closable,
                    [styles.root_image]: showImage,
                    [styles[`root_size_${size}`]]: Boolean(size),
                    [styles[`root_appearance_${appearance}`]]: Boolean(appearance),
                    [styles[`root_placement_${finalPlacement}`]]: Boolean(finalPlacement),
                    [styles.root_no_arrow]: hideArrow,
                    [styles.root_withinModal]: withinModal,
                    [styles.root_customContent]: Boolean(children),
                    [styles.root_overAll]: overAll,
                })}
                ref={refs.setFloating}
                style={{
                    position: strategy,
                    top: y ?? 0,
                    left: x ?? 0,
                }}
                data-qa-tooltip={qaId}
                onClick={onTooltipClick}
            >
                {!hideArrow && (
                    <div className={styles.arrow}>
                        {ARROW_CENTER_PLACEMENTS.includes(finalPlacement as ETooltipPlacement) ? <ArrowCenter /> : <ArrowAside />}
                    </div>
                )}
                <div className={styles.content}>
                    {children ? (
                        children
                    ) : (
                        <>
                            {title && <Text className={styles.title}>{title}</Text>}
                            {text && <Text className={styles.text}>{text}</Text>}
                            {buttonText && (
                                <Button
                                    theme="vk"
                                    buttonVariantprimary
                                    primary={!isAlternativeButton}
                                    secondary={isAlternativeButton}
                                    sizeMode={ButtonSizeMode.small}
                                    fluid={false}
                                    className={styles.button}
                                    onClick={handleClick}
                                >
                                    {buttonText}
                                </Button>
                            )}
                            {showImage && (
                                <div className={styles.image}>
                                    <img src={img} alt="Картинка в тултипе" />
                                </div>
                            )}
                        </>
                    )}
                    {closable && (
                        <div className={styles.close} onClick={handleClose}>
                            <Icon20Cancel />
                        </div>
                    )}
                </div>
            </div>
        );
    }
);

FloatingTooltip.displayName = 'FloatingTooltip';
