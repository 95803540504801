import classNames from 'clsx';
import throttle from 'lodash.throttle';
import React, { memo, ReactElement, useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { TOP_TOOLBAR_ID } from 'reactApp/constants/magicIdentificators';
import { ActionPanelSelectors } from 'reactApp/modules/actionpanel/actionpanel.selectors';
import { FeatureSelector } from 'reactApp/modules/features/components/FeatureSelector';
import { getFeatureRequiredSignUpWhenDownloading } from 'reactApp/modules/features/features.selectors';
import { requiredAuthorizationHelpers } from 'reactApp/modules/requiredAuthorization/helpers';
import { reDownloadController } from 'reactApp/modules/requiredAuthorization/reDownloadController';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EActions, EStorageType } from 'reactApp/modules/storage/storage.types';
import { DownloadTooltip } from 'reactApp/ui/ReactViewer/ViewerHeader/DownloadTooltip';
import { useCustomActions } from 'reactApp/ui/Toolbar/customActions/useCustomActions';
import { shouldCollapse } from 'reactApp/ui/Toolbar/Toolbar.helpers';
import { ToolbarGapMode, ToolbarProps, TToolbarItem } from 'reactApp/ui/Toolbar/Toolbar.types';
import { DelimiterItem } from 'reactApp/ui/Toolbar/ToolbarItem/DelimiterItem';
import { ToolbarItem } from 'reactApp/ui/Toolbar/ToolbarItem/ToolbarItem';
import { useActions } from 'reactApp/ui/Toolbar/useActions';
import { useFilterActions } from 'reactApp/ui/Toolbar/useFilterActions';
import useResizeObserver from 'use-resize-observer/polyfilled';

import styles from './Toolbar.css';

const MAX_NUM_OF_BUTTON_POSIYION_TRIES = 10;

// eslint-disable-next-line max-lines-per-function
export const Toolbar = memo(
    ({ gapMode, toolbarType, isResponsiveEnable, isEbookReader, color, onBookmark }: ToolbarProps): ReactElement => {
        const rootRef = useRef<HTMLDivElement | null>(null);
        const leftRef = useRef<HTMLDivElement | null>(null);
        const rightRef = useRef<HTMLDivElement | null>(null);
        const counterRef = useRef(0);
        const timerRef = useRef<number | null>(null);
        const isPanelOpened = useSelector(ActionPanelSelectors.isActionPanelOpened);
        const actionPanelWidth = isPanelOpened ? 380 : 0;
        const downloadButtonRef = useRef<HTMLDivElement>(null);

        const storage = useSelector(getCurrentStorage) as EStorageType;

        const filters = useFilterActions({ storage, toolbarType });
        const actions = useActions({ storage, toolbarType, isEbookReader, downloadButtonRef });
        const { left: leftCustomActions, right: rightCustomActions } = useCustomActions({
            storage,
            isEbookReader,
            onBookmark,
        });

        const [priority, setPriority] = useState(0);

        let leftActions = [...leftCustomActions, ...actions];
        let rightActions = [...filters, ...rightCustomActions];

        if (isEbookReader) {
            rightActions = [...leftCustomActions, ...actions, ...rightCustomActions];
            leftActions = [];
        }

        const setResetStackCounter = () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
            timerRef.current = window.setTimeout(() => {
                counterRef.current = 0;
            }, 1000);
        };

        const findButtonPriority = useCallback((): void => {
            if (leftActions?.length && leftRef?.current && rootRef?.current) {
                const leftWidth = leftRef.current.getBoundingClientRect()?.width;

                const rightWidth = (rightRef?.current ? rightRef?.current.getBoundingClientRect()?.width : 0) + actionPanelWidth;
                const rootWidth = rootRef.current.getBoundingClientRect()?.width;

                const emptyPlace = rootWidth - leftWidth - rightWidth;

                if (emptyPlace <= 10 && priority < 20) {
                    if (counterRef.current > MAX_NUM_OF_BUTTON_POSIYION_TRIES) {
                        // useState для этого каунтера использовать нельзя, так как его изменение вызывает перерендер и сбрасывая каунтер, мы опять попадаем в начало этого цикла
                        setResetStackCounter();
                        return;
                    }
                    setPriority(priority + 1);
                    counterRef.current++;
                } else if (emptyPlace > 150 && priority > 0) {
                    setPriority(priority - 1);
                    counterRef.current++;
                }

                // Если будет зацикливание в рендере тулбара, то в вызове только в этом месте поднять таймаут с 1000
                setResetStackCounter();
            }
        }, [leftActions?.length, priority]);

        const renderItem = useCallback(
            ({ Item, itemProps, ...data }: TToolbarItem, index) => {
                if (Item) {
                    return <Item key={itemProps?.id || index} color={color} parentRef={rootRef} {...itemProps} />;
                }

                if (data.id === EActions.delimiter) {
                    return <DelimiterItem color={color} />;
                }

                return (
                    <ToolbarItem
                        key={data.id}
                        ref={data.ref}
                        id={data.id}
                        text={data.text}
                        onClick={data.onClick}
                        hint={data.hint}
                        icon={data.icon}
                        color={color}
                        collapse={
                            isEbookReader && data.id !== EActions.publish
                                ? true
                                : shouldCollapse(data, priority, Boolean(isResponsiveEnable))
                        }
                        list={data.list}
                        parentRef={rootRef}
                        dropdownTheme={data.dropdownTheme}
                        dropdownPosition={data.dropdownPosition}
                        withoutDropdownIcon={data.withoutDropdownIcon}
                        openDropdownByArrow={data.openDropdownByArrow}
                        tooltipId={data?.tooltipId}
                        disabled={data.disabled}
                    />
                );
            },
            [priority, isResponsiveEnable, color]
        );

        const handleOnResize = useMemo(() => throttle(findButtonPriority, 500), [findButtonPriority]);

        useResizeObserver({ onResize: handleOnResize, ref: rootRef });

        return (
            <div className={styles.root} ref={rootRef} id={TOP_TOOLBAR_ID} data-qa-id="toolbar">
                {/* tempexp_15344-next-line */}
                <FeatureSelector
                    selector={getFeatureRequiredSignUpWhenDownloading}
                    control={<></>}
                    variant1={
                        !reDownloadController.isDownloadHappened() ? (
                            <DownloadTooltip
                                btnText="Скачать"
                                downloadRef={downloadButtonRef?.current}
                                isWhite
                                onShow={() => requiredAuthorizationHelpers.sendGa('shw-redwnld-tltip', 'variant1', 'folder')}
                                onClick={() => requiredAuthorizationHelpers.sendGa('clck-redwnld-tltip', 'variant1', 'folder')}
                                onClose={() => requiredAuthorizationHelpers.sendGa('cls-redwnld-tltip', 'variant1', 'folder')}
                            />
                        ) : undefined
                    }
                />
                {Boolean(leftActions?.length) && (
                    <div
                        className={classNames(styles.left, {
                            [styles.wideGap]: gapMode === ToolbarGapMode.wide,
                        })}
                        ref={leftRef}
                    >
                        {leftActions?.map(renderItem)}
                    </div>
                )}
                {Boolean(rightActions?.length) && (
                    <div
                        className={classNames(styles.right, {
                            [styles.wideGap]: gapMode === ToolbarGapMode.wide,
                        })}
                        ref={rightRef}
                    >
                        {rightActions.map(renderItem)}
                    </div>
                )}
            </div>
        );
    }
);

Toolbar.displayName = 'Toolbar';
