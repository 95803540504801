import { userKeeper } from '@mail-core/dashboard';
import { captureMessage } from '@sentry/browser';
import config from 'Cloud/config';
import classNames from 'clsx';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import { path } from 'ramda';
import React, { PureComponent } from 'react';
import { abReactDmrHeightFix } from 'reactApp/appHelpers/featuresHelpers';
import { sendSuccessRadar } from 'reactApp/components/BuyModal/BuyModal.helpers';
import { DmrError } from 'reactApp/errors/serviceErrors/DmrError';
import { EnvironmentSelectors } from 'reactApp/modules/environment';
import Content from 'reactApp/ui/Content/Content';
import DMRFrame from 'reactApp/ui/DMR/DMR';
import { sendGa, sendXray } from 'reactApp/utils/ga';
import { sendGaPurchase } from 'reactApp/utils/gaHelpers';
import { noop } from 'reactApp/utils/helpers';
import { Radar } from 'reactApp/utils/Radar';
import { sendYaMetrika } from 'reactApp/utils/yaHelpers';
import { Ref } from 'semantic-ui-react';

import styles from './DMR.css';
import ErrorMessage from './ErrorMessage';

const DMR_LOAD_NAME = 'dmr-load';

const sendMobileEventWithTime = (prefix, time) => {
    sendXray([prefix, 'load-success']);
    sendXray([prefix, 'load-time'], { ms: time });
};

export class DMR extends PureComponent {
    static propTypes = {
        buySubscription: PropTypes.func.isRequired,
        resetSubscription: PropTypes.func.isRequired,
        checkoutLink: PropTypes.string,
        size: PropTypes.oneOf([null, 'large', 'medium', 'larger']),
        hasError: PropTypes.bool,
        isLoading: PropTypes.bool,
        disableLoaderCentering: PropTypes.bool,
        subscription: PropTypes.object,
        onSuccess: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
        onError: PropTypes.func,
        onFormSend: PropTypes.func,
        onResize: PropTypes.func,
        className: PropTypes.string,
        eventCategory: PropTypes.string,
        skin: PropTypes.string,
        qaId: PropTypes.string,
        payMethod: PropTypes.string,
        minHeight: PropTypes.number,
        isMidas: PropTypes.bool,
    };

    static defaultProps = {
        eventCategory: 'fastCheckout',
        size: null,
        onError: noop,
        onResize: noop,
        onFormSend: noop,
        skin: '',
    };

    state = {
        node: '',
        dmrLoading: false,
        dmrHasError: false,
        dmrHeight: 0,
    };

    height = 0;

    radar = new Radar({
        logFile: 'fastCheckout-dmr',
        groupName: `${this.props.eventCategory.toLowerCase()}-dmr`,
    });

    componentDidUpdate(prevProps) {
        if (this.props.hasError) {
            return;
        }

        if (
            (this.props.subscription && path(['subscription', 'id'], prevProps) !== path(['subscription', 'id'], this.props)) ||
            (!this.dmr && this.props.subscription && !this.props.isLoading && !this.props.checkoutLink)
        ) {
            this.props.buySubscription(this.props.subscription.id);
        }

        if (this.props.checkoutLink && (prevProps.checkoutLink !== this.props.checkoutLink || !this.dmr)) {
            this.setState({
                dmrHasError: false,
                dmrLoading: false,
            });

            this.destroyDmr();
            this.initDmr();
        }
    }

    componentWillUnmount() {
        this.destroyDmr();

        if (this.props.subscription && this.props.subscription.id) {
            this.props.resetSubscription(this.props.subscription.id);
        }
    }

    handleRef = (node) => this.setState({ node });

    retry = () => {
        this.setState({
            dmrHasError: false,
        });

        this.destroyDmr();

        this.props.buySubscription(this.props.subscription.id);
    };

    cameToKebabCase(str) {
        return str.replace(/[A-Z]/g, (a, i) => (i ? '-' : '') + a.toLowerCase());
    }

    destroyDmr() {
        if (this.dmr) {
            this.dmr.destroy();
            this.dmr = null;
        }
    }

    resize = debounce(() => {
        const { onResize } = this.props;
        const { dmrLoading, dmrHeight } = this.state;
        const { height } = this;

        if (!dmrLoading && height && height !== dmrHeight) {
            this.setState({ dmrHeight: height });
            onResize({ height });
        }
    });

    sendPaymentSuccessEvents() {
        const { subscription, eventCategory } = this.props;

        sendSuccessRadar(subscription.id);

        const isPhone = EnvironmentSelectors.isPhone();
        sendGa(
            eventCategory,
            'buy-sub-success',
            `space-${subscription.space.space}-${isPhone ? 'touch' : 'desktop'}${subscription.hasTrial ? '-trial' : ''}`,
            null
        );

        sendYaMetrika('pay-button');
        sendGaPurchase({
            tariffId: subscription.id,
            space: subscription.space.value,
            period: subscription.period,
            price: subscription.price.toString(),
            hasTrial: subscription.hasTrial?.toString(),
            hasDiscount: subscription.hasDiscount?.toString(),
            eventCategory,
        });
        sendGa(eventCategory, 'buy-success');

        this.radar.addCounter('success');
        this.radar.send();
    }

    sendPaymentFailEvents() {
        const { subscription, eventCategory } = this.props;

        const isPhone = EnvironmentSelectors.isPhone();
        sendGa(
            eventCategory,
            'buy-sub-fail',
            `space-${subscription.space.space}-${isPhone ? 'touch' : 'desktop'}${subscription.hasTrial ? '-trial' : ''}`,
            null
        );

        sendGa(eventCategory, 'buy-fail');

        this.radar.addCounter('fail');
        this.radar.send();
    }

    initDmr() {
        const { eventCategory, checkoutLink, onCancel, onSuccess, onError, skin, payMethod, isMidas } = this.props;

        const isPhone = EnvironmentSelectors.isPhone();

        if (checkoutLink && !this.dmr) {
            const startTime = new Date();
            // Меряем время загрузки и рендера DMR фрейма
            userKeeper.time(DMR_LOAD_NAME);

            const errorConf = {
                url: checkoutLink,
                source: 'buy',
                pageId: config.get('x-page-id'),
                user: config.get('user.email'),
                component: eventCategory.toLowerCase(),
            };

            this.dmr = new DMRFrame({ el: this.state.node, payMethod, isMidas });

            this.dmr.on(this.dmr.EVENT_FORM_SEND, () => {
                RADAR.ga('send', 'event', {
                    eventCategory,
                    eventAction: 'buySubscriptionFormSend',
                });
                this.props.onFormSend();
            });

            this.dmr.on(this.dmr.EVENT_PAYMENT_SUCCESS, () => {
                this.sendPaymentSuccessEvents();
                onSuccess();
            });

            this.dmr.on(this.dmr.EVENT_CLOSE, () => {
                this.radar.addCounter('close');
                this.radar.send();
                onCancel();
            });

            this.dmr.on(this.dmr.EVENT_PAYMENT_CANCEL, () => {
                this.radar.addCounter('close');
                this.radar.send();
                onCancel();
            });

            this.dmr.on(this.dmr.EVENT_LOADED, () => {
                const time = new Date() - startTime;
                RADAR.ga('send', 'event', {
                    eventCategory,
                    eventAction: 'buySubscriptionFormShow',
                });

                userKeeper.timeEnd(DMR_LOAD_NAME, { score: 'fetch' });

                if (isPhone) {
                    sendMobileEventWithTime('dmr-mobile', time);
                }

                this.radar.addCounter('load-success');
                this.radar.addCounter('load-time', time);
                this.radar.send();

                sendGa(eventCategory, 'dmr-loaded', skin, { time });
                sendGa(
                    eventCategory,
                    `dmr-loaded${skin ? `-${skin}` : ''}`,
                    [1, 2, 4, 6, 8, 10, 16].find((t) => time / 1000 <= t) ?? '16<'
                );
                this.setState({
                    dmrLoading: false,
                });
                this.resize();
            });

            this.dmr.on(this.dmr.EVENT_MESSAGE, ({ type, action }) => {
                this.radar.addCounter(`type_${this.cameToKebabCase(type)}_action_${this.cameToKebabCase(action)}`);
                this.radar.send();
            });

            this.dmr.on(this.dmr.EVENT_LOAD_ERROR, ({ type }) => {
                const time = new Date() - startTime;

                errorConf.loadTime = new Date() - startTime;
                const error = new DmrError(errorConf);

                this.radar.addLogRecord(error);
                this.radar.addCounter(`error_type_${type}`);
                this.radar.send();

                onError();

                sendGa(eventCategory, 'dmr-load-error', skin, { time });
                sendGa(
                    eventCategory,
                    `dmr-load-error${skin ? `-${skin}` : ''}`,
                    [1, 2, 4, 6, 8, 10, 16].find((t) => time / 1000 <= t) ?? '16<'
                );

                this.setState({
                    dmrLoading: false,
                    dmrHasError: true,
                });
                this.resize();
            });

            this.dmr.on(this.dmr.EVENT_PAYMENT_FAIL, () => {
                this.sendPaymentFailEvents();
                onError();
            });

            this.dmr.on(this.dmr.EVENT_RETRY, () => {
                this.destroyDmr();
                this.initDmr();
            });

            this.dmr.on(this.dmr.EVENT_RESIZE, (data) => {
                this.height = (path(['height'], data) || 0) + abReactDmrHeightFix;

                if (!this.height) {
                    sendGa('dmr', 'resize', 'noheight');

                    captureMessage('DMR_RESIZE NULL HEIGHT', {
                        extra: data,
                    });
                }

                this.resize();
            });

            this.setState({
                dmrLoading: true,
            });

            this.dmr.load(checkoutLink).catch(() => {});
        }
    }

    render() {
        const { hasError, isLoading, className, disableLoaderCentering, size, qaId, minHeight, subscription } = this.props;
        const { dmrHeight } = this.state;

        const showError = hasError || this.state.dmrHasError;
        const showLoader = isLoading || this.state.dmrLoading;

        const style = {
            height: dmrHeight ? dmrHeight : undefined,
            minHeight: minHeight ? minHeight : undefined,
        };

        return (
            <div
                className={classNames(styles.root, {
                    [styles.root_error]: showError,
                    [className]: !!className,
                })}
            >
                <Content
                    retry={this.retry}
                    buttonText="Попробовать ещё раз"
                    wrapClass={classNames(styles.content, {
                        [styles[`content_${size}`]]: !!size,
                        // CLOUDWEB-13621: Добавляет отступы для мобильной формы оплаты триала
                        [styles.content_trial]: subscription?.hasTrial && size === 'medium',
                    })}
                    description={<ErrorMessage />}
                    isLoading={showLoader}
                    hasError={false}
                    isModal
                    disableLoaderCentering={disableLoaderCentering}
                    prerenderContent
                    isMobile={size === 'medium'}
                >
                    <div className={styles.frame} style={style} data-qa-id={qaId}>
                        <Ref innerRef={this.handleRef}>
                            <div />
                        </Ref>
                    </div>
                </Content>
            </div>
        );
    }
}
