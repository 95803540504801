import classNames from 'clsx';
import React, { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';
import { ENABLE_FULL_RESPONSIVE } from 'reactApp/appHelpers/configHelpers';
import { ActionPanelSelectors } from 'reactApp/modules/actionpanel/actionpanel.selectors';
import { accessControlListLoadStart } from 'reactApp/modules/folderAccessControlList/folderAccessControlList.actions';
import { getCurrentFolderHome } from 'reactApp/modules/home/home.selectors';
import { HomeFolder } from 'reactApp/modules/home/home.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { setButtonTypeClickAction, setShowInputAction } from 'reactApp/modules/upload/upload.module';
import {
    getDescriptorId,
    hasAutoFix,
    isProgressComplete,
    showErrorIcon,
    showInfoIcon,
} from 'reactApp/modules/uploadList/uploadList.getters';
import { sendGaUploader } from 'reactApp/modules/uploadList/uploadList.helpers';
import { EProgressStatus, ICountStatistic, IInputFile, IUploaderInformerItem } from 'reactApp/modules/uploadList/uploadList.model';
import {
    setHighlightProblemFilesAction,
    setHighlightSelectedErrorAction,
    setProgressStatusAction,
    setSelectedErrorAction,
    setShowUploaderAction,
    updateUploadFilesAction,
} from 'reactApp/modules/uploadList/uploadList.module';
import {
    getCountStatistic,
    getGroupedByFolderInputFiles,
    getHasNotAllowedExt,
    getPercentLoaded,
    getProgressStatus,
    getRemainTime,
    getSelectedErrorId,
    getUploaderInformers,
    getUploadSpeed,
    hasErrorFiles,
    hasWarningFiles,
    isUploaderVisible,
    showUploadButton,
} from 'reactApp/modules/uploadList/uploadList.selectors';
import { AlertComponent } from 'reactApp/ui/UploadList/Alert/Alert';
import { Header } from 'reactApp/ui/UploadList/Header/Header';
import { Item } from 'reactApp/ui/UploadList/Item/Item';
import { NotDocumentWarning } from 'reactApp/ui/UploadList/NotDocumentWarning/NotDocumentWarning';
import { PopupWarningsContainer } from 'reactApp/ui/UploadList/PopupWarnings/PopupWarnings';
import { ProblemsContainer } from 'reactApp/ui/UploadList/Problems/Problems';

import styles from './UploadList.css';

const mapStateToProps = (state) => {
    return {
        showUploader: isUploaderVisible(state),
        showUploadButton: showUploadButton(state),
        informers: getUploaderInformers(state),
        groupedByFolderInputFiles: getGroupedByFolderInputFiles(state),
        percentLoaded: getPercentLoaded(state),
        progressStatus: getProgressStatus(state),
        hasWarningFiles: hasWarningFiles(state),
        hasErrorFiles: hasErrorFiles(state),
        selectedErrorFileId: getSelectedErrorId(state),
        storage: getCurrentStorage(state) as EStorageType,
        countStatistic: getCountStatistic(state),
        currentFolder: getCurrentFolderHome(state),
        remainTime: getRemainTime(state),
        uploadSpeed: getUploadSpeed(state),
        hasNotAllowedExtensions: getHasNotAllowedExt(state),
        isActionPanelOpened: ActionPanelSelectors.isActionPanelOpened(state),
    };
};

const mapDispatchToProps: IDispatchToProps = {
    updateUploadFiles: updateUploadFilesAction,
    setProgressStatus: setProgressStatusAction,
    setShowUploader: setShowUploaderAction,
    setSelectedError: setSelectedErrorAction,
    setHighlightSelectedError: setHighlightSelectedErrorAction,
    setShowInput: setShowInputAction,
    setButtonTypeClick: setButtonTypeClickAction,
    setHighlightProblemFiles: setHighlightProblemFilesAction,
    accessControlListLoadStart,
};

interface IDispatchToProps {
    updateUploadFiles: typeof updateUploadFilesAction;
    setProgressStatus: typeof setProgressStatusAction;
    setShowUploader: typeof setShowUploaderAction;
    setSelectedError: typeof setSelectedErrorAction;
    setHighlightSelectedError: typeof setHighlightSelectedErrorAction;
    setShowInput: typeof setShowInputAction;
    setHighlightProblemFiles: typeof setHighlightProblemFilesAction;
    setButtonTypeClick: typeof setButtonTypeClickAction;
    accessControlListLoadStart: typeof accessControlListLoadStart;
}

interface IProps extends IDispatchToProps {
    showUploader: boolean;
    showUploadButton: boolean;
    groupedByFolderInputFiles: IInputFile[];
    percentLoaded: number;
    progressStatus: EProgressStatus;
    hasWarningFiles: boolean;
    hasErrorFiles: boolean;
    selectedErrorFileId: string;
    informers: IUploaderInformerItem[];
    allowUpload: boolean;
    storage: EStorageType;
    countStatistic: ICountStatistic;
    currentFolder?: HomeFolder;
    remainTime: number;
    uploadSpeed: number;
    hasNotAllowedExtensions: boolean;
    isActionPanelOpened: boolean;
}

interface IState {
    toggleExpand: boolean;
    showCancelAlert: boolean;
}

class UploadListComponent extends PureComponent<IProps, IState> {
    private errorsList = createRef<HTMLDivElement>();

    state = {
        toggleExpand: false,
        showCancelAlert: false,
    };

    componentDidMount() {
        const { accessControlListLoadStart, currentFolder } = this.props;

        if (currentFolder) {
            // Запрашиваем, что бы получить информацию об авторе папки
            accessControlListLoadStart({ id: currentFolder.id });
        }
    }

    componentDidUpdate(prevProps: IProps) {
        const { progressStatus, countStatistic, hasWarningFiles, hasErrorFiles } = this.props;
        const autoExpandFiles = countStatistic.commonCount === 1;
        const prevProgressComplete = isProgressComplete(prevProps.progressStatus);
        const progressComplete = isProgressComplete(progressStatus);

        if (
            (prevProgressComplete !== progressComplete && progressComplete && autoExpandFiles) ||
            (prevProps.hasWarningFiles !== hasWarningFiles && hasWarningFiles) ||
            (prevProps.hasErrorFiles !== hasErrorFiles && hasErrorFiles)
        ) {
            this.setState({ toggleExpand: false });
        }
    }

    private onExpand = () => {
        const { hasWarningFiles } = this.props;
        const { toggleExpand } = this.state;

        if (hasWarningFiles) {
            return;
        }

        sendGaUploader('upload', toggleExpand ? 'expand' : 'collapse');
        this.setState({ toggleExpand: !toggleExpand });
    };

    private onClose = () => {
        const { progressStatus, setShowUploader } = this.props;
        const progressComplete = isProgressComplete(progressStatus);

        if (progressComplete) {
            setShowUploader(false);
            return;
        }

        this.setState({ showCancelAlert: true });
    };

    private doActionClick = () => {
        const { progressStatus, setShowUploader, setProgressStatus } = this.props;
        const progressComplete = isProgressComplete(progressStatus);

        if (progressComplete) {
            setShowUploader(false);
            return;
        }

        setProgressStatus({ status: EProgressStatus.CANCEL_ALL });
    };

    private onCloseAlert = () => {
        this.setState({ showCancelAlert: false });
    };

    private onUpload = () => {
        const { setShowInput, setButtonTypeClick } = this.props;

        sendGaUploader('upload', 'click');

        setShowInput(true);
        setButtonTypeClick('click');
    };

    private showError = (file: IInputFile) => {
        const { selectedErrorFileId, setSelectedError, setHighlightSelectedError, updateUploadFiles } = this.props;
        const { cloudPath, descriptorId } = file;

        if (hasAutoFix(file)) {
            sendGaUploader('message', 'autofix');
        } else if (showInfoIcon(file)) {
            sendGaUploader('message', 'info');
        } else if (showErrorIcon(file)) {
            sendGaUploader('message', 'error');
        }

        updateUploadFiles({ descriptorId, cloudPath, hideError: false });

        if (selectedErrorFileId === descriptorId) {
            setHighlightSelectedError(true);
            return;
        }

        setSelectedError({ file, fileId: getDescriptorId(file) || '', userClick: true });
    };

    private getInputFiles = () => {
        const { groupedByFolderInputFiles, updateUploadFiles, selectedErrorFileId, storage, uploadSpeed, isActionPanelOpened } = this.props;

        return groupedByFolderInputFiles.map((file, index) => (
            <Item
                key={getDescriptorId(file) || index}
                file={file}
                show={getDescriptorId(file) === selectedErrorFileId}
                updateUploadFiles={updateUploadFiles}
                showError={this.showError}
                storage={storage}
                uploadSpeed={uploadSpeed}
                narrow={isActionPanelOpened}
            />
        ));
    };

    private scrollToStart = () => {
        const { setHighlightProblemFiles } = this.props;

        if (this.errorsList?.current) {
            this.errorsList.current.scrollTop = 0;

            setHighlightProblemFiles();
        }
    };

    render() {
        const {
            showUploader,
            showUploadButton,
            percentLoaded,
            progressStatus,
            hasWarningFiles,
            groupedByFolderInputFiles,
            informers,
            allowUpload,
            countStatistic,
            remainTime,
            hasNotAllowedExtensions,
            isActionPanelOpened,
        } = this.props;
        const { toggleExpand, showCancelAlert } = this.state;
        const hasFiles = groupedByFolderInputFiles.length;

        if (!showUploader) {
            return null;
        }

        return (
            <div
                className={classNames(styles.root, {
                    [styles.responsive]: ENABLE_FULL_RESPONSIVE,
                    [styles.shifted_by_panel]: isActionPanelOpened,
                })}
            >
                {showCancelAlert && <AlertComponent onClose={this.onCloseAlert} onActionClick={this.doActionClick} />}
                <Header
                    onExpand={this.onExpand}
                    toggleExpand={toggleExpand}
                    percentLoaded={percentLoaded}
                    countStatistic={countStatistic}
                    warning={hasWarningFiles}
                    progressStatus={progressStatus}
                    remainTime={remainTime}
                    onClose={this.onClose}
                    onUpload={this.onUpload}
                    scrollToStart={this.scrollToStart}
                    showUploadButton={showUploadButton && allowUpload}
                    narrow={isActionPanelOpened}
                />
                {hasWarningFiles && <PopupWarningsContainer />}
                {informers.length > 0 && <ProblemsContainer informers={informers} />}
                {hasNotAllowedExtensions && <NotDocumentWarning />}
                <div
                    ref={this.errorsList}
                    className={classNames({
                        [styles.files]: true,
                        [styles.hide]: toggleExpand,
                        [styles.no_files]: !hasFiles,
                        [styles.less_height]: hasWarningFiles,
                    })}
                >
                    {this.getInputFiles()}
                </div>
            </div>
        );
    }
}

export const UploadListContainer = connect(mapStateToProps, mapDispatchToProps)(UploadListComponent);
