/* eslint-disable max-lines-per-function */
/* eslint-disable sonarjs/no-duplicate-string */
import { getFeature } from 'Cloud/Application/FeaturesEs6';
import { sendAgreement } from 'Cloud/Application/User';
import { xray } from 'lib/xray';
import { renderCreateDocumentByQueryActionDialog } from 'reactApp/components/CreateDocumentByLinkDialog/createDocumentByLinkDialog.helpers';
import { renderLicenseAgreementDialog } from 'reactApp/components/LicenseAgreement/licenseAgreement.toolkit';
import { IWelcomeAttachesRepeatingProps } from 'reactApp/components/WelcomeAttaches/WelcomeAttachesRepeating';
import {
    renderWelcomeAllDocumentsDialog,
    renderWelcomeAttachesDialogHelper,
    renderWelcomeDialogHelper,
    renderWelcomeDialogNewAttaches,
    renderWelcomeRepeatingAttachesDialogHelper,
} from 'reactApp/components/WelcomeDialog/welcomeDialogToolkit';
import { sendAuthDwhRequest } from 'reactApp/modules/dwh/dwh.module';
import { EAuthDwhAction } from 'reactApp/modules/dwh/dwh.types';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { chooseVariant } from 'reactApp/modules/features/features.helpers';
import { getFeatureAbMailAttachesAfterSave, getFeatureRequiredSignUpWhenDownloading } from 'reactApp/modules/features/features.selectors';
import { requiredAuthorizationHelpers } from 'reactApp/modules/requiredAuthorization/helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { showUploaderAction } from 'reactApp/modules/upload/upload.module';
import { acceptWelcomeLA } from 'reactApp/modules/welcome/welcome.module';
import { store as reduxStore } from 'reactApp/store';
import { sendXray } from 'reactApp/utils/ga';
import { noopVoid } from 'reactApp/utils/helpers';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

const showUploader = () => reduxStore.dispatch(showUploaderAction());

const groupAb = getFeature('ab-trial-popup');
// tempexp_15327-next-line
const allDocumentsWelcomeLicenceEnabled = getFeature('alldocuments-welcome-licence');

// tempexp_15327-next-line
const sendAllDocumentsWelcomeAnalytics = (type: 'show' | 'accept') => {
    xray.send(`alldocuments_welcome-licence_${type}`);
};

const sendFirstEnter = () =>
    sendPaymentGa({
        eventCategory: ECategoryGa.onboarding,
        action: 'first-enter',
        label: '',
        group: groupAb || 'None',
    });

let dialog;
export const renderWelcomeHelper = ({
    storage,
    onSuccess = noopVoid,
    publishMode = false,
    onClose = noopVoid,
    newDialog,
    source = 'none',
}: {
    storage: EStorageType;
    onSuccess?();
    onClose?();
    publishMode?: boolean;
    newDialog?: boolean;
    source?: string;
}) => {
    const state = reduxStore.getState();

    const isPhone = EnvironmentSelectors.isPhone();
    // tempexp_15947-next-line
    const isFeatureAbMailAttachesAfterSave = getFeatureAbMailAttachesAfterSave(state);

    // eslint-disable-next-line unicorn/consistent-function-scoping
    const onAccept = () => {
        onSuccess();
        sendFirstEnter();
        showUploader();
        renderCreateDocumentByQueryActionDialog();
        reduxStore.dispatch(acceptWelcomeLA());
    };

    // tempexp_15947-start
    if (storage === EStorageType.attaches && isFeatureAbMailAttachesAfterSave) {
        renderWelcomeDialogNewAttaches({
            onSuccess: () => {
                sendAgreement(source);
                onAccept();
                onClose();
                reduxStore.dispatch(
                    sendAuthDwhRequest({
                        action: EAuthDwhAction.licenseAccept,
                        source: 'attaches',
                    })
                );
            },
            onShow: () => {
                reduxStore.dispatch(
                    sendAuthDwhRequest({
                        action: EAuthDwhAction.licenseOpen,
                        source: 'attaches',
                    })
                );
            },
        });
        return;
    }
    // tempexp_15947-end

    // tempexp_15327-next-line
    if (storage === EStorageType.alldocuments && allDocumentsWelcomeLicenceEnabled) {
        renderWelcomeAllDocumentsDialog({
            onSuccess: () => {
                sendAgreement();
                onAccept();
                onClose();
                sendAllDocumentsWelcomeAnalytics('accept');
                reduxStore.dispatch(
                    sendAuthDwhRequest({
                        action: EAuthDwhAction.licenseAccept,
                        source: 'alldocuments',
                    })
                );
            },
            onShow: () => {
                sendAllDocumentsWelcomeAnalytics('show');
                reduxStore.dispatch(
                    sendAuthDwhRequest({
                        action: EAuthDwhAction.licenseOpen,
                        source: 'alldocuments',
                    })
                );
            },
        });
        return;
    }

    // tempexp_15946-next-line
    if (storage === EStorageType.attaches && !newDialog) {
        dialog = renderWelcomeAttachesDialogHelper({
            publishMode,
            onSuccess: () => {
                sendAgreement(isPhone ? 'save_attach_touch' : 'save_attach').done(() => {
                    sendXray('ui_welcome_start-mail');
                    if (dialog) {
                        dialog.close();
                        dialog = null;
                    }
                    onAccept();
                    reduxStore.dispatch(
                        sendAuthDwhRequest({
                            action: EAuthDwhAction.licenseAccept,
                            source: 'attaches',
                        })
                    );
                });
            },
            onShow: () =>
                reduxStore.dispatch(
                    sendAuthDwhRequest({
                        action: EAuthDwhAction.licenseOpen,
                        source: 'attaches',
                    })
                ),
        });

        return;
    }

    if (isPhone) {
        renderLicenseAgreementDialog({
            source: 'touch',
            closable: false,
            onSuccess: () => {
                onAccept();
                reduxStore.dispatch(
                    sendAuthDwhRequest({
                        action: EAuthDwhAction.licenseAccept,
                        source: 'none',
                    })
                );
            },
        });

        return;
    }

    const newWelcomDialog_tempexp_15946 = newDialog;
    /* tempexp_15344-next-line */
    const newWelcomDialog_tempexp_15344 = newWelcomDialog_tempexp_15946 || requiredAuthorizationHelpers.isSuccessAuthWhenDownload(state);

    const welcomeSource = storage === EStorageType.attaches ? 'welcome_attaches' : 'welcome_promo';

    // tempexp_15946-start
    if (
        /* tempexp_15344-next-line */
        chooseVariant(getFeatureRequiredSignUpWhenDownloading, {
            control: newWelcomDialog_tempexp_15946,
            variant1: newWelcomDialog_tempexp_15344,
        })()
    ) {
        renderWelcomeDialogNewAttaches({
            onSuccess: () => {
                sendAgreement(welcomeSource);
                onAccept();
                onClose();
                reduxStore.dispatch(
                    sendAuthDwhRequest({
                        action: EAuthDwhAction.licenseAccept,
                        source,
                    })
                );
            },
            onShow: () => {
                reduxStore.dispatch(
                    sendAuthDwhRequest({
                        action: EAuthDwhAction.licenseOpen,
                        source,
                    })
                );
            },
        });
    } else {
        // tempexp_15946-end
        renderWelcomeDialogHelper({
            onComplete: showUploader,
            // eslint-disable-next-line sonarjs/no-identical-functions
            onClose: () => {
                sendAgreement(welcomeSource);
                onAccept();
                onClose();
                reduxStore.dispatch(
                    sendAuthDwhRequest({
                        action: EAuthDwhAction.licenseAccept,
                        source,
                    })
                );
            },
            onShow: () =>
                reduxStore.dispatch(
                    sendAuthDwhRequest({
                        action: EAuthDwhAction.licenseOpen,
                        source,
                    })
                ),
        });
    }
};

export const isWelcomeDialogShown = (): boolean => !!dialog;

export interface WelcomeRepeatingHelperHandler {
    (data: { storage: EStorageType }): void;
}

const ATTACHES_REPEATING_RADAR = 'repeat_welcome_attaches';

export const renderWelcomeRepeatingHelper: WelcomeRepeatingHelperHandler = ({ storage }) => {
    if (storage !== EStorageType.attaches || isWelcomeDialogShown()) {
        return;
    }
    const params: IWelcomeAttachesRepeatingProps = {
        onClose: () => {
            sendXray(ATTACHES_REPEATING_RADAR, {
                show: 1,
            });
            dialog = null;
        },
        onAccept: () => {
            sendXray(ATTACHES_REPEATING_RADAR, {
                accept: 1,
            });
            dialog?.close?.();
        },
        onShow: () => {
            sendXray(ATTACHES_REPEATING_RADAR, {
                show: 1,
            });
        },
    };
    dialog = renderWelcomeRepeatingAttachesDialogHelper(params);
};
