import classNames from 'clsx';
import React, { memo, useCallback, useRef, useState } from 'react';
import { Kind } from 'reactApp/types/Tree';
import { Avatar } from 'reactApp/ui/Avatar/Avatar';
import { logHandler } from 'reactApp/ui/Datalist/DataList.helpers';
import { IThumb } from 'reactApp/ui/DataListItem/DataListItem.helpers';
import { FileIcon } from 'reactApp/ui/FileIcon/FileIcon';
import { getIconType } from 'reactApp/ui/FileIcon/getIconType';
import { Hint } from 'reactApp/ui/Hint/Hint';
import { ImageCancelable } from 'reactApp/ui/ImageCancelable/ImageCancelable';

import styles from './FilePic.css';

interface PicProps {
    className?: string;
    isFolder?: boolean;
    thumbUrl?: IThumb;
    id?: string | undefined;
    ext?: string;
    isVirus?: boolean;
    isLarge?: boolean;
    author?: { name?: string; email: string };
    kind?: Kind;
    isAutoDelete?: boolean;
    isAuthorView?: boolean;
    publicExpires?: number;
    isPublic?: boolean;
    isDark?: boolean;
}

export const FilePic = memo<PicProps>(function FilePic({
    className,
    isFolder = false,
    kind,
    ext,
    isVirus,
    isAutoDelete,
    isAuthorView,
    author,
    thumbUrl,
    publicExpires,
    isPublic = false,
    isDark = false,
}) {
    const iconType = getIconType({
        isFolder,
        kind: isFolder ? kind : undefined,
        ext: isFolder ? undefined : ext,
        isVirus: isFolder ? false : isVirus,
        isAutoDelete: isFolder ? isAutoDelete : false,
        publicExpires,
        isPublic,
    });

    const [isLoaded, setLoaded] = useState<boolean>(false);

    const startTime = useRef(Date.now());

    const handleThumbLoad = useCallback(() => {
        setLoaded(true);

        logHandler({
            radarName: `list_load_success`,
            radars: [`all=${Date.now() - startTime.current}`],
        });

        if (kind) {
            logHandler({
                radarName: `list_load_success`,
                radars: [`${kind}=${Date.now() - startTime.current}`],
            });
        }
    }, [kind]);

    const handleThumbError = useCallback(() => {
        logHandler({
            radarName: `list_load_fail`,
            radars: [`all=${Date.now() - startTime.current}`],
        });

        if (kind) {
            logHandler({
                radarName: `list_load_fail`,
                radars: [`${kind}=${Date.now() - startTime.current}`],
            });
        }
    }, [kind]);

    if (isAuthorView && author) {
        const { email, name } = author;

        return (
            <div className={styles.root}>
                <Hint text={name || email}>
                    <Avatar size={26} email={email} name={name} />
                </Hint>
            </div>
        );
    }

    return (
        <div className={classNames(styles.root, className)}>
            {author && (
                <Hint text={author.name || author.email}>
                    <div className={styles.author} data-qa-id="author">
                        <Avatar size={20} email={author.email} name={author.name} />
                    </div>
                </Hint>
            )}
            <div className={styles.container}>
                {!isLoaded && (
                    <div className={classNames(styles.icon)}>
                        <FileIcon.New type={iconType} mode={isDark ? 'dark' : 'light'} />
                    </div>
                )}
                {!isFolder && thumbUrl?.src && (
                    <ImageCancelable
                        className={classNames(styles.thumb, {
                            [styles.thumb_loaded]: isLoaded,
                        })}
                        src={thumbUrl?.src}
                        srcSet={thumbUrl?.srcSet ? `${thumbUrl?.srcSet} 2x` : ''}
                        alt=""
                        onError={handleThumbError}
                        onLoad={handleThumbLoad}
                    />
                )}
            </div>
        </div>
    );
});
