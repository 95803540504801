import React, { memo } from 'react';
import { IS_B2B_BIZ_USER, IS_WEBVIEW, LOGO_SRC } from 'reactApp/appHelpers/configHelpers';

import { LogoComponent } from './LogoComponents';

export const Logo = memo<{ withEndorsment?: boolean; logoSrc?: string }>(function Logo({ withEndorsment = true, logoSrc }) {
    if (IS_WEBVIEW) {
        return null;
    }

    return <LogoComponent logoSrc={logoSrc || LOGO_SRC} withEndorsment={withEndorsment} isBizUser={IS_B2B_BIZ_USER} />;
});
