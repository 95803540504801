// @todo роутинг
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { historyPush } from 'reactApp/modules/router/router.module';
import { searchUpdateStartLocation } from 'reactApp/modules/search/search.module';
import { getSearchStartLocation } from 'reactApp/modules/search/search.selectors';
import { getIdByStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { RootState } from 'reactApp/store';

export const useFallbackSearchUrl = () => {
    const dispatch = useDispatch();

    let location = {
        pathname: getIdByStorage(EStorageType.home),
        search: '',
    };

    // для случаев рендера вне контекста react-router
    try {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        location = useLocation();
    } catch (err) {}

    const { pathname: id, search } = location;

    const searchStartLocation = useSelector((state: RootState) => getSearchStartLocation(state));

    const setStartLocation = useCallback(() => {
        dispatch(searchUpdateStartLocation({ id, search }));
    }, [dispatch, id, search]);

    const startSearch = useCallback(() => {
        dispatch(searchUpdateStartLocation({ id, search }));
        dispatch(historyPush({ id: getIdByStorage(EStorageType.search) }));
    }, [dispatch, id, search]);

    const closeSearch = useCallback(() => {
        dispatch(historyPush(searchStartLocation));
    }, [dispatch, searchStartLocation]);

    return { setStartLocation, startSearch, closeSearch };
};
