import { createAvatarUrl } from 'reactApp/utils/avatar';

import { IACListItem, IACListOwner } from './folderAccessControlList.types';

export const convertInvitedToACL = (invited): IACListItem[] => {
    if (!invited?.length) {
        return [];
    }

    return invited.map((item) => ({
        email: item.email,
        name: item.name || item.email,
        accessRights: item.access,
        avatarUrl: createAvatarUrl(item.email, item.name),
        status: item.status,
    }));
};

export const getOwner = (owner): IACListOwner => {
    const domain: string[] = owner.email.match(/[^@]+/g);
    return {
        email: owner.email,
        name: owner.name,
        avatarUrl: createAvatarUrl(owner.email, owner.name),
        domain: domain[1],
    };
};

const getError = (error) => {
    if (error?.home) {
        return error.home;
    }

    if (typeof error === 'string') {
        return { error };
    }

    return { error };
};

export const getSharingError = (shareError, isVerifiedAccount?: boolean): string => {
    const { status } = shareError;
    const error = getError(shareError.response.error || shareError.error);
    const errorType = error.error;

    if (status === 406) {
        return 'Нельзя добавить этого пользователя';
    }

    if (status === 451) {
        return 'Невозможно создать ссылку. Публикация контента заблокирована по требованию правообладателя или уполномоченного государственного ведомства. Если у вас есть вопросы, пожалуйста, обратитесь в <a href="https://help.mail.ru/cloud_web" targe="_blank">службу поддержки</a>.';
    }

    if (errorType === 'read_only') {
        return 'Функциональность недоступна';
    }

    if (errorType?.error === 'user_limit_exceeded') {
        const limit = errorType.limit || 200;
        return `Невозможно добавить пользователя. Вы можете иметь не более ${limit} пользователей в одной общей папке`;
    }

    if (errorType?.error === 'export_limit_exceeded') {
        const limit = errorType.limit || 50;
        return `Невозможно добавить пользователя. Вы можете создать не более ${limit} общих папок`;
    }

    if (errorType?.error === 'invite_limit_exceeded') {
        const limit = errorType.limit || 25;
        const verifiedPart = isVerifiedAccount
            ? ''
            : '. Чтобы увеличить этот лимит, <a href="https://account.mail.ru/user/phones/bind" target="_blank">привяжите свой номер телефона</a>';
        return `Невозможно добавить пользователя. Вы можете иметь не более ${limit} приглашений в одной общей папке${verifiedPart}`;
    }

    if (errorType['invite.email']?.error === 'owner') {
        return 'Нельзя использовать собственный email';
    }

    if (errorType === 'unprocessable_entry') {
        return 'Нельзя открыть доступ к файлу';
    }

    if (errorType === 'not_exists') {
        return 'Папка отсутствует';
    }

    if (errorType === 'trees_conflict') {
        return error.value === '/'
            ? 'Нельзя открыть доступ к корню'
            : 'Нельзя сделать папку общей, если она содержит другие общие папки или находится в общей папке';
    }

    if (errorType === 'CONDITION/ACCESSDENIED') {
        return 'Делиться папками с пользователями сторонних доменов запрещено администратором';
    }

    return 'Неправильная папка';
};
