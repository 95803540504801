import { isFeature } from 'Cloud/Application/FeaturesEs6';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { DISK_O_PROMO_ID } from 'reactApp/modules/promo/sagas/diskOAsidePromoModal';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserStorageActions } from 'reactApp/modules/user/userStorage';
import { EDiskOPromoAnalytics, sendDiskOPromoAnalytics } from 'reactApp/utils/diskOPromoGa';

const TREE_PROMO_DISK_O_CLOSED_KEY = 'treePromoDiskOClosed';
const TIMEOUT = 3600 * 24 * 7 * 1000; // Семь дней.
const diskOTreePromoEnabled = isFeature('disko-tree-promo');

export const useDiskOTreePromo = () => {
    const dispatch = useDispatch();
    const isClosed = dispatch(UserStorageActions.get(TREE_PROMO_DISK_O_CLOSED_KEY));
    const storage = useSelector(getCurrentStorage);
    const asideModalClosedTimestamp = storeHelper.getLastTime(DISK_O_PROMO_ID);

    // Показываем промку если еще не была закрыта или если прошло больше 1 недели с закрытия попапа asidePromoModal и если включена фича.
    const showDiskOTreePromo =
        storage !== EStorageType.integration &&
        storage !== EStorageType.family &&
        diskOTreePromoEnabled &&
        !isClosed &&
        asideModalClosedTimestamp &&
        Date.now() >= Number(asideModalClosedTimestamp) + TIMEOUT;

    const handleDiskOTreePromoShow = useCallback(() => {
        sendDiskOPromoAnalytics(EDiskOPromoAnalytics['show-upgrade']);
    }, []);

    const handleDiskOTreePromoClick = useCallback(() => {
        sendDiskOPromoAnalytics(EDiskOPromoAnalytics['click-upgrade']);
        dispatch(UserStorageActions.set(TREE_PROMO_DISK_O_CLOSED_KEY, true));
    }, [dispatch]);

    const handleDiskOTreePromoClose = useCallback(() => {
        sendDiskOPromoAnalytics(EDiskOPromoAnalytics['cancel-upgrade']);
        dispatch(UserStorageActions.set(TREE_PROMO_DISK_O_CLOSED_KEY, true));
    }, [dispatch]);

    return {
        showDiskOTreePromo,
        handleDiskOTreePromoShow,
        handleDiskOTreePromoClick,
        handleDiskOTreePromoClose,
    };
};
