import { createAction } from '@reduxjs/toolkit';
import { ILoadMoreRequestData, WeblinkDownloadsCounts } from 'reactApp/modules/public/public.types';
import type { IPublicApiFolder } from 'server/types/context/IPublicFolder';

export const loadPublicPage = createAction<IPublicApiFolder>('public/load/page');
export const loadPublicFileSuccess = createAction<IPublicApiFolder>('public/load/loadFileSuccess');
export const loadPublicFolder = createAction<IPublicApiFolder>('public/load/folder');
export const loadMorePublicFolderRequest = createAction<ILoadMoreRequestData>('public/loadMore/request');
export const loadMoreToFileRequest = createAction<{ folderId: string; fileId: string }>('public/loadMoreToFile/request');
export const loadPublicFolderFail = createAction<{ id: string; error: string }>('public/load/folderFail');
export const loadMorePublicFolderSuccess = createAction<IPublicApiFolder>('public/loadMore/success');
export const loadFolderStart = createAction<{ id: string }>('public/load/loadFolderStart');

export const forceShowDownloadAppButton = createAction('public/show/forceDownloadAppButton');
export const getPublicInfo = createAction('public/get/info');
export const updateCountDownloads = createAction<WeblinkDownloadsCounts>('public/updateCountDownloads');
