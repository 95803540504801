import { IS_REACT_PROMO_QUOTA_TARIFFS_PAGE } from 'reactApp/appHelpers/configHelpers';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

const COMMON_DATA = {
    eventCategory: IS_REACT_PROMO_QUOTA_TARIFFS_PAGE ? ECategoryGa.quotaTariffs : ECategoryGa.payment,
} as const;

export const SUBSCRIPTION_ANALYTICS = {
    FAQ_CANCEL_SUBS_OPEN: {
        ...COMMON_DATA,
        action: 'faq-cancel-subs-open',
    },
    MY_SUBS_OPEN: {
        ...COMMON_DATA,
        action: 'my-subs-open',
    },
    MY_TARIFF_OPEN: {
        ...COMMON_DATA,
        action: 'my-tariff-open',
    },
    MY_TARIFF_ADD_SPACE: {
        ...COMMON_DATA,
        action: 'my-tariff-add-space',
    },
    MY_TARIFF_CANCEL_AUTOPAYMENT: {
        ...COMMON_DATA,
        action: 'my-tariff-cancel-autopay',
    },
    MY_TARIFF_TURNON_AUTOPAYMENT: {
        ...COMMON_DATA,
        action: 'my-tariff-turnon-autopay',
    },
    MY_TARIFF_CHANGE_CARD: {
        ...COMMON_DATA,
        action: 'my-tariff-change-card',
    },
    MY_TARIFF_PARTNER_CLICK: {
        ...COMMON_DATA,
        action: 'my-tariff-partner-click',
    },
    MY_TARIFF_CANCEL_STEP0_OPEN: {
        ...COMMON_DATA,
        action: 'my-tariff-cancel-step0-open',
    },
    MY_TARIFF_CANCEL_STEP0_PROLONG: {
        ...COMMON_DATA,
        action: 'my-tariff-cancel-step0-prolong',
    },
    MY_TARIFF_CANCEL_STEP0_CANCEL: {
        ...COMMON_DATA,
        action: 'my-tariff-cancel-step0-cancel',
    },
    MY_TARIFF_CANCEL_STEP1_OPEN: {
        ...COMMON_DATA,
        action: 'my-tariff-cancel-step1-open',
    },
    MY_TARIFF_CANCEL_STEP1_GRACE_START: {
        ...COMMON_DATA,
        action: 'my-tariff-cancel-stp1-grace-strt',
    },
    MY_TARIFF_CANCEL_STEP1_CANCEL: {
        ...COMMON_DATA,
        action: 'my-tariff-cancel-step1-cancel',
    },
    MY_TARIFF_CANCEL_STEP2_OPEN: {
        ...COMMON_DATA,
        action: 'my-tariff-cancel-step2-open',
    },
    MY_TARIFF_CANCEL_STEP2_SAFE_SUBS: {
        ...COMMON_DATA,
        action: 'my-tariff-cancel-step2-safe-subs',
    },
    MY_TARIFF_CANCEL_STEP2_CANCEL: {
        ...COMMON_DATA,
        action: 'my-tariff-cancel-step2-cancel',
    },
    MY_TARIFF_CANCEL_STEP3_OPEN: {
        ...COMMON_DATA,
        action: 'my-tariff-cancel-step3-open',
    },
    MY_TARIFF_CANCEL_STEP3_DISCOUNT_CLICK: {
        ...COMMON_DATA,
        action: 'my-tariff-cancel-step3-dsk-clck',
    },
    POLL_OPEN: {
        ...COMMON_DATA,
        action: 'poll-open',
    },
    POLL_SEND: {
        ...COMMON_DATA,
        action: 'poll-send',
    },
    CANCEL_AUTOPAY_RESTORE: {
        ...COMMON_DATA,
        action: 'my-tariff-cancel-autopay-restore',
    },
} as const;
