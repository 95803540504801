import { Space } from '@mail/cross-sizes-utils';
import { ReactComponent as CloseIcon } from '@paradigm/icons/src/mailru/actions/close.svg';
import { ReactComponent as DoneIcon } from '@paradigm/icons/src/mailru/status/done.svg';
import theme from '@paradigm/tokens/cloud';
import classNames from 'clsx';
import { ReactComponent as MarkIcon } from 'img/icons/mark2.svg';
import React, { PureComponent, ReactElement } from 'react';
import { features, IFeatureData } from 'reactApp/constants/products/features';
import { noop } from 'reactApp/utils/helpers';

import styles from './FeaturesList.css';

interface IProps {
    space: Space;
    paidFeatures?: boolean;
    upload: boolean;
    professional: boolean;
    professionalNew: boolean;
    professionalShort: boolean;
    paidWithIcon: boolean;
    isAction: boolean;
    itemClassName: string;
    showIcon: boolean;
    large: boolean;
    isYear: boolean;
    modificator: string;
    customItems: IFeatureData[] | null;
    plusGifts: string;
    base: boolean;
    plusSpace: boolean;
    disko: boolean;
    isPromoPro: boolean;
    isCorpUser: boolean;
    modalPro: boolean;
    autodelete: boolean;
    isOverquota: boolean;
    isQuotaTariffsLanding?: boolean;
    isQuotaTariffsLandingBase?: boolean;
    // Для АБ теста, потом выпилить https://jira.vk.team/browse/CLOUDWEB-13305
    isOverquotaB?: boolean;
    partner?: string;
    isSingleQuota?: boolean;
    isSingleQuotaPro?: boolean;
    isSingleQuotaFamily?: boolean;
    partnerUpload?: boolean;
}

export class FeaturesList extends PureComponent<Partial<IProps>> {
    static defaultProps = {
        isYear: true,
    };

    renderItem(item, html = false) {
        const { showIcon, large, itemClassName = '' } = this.props;

        return (
            <li
                key={item.key}
                className={classNames({
                    [styles.item]: true,
                    [styles.item_large]: large,
                    [itemClassName]: !!itemClassName,
                })}
            >
                {showIcon && item.icon && <div className={styles.icon}>{item.icon}</div>}
                {html ? <span dangerouslySetInnerHTML={{ __html: item.text }} /> : item.text}
            </li>
        );
    }

    // eslint-disable-next-line max-lines-per-function
    render() {
        const {
            space,
            paidFeatures,
            upload,
            professional,
            professionalNew,
            professionalShort,
            showIcon,
            large,
            modificator,
            isYear,
            customItems,
            paidWithIcon,
            plusGifts,
            base,
            isAction,
            plusSpace,
            disko,
            isPromoPro,
            isCorpUser,
            modalPro,
            autodelete,
            isOverquota,
            isOverquotaB,
            itemClassName = '',
            isQuotaTariffsLanding,
            isQuotaTariffsLandingBase,
            partner,
            isSingleQuota,
            isSingleQuotaPro,
            partnerUpload,
            isSingleQuotaFamily,
        } = this.props;

        const icon = (
            <div className={styles.icon}>
                <MarkIcon width={12} height={11} />
            </div>
        );

        const items: ReactElement[] = [];

        if (!!space && !!space.original) {
            items.push(
                <li
                    key="space"
                    className={classNames({
                        [styles.item]: true,
                        [styles.item_large]: large,
                        [itemClassName]: !!itemClassName,
                    })}
                >
                    {showIcon && icon}
                    {plusSpace
                        ? `+${space.value} в ${isSingleQuotaPro || isSingleQuota ? 'едином хранилище' : 'Облаке'}`
                        : `Увеличение объема Облака на ${space.value}`}
                </li>
            );
        }

        if (paidFeatures && !professionalNew) {
            (professional ? features.noadsPro : features.noads).map((item) =>
                items.push(
                    <li
                        key={item.key}
                        className={classNames({
                            [styles.item]: true,
                            [styles.item_large]: large,
                            [itemClassName]: !!itemClassName,
                        })}
                    >
                        {showIcon && <div className={styles.icon}>{item.icon}</div>}
                        <span dangerouslySetInnerHTML={{ __html: item.text }} />
                    </li>
                )
            );
        }

        if (base && !partnerUpload) {
            (isCorpUser ? features.corpUpload : features.baseUpload).map((item) => items.push(this.renderItem(item, true)));
        }

        if (upload && !partnerUpload) {
            features.upload.map((item) => items.push(this.renderItem(item, true)));
        }

        if (partnerUpload && partner) {
            features[partner].map((item) => items.push(this.renderItem(item, true)));
        }

        if (autodelete) {
            features.autodelete.map((item) => items.push(this.renderItem(item, true)));
        }

        if (paidFeatures && !partner) {
            features.paidFeatures.map((item) => items.push(this.renderItem(item, false)));
        }

        if (disko) {
            features.disko.map((item) => items.push(this.renderItem(item, true)));
        }

        if (isSingleQuota && !isSingleQuotaFamily) {
            features.singleQuota.map((item) => items.push(this.renderItem(item, true)));
        }

        if (isSingleQuotaFamily) {
            features.singleQuotaFamily.map((item) => items.push(this.renderItem(item, true)));
        }

        if (isSingleQuotaPro) {
            features.singleQuotaPro.map((item) => items.push(this.renderItem(item, true)));
        }

        if (modalPro) {
            features.modalPro
                .filter((feature) => (isPromoPro ? feature.key !== 'disko' && feature.key !== 'discount' : true))
                .map((item) => items.push(this.renderItem(item, true)));
        }

        if (plusGifts === 'plusGiftsOpen' || plusGifts === 'plusGiftsClose') {
            features[plusGifts === 'plusGiftsOpen' ? 'giftsOpen' : 'giftsClose'].map((item) =>
                items.push(
                    <li
                        key={item.key}
                        className={classNames({
                            [styles.item]: true,
                            [styles.item_large]: large,
                            [itemClassName]: !!itemClassName,
                        })}
                    >
                        <div>
                            {item.text}
                            {showIcon && <div>{item.icon}</div>}
                        </div>
                    </li>
                )
            );
        }

        if (paidWithIcon) {
            features.paidWithIcon.map((item) =>
                items.push(
                    <li
                        key={item.key}
                        className={classNames({
                            [styles.item]: true,
                            [styles.item_large]: large,
                            [itemClassName]: !!itemClassName,
                        })}
                    >
                        <div className={classNames(styles.icon, styles.icon_paid)}>{item.icon}</div>
                        <span dangerouslySetInnerHTML={{ __html: item.text }} />
                    </li>
                )
            );
        }

        const profCode = professionalNew ? 'professional_new' : 'professional';

        if (professional) {
            (isYear ? features[profCode] : features[`${profCode}6M`])
                .filter((item, idx) => !professionalShort || idx < 4)
                .map((item) =>
                    items.push(
                        <li
                            key={item.key}
                            className={classNames({
                                [styles.item]: true,
                                [styles.item_large]: large,
                                [itemClassName]: !!itemClassName,
                                [styles.item_delimiters]: Boolean(item.delimiters),
                            })}
                        >
                            {showIcon && <div className={classNames(styles.icon, styles.icon_pro)}>{item.icon}</div>}
                            <span
                                dangerouslySetInnerHTML={{ __html: item.text }}
                                onClick={item.onClick || noop}
                                className={classNames({ [styles.pointerText]: !!item.onClick })}
                            />
                        </li>
                    )
                );
        }

        if (Array.isArray(customItems)) {
            customItems.map((item) =>
                items.push(
                    <li
                        key={item.key}
                        className={classNames({
                            [styles.item]: true,
                            [styles.item_large]: large,
                        })}
                    >
                        {showIcon && <div className={classNames(styles.icon, styles.icon_pro)}>{item.icon}</div>}
                        <span dangerouslySetInnerHTML={{ __html: item.text }} />
                    </li>
                )
            );
        }

        if (isAction) {
            features.action
                .filter((item, idx) => !professionalShort || idx < 2)
                .map((item) =>
                    items.push(
                        <li
                            key={item.key}
                            className={classNames({
                                [styles.item]: true,
                                [styles.item_large]: large,
                                [itemClassName]: !!itemClassName,
                            })}
                        >
                            {showIcon && <div className={classNames(styles.icon, styles.icon_action)}>{item.icon}</div>}
                            <span
                                dangerouslySetInnerHTML={{ __html: item.text }}
                                onClick={item.onClick || noop}
                                className={classNames({ [styles.pointerText]: !!item.onClick })}
                            />
                        </li>
                    )
                );
        }

        if (isQuotaTariffsLanding || isOverquota) {
            items.length = 0;
            const featuresList = isQuotaTariffsLanding ? features.quotaTariffsLanding : features.overquota;

            let icon;
            if (isQuotaTariffsLanding) {
                icon = isQuotaTariffsLandingBase ? (
                    <CloseIcon fill={theme.colorIconSecondary.normal} />
                ) : (
                    <DoneIcon fill={theme.colorPrimary.normal} />
                );
            }

            featuresList.forEach((item) => {
                if (item.key === 'autodelete' && isOverquotaB && !isQuotaTariffsLanding) {
                    return;
                }

                items.push(
                    <li
                        key={item.key}
                        className={classNames({
                            [styles.item]: true,
                            [styles.item_large]: large,
                            [itemClassName]: !!itemClassName,
                        })}
                    >
                        <div className={classNames(styles.icon, styles.icon_action)}>{icon || item.icon}</div>
                        <span dangerouslySetInnerHTML={{ __html: item.text }} />
                    </li>
                );
            });
        }

        if (!items.length) {
            return null;
        }

        return (
            <ul
                className={classNames({
                    [styles.root]: true,
                    [styles.root_icon]: showIcon,
                    [styles[`root_${modificator}`]]: !!modificator,
                })}
            >
                {items}
            </ul>
        );
    }
}
