import { groupBy } from 'ramda';
import { IS_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { getAllDocumentsCurrentType, getExtensionsForType } from 'reactApp/modules/allDocuments/allDocuments.selectors';
import { EAllDocumentsType } from 'reactApp/modules/allDocuments/allDocuments.types';
import { loadHomeFolderRequest } from 'reactApp/modules/home/home.actions';
import { getHomeItemById } from 'reactApp/modules/home/home.selectors';
import { addFilesSuccess, updateItem } from 'reactApp/modules/modifying/modifying.actions';
import { getPublicItemById } from 'reactApp/modules/public/public.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { isMountedFolder } from 'reactApp/modules/storage/folder.helpers';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { getCurrentFolder } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { IAddFileToStore } from 'reactApp/modules/uploading/helpers/cloudFs/cloudFs.types';
import { UploadingDescriptor } from 'reactApp/modules/uploading/serviceClasses/UploadingDescriptor';
import { getInputFile } from 'reactApp/modules/uploadList/uploadList.selectors';
import { FileType } from 'reactApp/types/FileType';
import { put, select } from 'redux-saga/effects';

export function* addItemsToStore({
    files,
    email,
    currentWorkingDir = '',
    skipItemStoreUpdate = false,
}: {
    files: (UploadingDescriptor | IAddFileToStore)[];
    currentWorkingDir?: string;
    email: string;
    skipItemStoreUpdate?: boolean;
}) {
    const items: any = [];

    for (const file of files) {
        const storage = 'storage' in file ? file.storage : file.uploadingPacketConfig.storage;
        const { isPublic, isHome, isReactPage, isStock } = getStorage(storage);

        if (skipItemStoreUpdate && (!isHome || !!file.errorFile)) {
            continue;
        }

        if (!currentWorkingDir) {
            currentWorkingDir =
                'uploadingPacketConfig' in file ? file.uploadingPacketConfig?.workingDirectory : file.workingDirectory ?? '';
        }

        const cloudPath = file.cloudPath;
        const pathParts = cloudPath.split('/'); // → ['', 'folder1', 'folder2', 'filename.ext']

        let parent = pathParts[0];
        for (let i = 1; i < pathParts.length; i++) {
            const name = pathParts[i];
            const path = `${parent}/${name}`;
            const cloudItem = isPublic ? yield select(getPublicItemById, path) : yield select(getHomeItemById, path);
            const isFolder = path !== cloudPath || ('isDirectory' in file && file.isDirectory);
            const sizeCheck = file.size && file.size > 0 ? file.size : 0;
            const fakeCloudItem = {
                id: path,
                descriptorId: file.id,
                weblink: isPublic ? path : null,
                home: isPublic ? '' : path,
                name,
                nameWithoutExt: '',
                kind: 'file',
                hash: file.cloudHash,
                size: sizeCheck,
                mtime: Math.floor(Date.now() / 1000),
                virus_scan: 'not_yet',
                type: 'file',
                parent: '',
                attr: isPublic
                    ? {
                          actor: email,
                      }
                    : undefined,
                count: {
                    folders: 0,
                    files: 0,
                },
            };

            if (path === currentWorkingDir) {
                parent = path;
                continue;
            }

            if (cloudItem) {
                if (!skipItemStoreUpdate) {
                    const cloudItemSize = sizeCheck && sizeCheck > 0 ? sizeCheck : cloudItem?.size;
                    const cloudItemCopy = {
                        ...cloudItem,
                        size: cloudItemSize,
                        mtime: Math.floor(Date.now() / 1000),
                    }; // cloudItem readonly object

                    yield put(updateItem(cloudItemCopy));
                }

                parent = path;
                continue;
            }

            if (!parent) {
                parent = '/';
            }

            if (!isFolder) {
                if (isReactPage || isPublic || isStock) {
                    items.push({
                        parent,
                        storage,
                        items: [
                            {
                                ...fakeCloudItem,
                                name: fakeCloudItem.name || fakeCloudItem.nameWithoutExt,
                                kind: 'file',
                            },
                        ],
                    });
                }
            } else {
                fakeCloudItem.parent = parent;
                fakeCloudItem.kind = 'folder';
                fakeCloudItem.type = 'folder';
                fakeCloudItem.size = 0;
                fakeCloudItem.count = {
                    folders: 0,
                    files: 0,
                };
                // @ts-ignore
                delete fakeCloudItem.hash;
                // @ts-ignore
                delete fakeCloudItem.virus_scan;
                delete fakeCloudItem.attr;

                if (isReactPage || isPublic || isStock) {
                    items.push({
                        items: [
                            {
                                ...fakeCloudItem, /// ??? bug: folder has the same descriptorId as its first file
                                kind: 'folder',
                                name: fakeCloudItem.name || fakeCloudItem.id.split('/').pop(),
                                count: {
                                    folders: 0,
                                    files: 0,
                                },
                            },
                        ],
                        parent,
                    });
                }
            }

            parent = path;
        }
    }

    if (!items) {
        return;
    }

    // @ts-ignore
    const list = groupBy((item) => item.parent, items);
    const currentStorage: EStorageType = yield select(getCurrentStorage);
    for (const parent of Object.keys(list)) {
        const group = list[parent];
        const inputItems = [];
        const namesHashTable = {};
        let extensions: undefined | string[];

        if (currentStorage === EStorageType.alldocuments) {
            const documentType = yield select(getAllDocumentsCurrentType) || EAllDocumentsType.document;
            extensions = yield select(getExtensionsForType, documentType);
        }

        for (const itemData of group) {
            // @ts-ignore
            for (const item of itemData.items) {
                if (!namesHashTable[item.name]) {
                    // Не даем добавлять папку, при попытке загрузки в readonly папку в хомяке
                    const destinationFolder = yield select(getHomeItemById, item.parent);
                    if (
                        item.parent !== ROOT_FOLDER_ID &&
                        currentStorage === EStorageType.home &&
                        destinationFolder?.isReadOnly &&
                        item.type === FileType.FOLDER &&
                        IS_BIZ_USER
                    ) {
                        continue;
                    }

                    const currentItem = yield select(getInputFile, {
                        cloudPath: item.home,
                        descriptorId: item.descriptorId,
                    });

                    // Изза батчинга addFilesSuccess может прилететь после выставления статуса ошибки и файл будет добавлен,
                    // хотя с ошибками мы должны скрывать. Потому дополнительно проверим, не появился ли у него статус ошибки.
                    if (!currentItem || currentItem.status !== 'error') {
                        // @ts-ignore
                        inputItems.push(item);
                        namesHashTable[item.name] = true;
                    }
                }
            }
        }

        yield put(
            addFilesSuccess({
                items: inputItems,
                // @ts-ignore
                storage: group[0].storage,
                // @ts-ignore
                parent: group[0].parent,
                allowedExt: extensions,
            })
        );
    }

    const currentFolder = yield select(getCurrentFolder);

    if (isMountedFolder(currentFolder)) {
        yield put(loadHomeFolderRequest({ id: currentFolder.home, isFolder: true, force: true }));
    }
}
