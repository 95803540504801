import React from 'react';
import { ReactViewerEmbedded } from 'reactApp/ui/ReactViewer/ReactViewerEmbedded';
import { noopVoid } from 'reactApp/utils/helpers';

const XRAY_CONFIG = {
    defaultParams: { p: 'vk_file_viewer' },
};

export const EmbeddedPage = () => {
    return (
        <ReactViewerEmbedded
            deleteFile={noopVoid}
            file={null}
            isActionOpen={false}
            isAuthorized={false}
            isMountedFolder={false}
            isPublicCopyEditable={false}
            itemCount={1}
            onClose={noopVoid}
            publishItemAction={noopVoid}
            selectItemIndexInArray={noopVoid}
            showEditorHeader={false}
            showSnackbar={noopVoid}
            showViewerHeader={false}
            toggleFavorite={noopVoid}
            sendSearchRadar={noopVoid}
            xrayConfig={XRAY_CONFIG}
            embedded
            isViewerFileVerifiedByKaspersky={false}
        />
    );
};
