import React, { memo, ReactElement, useRef } from 'react';
import { useSelector } from 'react-redux';
import { IProps } from 'reactApp/components/SharingWindow/Sharing.types';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { IPropsWithPopup } from 'reactApp/modules/popup/popup.helpers';
import Content from 'reactApp/ui/Content/Content';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';

import { SharingContent } from '../SharingContent/SharingContent';
import styles from './SharingNew.css';

export const SharingNewDesktop = memo(function SharingNewDesktop(props: IPropsWithPopup<IProps>): ReactElement {
    return (
        <Dialog open id="sharing-new" onCancel={props.onClose} className={styles.modal}>
            <Content isModal>
                <SharingContent {...props} />
            </Content>
        </Dialog>
    );
});

export const SharingNewPhone = memo(function SharingNewPhone(props: IPropsWithPopup<IProps>): ReactElement {
    const scrollRef = useRef(null);

    return (
        <MobileDialog id="sharing-new" closeOnDimmerClick mod="base" topmost onClose={props.onClose} scrollRef={scrollRef}>
            <SharingContent {...props} isPhone ref={scrollRef} />
        </MobileDialog>
    );
});

export const SharingNew = memo(function SharingNew(props: IPropsWithPopup<IProps>): ReactElement {
    const isPhone = useSelector(EnvironmentSelectors.isPhone);

    return isPhone ? <SharingNewPhone {...props} /> : <SharingNewDesktop {...props} />;
});
