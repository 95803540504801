import React, { memo, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { IS_MY_TEAM } from 'reactApp/appHelpers/configHelpers';
import { PublicItem } from 'reactApp/modules/public/public.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { StockItem } from 'reactApp/modules/stock/stock.type';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { getStorageItemById, isRootCurrentFolder } from 'reactApp/modules/storage/storage.selectors';
import { RootState } from 'reactApp/store';
import { ExpiresInfo } from 'reactApp/ui/Mobile/ExpiresInfo/ExpiresInfo';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';
import { useItems } from 'reactApp/ui/Mobile/OptionsModal/hooks/useItems';
import { ItemInfoContainer as ItemInfo } from 'reactApp/ui/Mobile/OptionsModal/ItemInfo/ItemInfoContainer';
import { OptionsBlock } from 'reactApp/ui/Mobile/OptionsModal/OptionsBlock/OptionsBlock';
import { Props } from 'reactApp/ui/Mobile/OptionsModal/OptionsModal.types';
import { UploadInfo } from 'reactApp/ui/Mobile/UploadInfo/UploadInfo';
import { UserBlock } from 'reactApp/ui/Mobile/UserBlock/UserBlock';

import styles from './OptionsModal.css';

export const OptionsModal = memo<Props>(
    ({
        onlyActions = false,
        id,
        storage,
        onClose,
        sendAnalytics,
        skipRename = false,
        skipDelete = false,
        isItemInfoHidden,
        optionHeaderText,
    }) => {
        const ref = useRef<HTMLDivElement>(null);
        const currentStorage = useSelector(getCurrentStorage);
        if (!storage) {
            storage = currentStorage;
        }
        const { isGallery, isQuotaCleaner } = getStorage(storage);
        const isRoot = useSelector((state: RootState) => isRootCurrentFolder(state, storage));
        const item = useSelector((state: RootState) => getStorageItemById(state, storage, id)) as PublicItem | StockItem;
        const showUserBlock = isRoot && !IS_MY_TEAM;

        const props = useMemo(() => {
            const isGalleryCategory = isGallery && (!item || !('isFolder' in item));

            const weblink = Boolean(item && 'weblink' in item && item.weblink);
            const { isFolder } = item || {};
            const subKind = !isFolder && item && 'subKind' in item && item.subKind;
            const isArchive = subKind && subKind === 'archive';

            return { isGalleryCategory, weblink, isFolder, isArchive };
        }, [item]);

        const itemsBlocks = useItems({
            ...props,
            id,
            storage,
            onlyActions,
            onClose,
            sendAnalytics,
            skipRename,
            skipDelete,
        });

        const content = useMemo(() => {
            if (onlyActions) {
                return (
                    <>
                        {itemsBlocks.map((menuItem) => (
                            <div className={styles.option} key={menuItem.id}>
                                <OptionsBlock id={menuItem.id} onClose={onClose} items={menuItem.items} />
                            </div>
                        ))}
                    </>
                );
            }

            return (
                <>
                    <div className={styles.expires}>
                        <ExpiresInfo colored />
                    </div>
                    <div className={styles.uploadInfo}>
                        <UploadInfo />
                    </div>

                    {itemsBlocks.map((menuItem) => (
                        <div className={styles.option} key={menuItem.id}>
                            <OptionsBlock
                                id={menuItem.id}
                                onClose={onClose}
                                header={menuItem.header}
                                items={menuItem.items}
                                headerComponent={menuItem.headerComponent}
                            />
                        </div>
                    ))}
                    {showUserBlock && <UserBlock onClose={onClose} />}
                </>
            );
        }, [itemsBlocks, onClose, onlyActions, showUserBlock]);

        return (
            <MobileDialog
                id="mobile-options-modal"
                onClose={onClose}
                closeOnDimmerClick
                mod="base"
                topmost
                scrollRef={ref}
                disableScrolling={!isQuotaCleaner}
            >
                <div className={styles.root}>
                    {item && !isItemInfoHidden && (
                        <div className={styles.itemInfo}>
                            <ItemInfo id={id} storage={storage} />
                        </div>
                    )}

                    {optionHeaderText && <div className={styles.header}>{optionHeaderText}</div>}

                    <div className={styles.content} ref={ref}>
                        {content}
                    </div>
                </div>
            </MobileDialog>
        );
    }
);

OptionsModal.displayName = 'OptionsModal';
