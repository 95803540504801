import { AlbumItem, IAlbumItem } from 'reactApp/modules/albums/albums.types';
import { AllDocumentsItem } from 'reactApp/modules/allDocuments/allDocuments.types';
import { AttachesItem } from 'reactApp/modules/attaches/attaches.types';
import { EditorItem } from 'reactApp/modules/editor/editor.types';
import { FavoriteFile, FavoriteItem } from 'reactApp/modules/favorites/favorites.types';
import { FeedItem } from 'reactApp/modules/feed/feed.types';
import { GalleryItem } from 'reactApp/modules/gallery/gallery.types';
import { HomeFile, HomeItem } from 'reactApp/modules/home/home.types';
import { IncomingFolder } from 'reactApp/modules/incoming/incoming.types';
import { DocumentsItem } from 'reactApp/modules/personalDocuments/personalDocuments.types';
import { PublicFile, PublicItem } from 'reactApp/modules/public/public.types';
import { SearchFile, SearchItem } from 'reactApp/modules/search/search.types';
import { SharedFile, SharedItem } from 'reactApp/modules/shared/shared.type';
import { SharedAutoDeleteFolder } from 'reactApp/modules/sharedAutoDelete/sharedAutoDelete.type';
import { StockFile, StockItem } from 'reactApp/modules/stock/stock.type';
import { IStoryFileItem } from 'reactApp/modules/stories/stories.types';
import { TrashbinItem } from 'reactApp/modules/trashbin/trashbin.types';
import { QuotaCleanerItem } from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.types';
import { Url } from 'reactApp/types/Tree';

export type CloudItem = {
    url?: Url;
    weblink?: string;
} & (
    | HomeItem
    | PublicItem
    | SharedItem
    | FavoriteItem
    | StockItem
    | SearchItem
    | IncomingFolder
    | AttachesItem
    | TrashbinItem
    | FeedItem
    | EditorItem
    | GalleryItem
    | SharedAutoDeleteFolder
    | DocumentsItem
    | IStoryFileItem
    | AlbumItem
    | QuotaCleanerItem
    | AllDocumentsItem
);

export type CloudFile =
    | HomeFile
    | PublicFile
    | SharedFile
    | FavoriteFile
    | StockFile
    | SearchFile
    | AttachesItem
    | FeedItem
    | EditorItem
    | GalleryItem
    | DocumentsItem
    | IAlbumItem
    | AllDocumentsItem;

export enum EStorageType {
    home = 'home',
    'public' = 'public',
    favorites = 'favorites',
    sharedIncoming = 'shared/incoming',
    sharedLinks = 'shared/links',
    sharedAutodelete = 'shared/autodelete',
    search = 'search',
    attaches = 'attaches',
    viewerAttaches = 'viewer/attaches',
    stock = 'stock',
    trashbin = 'trashbin',
    editor = 'editor',
    r7 = 'r-seven/edit/home',
    office = 'office/edit/home',
    recommend = 'recommend',
    feed = 'feed',
    start = 'start',
    gallery = 'gallery',
    documents = 'documents',
    archive = 'archive',
    myoffice = 'myoffice/edit/home',
    myofficeAttaches = 'myoffice/edit/attaches',
    story = 'story',
    albums = 'albums',
    subscriptions = 'subscriptions',
    quotaCleaner = 'promo/quota',
    quotaTariffs = 'promo/quota/tariffs',
    quotaPartner = 'promo/quota/partner',
    promocodes = 'promocodes',
    embedded = 'embedded',
    alldocuments = 'alldocuments',
    pdfEdit = 'pdf/edit',
    integration = 'integration',
    family = 'family',
    r7wopi = 'r7w/edit/home',
    safe = 'safe',
}

export enum EActions {
    copy = 'copy',
    move = 'move',
    remove = 'remove',
    rename = 'rename',
    history = 'history',
    addToFavorites = 'addToFavorites',
    removeFromFavorites = 'removeFromFavorites',
    publish = 'publish',
    unpublish = 'unpublish',
    share = 'share',
    shareLink = 'shareLink',
    openParentFolder = 'openParentFolder',
    download = 'download',
    add = 'add',
    upload = 'upload',
    uploadFolder = 'uploadFolder',
    uploadFile = 'uploadFile',
    clone = 'clone',
    view = 'view',
    sort = 'sort',
    restoreAll = 'restoreAll',
    restore = 'restore',
    clear = 'clear',
    sendViaMail = 'sendViaMail',
    mount = 'mount',
    selectAll = 'selectAll',
    authorSort = 'authorSort',
    favorites = 'favorites',
    create = 'create',
    forward = 'forward',
    more = 'more',
    viewThumbs = 'viewThumbs',
    viewList = 'viewList',
    viewGallery = 'viewGallery',
    sortName = 'sortName',
    sortSize = 'sortSize',
    sortDate = 'sortDate',
    createFolder = 'createFolder',
    createDoc = 'createDoc',
    createCell = 'createCell',
    createPpt = 'createPpt',
    back = 'back',
    facesFilter = 'facesFilter',
    publishAlbum = 'publishAlbum',
    addToAlbum = 'addToAlbum',
    yearFilter = 'yearFilter',
    newFeatures = 'newFeatures',
    showSelected = 'showSelected',
    fullscreen = 'fullscreen',
    options = 'options',
    bookmark = 'bookmark',
    delimiter = 'delimiter',
    createAlbum = 'createAlbum',
}

export interface IEnabledActions {
    [EActions.copy]: boolean;
    [EActions.move]: boolean;
    [EActions.remove]: boolean;
    [EActions.rename]: boolean;
    [EActions.history]: boolean;
    [EActions.favorites]: boolean;
    [EActions.publish]: boolean;
    [EActions.share]: boolean;
    [EActions.openParentFolder]: boolean;
    [EActions.download]: boolean;
    [EActions.add]: boolean;
    [EActions.view]: boolean;
    [EActions.sort]: boolean;
    [EActions.selectAll]: boolean;
    [EActions.mount]: boolean;
    [EActions.sendViaMail]: boolean;
    [EActions.facesFilter]: boolean;
    [EActions.publishAlbum]: boolean;
    [EActions.addToAlbum]: boolean;
    [EActions.createAlbum]: boolean;
}

export interface IStorageState {
    selectedItem: string;
}
