import classNames from 'clsx';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Switch } from 'reactApp/ui/Switch/Switch';

import styles from './SharingNewSection.css';
import { IProps } from './SharingNewSection.types';

const DEFAULT_HEIGHT = 500;

export const SharingNewSection = React.memo(function SharingNewSection({
    isOpened,
    className = '',
    noSwitch = false,
    title,
    onChange,
    name,
    children,
    isPhone,
}: IProps): ReactElement {
    const ref = useRef<HTMLDivElement | null>(null);
    const [maxHeight, setMaxHeight] = useState<number>(isOpened ? DEFAULT_HEIGHT : 0);

    useEffect(() => {
        const height = ref.current?.getBoundingClientRect().height || DEFAULT_HEIGHT;
        setMaxHeight(height);
    }, [children]);

    return (
        <div
            className={classNames({
                [styles.root]: true,
                [styles.root_opened]: isOpened,
                [styles.root_phone]: isPhone,
                [className]: Boolean(className),
            })}
            data-qa-section={name}
        >
            {!noSwitch && (
                <div className={styles.header} onClick={onChange}>
                    <span className={styles.title}>{title}</span>
                    <Switch name={name} checked={isOpened} theme="rounded" />
                </div>
            )}

            <div className={styles.content} style={{ maxHeight: `${isOpened ? maxHeight : 0}px` }}>
                <div ref={ref}>{children}</div>
            </div>
        </div>
    );
});
