import { bytesToNDigits } from '@mail/cross-sizes-utils';
import { xray } from 'lib/xray';
import { isNil } from 'ramda';
import React, { memo, ReactElement, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isQuotaCleanerMobLetterViewing, isQuotaCleanerMobViewing } from 'reactApp/appHelpers/featuresHelpers';
import { publishHelper } from 'reactApp/appHelpers/publishHelper';
import { isVirusItem } from 'reactApp/modules/file/utils';
import { changeHomeHistory, setDomainFoldersFilterActive } from 'reactApp/modules/home/home.actions';
import { getIntegrationQueryString } from 'reactApp/modules/integration/integration.selectors';
import { PublicItem } from 'reactApp/modules/public/public.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { toggle } from 'reactApp/modules/selections/selections.actions';
import { SelectionsSelectors } from 'reactApp/modules/selections/selections.selectors';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { StockItem } from 'reactApp/modules/stock/stock.type';
import { changeStorageHistory, getStorage } from 'reactApp/modules/storage/storage.helpers';
import { getStorageItemById } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { openUserQuotaItemView } from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.actions';
import { QuotaCleanerItem } from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.types';
import { openMobileViewer } from 'reactApp/modules/viewer/viewer.module';
import { sendQuotaCleanerGa } from 'reactApp/sections/QuotaLanding/QuotaLanding.helpers';
import { RootState } from 'reactApp/store';
import { UserQuotaGroupType } from 'reactApp/types/QuotaCleaner';
import { DatalistGalleryItem } from 'reactApp/ui/Mobile/Datalist/DatalistGalleryItem/DatalistGalleryItem';
import { getThumbUrl } from 'reactApp/ui/Mobile/Datalist/DatalistPic/DatalistPic.helpers';
import { DatalistRowItem } from 'reactApp/ui/Mobile/Datalist/DatalistRowItem/DatalistRowItem';
import { DatalistThumbItem } from 'reactApp/ui/Mobile/Datalist/DatalistThumbItem/DatalistThumbItem';
import { renderMobileOptionsModal } from 'reactApp/ui/Mobile/OptionsModal/OptionsModal.helpers';
import { scrollToTop } from 'reactApp/utils/helpers';

export const DatalistItem = memo(({ id }: { id: string }): ReactElement => {
    const dispatch = useDispatch();

    const storage = useSelector(getCurrentStorage);
    const { isTrashBin, isGallery, isSearch, isIntegration, isQuotaCleaner } = getStorage(storage);

    const integrationQueryString = useSelector(getIntegrationQueryString);

    const viewMode = useSelector((state) => SettingsSelectors.getViewByStorage(state, storage));

    const item = useSelector((state: RootState) => getStorageItemById(state, storage, id)) as PublicItem | StockItem | QuotaCleanerItem;

    const author = item.correspondents?.from?.[0];

    const { isFolder, kind } = item;

    const name = isFolder ? item.name : item.nameWithoutExt;

    const ext = isFolder ? undefined : item?.ext;
    const size = ('size' in item && !isNil(item.size) && bytesToNDigits(item.size, 3).value) || '';

    const selectedIdxs = useSelector(SelectionsSelectors.getSelectedIdxs);
    const isSelecting = useSelector(SelectionsSelectors.isSelecting);
    const isSelected = selectedIdxs.includes(id);

    const isVirus = isVirusItem(item);

    const thumbUrl = getThumbUrl({
        item,
        isVirus,
        viewMode,
    });

    const weblink = 'weblink' in item && item.weblink;

    const onSelect = useCallback(() => {
        dispatch(toggle({ selectedIdx: id, storage }));
    }, [dispatch, id, storage]);

    const onOptions = useCallback(
        (e) => {
            e.stopPropagation();
            renderMobileOptionsModal({ id, onlyActions: true });
        },
        [id]
    );

    const onShare = useCallback(
        (e) => {
            e.stopPropagation();
            publishHelper({ item });
        },
        [item]
    );

    const onQuotaCleanerItemViewOpen = useCallback(
        ({ id, groupId }: QuotaCleanerItem) => {
            dispatch(openUserQuotaItemView({ id, groupId, isMobile: true }));
        },
        [dispatch]
    );

    const onClick = useCallback(
        (e) => {
            const { groupType } = item as QuotaCleanerItem;
            if (isQuotaCleaner && isQuotaCleanerMobViewing && groupType === UserQuotaGroupType.Cloud) {
                sendQuotaCleanerGa({ action: 'cleaner-show-file' });
                xray.send('quota-cln-show-file-mob');
                dispatch(openMobileViewer({ id }));
                return;
            }

            if (isQuotaCleaner && isQuotaCleanerMobLetterViewing && groupType === UserQuotaGroupType.Letter) {
                sendQuotaCleanerGa({ action: 'cleaner-show-letter' });
                xray.send('quota-cln-show-letter-mob');
                onQuotaCleanerItemViewOpen(item as QuotaCleanerItem);
                return;
            }

            if (isSelecting) {
                onSelect();
                return;
            }

            if (isTrashBin) {
                onOptions(e);
                return;
            }

            if (isSearch && isFolder) {
                changeStorageHistory({
                    id,
                    storage: undefined,
                    navigateToStorage: EStorageType.home,
                });
                return;
            }

            if (!isGallery) {
                changeStorageHistory({ id, storage });
                dispatch(setDomainFoldersFilterActive(false));
            }

            if (!isFolder) {
                dispatch(openMobileViewer({ id }));
                return;
            }

            scrollToTop();
        },
        [dispatch, id, isFolder, isSelecting, isTrashBin, isGallery, onOptions, onSelect, storage, isQuotaCleaner, item]
    );

    const onQuotaCleanerSelect = useCallback(
        (e) => {
            if (isQuotaCleaner && (isQuotaCleanerMobViewing || isQuotaCleanerMobLetterViewing)) {
                e.stopPropagation();
                onSelect();
            }
        },
        [isQuotaCleaner, onSelect]
    );

    const onDoubleClick = () => {
        if (isIntegration) {
            // для storage === integration критически важно не терять гет параметры при навигации
            dispatch(changeHomeHistory({ id, search: isIntegration ? integrationQueryString : '' }));
            scrollToTop();
        }
    };

    const datalistRow = useMemo(() => {
        switch (viewMode) {
            case EViewMode.thumbs:
                return (
                    <DatalistThumbItem
                        isFolder={isFolder}
                        kind={kind}
                        ext={ext}
                        name={name}
                        isVirus={isVirus}
                        thumbUrl={thumbUrl}
                        weblink={weblink}
                        isSelecting={isSelecting}
                        isSelected={isSelected}
                        onClick={onClick}
                        onOptions={onOptions}
                        onShare={onShare}
                        id={id}
                        author={author}
                        onQuotaCleanerSelect={isQuotaCleaner ? onQuotaCleanerSelect : undefined}
                    />
                );
            case EViewMode.list:
                return (
                    <DatalistRowItem
                        isFolder={isFolder}
                        kind={kind}
                        ext={ext}
                        name={name}
                        isVirus={isVirus}
                        thumbUrl={thumbUrl}
                        weblink={weblink}
                        isSelecting={isSelecting}
                        isSelected={isSelected}
                        size={size}
                        onClick={onClick}
                        onDoubleClick={isFolder ? onDoubleClick : undefined}
                        onOptions={onOptions}
                        onShare={onShare}
                        id={id}
                        showOptionIcon={!isIntegration}
                        author={author}
                        onQuotaCleanerSelect={isQuotaCleaner ? onQuotaCleanerSelect : undefined}
                    />
                );
            case EViewMode.galleryGrid3:
                return (
                    <DatalistGalleryItem
                        isFolder={isFolder}
                        kind={kind}
                        ext={ext}
                        name={name}
                        isVirus={isVirus}
                        thumbUrl={thumbUrl}
                        isSelecting={isSelecting}
                        isSelected={isSelected}
                        onClick={onClick}
                        id={id}
                    />
                );
            default:
        }
    }, [viewMode, isFolder, kind, ext, name, isVirus, thumbUrl, weblink, isSelecting, isSelected, onClick, onOptions, id, size]);

    return <>{datalistRow}</>;
});

DatalistItem.displayName = 'DatalistItem';
