import { PayloadAction } from '@reduxjs/toolkit';
import { addParams } from 'lib/urlUtils';
import { ACTION_PROMO } from 'reactApp/appHelpers/configHelpers';
import { renderBuyModal } from 'reactApp/components/BuyModal/renderBuyModal';
import { ISaveSubscriptionProps } from 'reactApp/components/VkBuyModalWithSidebar/SaveSubscriptionSidebar/SaveSubscriptionSidebar';
import { openVkBuyModalWithSidebar } from 'reactApp/components/VkBuyModalWithSidebar/VkBuyModalWithSidebar.helpers';
import { EPaymentModalType } from 'reactApp/components/VkBuyModalWithSidebar/VkBuyModalWithSidebar.types';
import { CardActions } from 'reactApp/modules/creditCard/creditCard.module';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import {
    loadDataForSubscriptions,
    loadProductsAndOpenFastCheckout,
    openFastCheckout,
    openPromocodeGiftModalAfterBuy,
    openSaveSubscriptionPayment,
    openTariffBuy,
} from 'reactApp/modules/payment/payment.module';
import {
    LoadProductsAndOpenFastCheckoutAction,
    OpenFastCheckoutAction,
    OpenPromocodeGiftModalAfterBuyAction,
    OpenTariffBuyAction,
} from 'reactApp/modules/payment/payment.types';
import { authPopup } from 'reactApp/modules/ph/ph.thunkActions';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { productsController } from 'reactApp/modules/products/products.controller';
import { updateProducts } from 'reactApp/modules/products/products.module';
import { getProductById, ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { EProductPeriod } from 'reactApp/modules/products/products.types';
import { removePromo } from 'reactApp/modules/promo/promo.module';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { initPromoTariffs } from 'reactApp/modules/promoTariffs/promoTariffs.saga';
import { updatePurchasedGiftsAction } from 'reactApp/modules/purchasedGifts/purchasedGifts.actions';
import { getLastPurchasedGiftById } from 'reactApp/modules/purchasedGifts/purchasedGifts.selectors';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { updateSubscriptionsRequest } from 'reactApp/modules/subscriptions/subscriptions.module';
import { handleSubscriptionsRequest } from 'reactApp/modules/subscriptions/subscriptions.saga';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { updateUser } from 'reactApp/modules/user/user.thunkActions';
import { waitForUserUpdate } from 'reactApp/modules/user/waitForUserUpdate';
import { openTariffBuyDialog } from 'reactApp/ui/TariffBuy/TariffBuy.helpers';
import { channel } from 'redux-saga';
import { call, cancel, put, putResolve, select, take, takeEvery } from 'redux-saga/effects';

function* openFastCheckoutModal(action: PayloadAction<OpenFastCheckoutAction>) {
    const { productId, onSuccess, afterUpdate, onClose, isMarketingPromo } = action.payload;

    if (!productId) {
        yield call(initPromoTariffs, {});
    }

    let tariffData;

    if (isMarketingPromo && !productId) {
        tariffData = yield select(ProductsSelectors.getPrimaryPromoTariff, ACTION_PROMO);
    } else {
        tariffData = yield select(ProductsSelectors.getPrimaryProduct, productId);
    }

    const { tariff, product } = tariffData;

    if (!product) {
        yield put(
            showSnackbarAction({
                type: SnackbarTypes.failure,
                id: 'file-upload-err',
                text: 'Нет доступных тарифов',
                closable: true,
            })
        );
        return;
    }

    const fastCheckoutChannel = channel();
    const isPhone = yield select(EnvironmentSelectors.isPhone);

    if (isPhone) {
        yield openTariffBuyDialog({
            onSuccess: () => fastCheckoutChannel.put(true),
            onClose: () => {
                onClose?.();
                fastCheckoutChannel.close();
            },
            onError: () => {
                onClose?.();
                fastCheckoutChannel.close();
            },
            id: product.id,
            isMobile: true,
        });
    } else {
        yield openVkBuyModalWithSidebar({
            onSuccess: () => fastCheckoutChannel.put(true),
            onClose: () => {
                onClose?.();
                fastCheckoutChannel.close();
            },
            productId: product.id,
            tariff,
            type: EPaymentModalType.fastcheckout,
            isMarketingPromo,
        });
    }

    const isSuccess = yield take(fastCheckoutChannel);

    if (isSuccess) {
        /* Убираем промку после успешной оплаты */
        yield put(removePromo(EPromoType.space));

        yield onSuccess?.();
        yield put(updateProducts());
        yield waitForUserUpdate();
        yield afterUpdate?.();
    }
}

function* openPromocodeGiftModalAfterBuySaga(action: PayloadAction<OpenPromocodeGiftModalAfterBuyAction>) {
    const { productId } = action.payload;

    const product = yield select(ProductsSelectors.getProductById, productId);

    if (!product?.gift) {
        return;
    }

    try {
        yield putResolve(yield call(updatePurchasedGiftsAction));
    } catch (_) {
        yield cancel();
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const gift = yield select(getLastPurchasedGiftById, product.gift.id);

    openPopupHelper({
        popupName: popupNames.MOBILE_GIFT_PROMOCODE_DIALOG,
        data: {
            code: gift.code,
            space: gift.space,
            giftId: gift.id,
        },
    });
}

function* openTariffBuyModal(action: PayloadAction<OpenTariffBuyAction>) {
    const { productId, onSuccess, onClose, source, checkAnonymous, isMobile, onError, isQuotaCleaner } = action.payload;
    const product = yield select(ProductsSelectors.getProductById, productId);

    const isAnonymous = yield select(UserSelectors.isAnonymous);

    if (checkAnonymous && isAnonymous) {
        yield put(
            authPopup({
                closable: true,
                loginRequest: true,
                successPage: addParams(window.location.href, {
                    action: 'request-payment',
                    productId,
                }),
            })
        );

        yield cancel();
    }

    const paymentChannel = channel();

    if (product?.hasTrial && !isMobile) {
        yield call(renderBuyModal, {
            id: productId,
            onSuccess: () => paymentChannel.put(true),
            onClose: () => {
                onClose?.();
                paymentChannel.close();
            },
            source,
        });
    } else {
        yield openTariffBuyDialog({
            source,
            isMobile,
            onSuccess: () => paymentChannel.put(true),
            onClose: () => {
                onClose?.();
                paymentChannel.close();
            },
            onError: () => {
                onError?.();
                onClose?.();
                paymentChannel.close();
            },
            id: productId,
            isQuotaCleaner,
        });
    }

    const isSuccess = yield take(paymentChannel);

    if (isSuccess) {
        yield onSuccess?.();
        yield put(updateProducts());
        yield put(CardActions.updateCard({ isSilent: true }));
        yield put(updateSubscriptionsRequest());
        yield put(updateUser());

        yield put(
            showSnackbarAction({
                type: SnackbarTypes.success,
                id: 'buy',
                text: 'Подписка успешно оформлена',
                closable: true,
            })
        );
    }
}

function* openSaveSubscriptionPaymentSaga(action: PayloadAction<ISaveSubscriptionProps>) {
    const { source, oldSubscriptionSpace, isTransit } = action.payload;

    yield productsController.loadProducts();

    const product = yield select(ProductsSelectors.getSaveSubscriptionProduct, {
        isTransit,
        isIos: source === 'ios',
        quota: oldSubscriptionSpace.space,
    });

    if (!product) {
        return;
    }

    yield openVkBuyModalWithSidebar({
        productId: product.id,
        type: EPaymentModalType.saveSubscription,
        sidebarProps: {
            source,
            oldSubscriptionSpace,
            isTransit,
        },
    });
}

function* loadDataForSubscriptionsSaga() {
    yield call(handleSubscriptionsRequest, { skipFutureReq: false });
    yield call(initPromoTariffs, {});
    yield put(CardActions.loadCard());
}

function* loadProductsAndOpenFastCheckoutSaga(action: PayloadAction<LoadProductsAndOpenFastCheckoutAction>) {
    const { quota, productId, onSuccess } = action.payload;

    yield productsController.loadProducts();

    const product = yield select(getProductById, productId);
    const { product: productByQuota } = yield select(ProductsSelectors.getProductAndTariffByQuota, quota, EProductPeriod.year, false);

    yield put(
        openFastCheckout({
            productId: productId ? product?.id : productByQuota?.id,
            onSuccess,
        })
    );
}

export function* watchPayment() {
    yield takeEvery(openTariffBuy.toString(), openTariffBuyModal);
    yield takeEvery(openFastCheckout.toString(), openFastCheckoutModal);
    yield takeEvery(openPromocodeGiftModalAfterBuy.toString(), openPromocodeGiftModalAfterBuySaga);
    yield takeEvery(openSaveSubscriptionPayment.toString(), openSaveSubscriptionPaymentSaga);
    yield takeEvery(loadDataForSubscriptions.toString(), loadDataForSubscriptionsSaga);
    yield takeEvery(loadProductsAndOpenFastCheckout.toString(), loadProductsAndOpenFastCheckoutSaga);
}
