import classNames from 'clsx';
import { ReactComponent as IconClose } from 'img/icons/remove.svg';
import { find, propEq } from 'ramda';
import React, { memo, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { Button } from 'reactApp/ui/Button/Button';
import { noop } from 'reactApp/utils/helpers';
import { Popup } from 'semantic-ui-react';

import styles from './Tip.css';

export interface TipData {
    id: string;

    onHideClick?(id: string): void;

    onShow?(id: string): void;

    getTipContent?(isFolder: boolean): React.ReactElement | string;
}

interface IProps {
    show: boolean;
    showBubble: boolean;
    preventOverflow?: boolean;
    position: 'top left' | 'top right' | 'bottom right' | 'bottom left' | 'right center' | 'left center' | 'top center' | 'bottom center';
    offset?: number;
    text?: string | ReactNode;
    buttonText?: string;
    icon?: ReactNode | null;
    id: string;
    size?: 'mini' | 'tiny' | 'small' | 'large' | 'huge';
    className: string;

    hideTip?(id?: string): void;

    onShow?(id?: string): void;

    tips?: TipData[];
}

export const Tip = memo(
    ({
        position = 'right center',
        size = 'large',
        preventOverflow = true,
        show = false,
        showBubble = true,
        className = '',
        buttonText = '',
        offset = 0,
        hideTip = noop,
        onShow = noop,
        icon = null,
        id,
        tips,
        text,
    }: IProps) => {
        const [isOpen, setIsOpen] = useState(show);

        const showState = Boolean(!tips || find(propEq('id', id), tips));

        const timerId = useRef<number | null>(null);

        const clearTimer = () => {
            if (timerId?.current) {
                clearTimeout(timerId?.current);
            }
        };

        const closeTip = () => setIsOpen(false);

        const handleOnHideClick = (e) => {
            e.stopPropagation();
            hideTip(id);
            closeTip();
        };

        const handleOpen = () => {
            clearTimer();
            setIsOpen(true);
            onShow(id);
        };

        const handleClose = () => {
            clearTimer();
            timerId.current = window.setTimeout(() => closeTip(), 100000);
        };

        const handleStopPropagation = useCallback((e) => e.stopPropagation(), []);

        const handleOnContextMenu = useCallback((e) => {
            e.stopPropagation();
            e.preventDefault();
        }, []);

        useEffect(() => {
            window.addEventListener?.('resize', closeTip);

            return () => {
                clearTimer();
                window.removeEventListener?.('resize', closeTip);
            };
        }, []);

        useEffect(() => {
            if (isOpen && showState) {
                onShow(id);
            }
        }, [isOpen, showState]);

        if (!showState) {
            return null;
        }

        const popperModifiers = [];

        if (preventOverflow) {
            // @ts-ignore
            popperModifiers.push({ name: 'preventOverflow', enabled: true, options: {} });
        }

        return (
            <Popup
                trigger={
                    <div className={classNames({ [styles.root]: true, [styles.rootHidden]: !showBubble })}>
                        <div className={styles.dot} />
                    </div>
                }
                position={position}
                offset={[offset]}
                size={size}
                className={classNames({ [styles.tipPopup]: true, [className]: !!className })}
                hoverable
                flowing
                on="hover"
                onOpen={handleOpen}
                onClose={handleClose}
                open={isOpen}
                popperModifiers={popperModifiers}
                onMouseMove={handleStopPropagation}
                onContextMenu={handleOnContextMenu}
            >
                <div onMouseEnter={clearTimer} className={styles.content} onDoubleClick={handleStopPropagation}>
                    <div id="tipClose" className={styles.close} onClick={handleOnHideClick}>
                        <IconClose width={16} height={16} viewBox="0 0 16 16" />
                    </div>
                    {icon && <div className={styles.icon}>{icon}</div>}
                    <div>
                        {text}
                        {buttonText && (
                            <div className={styles.button}>
                                <Button onClick={handleOnHideClick} primary>
                                    {buttonText}
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </Popup>
        );
    }
);

Tip.displayName = 'Tip';
