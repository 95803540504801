import { createAction } from '@reduxjs/toolkit';

import { FamilyItem, FamilyLinkUpdatePayload, FamilyPlan, InitFamilyProps, RemoveFamilyProps } from './family.types';

export const initFamilyOnboarding = createAction('family/onboarding');
export const createFamily = createAction('family/create');
export const familyLoadRequest = createAction<InitFamilyProps>('family/load/request');
export const familyLoadSuccess = createAction<FamilyItem[]>('family/load/success');
export const familyLoadFailure = createAction('family/load/failure');
export const familyUpdateRequest = createAction('family/update/request');
export const familyUpdateFailure = createAction('family/update/failure');
export const familyUpdate = createAction<FamilyItem>('family/update');
export const familyDelete = createAction('family/delete');
export const removeFamilyMember = createAction<RemoveFamilyProps>('family/remove/user');
export const familyInviteRequest = createAction<string>('family/invite/request');
export const familyInviteAccept = createAction<string>('family/invite/accept');
export const setFamilyPlan = createAction<FamilyPlan>('family/plan/success');
export const initFamilyInviteModal = createAction('family/openFamilyInviteModal');
export const updateFamilyInviteLink = createAction<FamilyLinkUpdatePayload>('family/update/inviteLink');
export const inviteToFamilyOrCreate = createAction('family/inviteOrCreate');
export const leaveFamily = createAction('family/leave');
export const leaveFamilyRequest = createAction('family/leave/request');
export const loadingInviteLink = createAction('family/loading/link');
export const loadInviteLinkFailure = createAction('family/load/link/failure');
export const setLoading = createAction('family/loading');
export const familyInitDataSuccess = createAction('family/init/success');
