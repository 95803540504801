import { escapeRegExp } from '@vkontakte/vkjs';
import apiError from 'Cloud/API/apiError';
import { addFileApiCall } from 'reactApp/api/FileAddAPICall';
import { HttpErrorCodes, isReadOnlyHttpStatus } from 'reactApp/api/HttpErrorCodes';
import { addFileWeblinksApiCall } from 'reactApp/api/weblinks/WeblinksFileAddApiCall';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { HttpError } from 'reactApp/modules/uploading/errors/HttpError';
import { InvalidCharactesFail } from 'reactApp/modules/uploading/fails/InvalidCharactesFail';
import { NameTooLongFail } from 'reactApp/modules/uploading/fails/NameTooLongFail';
import { ReadOnlyDirectoryFail } from 'reactApp/modules/uploading/fails/ReadOnlyDirectoryFail';
import { IAddFileToStore } from 'reactApp/modules/uploading/helpers/cloudFs/cloudFs.types';
import { UploadingDescriptor } from 'reactApp/modules/uploading/serviceClasses/UploadingDescriptor';
import { EUploadReasonSource } from 'reactApp/modules/uploading/serviceClasses/UploadingReason';
import { EConflictResolution } from 'reactApp/modules/uploading/uploading.types';
import { EFileError, EFileStatus } from 'reactApp/modules/uploadList/uploadList.model';
import { updateUploadFilesAction } from 'reactApp/modules/uploadList/uploadList.module';
import { put } from 'redux-saga/effects';

import { ConnectionFail } from '../../fails/ConnectionFail';
import { FileExistsFail } from '../../fails/FileExistsFail';
import { OverQuotaFail } from '../../fails/OverQuotaFail';
import { ViolatedFilenameFail } from '../../fails/ViolatedFilenameFail';
import { WrongDestinationPathFail } from '../../fails/WrongDestinationPathFail';
import { isRetryableHttpStatus, sendGaUploaderNew } from '../uploading.helpers';
import { addItemsToStore } from './addItemsToStore';

export function* addFile(descriptor: UploadingDescriptor, workingDirectoryArg = '') {
    return yield addFileToCloud({
        file: descriptor,
        conflictResolution: descriptor.conflictResolution,
        workingDirectoryArg,
    });
}

export function* addFileToCloud({
    file,
    workingDirectoryArg = '',
    conflictResolution,
}: {
    file: IAddFileToStore | UploadingDescriptor;
    conflictResolution: EConflictResolution;
    workingDirectoryArg?: string;
}) {
    const path = file.cloudPath;
    const hash = file.cloudHash;
    const size = file.size;
    const descriptorId = file.id;
    const localPath = file.localPath;
    const uploadingConfig = 'uploadingPacketConfig' in file ? file.uploadingPacketConfig : null;

    const isFolder = Boolean(localPath && localPath.split('/').length > 2);

    if (conflictResolution === EConflictResolution.strict && uploadingConfig) {
        conflictResolution = uploadingConfig.conflictResolution;
    }
    if (conflictResolution === EConflictResolution.skip) {
        // API не жрет такое
        conflictResolution = EConflictResolution.strict;
    }

    const isOwn = uploadingConfig?.isOwnPublic;
    const workingDirectory = workingDirectoryArg;
    const RE = new RegExp(`(${escapeRegExp(workingDirectory)}).+(/.*)`, 'gi');
    // Менеджмент решил, что если заливать в паблик папку, то не нужно создавать эту папку в облаке, а просто заливать ее содержимое в текущую директорию
    // Просто удалите регулярку, когда решение поменяется :)

    const isPublic = uploadingConfig?.storage === EStorageType.public;

    const pathTo = isPublic && !isOwn && path && isFolder ? path.replace(RE, (str, workingDir, fileName) => workingDir + fileName) : path;

    try {
        const params: any = {
            hash,
            size,
        };

        if (isPublic && !isOwn) {
            params.weblink = `/${pathTo}`;
            params.conflict = 'rename';
        } else {
            params.home = pathTo;
            params.conflict = conflictResolution;
        }

        let cloudPath = yield isPublic && !isOwn ? addFileWeblinksApiCall(params) : addFileApiCall(params);

        let currentWorkingDir = workingDirectory;
        if (isOwn && uploadingConfig?.ownPublicFolderWeblink) {
            // загрузили все в хомяк, но мы в паблике и фейк элементы надо добавлять туда
            cloudPath = cloudPath.replace(workingDirectory, uploadingConfig?.ownPublicFolderWeblink);
            currentWorkingDir = uploadingConfig?.ownPublicFolderWeblink;
            file = { ...file, cloudPath } as UploadingDescriptor;
        }

        file.cloudPath = cloudPath;

        yield addItemsToStore({ files: [file], email: uploadingConfig?.email ?? '', currentWorkingDir });

        return cloudPath;
    } catch (error: any) {
        const [data, statusCode, response, responseError] = error;
        const source = EUploadReasonSource.SOURCE_WEB_BACKEND;
        let stack;
        let reason;
        let fileError;
        let fileStatus = EFileStatus.ERROR;
        const status: number = (statusCode as number) || 0;
        const responseText = responseError ?? response.getXHR?.()?.responseText;

        if (status && !isRetryableHttpStatus(status)) {
            const isBadRequest = status === HttpErrorCodes.BAD_REQUEST;
            const isInsufficient = isReadOnlyHttpStatus(status);

            if (isBadRequest || isInsufficient) {
                const errorCode = apiError.getCode(data);

                switch (errorCode) {
                    case NameTooLongFail.WEB_BACKEND_ERROR:
                        stack = new Error('NameTooLongFail');
                        reason = new NameTooLongFail(stack, source);
                        fileError = EFileError.NAME_TOO_LONG;
                        break;

                    case InvalidCharactesFail.WEB_BACKEND_ERROR:
                        stack = new Error('InvalidCharactesFail');
                        reason = new InvalidCharactesFail(stack, source);
                        fileError = EFileError.INVALID_CHARACTERS;
                        break;

                    case ReadOnlyDirectoryFail.WEB_BACKEND_ERROR:
                        stack = new Error('ReadOnlyDirectoryFail');
                        reason = new ReadOnlyDirectoryFail(stack, source);
                        break;

                    case WrongDestinationPathFail.WEB_BACKEND_ERROR:
                        stack = new Error('WrongDestinationPathFail');
                        reason = new WrongDestinationPathFail(stack, source);
                        fileError = EFileError.UNKNOWN;
                        break;

                    case OverQuotaFail.WEB_BACKEND_ERROR:
                        stack = new Error('OverQuotaFail');
                        reason = new OverQuotaFail(stack, source);
                        fileStatus = EFileStatus.WARNING;
                        fileError = EFileError.OVER_QUOTA_CLOUD;
                        break;

                    case FileExistsFail.WEB_BACKEND_ERROR:
                        stack = new Error('FileExistsFail');
                        reason = new FileExistsFail(stack, source);
                        break;

                    case ViolatedFilenameFail.VIOLATED_FILENAME:
                        stack = new Error('ViolatedFilenameFail');
                        reason = new ViolatedFilenameFail(stack, source);
                        break;
                }
            }

            if (!reason) {
                stack = new Error('HttpError');
                reason = new HttpError(stack, source, status, responseText);
                fileError = EFileError.CONNECTION_ERROR;
            }
        } else {
            stack = new Error('ConnectionFail');
            reason = new ConnectionFail(stack, source, status);
            fileError = reason.isGatewayProblem() ? EFileError.GATEWAY_ERROR : EFileError.CONNECTION_ERROR;
        }

        if (fileError && descriptorId) {
            yield put(
                updateUploadFilesAction({
                    descriptorId,
                    cloudPath: path,
                    status: fileStatus,
                    error: fileError,
                })
            );

            sendGaUploaderNew('file', fileError);
        }

        throw reason;
    }
}
