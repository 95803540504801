import { Space } from '@mail/cross-sizes-utils';

import { ISubscription } from './BuyModal.types';

interface ISharedProps {
    link: string | null;
    hasError: boolean;
    isLoading: boolean;
}

interface IMapStateBase {
    subscription: ISubscription;
    userTotalSpace: Space;
}

export enum EBuyContentSize {
    small = 'small',
    large = 'large',
}

export enum EBuyContentType {
    TRIAL = 'trial',
}

interface IOwnPropsBase {
    onClose: () => void;
    onSuccess: () => void;
    onCancel?: () => void;
    onBuyClick?: () => void;
    retry?: (() => void) | null;
    id: string;
    isPromocodesTrial?: boolean;
    renew?: boolean;
    size?: EBuyContentSize;
    type?: EBuyContentType;
    onDmrResizeEvent?: (height) => void;
    qaId?: string;
    source?: string;
    onError?: () => void;
}

export interface IOwnProps extends IOwnPropsBase, Partial<ISharedProps> {}

export interface IMapState extends IMapStateBase, ISharedProps {}

export interface IDispatchProps {
    buySubscription: (id: string, source: string) => void;
}

export interface IState {
    node: any;
    dmrLoading: boolean;
    dmrHeight: number;
    hasError: boolean;
    expandFrame: boolean;
}

export interface IProps extends IOwnPropsBase, IMapStateBase, ISharedProps, IDispatchProps {}
