import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkUpdateLicenseRequest } from 'reactApp/modules/profile/profile.module';
import { getStorageCurrentFolder } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { RootState } from 'reactApp/store';
import { BreadcrumbsContainer } from 'reactApp/ui/BreadcrumbsContainer/BreadcrumbsContainer';
import { Datalist } from 'reactApp/ui/Mobile/Datalist/Datalist';

import styles from './IntegrationHomePage.css';

export const IntegrationHomePage: React.FC = () => {
    const dispatch = useDispatch();
    const folder = useSelector((state: RootState) => getStorageCurrentFolder(state, EStorageType.integration));
    const [updateLicenseCheck, setUpdateLicenseCheck] = React.useState<boolean>(false);
    const isLoaded = folder?.isLoaded;

    // десктопный DataList проверяет апдейты лицензионного соглашения сам, но мобильный так не умеет
    // поэтому проверим тут
    React.useEffect(() => {
        if (isLoaded && !updateLicenseCheck) {
            setUpdateLicenseCheck(true);
            dispatch(checkUpdateLicenseRequest());
        }
    }, [isLoaded, updateLicenseCheck, dispatch]);

    return (
        <div className={styles.root}>
            <BreadcrumbsContainer />
            <Datalist />
        </div>
    );
};
