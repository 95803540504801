import { useSelector } from 'react-redux';
import { IS_BIZ_USER, IS_MY_TEAM, IS_PUBLIC_ALBUM } from 'reactApp/appHelpers/configHelpers';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { useActionsConfig } from 'reactApp/hooks/useActionsConfig';
import { dispatchNewSearchRadar } from 'reactApp/modules/dwh/dwh.module';
import { getFacesFileId } from 'reactApp/modules/faces/faces.helpers';
import { hasFacesOnFile } from 'reactApp/modules/faces/faces.selectors';
import { getFeatureMobileGalleryGrid3 } from 'reactApp/modules/features/features.selectors';
import { getSearchItemById } from 'reactApp/modules/search/search.selectors';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import { getSort } from 'reactApp/modules/sort/sort.selectors';
import { EActions, EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { ViewerSelectors } from 'reactApp/modules/viewer/viewer.selectors';
import { dispatch } from 'reactApp/react-into-toolkit-block-wrapper/renderComponentInExternalDom';
import { RootState } from 'reactApp/store';
import {
    getActionsBlock,
    getActionsItems,
    getFacesOnFileItem,
    getFileInfoItem,
    getFilterBlock,
    getRemoveBlock,
    getSortBlock,
    getViewBlock,
} from 'reactApp/ui/Mobile/OptionsModal/OptionsModal.data';
import { IOptionBlock, IOptionItem, Props } from 'reactApp/ui/Mobile/OptionsModal/OptionsModal.types';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

import { useCallbacks } from './useCallbacks';
import { useFilterBlock } from './useFilterBlock';

type UseItemsHook = (
    props: Props & { isFolder: boolean; isArchive: boolean; weblink: boolean; isGalleryCategory: boolean }
) => IOptionBlock[];

// eslint-disable-next-line max-lines-per-function, complexity
export const useItems: UseItemsHook = ({
    id,
    storage,
    onlyActions = false,
    onClose,
    skipRename,
    skipDelete,
    sendAnalytics,
    weblink,
    isFolder,
    isArchive,
    isGalleryCategory,
}) => {
    const viewMode = useSelector((state) => SettingsSelectors.getViewByStorage(state, storage));
    const sort = useSelector((state: RootState) => getSort(state, { id }, storage));
    const isAnonymous = useSelector(UserSelectors.isAnonymous);
    const isNewMobileGalleryGridEnabled = useSelector(getFeatureMobileGalleryGrid3);
    const isQuotaCleaner = storage === EStorageType.quotaCleaner;

    const params = getQueryParams();

    const { changeSortOrder, onUpload, onDownload, resetFilter } = useCallbacks({
        onClose,
        storage,
        id,
        sendAnalytics,
        isAppViewer: params && Boolean(params.is_app_viewer),
    });
    const actionsConfig = useActionsConfig({
        id: id === ROOT_FOLDER_ID ? '' : id,
        skipUpload: onlyActions || IS_PUBLIC_ALBUM,
        skipRename,
        skipDelete,
        isMobile: true,
        storage,
    });
    let actionsItems = getActionsItems({
        storage,
        isAnonymous,
        id,
        onUpload,
        onDownload,
        weblink,
        sendAnalytics,
    });

    // https://jira.vk.team/browse/CLOUDWEB-15881
    if (params && Boolean(params.is_app_viewer)) {
        const availableActions = [EActions.publish, EActions.download, EActions.shareLink];

        actionsItems = (Object.entries(actionsItems) as [EActions, IOptionItem][])
            .filter(([action]) => availableActions.includes(action))
            .reduce((acc, [action, options]) => ({ ...acc, [action]: options }), {});
    }

    const { filtered, filterItems } = useFilterBlock({
        storage,
        onlyActions,
        facesFilter: Boolean(actionsConfig[EActions.facesFilter]),
    });
    const fileId = getFacesFileId(id);
    const fileHasFaces = useSelector((state: RootState) => hasFacesOnFile(state, fileId));
    const isViewerActive = useSelector(ViewerSelectors.isViewerActive);
    const viewerItem = useSelector(ViewerSelectors.getViewerItem);
    const viewerItemIdx = useSelector(ViewerSelectors.getViewerItemIndexInArray);
    const searchItem = useSelector((state: RootState) => getSearchItemById(state, id));

    const actionsList = Object.keys(actionsConfig)
        .map((id) => actionsItems[id])
        .filter((action) => action);

    // Добавляем пункт 'Люди на фото' в OptionsModal просмотрщика.
    if (fileHasFaces && isViewerActive) {
        actionsList.push(getFacesOnFileItem({ fileId }));
    }

    const sendSearchInfoDwh = () => {
        if (storage === EStorageType.search) {
            let dwhData = { eventCategory: ECategoryGa.context_search, action: 'open-info' };
            let item = {
                file_name: 'nameWithoutExt' in searchItem && searchItem.nameWithoutExt,
                file_id: searchItem.id,
                pos: searchItem.pos,
                type: searchItem.kind,
                placement: 'srchSrc' in searchItem && searchItem.srchSrc,
            };
            if (isViewerActive && viewerItem) {
                dwhData = { ...dwhData, eventCategory: ECategoryGa.viewer_search };
                item = {
                    file_name: 'nameWithoutExt' in viewerItem && viewerItem.nameWithoutExt,
                    file_id: viewerItem.id,
                    pos: Number(viewerItemIdx),
                    type: viewerItem.kind,
                    placement: 'srchSrc' in viewerItem && viewerItem.srchSrc,
                };
            }
            dispatch(
                dispatchNewSearchRadar({
                    dwhData,
                    items: [item],
                })
            );
        }
    };

    // https://jira.vk.team/browse/CLOUDWEB-15881
    if (params && !params.is_app_viewer) {
        actionsList.push(getFileInfoItem({ id, storage, isFolder, isArchive, callback: sendSearchInfoDwh }));
    }

    const hasRemoveBlock = Boolean(actionsConfig[EActions.remove]) && !params.is_app_viewer;

    const filterBlock = getFilterBlock({ filtered, items: filterItems, resetFilter });

    const blocksList: IOptionBlock[] = [];

    if (filtered && filterItems.length) {
        blocksList.push(filterBlock);
    }

    // Не показывать "Действия с папкой" на галерее.
    if (!isGalleryCategory && !isQuotaCleaner) {
        blocksList.push(getActionsBlock({ actionsList, onlyActions }));
    }

    if (hasRemoveBlock) {
        blocksList.push(getRemoveBlock({ id, sendAnalytics }));
    }

    if (onlyActions) {
        return blocksList;
    }
    if (!(IS_BIZ_USER || IS_MY_TEAM)) {
        blocksList.push(getViewBlock({ viewMode, storage, isNewMobileGalleryGridEnabled }));
    }

    if (!filtered && filterItems.length && !isQuotaCleaner) {
        blocksList.push(filterBlock);
    }
    if (!isGalleryCategory && !isQuotaCleaner) {
        blocksList.push(getSortBlock({ changeSortOrder, sort }));
    }

    return blocksList;
};
