import { bytesToNDigits } from '@mail/cross-sizes-utils';
import { plural } from 'lib/plural';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useButtons } from 'reactApp/components/UploadBigFileDialog/hooks/useButtons';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { NameComponent } from 'reactApp/ui/Name/Name';

import { Content, FILES_PLURAL, IBigFileModalContent } from '../UploadBigFileDialog.types';

export const useContent = ({ tariffInfo, numberOfFiles, file, files, callbacks }: IBigFileModalContent): Content => {
    const { uploadLimit } = useSelector(UserSelectors.getUserData);

    const annotation = useMemo(
        () => (
            <>
                Файл слишком тяжёлый. Чтобы загружать файлы больше {bytesToNDigits(uploadLimit, 3).value}, подключите один из платных
                тарифов.
            </>
        ),
        [uploadLimit]
    );

    const description = useMemo(() => {
        if (files) {
            return <>{FILES_PLURAL[plural(files.number)].replace('{count}', `${files.number}`)}</>;
        }

        return (
            <>
                <span>
                    <NameComponent name={file?.name || ''} truncate />{' '}
                </span>
                не может быть загружен
            </>
        );
    }, [file?.name, files]);

    const header = useMemo(() => {
        if (files) {
            return 'Невозможно загрузить файлы';
        }

        return (
            <>
                Невозможно загрузить файл <NameComponent name={file?.name || ''} truncate />
            </>
        );
    }, [file?.name, files]);

    const buttonActions = useButtons({ tariffInfo, numberOfFiles, files, callbacks });

    return {
        header,
        annotation,
        actions: buttonActions,
        description,
    };
};
