/* eslint-disable max-lines */
import { createSelector } from '@reduxjs/toolkit';
import config from 'Cloud/config';
import localState from 'lib/store';
import { find, path, propEq, sortBy, values } from 'ramda';
import { PROMO_TARIFFS, TARIFFS_LIST } from 'reactApp/appHelpers/configHelpers';
import {
    abEternalPromoYearTariffs,
    abMonthTariffsWithIncreasedPrice,
    desktopTariffMinSize,
    favorableTariffs as favorableTariffsIDs,
    mobileTariffsOrderByQuota,
} from 'reactApp/appHelpers/featuresHelpers';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import {
    getFeatureAbTwoYearsTariff,
    getFeatureAbTwoYearsTariffTouch,
    getFeatureSaveSubscriptionTariffs,
} from 'reactApp/modules/features/features.selectors';
import { PaidInfoSelectors } from 'reactApp/modules/paidInfo/paidInfo.selectors';
import {
    addTwoYearProducts,
    DEFAULT_PRIMARY_PRODUCT,
    filterAndSortTariffsByQuota,
    getTariffsByQuotaHelper,
    isNotSpecialProduct,
    isTariffForExclude,
    PROMO_TARIFFS_REGEX,
    replaceProductInTariff,
    SINGLE_QUOTA_DISKO_REGEX,
    SPACE_SIZES,
    YEAR_COST_DECREASE_SINGLE_QUOTA_REGEX,
} from 'reactApp/modules/products/products.helpers';
import { EProductPeriod } from 'reactApp/modules/products/products.types';
import { QuotaLandingSelectors } from 'reactApp/modules/quotaLanding/quotaLanding.selector';
import { RootState } from 'reactApp/store';
import { Product, Tariff } from 'reactApp/types/Billing';
import { findHigherTariff } from 'reactApp/utils/findTariff';
import { getTariffProductsByPeriods, isMonthPeriod, isYearPeriod } from 'reactApp/utils/Period';

const { product_id: countdownTariffId, id: countdownId } = config.get('PROMO_COUNTDOWN') || {};

const getProductsState = (state: RootState) => state.products;

const getTariffs = createSelector(getProductsState, (productsState) => productsState.list);

const getLifeCycleState = createSelector(getProductsState, (productsState) => ({
    isLoaded: productsState.isLoaded,
    isLoading: productsState.isLoading,
    hasError: productsState.hasError,
}));

const isLoaded = createSelector(getLifeCycleState, (state) => state.isLoaded);

export const getProductById = createSelector(
    getTariffs,
    (state, id) => id,
    (products, id) => {
        let product;
        values(products).forEach((item) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const fProduct = find(propEq('id', id), item.products);
            if (fProduct) {
                product = fProduct;
            }
        });

        return product;
    }
);

const getTariffById = createSelector(
    getTariffs,
    (state, id) => id,
    (products, id) => products[id]
);

function isExist<T>(item: T | null | undefined): item is T {
    return Boolean(item);
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const sortBySpace = createSelector(getTariffs, (products) => sortBy(path(['space', 'original']), values(products).filter(isExist)));

const getAvailableProducts = createSelector(sortBySpace, (products) =>
    products.reduce((acc, cur) => {
        const productsList = cur.products.filter(
            (product) => product.available && (product.isPromo || product.hasTrial || TARIFFS_LIST.includes(product.id))
        );

        if (productsList.length) {
            return [
                ...acc,
                {
                    ...cur,
                    products: productsList,
                },
            ];
        }
        return acc;
    }, [] as Tariff[])
);

// На декстопе не надо показывать тарифы меньше заданного в фиче значения (по дефолту - 128ГБ). cloudweb-13079.
const getAvailableProductsCertainSize = createSelector(getAvailableProducts, EnvironmentSelectors.isPhone, (products, isPhone) => {
    if (!isPhone) {
        // tempexp_16349-start
        const minSizeDesktop = SPACE_SIZES[`_${desktopTariffMinSize}`];
        return products.filter((product) => product?.space?.original >= minSizeDesktop);
        // tempexp_16349-end
    }

    return products;
});

const getPromoRegex = (state, promo) => PROMO_TARIFFS_REGEX[promo];

// tempexp_16338-start
const getMonthTariffsIcreasedPrice = createSelector(
    (state: RootState) => state,
    (state) => {
        return getAvailableProductsByListIds(state, abMonthTariffsWithIncreasedPrice || []);
    }
);
// tempexp_16338-end

// tempexp_16215-start
export const getEternalPromoYearTariffs = createSelector(
    (state: RootState) => state,
    EnvironmentSelectors.isPhone,
    (state, isPhone) => {
        const tariffs = getAvailableProductsByListIds(state, abEternalPromoYearTariffs);
        // tempexp_16349-start
        const minSizeDesktop = SPACE_SIZES[`_${desktopTariffMinSize}`];
        return isPhone ? tariffs : tariffs.filter((product) => product?.space?.original >= minSizeDesktop);
        // tempexp_16349-end
    }
);
// tempexp_16215-end

// tempexp_16373-start
export const getFavorableTariffList = createSelector(
    (state: RootState) => state,
    (state) => {
        return getAvailableProductsByListIds(state, favorableTariffsIDs);
    }
);
// tempexp_16373-end

const getPromoTariffs = createSelector(
    // tempexp_16338-next-line
    getMonthTariffsIcreasedPrice,
    // tempexp_16215-next-line
    getEternalPromoYearTariffs,
    getPromoRegex,
    getAvailableProductsCertainSize,
    (state, mergeWithUsualTariffs) => mergeWithUsualTariffs,
    // tempexp_16373-next-line
    getFavorableTariffList,
    // tempexp_16338-next-line tempexp_16215-next-line tempexp_16373-next-line
    (monthTariffsIncreasedPrice, abYearEternalPromoYearTariffs, promoRegex, tariffs, mergeWithUsualTariffs, favorableTariffs) => {
        if (!promoRegex) {
            return [];
        }

        let promoTariffs = tariffs.filter((tariff) => promoRegex.test(tariff.id));

        // tempexp_16215-next-line
        if (abYearEternalPromoYearTariffs.length) {
            promoTariffs = abYearEternalPromoYearTariffs;
            // tempexp_16373-start
        } else if (favorableTariffs.length) {
            favorableTariffs.sort((a, b) => favorableTariffsIDs.indexOf(a.id) - favorableTariffsIDs.indexOf(b.id));
            promoTariffs = favorableTariffs;
        }
        // tempexp_16373-end

        // tempexp_16510-start
        if (mobileTariffsOrderByQuota.length) {
            promoTariffs = filterAndSortTariffsByQuota(promoTariffs, mobileTariffsOrderByQuota);
        }
        // tempexp_16510-end

        if (!mergeWithUsualTariffs) {
            return promoTariffs;
        }

        const usualTariffs = tariffs.filter((tariff) => !tariff.isProTariff && isNotSpecialProduct(tariff));

        return promoTariffs.map((tariff) => {
            const usualTariff = getTariffsByQuotaHelper(usualTariffs, tariff.space.original)[0] as Tariff;
            // tempexp_16338-start
            const tariffWithIncreasedPrice = getTariffsByQuotaHelper(monthTariffsIncreasedPrice, tariff.space.original)[0] as Tariff;
            const tariffToAdd = tariffWithIncreasedPrice || usualTariff;
            // tempexp_16338-end

            // tempexp_16338-next-line
            const usualProduct = tariffToAdd?.products?.find((product) =>
                tariff.products.some((promoProduct) => promoProduct.period !== product.period)
            );

            if (!usualProduct) {
                return tariff;
            }

            return {
                ...tariff,
                products: [...tariff.products, usualProduct],
            };
        });
    }
);

const getPrimaryPromoTariff = createSelector(getPromoTariffs, (tariffs) => {
    const tariff = getTariffsByQuotaHelper(tariffs, DEFAULT_PRIMARY_PRODUCT)[0] as Tariff;

    const { yearProduct, monthProduct } = getTariffProductsByPeriods(tariff?.products);

    return { product: yearProduct || monthProduct, tariff };
});

const getPromoCodeTrialTariffs = createSelector(getAvailableProducts, (products) => {
    return products.filter((tariff) => tariff.isPromoCode && tariff.products.some((product) => product.hasTrial));
});

const getNonSpecialTariffs = createSelector(getAvailableProductsCertainSize, getTariffById, (products, additionalTariff) => {
    let tariffs = [];

    if (additionalTariff) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const isCountdownPromoTariff = additionalTariff.id === countdownTariffId;
        const email = config.get('user.email');
        const countdownEndDate = localState.get(`${email}|promo-countdown_${countdownId}`);

        tariffs.push({
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ...additionalTariff,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            isPromoTariff: true,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            isCountdownPromoTariff,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            countdownEndDate,
        });
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    tariffs = [...tariffs, ...products.filter((tariff) => isNotSpecialProduct(tariff) && !tariff.isSingleQuota)];
    const defaultProducts = products.filter(
        (t) => isNotSpecialProduct(t) && !t.products.some((p) => p.isProfessional) && t.products.some((p) => isMonthPeriod(p.period))
    );

    // FIXME Пофиксить ПОСЛЕ https://jira.vk.team/browse/CLOUDWEB-13535
    tariffs.forEach((tariff) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const yearCustomTariff = tariff.products.find((p) => p.id.match(YEAR_COST_DECREASE_SINGLE_QUOTA_REGEX));
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (!tariff.products.some((p) => p.isProfessional) && yearCustomTariff) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const defaultTariff = getTariffsByQuotaHelper(defaultProducts, tariff.space.original)[0];
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (!defaultTariff.products.some(({ id }) => id === yearCustomTariff.id)) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                defaultTariff.products.push(yearCustomTariff);

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                tariffs = tariffs.filter(({ id }) => id !== tariff.id);
            }
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (!tariff.products.some((p) => p.isProfessional) && !yearCustomTariff) {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const defaultTariff = getTariffsByQuotaHelper(defaultProducts, tariff.space.original)[0];
        if (defaultTariff) {
            const monthlyDefaultPrice = path(
                ['price'],
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                defaultTariff.products.find((p) => isMonthPeriod(p.period))
            );
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            tariff.products = tariff.products.map((p) => ({ ...p, monthlyDefaultPrice }));
        }
    });

    return tariffs;
});

const getSingleQuotaTariffs = createSelector(getAvailableProductsCertainSize, (tariffs) => {
    return tariffs.filter((tariff) => isNotSpecialProduct(tariff) && tariff.isSingleQuota);
});

// TODO После того как логика зафиксируется, возможно стоит написать юнит
const getNonSpecialSingleQuoteTariffs = createSelector(getAvailableProductsCertainSize, (tariffs: Tariff[]) => {
    const proTariffs = tariffs.filter((tariff) => tariff.isProTariff);
    const usualTariffs = tariffs.filter((tariff) => tariff.isSingleQuota && !tariff.isProTariff && isNotSpecialProduct(tariff));

    const result: Tariff[] = [];

    for (const tariff of [...proTariffs, ...usualTariffs]) {
        const tariffWithYearProduct = tariff.products.find((product) => isYearPeriod(product.period));

        // если в тарифе уже есть пара продуктов, один из которых на год, то сразу добавляем тариф в список
        if (tariff.products.length === 2 && tariffWithYearProduct) {
            result.push(tariff);
            continue;
        }

        if (tariff.products.length > 1 || !tariffWithYearProduct) {
            continue;
        }

        const isPro = tariff.id.match(SINGLE_QUOTA_DISKO_REGEX);
        const tariffsByQuota = getTariffsByQuotaHelper(isPro ? proTariffs : usualTariffs, tariff.space.original) as Tariff[];

        const yearNotMergedTariff = tariff.products[0];
        const monthNotMergedTariff = tariffsByQuota.find((tariff) => !isYearPeriod(tariff.products[0].period))?.products?.[0];

        if (monthNotMergedTariff) {
            result.push({
                ...tariff,
                products: [yearNotMergedTariff, monthNotMergedTariff],
            });
        }
    }

    return result;
});

const getAvailableProductsByListIds = createSelector(
    sortBySpace,
    (state, listIds: string[]): string[] => listIds || [],
    (tariffs, listIds) => tariffs.filter((tariff) => tariff.products.some((product) => listIds.includes(product.id)))
);

const getNormalizedProductsBySpace = createSelector(
    getAvailableProductsByListIds,
    (state, listIds) => listIds,
    (
        tariffs,
        listIds
    ): Record<number, { yearProduct: undefined | Product; twoYearProduct: undefined | Product; monthProduct: undefined | Product }> => {
        const newTariffsList = tariffs.map((tariff) => ({
            ...tariff,
            products: tariff.products.filter((product) => listIds.includes(product.id)),
        }));

        return newTariffsList.reduce(
            (acc, tariff) => ({
                ...acc,
                [tariff.space.original]: getTariffProductsByPeriods(tariff.products),
            }),
            {}
        );
    }
);

const getTwoYearsTariffs = createSelector(
    (state) => state,
    EnvironmentSelectors.isPhone,
    getFeatureAbTwoYearsTariff,
    getFeatureAbTwoYearsTariffTouch,
    (state, isPhone, list, listTouch) => {
        const productList = isPhone ? listTouch : list;

        if (!productList) {
            return;
        }

        return getAvailableProductsByListIds(state, productList);
    }
);

const getTariffsForQuotaLanding = createSelector(
    getNonSpecialSingleQuoteTariffs,
    QuotaLandingSelectors.getAbLandingFeature,
    getTwoYearsTariffs,
    // tempexp_16338-next-line
    getMonthTariffsIcreasedPrice,
    // tempexp_16215-next-line
    getEternalPromoYearTariffs,
    // tempexp_16373-next-line
    getFavorableTariffList,
    // tempexp_16338-next-line tempexp_16215-next-line tempexp_16373-next-line
    (tariffs, isNewLanding, twoYearTariffssList, monthTariffsIncreasedPrice, abYearEternalPromoYearTariffs, favorableTariffs) => {
        // tempexp_16338-start
        const minSizeDesktop = SPACE_SIZES[`_${desktopTariffMinSize}`];
        let tariffList = tariffs.filter((tariff) => tariff?.space?.original >= minSizeDesktop && !tariff.isProTariff);
        // tempexp_16338-end

        if (isNewLanding) {
            tariffList = tariffs.filter((tariff) => tariff?.space?.original >= SPACE_SIZES._64 && !tariff.isProTariff);
        }

        // tempexp_15246-start
        if (twoYearTariffssList) {
            tariffList = addTwoYearProducts(tariffList, twoYearTariffssList);
        }
        // tempexp_15246-end

        // tempexp_16373-start
        if (favorableTariffs.length) {
            favorableTariffs.sort((a, b) => favorableTariffsIDs.indexOf(a.id) - favorableTariffsIDs.indexOf(b.id));
            tariffList = favorableTariffs;
        }
        // tempexp_16373-end

        // tempexp_16510-start
        if (mobileTariffsOrderByQuota.length) {
            tariffList = filterAndSortTariffsByQuota(tariffList, mobileTariffsOrderByQuota);
        }
        // tempexp_16510-end

        // tempexp_16338-start Заменяем месяные тарифы если есть такие в аб
        if (monthTariffsIncreasedPrice.length) {
            tariffList = replaceProductInTariff(tariffList, monthTariffsIncreasedPrice);
        }
        // tempexp_16338-end

        // tempexp_16215-next-line  Заменяем годовые тарфифы если есть такие в аб
        if (abYearEternalPromoYearTariffs.length) {
            tariffList = replaceProductInTariff(tariffList, abYearEternalPromoYearTariffs);
        }

        return tariffList;
    }
);

const getTariffsForSubscriptionsPage = createSelector(
    getNonSpecialSingleQuoteTariffs,
    EnvironmentSelectors.isPhone,
    // tempexp_16338-next-line
    getMonthTariffsIcreasedPrice,
    // tempexp_16215-next-line
    getEternalPromoYearTariffs,
    (tariffs, isPhone, monthTariffsIncreasedPrice, abYearEternalPromoYearTariffs) => {
        let tariffList = [...tariffs];
        // tempexp_16338-start
        if (isPhone) {
            tariffList = tariffList.filter((tariff) => !isTariffForExclude(tariff, SPACE_SIZES._32));
        }

        // Заменяем месяные тарифы если есть такие в аб
        if (monthTariffsIncreasedPrice.length) {
            tariffList = replaceProductInTariff(tariffList, monthTariffsIncreasedPrice);
        }
        // tempexp_16338-end

        // tempexp_16215-next-line Заменяем годовые тарфифы если есть такие в аб
        if (abYearEternalPromoYearTariffs.length) {
            tariffList = replaceProductInTariff(tariffList, abYearEternalPromoYearTariffs);
        }

        return tariffList;
    }
);

const getTariffsActivatedByPromocode = createSelector(getAvailableProducts, (tariffs) => {
    if (!tariffs.length) {
        return [];
    }

    return tariffs.filter((tariff) => tariff.products.some((product) => product.isPromo && !product.hasTrial));
});

const getTariffsByQuota = createSelector(
    getNonSpecialTariffs,
    getNonSpecialSingleQuoteTariffs,
    (state, quota) => quota,
    (nonSpecialTariffs, singleQuotaNonSpecialTariffs, quota) =>
        getTariffsByQuotaHelper([...singleQuotaNonSpecialTariffs, ...nonSpecialTariffs], quota)
);

const getExpiredDeviceProduct = createSelector(
    getNonSpecialSingleQuoteTariffs,
    (state, quota) => quota,
    (state, quota, period) => period,
    (tariffs, quota, period) => {
        const tariff = tariffs.filter(
            (tariff) => tariff.space.original === quota && tariff.products.some((product) => product.period === period && !product.hasDiskO)
        )?.[0];

        return tariff && tariff.products.find((product) => product.period === period);
    }
);

/**
 * В тарифах в продукты могут быть замерджены месячные или годовые, а может и нет
 * НО мы точно знаем, что в единой квоте селектор возвращает замердженные, по этому на всякий случай проверяем
 * есть ли выбранный тариф в единой квоте. Так как замердженный в приоритете, потому что у него есть и годовой и месячный продукт
 * */
const getTariffByProductId = createSelector(
    sortBySpace,
    getNonSpecialSingleQuoteTariffs,
    (state, id) => id,
    (tariffs, singleQuotaTariffs, id) => {
        const findByProductPredicate = (tariff: Tariff) => tariff.products.find((product) => product.id === id);

        return singleQuotaTariffs.find(findByProductPredicate) || tariffs.find(findByProductPredicate);
    }
);

const getPrimaryTariff = (state, primaryProduct = DEFAULT_PRIMARY_PRODUCT) => {
    if (!isNaN(primaryProduct * 1)) {
        const tariffs = getTariffsByQuota(state, primaryProduct);
        if (!tariffs?.length) {
            return;
        }
        // @ts-ignore
        if (tariffs[0].products?.length === 1 && tariffs.length > 1) {
            return {
                // @ts-ignore
                ...tariffs[0],
                // @ts-ignore
                products: [...tariffs[0].products, ...tariffs[1].products],
            };
        }
        return tariffs[0];
    }

    // Пришли из письма и явно передали ID
    const product = getTariffByProductId(state, primaryProduct);

    return product ? product : getTariffsByQuota(state, DEFAULT_PRIMARY_PRODUCT)[0];
};

const getPrimaryProduct = createSelector(
    getTariffsActivatedByPromocode,
    getNonSpecialTariffs,
    getPrimaryTariff,
    (state, productId: string) => productId,
    (tariffsActivatedByPromoCodes, tariffs, primaryTariff, productId) => {
        if (!primaryTariff) {
            return;
        }

        let tariff = primaryTariff;

        if (tariffsActivatedByPromoCodes.length && !productId) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const sortedBySpace = sortBy(path(['space', 'original']), tariffsActivatedByPromoCodes);
            tariff = sortedBySpace[sortedBySpace.length - 1];
        }

        const { yearProduct, monthProduct } = getTariffProductsByPeriods(tariff?.products);

        return { product: yearProduct || monthProduct, tariff };
    }
);

const getMonthProductByQuota = createSelector(
    getSingleQuotaTariffs,
    (state, quota) => quota,
    (tariffs, quota) => {
        const monthTariffs = tariffs.filter((tariff) => tariff.products.some((product) => isMonthPeriod(product.period)));

        const tariff = findHigherTariff({ tariffs: monthTariffs, quota, period: null }) || monthTariffs.pop();

        return tariff && tariff.products.find((product) => isMonthPeriod(product.period));
    }
);

const getProductAndTariffByQuota = createSelector(
    getNonSpecialSingleQuoteTariffs,
    (state, quota): number => quota,
    (state, quota, period): EProductPeriod => period,
    (state, quota, period, hasDisko): boolean => hasDisko,
    (tariffs, quota, period, hasDisko) => {
        const periodFunc = period === EProductPeriod.year ? isYearPeriod : isMonthPeriod;

        const tariffsByPeriod = tariffs.filter((tariff) =>
            tariff.products.some((product) => periodFunc(product.period) && product.hasDiskO === hasDisko)
        );

        const tariff = findHigherTariff({ tariffs: tariffsByPeriod, quota, period: null }) || tariffsByPeriod.pop();

        return { tariff, product: tariff && tariff.products.find((product) => periodFunc(product.period)) };
    }
);

const getYearAvailableTariffs = createSelector(
    getNonSpecialSingleQuoteTariffs,
    (state, hasDisko): boolean => hasDisko,
    (tariffs, hasDisko) =>
        tariffs.filter((tariff) => tariff.products.some((product) => isYearPeriod(product.period) && product.hasDiskO === hasDisko))
);

const getQuotaDiscountTariff = createSelector(
    (state: RootState) => getAvailableProductsByListIds(state, PROMO_TARIFFS?.discountQuotaPopup),
    (state, quota) => quota,
    (tariffs, quota) => findHigherTariff({ tariffs, quota, period: null }) || tariffs.pop()
);

const getMailTrialByQuota = createSelector(
    (state: RootState) => getAvailableProductsByListIds(state, PROMO_TARIFFS?.trialsMailPopup),
    (state, quota) => quota,
    (tariffs, quota) => {
        const tariff = findHigherTariff({ tariffs, quota, period: null }) || tariffs.pop();

        return tariff && tariff.products.find((product) => isMonthPeriod(product.period));
    }
);

const isTrialAvailable = createSelector(
    (state, id) => getProductById(state, id),
    (state) => PaidInfoSelectors.isUserWithoutPayment(state),
    (product, isUserWithoutPayment) => product && isUserWithoutPayment
);

const getSaveSubscriptionProduct = createSelector(
    (state): RootState => state,
    (state: RootState) => getFeatureSaveSubscriptionTariffs(state) as { quota: number; isIos: boolean; isTransit: boolean },
    (_, params) => params,
    (state, tariffsConfig, params) => {
        const { isIos, isTransit, quota } = params;

        const config = tariffsConfig?.[quota];

        if (!config) {
            return;
        }

        let id = config['web_id'];

        if (isTransit) {
            id = isIos ? config['web_id_transit_ios'] : config['web_id_transit_android'];
        }

        return getProductById(state, id);
    }
);

export const getBuyFrameProduct = createSelector(
    (state: RootState, productFromQuery) => getProductById(state, productFromQuery),
    (state: RootState) => getProductById(state, PROMO_TARIFFS?.buyFastchekoutFrame),
    getPrimaryProduct,
    getNonSpecialSingleQuoteTariffs,
    (productFromQuery, productFromConfig, primaryProduct, tariffs) => {
        // если в квери параметрах есть productId, берем его
        if (productFromQuery) {
            return { product: productFromQuery };
        }

        // иначе берем тариф из PROMO_TARIFFS и подмешиваем продукт к дефолтному тарифу из списка
        if (productFromConfig) {
            const tariff = getTariffsByQuotaHelper(tariffs, productFromConfig.space.original)[0] as Tariff;

            return {
                product: productFromConfig,
                tariff: {
                    ...tariff,
                    products: tariff.products.map((tariffProduct) =>
                        tariffProduct.period === productFromConfig.period ? productFromConfig : tariffProduct
                    ),
                },
            };
        }

        // если нет ни того ни другого, то берем праймари тариф на 128 гб
        return primaryProduct;
    }
);

export const ProductsSelectors = {
    getTariffs,
    getLifeCycleState,
    isLoaded,
    getProductById,
    getTariffById,
    sortBySpace,
    getAvailableProducts,
    getPromoTariffs,
    getQuotaDiscountTariff,
    getPromoCodeTrialTariffs,
    getNonSpecialSingleQuoteTariffs,
    getNonSpecialTariffs,
    getTariffsActivatedByPromocode,
    getTariffByProductId,
    getMonthProductByQuota,
    getMailTrialByQuota,
    isTrialAvailable,
    getPrimaryProduct,
    getExpiredDeviceProduct,
    getProductAndTariffByQuota,
    getYearAvailableTariffs,
    getTariffsForSubscriptionsPage,
    getSaveSubscriptionProduct,
    getTariffsForQuotaLanding,
    getPrimaryPromoTariff,
    getTwoYearsTariffs,
    getBuyFrameProduct,
    getNormalizedProductsBySpace,
};
