import React, { FC } from 'react';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { Checkbox } from 'reactApp/ui/DataListItemCommonComponents/Checkbox/Checkbox';
import { Destination } from 'reactApp/ui/DataListItemCommonComponents/Destination/Destination';
import { Publish } from 'reactApp/ui/DataListItemCommonComponents/Publish/Publish';
import { Access } from 'reactApp/ui/DataListItemRow/components/Access/Access';
import { Invitation } from 'reactApp/ui/DataListItemRow/components/Invitation/Invitation';
import { ItemName } from 'reactApp/ui/DataListItemRow/components/ItemName/ItemName';
import { Size } from 'reactApp/ui/DataListItemRow/components/Size/Size';
import { TableColumn } from 'reactApp/ui/DataListItemRow/components/TableColumn/TableColumn';
import { IncomingItemProps } from 'reactApp/ui/DataListItemRow/DataListItemRow.types';
import { FilePic } from 'reactApp/ui/FilePic/FilePic';
import { useCompactView } from 'reactApp/ui/VirtualList/VirtualList.hooks';

import styles from '../../DataListItemRow.new.css';

export const IncomingItem: FC<IncomingItemProps> = (props) => {
    const { id, kind, isPublic, name, size, isSelected, isMounted, owner, access, onCheckboxClick, onMount, onAccept, onReject } = props;

    const isCompact = useCompactView();

    return (
        <>
            <TableColumn>
                <Checkbox viewType={EViewMode.list} className={styles.check} onCheckboxClick={onCheckboxClick} isSelected={isSelected} />
                <FilePic isFolder={true} kind={kind} author={isCompact ? owner : undefined} isPublic={isPublic} />
            </TableColumn>

            {isCompact ? (
                <TableColumn width="100%" overflow="hidden">
                    <div className={styles.nameWrapper}>
                        <ItemName name={name} id={id} />
                        <span className={styles.responsiveDateWrapper}>
                            <Access access={access} marginRight />
                            <Size size={size} isInline />
                        </span>
                    </div>
                </TableColumn>
            ) : (
                <>
                    <TableColumn width="100%" overflow="hidden">
                        <ItemName name={name} id={id} />
                    </TableColumn>
                    <TableColumn minWidth={220} width="100%">
                        <Destination destination={owner.name} author={owner} />
                    </TableColumn>
                    <TableColumn flexBasis={132} flexShrink={0} justifyContent="flex-end">
                        <Access access={access} />
                    </TableColumn>
                    <TableColumn flexBasis={132} flexShrink={0} justifyContent="center">
                        <Size size={size} />
                    </TableColumn>
                </>
            )}

            <TableColumn flexBasis={128} flexShrink={0} justifyContent="flex-end" gap={20}>
                {isMounted ? (
                    <Publish viewType={EViewMode.list} onClick={onMount} isShared={true} />
                ) : (
                    <Invitation onAccept={onAccept} onReject={onReject} />
                )}
            </TableColumn>
        </>
    );
};
