import { Icon20Cancel } from '@vkontakte/icons';
import { stopPropagation } from '@vkontakte/vkui/dist/lib/utils';
import classNames from 'clsx';
import { ReactComponent as AlphaIcon } from 'img/pdf-edit/alpha_icon.svg';
import { ReactComponent as CornerIcon } from 'img/pdf-edit/corner.svg';
import React, { useEffect, useState } from 'react';

import styles from './Alpha.css';

interface Props {
    className?: string;
}

export const Alpha: React.FC<Props> = ({ className = '' }) => {
    const [showHint, setShowHint] = useState(false);

    useEffect(() => {
        const blur = () => {
            setShowHint(false);
        };

        window.addEventListener?.('click', blur);

        return () => {
            window.removeEventListener?.('click', blur);
        };
    }, []);

    return (
        <div onClick={stopPropagation} className={classNames(styles.alpha, className)}>
            <AlphaIcon className={styles.alphaIcon} onClick={() => setShowHint(true)} />
            <div className={classNames(styles.alphaHint, { [styles.show]: showHint })}>
                <CornerIcon className={styles.corner} />
                <div className={styles.alphaTitle}>Альфа версия</div>
                <div className={styles.alphaText}>Вы используете тестовую версию pdf-редактора</div>
                <Icon20Cancel className={styles.cancelIcon} onClick={() => setShowHint(false)} />
            </div>
        </div>
    );
};
