import { sendAgreement } from 'Cloud/Application/User';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { X_PAGE_ID } from 'reactApp/appHelpers/configHelpers';
import { BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import { sendAuthDwhRequest } from 'reactApp/modules/dwh/dwh.module';
import { EAuthDwhAction } from 'reactApp/modules/dwh/dwh.types';
import { snackbarController } from 'reactApp/modules/snackbar/snackbar.controller';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import Checkbox from 'reactApp/ui/CheckBox/CheckBox';
import { createGaSender } from 'reactApp/utils/ga';

import styles from './LicenseAgreement.css';
import { IProps } from './LicenseAgreement.types';

const sendGa = createGaSender(`la-dialog`);

export const LicenseAgreement = React.memo(function LicenseAgreement(props: IProps) {
    const { closable = true, source = 'la-dialog' } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const [isCheckboxSelected, setCheckboxSelected] = useState<boolean>(false);
    const dispatch = useDispatch();

    const onChecboxClick = useCallback(() => setCheckboxSelected(!isCheckboxSelected), [isCheckboxSelected]);

    const onConfirm = useCallback(() => {
        setLoading(true);
        sendAgreement(source)
            .done(() => {
                props.onSuccess();
                dispatch(sendAuthDwhRequest({ action: EAuthDwhAction.licenseAccept, source: 'la-dialog' }));
            })
            .fail(() => {
                props.onClose();
                dispatch(sendAuthDwhRequest({ action: EAuthDwhAction.licenseReject, source: 'la-dialog' }));
                snackbarController.showSnackbar({
                    type: SnackbarTypes.failure,
                    text: 'Не удалось согласиться',
                    id: 'la-reject',
                    closable: true,
                });
            });
        sendGa('confirm');
    }, [props]);

    const onClose = useCallback(() => {
        sendGa('close');
        props.onClose();
    }, [props]);

    const renderHeader = useCallback(() => <>Условия использования сервиса</>, []);

    const renderContent = useCallback(() => {
        return (
            <div className={styles.checkboxContainer} onClick={onChecboxClick}>
                <Checkbox selected={isCheckboxSelected} mod="blue" />
                <div className={styles.text}>
                    Я соглашаюсь с использованием{' '}
                    <a href="https://help.mail.ru/legal/terms/cloud/recommendation">рекомендательных технологий</a> в Облаке и принимаю
                    условия{' '}
                    <a
                        className={styles.la}
                        href={`https://help.mail.ru/legal/terms/cloud/LA?signupid=${X_PAGE_ID.toLowerCase()}`}
                        rel="noopener"
                    >
                        Лицензионного соглашения
                    </a>{' '}
                    и{' '}
                    <a className={styles.la} href="https://help.mail.ru/legal/terms/cloud/privacy" target="_blank" rel="noreferrer">
                        Политики конфиденциальности
                    </a>
                </div>
            </div>
        );
    }, [isCheckboxSelected, onChecboxClick]);

    useEffect(() => {
        sendGa('show');
        dispatch(sendAuthDwhRequest({ action: EAuthDwhAction.licenseOpen, source: 'la-dialog' }));
    }, []);

    const buttons = useMemo(() => (closable ? [EButtonType.accept, EButtonType.cancel] : [EButtonType.accept]), [closable]);

    return (
        <BaseConfirmDialog
            dataQAId="license-agreement-dialog"
            renderHeader={renderHeader}
            successButtonText="Согласен"
            cancelButtonText="Не согласен"
            buttons={buttons}
            onClose={onClose}
            onSuccess={onConfirm}
            renderContent={renderContent}
            loading={loading}
            disableAcceptButton={!isCheckboxSelected}
            closable={closable}
        />
    );
});
