import { extInfo } from 'lib/extInfo';
import { USER_EMAIL } from 'reactApp/appHelpers/configHelpers';
import { getExtension, getItemNameWithoutExt, getNameById, getParent, isFolder, normalizePublicApiInfo } from 'reactApp/modules/file/utils';
import {
    ApiFolderResponse,
    ApiFolderResponseFile,
    IFolderFlags,
    Item,
    NormalizeFileFunc,
    NormalizeFolderFunc,
} from 'reactApp/modules/home/home.types';
import { ESortOder, ESortTypes } from 'reactApp/modules/sort/sort.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UrlBuilder } from 'reactApp/modules/urlBuilder/UrlBuilder';
import { normalizeMtime, renameCameraUploadFolder } from 'reactApp/utils/tree.helpers';

export const isApiFolderType = (item: ApiFolderResponse | ApiFolderResponseFile): item is ApiFolderResponse => isFolder(item);

const urlBuilder = new UrlBuilder();

const injectIntraDomainShareProp = (localIntraDomainShare?: boolean, globalIntraDomainShare?: boolean) => {
    const hasSomeIntraDomainShareDefined = !!localIntraDomainShare || !!globalIntraDomainShare;
    return hasSomeIntraDomainShareDefined && { intraDomainShare: true };
};

export const normalizeItem = (item: ApiFolderResponse | ApiFolderResponseFile, parent?: string, globalIntraDomainShare?: boolean): Item => {
    const id = item.home;
    const parentId = parent || getParent(id);

    return {
        id,
        parent: id === '/' ? '' : parentId,
        type: item.type,
        name: item.name || getNameById(id),
        home: id,
        size: item.size,
        descriptorId: item.descriptorId,
        __reduxTree: true,
        ...injectIntraDomainShareProp(item.intra_domain_share, globalIntraDomainShare),
        ...normalizePublicApiInfo(item),
    };
};

export const normalizeFile: NormalizeFileFunc = ({ item, normalizedItem }) => {
    const ext = getExtension(item);
    const nameWithoutExt = getItemNameWithoutExt(item.name, ext);
    const author = item.attr ? item.attr.actor : USER_EMAIL;
    const { kind, subKind } = extInfo.get(ext);
    const path = item.home;

    return {
        ...normalizedItem,
        mtime: normalizeMtime(item.mtime),
        virusScan: item.virus_scan, // temp
        hash: item.hash,
        ext,
        attr: item.attr,
        nameWithoutExt,
        author,
        storage: EStorageType.home,
        kind,
        subKind,
        isMounted: Boolean(item.flags?.mounted),
        isFolder: false,
        uflr: item.uflr,
        isInFavorites: !!item.favorite,
        thumbnails: urlBuilder.getThumb({
            hash: item.hash,
            ext,
            id: normalizedItem.id,
            size: normalizedItem.size,
            kind,
            name: item.name,
            path,
            isPublic: false,
            isStock: false,
            dwl_token: null,
            weblink: '',
        }),
        url: urlBuilder.getUrls({
            ext,
            isPublic: false,
            id: normalizedItem.id,
            weblink: '',
            size: normalizedItem.size,
            kind,
            subKind,
            isStock: false,
            name: item.name,
            path,
        }),
    };
};

export const normalizeFlags = (flags): IFolderFlags | undefined => {
    if (!flags) {
        return;
    }
    if (flags.is_gallery) {
        return {
            isMedia: true,
        };
    }
};

export const normalizeFolder: NormalizeFolderFunc = ({ item, normalizedItem, isLoaded }) => {
    const folders = item.count?.folders ?? 0;
    const files = item.count?.files ?? 0;
    const count = {
        folders,
        files,
        all: folders + files,
        loaded: (item.list || []).length,
    };

    return {
        ...normalizedItem,
        name: normalizedItem.id === '/' ? 'Все файлы' : renameCameraUploadFolder(item),
        isReadOnly: !!item.readonly,
        storage: EStorageType.home,
        isFolder: true,
        __reduxTree: true,
        rev: item.rev,
        kind: item.kind,
        isLoading: false,
        tree: item.tree,
        isInFavorites: !!item.favorite,
        count,
        quota: item.quota,
        sort: item.sort || { type: ESortTypes.name, order: ESortOder.asc },
        hasMoreToLoad: Boolean(isLoaded && count.loaded < count.all),
        childs: [],
        isLoaded,
        isMounted: item.kind === 'mounted' || Boolean(item?.flags?.mounted),
        flags: normalizeFlags(item.flags),
    };
};
