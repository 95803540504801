import { ReactComponent as ShareIcon } from 'img/icons/editor/shareIcon.svg';
import React, { FC, useRef, useState } from 'react';
import { isModifiedSharing } from 'reactApp/appHelpers/appHelpers';
import { publishHelper } from 'reactApp/appHelpers/publishHelper';
import { renderSharingNew } from 'reactApp/components/SharingNewBiz/SharingNew.helpers';
import { renderSharingModified } from 'reactApp/components/SharingWindow/Sharing.helpers';
import { EFrom } from 'reactApp/components/SharingWindow/Sharing.types';
import { EditorItem } from 'reactApp/modules/editor/editor.types';
import { HeaderButton } from 'reactApp/ui/EditorHeader/WhiteEditorHeader/components/HeaderButton/HeaderButton';
import { ShareBubble } from 'reactApp/ui/EditorHeader/WhiteEditorHeader/components/HeaderRightGroup/components/ShareBubble/ShareBubble';
import { ShareButton } from 'reactApp/ui/EditorHeader/WhiteEditorHeader/components/HeaderRightGroup/components/ShareSection/components/ShareButton/ShareButton';
import { ShareButtonCoEditPopup } from 'reactApp/ui/EditorHeader/WhiteEditorHeader/ShareButtonCoEditPopup';

interface Props {
    isPublic: boolean;
    gaSender: (action: string, label: string, data?: any) => void;
    isStock: boolean;
    file: EditorItem | null;
    isCoEditSwitchButtonEnable: boolean;
    id?: string;
    isViewer?: boolean;
}

export const ShareSection: FC<Props> = ({ isPublic, gaSender, isCoEditSwitchButtonEnable, file, id = '', isViewer, isStock }) => {
    const [isShowBubble, setIsShowBubble] = useState(false);
    const buttonTextRef = useRef<HTMLDivElement>(null);

    const closeBubble = () => {
        setIsShowBubble(false);
    };

    const handlePublish = (e?: React.MouseEvent<HTMLDivElement> | React.ChangeEvent<HTMLInputElement>) => {
        e?.stopPropagation();

        // tempexp_15211-next-line
        gaSender('publish', 'click', { type: isCoEditSwitchButtonEnable ? 'switch' : 'button' });

        closeBubble();

        publishHelper({ item: file, gaSuffix: '_header' });
    };

    const handleShareDocument = () => {
        gaSender('share', 'click');

        const renderSharing = isModifiedSharing(file) ? renderSharingModified : renderSharingNew;

        renderSharing({ id, from: EFrom.SHARING });
    };

    return isPublic ? (
        <HeaderButton icon={<ShareIcon />} onClick={handleShareDocument} textRef={buttonTextRef} hint="Поделиться ссылкой" qaId="share" />
    ) : (
        <>
            {isShowBubble && <ShareBubble buttonTextRef={buttonTextRef} closeBubble={closeBubble} />}
            {!isPublic && !isViewer && !isStock ? (
                <ShareButtonCoEditPopup>
                    <ShareButton
                        buttonTextRef={buttonTextRef}
                        file={file}
                        handlePublish={handlePublish}
                        isCoEditSwitchButtonEnable={isCoEditSwitchButtonEnable}
                    />
                </ShareButtonCoEditPopup>
            ) : (
                <ShareButton
                    buttonTextRef={buttonTextRef}
                    file={file}
                    handlePublish={handlePublish}
                    isCoEditSwitchButtonEnable={isCoEditSwitchButtonEnable}
                />
            )}
        </>
    );
};
