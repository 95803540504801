/* eslint-disable max-lines-per-function */
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { extInfo } from 'lib/extInfo';
import { itemV4ToV2 } from 'reactApp/api/helpers/apiV4Helpers';
import { IS_OWN_PUBLIC } from 'reactApp/appHelpers/configHelpers';
import { getExtension, isMedia } from 'reactApp/modules/file/utils';
import { addFilesSuccess, removeFileSuccess, updateFolder } from 'reactApp/modules/modifying/modifying.actions';
import { AddFileSuccessAction, RemoveFileSuccessAction } from 'reactApp/modules/modifying/modifying.types';
import { isPublicApiResponseType, PublicFolder, PublicState, WeblinkDownloadsCounts } from 'reactApp/modules/public/public.types';
import { setTreeAction } from 'reactApp/modules/tree/tree.actions';
import { AccessRights } from 'reactApp/types/Tree';
import { IPublicApiFolder, isPublicFile } from 'server/types/context/IPublicFolder';

import {
    forceShowDownloadAppButton,
    loadFolderStart,
    loadMorePublicFolderRequest,
    loadMorePublicFolderSuccess,
    loadPublicFileSuccess,
    loadPublicFolder,
    loadPublicFolderFail,
    loadPublicPage,
    updateCountDownloads,
} from './public.actions';
import { filterUflrFiles, normalize } from './public.helpers';
import { loadPublicAlbumListSuccess, loadPublicAlbumPageSuccess } from './publicAlbum.actions';
import { normalizeAlbum, normalizeAlbumItem } from './publicAlbum.helper';
import { ILoadPublicAlbumListSuccess, ILoadPublicAlbumPageSuccess } from './publicAlbum.types';

const initialState: PublicState = {
    list: {},
    rootWeblink: {
        isOwn: false,
        isUploadEnabled: false,
        weblink: '',
        name: '',
    },
    forceShowDownloadButton: false,
};

export const publicReducer = createReducer(initialState, {
    [setTreeAction.type]: (state, action) => {
        const { payload } = action;

        if (!payload.length || !payload[0].weblink) {
            return state;
        }

        payload.forEach((item) => {
            if (state.list[item.weblink]) {
                return;
            }

            normalize({
                list: state.list,
                item,
                loaded: false,
                isLoading: true,
                isOwn: state.rootWeblink.isOwn,
                ownerEmail: state?.owner?.email,
            });
        });
    },
    [loadPublicPage.type]: (state, action: ReturnType<typeof itemV4ToV2>) => {
        const { payload } = action;

        const rootWeblink = payload.public;

        state.rootWeblink = {
            weblink: rootWeblink?.id || '',
            name: rootWeblink?.name || '',
            isOwn: Boolean(payload.home),
            isUploadEnabled: payload.weblinkAccessRights === AccessRights.rw,
            uploadLimit: payload.owner?.upload_limit || 0,
            isAutodelete: payload.weblinkAutoDelete,
            weblinkExpires: payload.weblinkExpires,
            weblinkDownloadable: rootWeblink?.flags?.DOWNLOADABLE,
            count_downloads_left: rootWeblink.count_downloads_left,
            count_downloads_total: rootWeblink.count_downloads_total,
        };

        state.owner = payload.owner
            ? {
                  email: payload.owner.email,
                  nick: payload.owner.nick,
                  lastName: payload.owner.last_name,
                  firstName: payload.owner.first_name,
              }
            : undefined;
        normalize({ list: state.list, item: payload, loaded: true });
    },
    [loadFolderStart.type]: (state, action: PayloadAction<{ id: string }>): void => {
        const folder = state.list[action.payload.id];

        if (folder?.isFolder) {
            folder.isLoading = true;
            folder.error = '';
        }
    },
    [loadPublicFolder.type]: (state, action: PayloadAction<IPublicApiFolder>) => {
        state.rootWeblink.uploadLimit = action.payload.owner?.upload_limit || 0;
        state.rootWeblink.count_downloads_left = action.payload.public?.count_downloads_left;
        state.rootWeblink.count_downloads_total = action.payload.public?.count_downloads_total;

        normalize({ list: state.list, item: action.payload, loaded: true });
    },
    [loadPublicFolderFail.type]: (state, action: PayloadAction<{ id: string; error: string }>): void => {
        const folder = state.list[action.payload.id];
        if (folder?.isFolder) {
            folder.isLoading = false;
            folder.error = action.payload.error ?? 'error';
        }
    },
    [loadMorePublicFolderRequest.type]: (state, action: PayloadAction<{ id: string; offset: number }>) => {
        const folder = state.list[action.payload.id];
        if (folder && folder.isFolder) {
            folder.isLoading = true;
        }
    },
    [loadMorePublicFolderSuccess.type]: (state, action: PayloadAction<IPublicApiFolder>) => {
        const { payload } = action;
        const id = payload.weblink;
        const folder = state.list[id];

        if (folder && isPublicApiResponseType(payload) && folder.isFolder && payload.list) {
            const list = filterUflrFiles(payload.list) || [];
            const uflrCount = payload.list.length - list.length;

            folder.count = {
                files: payload.count.files - uflrCount,
                folders: payload.count.folders,
                all: payload.count.files - uflrCount + payload.count.folders,
                loaded: (folder?.count?.loaded || 0) + list.length,
            };
            folder.isLoading = false;
            folder.hasMoreToLoad = folder.count.all > folder.count.loaded + uflrCount;
            list.forEach((item) => {
                folder.childs.push(item.weblink);
                normalize({ list: state.list, item, loaded: true });
            });
        }
    },
    [addFilesSuccess.type]: (state, action: PayloadAction<AddFileSuccessAction>) => {
        const { parent: parentId, items } = action.payload;
        const parent = state.list[parentId] as PublicFolder | undefined;

        if (!parent) {
            return;
        }

        items.forEach((item) => {
            if (!item.weblink) {
                return;
            }

            normalize({
                list: state.list,
                parent: parentId,
                // @ts-ignore
                item: {
                    ...item,
                    weblink: item.weblink,
                },
            });

            const isFile = item.type === 'file';

            let kind = item.kind;

            if (item.kind === 'file') {
                const ext = getExtension(item);
                kind = ext ? extInfo.get(ext?.toLowerCase())?.kind : null || kind;
            }

            if (!isFile || !isMedia({ kind })) {
                parent.flags = {
                    ...(parent.flags || {}),
                    isMedia: false,
                };
            }

            parent.childs.push(item.weblink);
            parent.count = {
                all: parent.count.all + 1,
                loaded: parent.count.loaded + 1,
                folders: parent.count.folders + Number(!isFile),
                files: parent.count.files + Number(isFile),
            };
        });
    },
    [updateFolder.type]: (state, action: PayloadAction<IPublicApiFolder>) => {
        const { payload } = action;

        if (!state.list[payload.weblink]) {
            return;
        }
        normalize({ list: state.list, item: payload, loaded: true });
    },
    [loadFolderStart.type]: (state, action: PayloadAction<{ id: string }>): void => {
        const folder = state.list[action.payload.id];

        if (folder?.isFolder) {
            folder.isLoading = true;
        }
    },
    [loadPublicFileSuccess.type]: (state, action: PayloadAction<IPublicApiFolder>): void => {
        const item = action.payload;

        if (!item.weblink || state.list[item.weblink]) {
            return;
        }

        if (isPublicFile(item)) {
            state.rootWeblink.isOwn = !!item.home;
            state.rootWeblink.name = item.name;
            state.rootWeblink.weblink = item.weblink;
            // @ts-ignore
            state.rootWeblink.weblinkExpires = item.weblinkExpires;
            state.rootWeblink.weblinkDownloadable = item.public?.flags?.DOWNLOADABLE;
            state.rootWeblink.count_downloads_left = item.public?.count_downloads_left;
            state.rootWeblink.count_downloads_total = item.public?.count_downloads_total;
        }

        normalize({
            list: state.list,
            parent: '/',
            item,
        });
    },
    [removeFileSuccess.type]: (state, action: PayloadAction<RemoveFileSuccessAction>) => {
        const { ids } = action.payload;

        ids.forEach((id) => {
            const item = state.list[id];

            if (item) {
                const parent = state.list[item.parent || ''] as PublicFolder | undefined;

                if (parent) {
                    parent.childs = parent.childs.filter((child) => child !== id);

                    parent.count = {
                        all: parent.count.all - 1,
                        loaded: parent.count.loaded - 1,
                        folders: parent.count.folders - Number(item.isFolder),
                        files: parent.count.files - Number(!item.isFolder),
                    };

                    if (!item.isFolder && item.author && parent.authors) {
                        const authorCount = parent.authors[item.author];

                        if (authorCount === 1) {
                            delete parent.authors[item.author];
                        } else {
                            parent.authors[item.author] = authorCount - 1;
                        }
                    }
                }

                delete state.list[id];
            }
        });
    },
    [loadPublicAlbumPageSuccess.type]: (state, action: PayloadAction<ILoadPublicAlbumPageSuccess>) => {
        const folder = normalizeAlbum(action.payload.folder);
        const { list, ...album } = folder;
        const folderWeblink = folder.weblink;

        state.rootWeblink = {
            albumId: folder.albumId,
            weblink: folderWeblink,
            name: folder.name,
            isUploadEnabled: false,
            isOwn: IS_OWN_PUBLIC,
            count_downloads_left: folder.count_downloads_left,
            count_downloads_total: folder.count_downloads_total,
        };

        state.list[folderWeblink] = album;

        list.forEach((file) => {
            state.list[file.weblink] = file;
        });
    },
    [loadPublicAlbumListSuccess.type]: (state, action: PayloadAction<ILoadPublicAlbumListSuccess>) => {
        const { list: apiList, weblink, cursor } = action.payload;
        const parent = state.list[weblink] as PublicFolder;
        const filteredItems = filterUflrFiles(apiList);

        if (!parent || !filteredItems || !weblink) {
            return;
        }

        const childs: string[] = [...parent.childs];

        filteredItems.forEach((file) => {
            const item = normalizeAlbumItem(file, weblink);

            childs.push(item.weblink);
            state.list[item.weblink] = item;
        });

        state.list[weblink] = {
            ...parent,
            childs,
            isLoading: false,
            isLoaded: true,
            hasMoreToLoad: Boolean(cursor),
            cursor,
            count: {
                files: 0,
                folders: 0,
                all: childs.length,
                loaded: childs.length,
            },
        };
    },
    [forceShowDownloadAppButton.type]: (state) => {
        state.forceShowDownloadButton = true;
    },
    [updateCountDownloads.type]: (state, action: PayloadAction<WeblinkDownloadsCounts>) => {
        const {
            payload: { count_downloads_left, count_downloads_total },
        } = action;
        state.rootWeblink.count_downloads_left = count_downloads_left;
        state.rootWeblink.count_downloads_total = count_downloads_total;
    },
});
