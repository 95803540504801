import { useEffect, useState } from 'react';
import { OvidiusApiError } from 'reactApp/modules/ovidius/ovidius.types';
import { convertToPdfAndDownload } from 'reactApp/utils/convertDocumentToPdfAndDownload';
import { isBuffer } from 'reactApp/utils/downloadDocumentHelpers';

import { ViewerPdfProps } from '../ViewerPdf';

const convertBufferToObjectURL = (content: ArrayBuffer) => {
    const blob = new Uint8Array(content);
    const file = new Blob([blob], { type: 'application/pdf' });

    return URL.createObjectURL(file);
};

export const useConvertDocumentToPdf = ({ file, convert }: Pick<ViewerPdfProps, 'file' | 'convert'>) => {
    const [error, setError] = useState<OvidiusApiError | undefined>();
    const [fileURL, setFileURL] = useState<string>('');

    useEffect(() => {
        let cancel = false;
        if (!file || !file.storage || !convert) {
            return;
        }

        const fetchDocumentData = async () => {
            const { content, error } = await convertToPdfAndDownload(file);

            if (cancel) {
                return;
            }
            if (isBuffer(content)) {
                const objectUrl = convertBufferToObjectURL(content);
                setFileURL(objectUrl);
                return;
            }

            if (error) {
                setError(error);
            }
        };

        fetchDocumentData();

        return () => {
            cancel = true;
            URL.revokeObjectURL(fileURL);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { fileURL, error, radarError: error ? 'convert_err' : '' };
};
