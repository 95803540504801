/* eslint-disable object-shorthand */
/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
/* eslint-disable no-var */
/* eslint-disable global-require */
define(['jquery', 'Cloud/Tree', 'Cloud/Application/api'], function ($, CloudTree, api) {
    const { logger } = require('lib/logger');
    const { store: reduxStore } = require('reactApp/store');
    const { loadFolderFail } = require('reactApp/modules/home/home.actions');
    const { checkFolderForUpdate } = require('reactApp/modules/modifying/modifying.actions');
    const { loadPublicFolder, loadFolderStart: loadPublicFolderStart, loadPublicFolderFail } = require('reactApp/modules/public/public.actions');
    const { getStorage } = require('reactApp/modules/storage/storage.helpers');
    const { getHomeItemById } = require('reactApp/modules/home/home.selectors');
    const { getLoadMoreLimit } = require('reactApp/modules/storage/storage.helpers');
    const { captureMessage } = require('@sentry/browser');
    const { itemV4ToV2 } = require('reactApp/api/helpers/apiV4Helpers');
    const { path } = require('ramda');
    const { promisifyDeferredCall } = require('reactApp/utils/helpers');
    const { sendXray } = require('reactApp/utils/ga');
    const { PublicListAPICall } = require('reactApp/api/PublicListAPICall');
    const { getSortById, defaultSort } = require('reactApp/modules/sort/sort.selectors');
    const { EStorageType } = require('reactApp/modules/storage/storage.types');
    const { MAIL_ATTACHES_FOLDER_ID } = require('reactApp/constants/magicIdentificators');
    const { cloneAttach, getMessage } = require('reactApp/api/axios.corsapi');
    const config = require('Cloud/config');
    const { parseAttachId } = require('reactApp/modules/attaches/attaches.helpers');
    const { routeStatusPage } = require('reactApp/modules/router/router.module');
    const { EStatus } = require('reactApp/sections/ErrorPage/ErrorPage.types');
    const { getAlbumIdsParams } = require('reactApp/modules/albums/albums.selector');

    return function (app, _) {
        var forest = {};

        var STORAGE_EVENTS = [
            'init',
            'setFolder',
            'setItem',
            'remove',
            'move',
            'rename',
            'clearCache',
            'updateRevision',
            'beforeInit',
            'beforeSetFolder',
            'beforeSetItem',
            'beforeRemove',
            'beforeMove',
            'beforeRename',
            'beforeClearCache',
            'beforeUpdateRevision',
        ];

        var getHistoryCurrentStateStorageName = function () {
            return _ && typeof _.sid === 'function' ? _.sid() : '';
        };

        var getHistoryCurrentStateId = function () {
            return _ && _.cid ? _.cid() : null;
        };

        Object.entries(app.settings.storages || {}).forEach(([storageName, storage]) => {
            var init_revision;

            if (storage.settings.revisions) {
                if (app.settings.folders && app.settings.folders.folder) {
                    init_revision = app.settings.folders.folder.rev;
                }
            }

            forest[storageName] = new CloudTree(storage, init_revision, { v2: _.v2, v4: _.v4 }, app);

            forest[storageName].on(STORAGE_EVENTS.join(' '), function (evt) {
                var params = Array.prototype.slice.call(arguments, 1);
                app.emit.apply(app, ['tree:' + storageName + ':' + evt.type].concat(params));
            });
        });

        _.tree = function (storageName) {
            return forest[storageName || getHistoryCurrentStateStorageName()];
        };

        Object.assign(_, {
            initTree: function () {
                Object.entries(app.settings.storages || {}).forEach(([storageName, storage]) => {
                    if (
                        (storageName === getHistoryCurrentStateStorageName() && !app.isTrashBin()) ||
                        (storageName === 'home' && app.isTrashBin())
                    ) {
                        forest[storageName].init(app.settings.folders);
                    } else {
                        forest[storageName].init([]);
                    }

                    if (storageName === getHistoryCurrentStateStorageName() && app.settings.file && app.settings.file.id) {
                        forest[storageName].setItem(app.settings.file, app.settings.file.folder || '/');
                    }
                });

                // Удалим кеш папок/файлов из этого объекта, т.е. он уже сохранён в локальном кеше
                // + app.settings может посылаться вместе с ошибками, так что не нужно в нём оставлять ненужные данные
                delete app.settings.folders;
            },

            idParams: function (storageName, id, otherParams) {
                var _idParams = {};
                var isArray = Array.isArray(id);

                // Получаем CloudTree хранилище
                var tree = _.tree(storageName);

                function getId(id) {
                    return tree && tree.has(id) ? tree.getId(id) : id;
                }

                if (isArray) {
                    id = id.map(getId);
                } else {
                    id = getId(id);
                }

                switch (storageName) {
                    case EStorageType.home:
                    case EStorageType.search:
                    case EStorageType.sharedLinks:
                    case EStorageType.sharedAutodelete:
                    case EStorageType.sharedIncoming:
                    case EStorageType.office:
                    case EStorageType.r7:
                    case EStorageType.myoffice:
                    case EStorageType.myofficeAttaches:
                    case EStorageType.favorites:
                    case EStorageType.recommend:
                    case EStorageType.feed:
                    case EStorageType.start:
                    case EStorageType.gallery:
                    case EStorageType.documents:
                    case EStorageType.alldocuments:
                    case EStorageType.attaches:
                    case EStorageType.story:
                    case EStorageType.quotaCleaner:
                    case EStorageType.r7wopi:
                        if (isArray) {
                            _idParams = { home_list: id };
                        } else if (otherParams?.version === 4) {
                            _idParams = { path: id };
                        } else {
                            _idParams = { home: id };
                        }
                        break;

                    case 'links':
                    case 'public':
                        if (isArray) {
                            _idParams = { weblink_list: id };
                        } else {
                            _idParams = { weblink: id };
                        }
                        break;

                    case 'files':
                        if (isArray) {
                            _idParams = { files_list: id };
                        } else {
                            _idParams = { files: id };
                        }
                        break;

                    case 'stock':
                        if (isArray) {
                            _idParams = { stock_list: id };
                        } else {
                            _idParams = { stock: id };
                        }
                        break;

                    case 'trashbin':
                        if (isArray) {
                            _idParams = { trashbin_list: id };
                        } else {
                            _idParams = { trashbin: id };
                        }
                        break;

                    case 'albums':
                        _idParams = getAlbumIdsParams(reduxStore.getState(), id);
                        break;

                    default:
                        logger.error('idParams wrong storage');
                }

                return Object.assign(_idParams, otherParams || {});
            },
        });

        function _extend(target, source) {
            for (var prop in source) {
                if (source.hasOwnProperty(prop) && !target.hasOwnProperty(prop)) {
                    target[prop] = source[prop];
                }
            }
            return target;
        }

        function _normalizeResultCount(result) {
            var count;
            result = result || {};

            if (result.count) {
                return result.count;
            }

            count = {
                files: 0,
                folders: 0,
            };

            result.list = Array.isArray(result.list) ? result.list : [];

            for (var i = 0, len = result.list.length; i < len; i++) {
                if (app.isFolder(result.list[i])) {
                    count.folders++;
                } else {
                    count.files++;
                }
            }

            return count;
        }

        function _normalizeResult(result) {
            if (Array.isArray(result)) {
                result = {
                    list: result,
                };
            }

            result = _extend(result, {
                id: '/',
                list: result.list || [],
                name: '/',
                rev: null,
            });

            if (!result.count) {
                result.count = _normalizeResultCount(result);
            }

            return [result];
        }

        function reloadStorage(storageName, id, deferred) {
            var xhr = api.file(_.idParams(storageName, id));

            xhr.done(function (result) {
                var tree = _.tree(storageName);

                tree.updateItem(result);
                tree.updateAuthors(tree.getParentId(id));

                deferred.resolve(result);
            }).fail(function (errors, status, response) {
                captureMessage('RELOAD', {
                    arguments: errors,
                });

                deferred.reject(errors, status, response);
            });

            return deferred;
        }

        function loadItemData(storageName, id, deferred) {
            var tree = _.tree(storageName);
            var xhr = (function (batchDefer) {
                let sort = tree.getSort(id);

                api.batch(
                    _.idParams(storageName, id, {
                        batch: [{ method: 'folder/tree' }, { method: 'folder' }],
                        sort: sort,
                    })
                )
                    .done(function (result) {
                        // FIXME: может быть эту логику стоит перенести глубже в API

                        if (!result[0].response.isOK()) {
                            batchDefer.reject(result[0].data, result[0].status, result[0].response);
                        } else if (!result[1].response.isOK()) {
                            batchDefer.reject(result[1].data, result[1].status, result[1].response);
                        } else {
                            const folder = result[1].data;

                            if (storageName === 'public') {
                                reduxStore.dispatch(loadPublicFolder({ ...folder }));
                            }

                            batchDefer.resolve({
                                tree: result[0].data,
                                folder,
                            });
                        }
                    })
                    .fail(batchDefer.reject);

                return batchDefer.promise();
            })(new $.Deferred());

            xhr.done(function (result, status, response, revision) {
                var tree = _.tree(storageName);
                var cid = getHistoryCurrentStateId();
                tree.setTree(result, revision, true);

                var parentId = tree.getParentId(id);
                var lastOffset;

                var loadNext = function (result) {
                    var newOffset = tree.itemsCount(parentId);

                    if (tree.has(id, parentId) || cid != getHistoryCurrentStateId()) {
                        deferred.resolve(result);
                        app.setTitle();

                        // проверяем, что мы не зацикливаемся
                    } else if (newOffset !== lastOffset) {
                        lastOffset = newOffset;

                        app.loadFolder(parentId, storageName, newOffset, false, true)
                            .done(loadNext)
                            .fail(function (errors, status, response) {
                                deferred.reject(errors, status, response);
                            });
                    } else {
                        deferred.reject(
                            {
                                offset: {
                                    error: 'the_same_offset',
                                    value: newOffset,
                                },
                            },
                            429,
                            response
                        );
                    }
                };

                loadNext(result);
            }).fail(function (errors, status, response) {
                captureMessage('LOAD', {
                    arguments: errors,
                });

                //					if ( response.isInvalid() ) {
                reduxStore.dispatch(routeStatusPage({ status: EStatus.NOT_FOUND }));
                //					}

                deferred.reject(errors, status, response);
            });

            return deferred;
        }

        function findAttach (unparsedMessageId) {
            if (!unparsedMessageId) {
                return Promise.resolve(null);
            }

            const { messageId, partId } = parseAttachId(unparsedMessageId);
            const email = config.get('user.email');

            return getMessage({
                messageId,
                email,
            }).then(response => {
                return response?.attaches?.list?.find((attach) => attach.id === partId);
            });
        }

        function addAttachToCloud (unparsedMessageId) {
            const { messageId, partId } = parseAttachId(unparsedMessageId);
            const email = config.get('user.email');

            return cloneAttach({
                ids: [`${messageId};${partId}`],
                folder: MAIL_ATTACHES_FOLDER_ID,
                email,
            });
        }

        function loadAttachItem (storageName, id, deferred) {
            findAttach(id).then(() => {
                addAttachToCloud(id).then((res) => {
                    loadItemData(storageName, res[0], deferred);
                    const state = _.history.currentState();
                    state.id = res[0];
                    _.history.setState(state);
                });
            });
        }

        Object.assign(app, {
            loadFolder: function (id, storageName, offset, dontDispatchAction, isLoadMore) {
                let versionApi = 2;
                storageName = storageName || getHistoryCurrentStateStorageName();

                const tree = _.tree(storageName);
                id = tree.getId(id);

                const sort = getSortById(reduxStore.getState(), id);

                var xhr;
                var d = new $.Deferred();

                const getParams = (versionApi) => {
                    const { type, order } = sort || defaultSort;
                    return _.idParams(storageName, id, {
                        sort: type,
                        order,
                        offset: offset || 0,
                        limit: getLoadMoreLimit(storageName),
                        version: versionApi,
                    });
                };

                if (storageName === 'public') {
                    reduxStore.dispatch(loadPublicFolderStart({ id }));
                    versionApi = 4;

                    xhr = new PublicListAPICall().makeRequest(getParams(versionApi));
                } else {
                    xhr = promisifyDeferredCall(
                        api.folder,
                        _.idParams(storageName, id, {
                            sort,
                            offset: offset || 0,
                            limit: getLoadMoreLimit(storageName),
                        }),
                        storageName === 'public'
                    );
                }

                sendXray(`app_load-folder_${getHistoryCurrentStateStorageName()}`);

                xhr.then(function (folder, status, response, revision) {
                    const result = versionApi === 4 ? itemV4ToV2(folder.data, storageName) : folder;

                    if (!dontDispatchAction && storageName === 'public') {
                        reduxStore.dispatch(loadPublicFolder({ ...result }));
                    }

                    try {
                        revision = revision || result.rev;
                        result.sort = sort;

                        _.tree(storageName).setFolder({ ...result }, revision, true, offset, dontDispatchAction);
                        d.resolve({ ...result }, status, response, revision);
                    } catch (e) {
                        d.reject(e);
                    }
                }).catch(function (errors, status, response) {
                    captureMessage('LOAD FOLDER', {
                        arguments: errors,
                    });

                    if (response && 'isInvalid' in response && response.isInvalid()) {
                        reduxStore.dispatch(routeStatusPage({ status: EStatus.NOT_FOUND }));
                    }

                    if (!dontDispatchAction) {
                        if (storageName === 'home' || storageName === 'start') {
                            reduxStore.dispatch(loadFolderFail({ id, error: status?.toString() }));
                            // если делать reject, то будет редирект на 404
                            d.resolve({}, status, response);
                            return;
                        } else if (storageName === 'public') {
                            reduxStore.dispatch(loadPublicFolderFail({ id, error: status?.toString() }));
                            d.resolve({}, status, response);
                            return;
                        }
                    }

                    d.reject(errors, status, response);
                });

                return d.promise();
            },

            load: function (id, storageName, reloadItem) {
                storageName = storageName || getHistoryCurrentStateStorageName();

                var deferred = new $.Deferred();
                var tree = _.tree(storageName);

                var storage = getStorage(storageName);

                // перезагружаем информацию по папке/файлу
                if (reloadItem) {
                    reloadStorage(storageName, id, deferred);

                    return RADAR.deferred('app_reload', deferred.promise());

                    // если объект уже загружен, то выполняем callback
                } else if (tree.isLoaded(id, true) && !app.isTrashBin() && !app.isSearch()) {
                    setTimeout(function () {
                        reduxStore.dispatch(checkFolderForUpdate(id));
                    }, 500); // 0,5 sec CLOUDWEB-6357

                    deferred.resolve();
                } else if (storage.isReactDataList || storage.isPublic) {
                    deferred.resolve();
                    // если объект есть, но не загружен, то это папка,
                    // но не загружены ее дочерние объекты
                    // нужно просто их догрузить
                    // или нет, но вызывающий код заранее уверен, что это папка
                } else if (tree.has(id) || (storageName === 'home' && path(['isFolder'], getHomeItemById(reduxStore.getState(), id)))) {
                    this.loadFolder(id, storageName, 0).done(deferred.resolve).fail(deferred.reject);
                    // в противном случае это хз что, сходим и посмотрим
                } else if (storageName === 'myoffice/edit/attaches') {
                    loadAttachItem(storageName, id, deferred);
                } else {
                    loadItemData(storageName, id, deferred);
                }

                return RADAR.deferred('app_load_' + getHistoryCurrentStateStorageName(), deferred.promise());
            },

            reload: function (id) {
                return this.load(id || app.currentFolder().id, getHistoryCurrentStateStorageName());
            },

            get: function (id, storageName) {
                if (typeof id === 'object' && id) {
                    // Фикс, чтобы правильно извлекался item, когда пользователь находится в другом разделе
                    storageName = id.__storage;

                    // в новом дереве нет полей url и тд. поэтому ходим в старое дерево по id
                    if (id.__reduxTree) {
                        id = id.id;
                    }
                }

                var tree = _.tree(storageName);

                return tree ? _.normalizeItem(tree.get(id)) : void 0;
            },

            current: function () {
                return this.get(getHistoryCurrentStateId());
            },

            getSort: function (folder) {
                return _.tree().getSort(folder);
            },

            setSort: function (storageName, sort) {
                return _.tree().setSort(storageName, sort);
            },

            isLoaded: function (id, storageName) {
                return _.tree(storageName).isLoaded(id);
            },
        });
    };
});
