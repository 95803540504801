import { PDFDocument } from 'pdf-lib';

export const mergePdfs = async (pdfsToMerges: ArrayBuffer[]) => {
    const mergedPdf = await PDFDocument.create();

    for (let i = 0; i < pdfsToMerges.length; i++) {
        const pdf = await PDFDocument.load(new Uint8Array(pdfsToMerges[i]));
        const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
        copiedPages.forEach((page) => mergedPdf.addPage(page));
    }

    return await mergedPdf.save();
};
