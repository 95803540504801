import { logger } from 'lib/logger';
import { handleSendAllDocumentsXray } from 'reactApp/modules/allDocuments/allDocuments.saga';
import { AllDocumentsXrayTypes } from 'reactApp/modules/allDocuments/allDocuments.types';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { isOwnPublic, isPublicUploadEnabled } from 'reactApp/modules/public/public.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { sendGaUploaderNew } from 'reactApp/modules/uploading/helpers/uploading.helpers';
import { clearUploadedQueue } from 'reactApp/modules/uploading/uploading.module';
import { filesPlural } from 'reactApp/utils/pluralHelpers';
import { cancel, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { resetProgress, setNotAllowedExtensionsAction, uploaderTotalProgress } from './upload.module';

const sendGa = (successCount, failedCount, isOwnPublicRO) => {
    sendGaUploaderNew('file', 'all');
    sendGaUploaderNew(`file-newUploader`, 'all');

    const count = 100;

    const sendPart = (startI) => {
        if (startI >= failedCount && startI >= successCount) {
            return;
        }

        for (let i = startI; i < failedCount && i < startI + count; i++) {
            sendGaUploaderNew('file', 'failed');
            sendGaUploaderNew(`file-newUploader`, 'failed');
        }

        for (let i = startI; i < successCount && i < startI + count; i++) {
            sendGaUploaderNew('file', 'success');
            sendGaUploaderNew(`file-newUploader`, 'success');
            if (isOwnPublicRO) {
                sendGaUploaderNew('file-own', 'success');
            }
        }

        setTimeout(() => sendPart(startI + count), 100);
    };

    setTimeout(() => sendPart(0), 100);
};

function* allDocumentsGa(count: number, xrayType: AllDocumentsXrayTypes) {
    for (let i = 0; i < count; i++) {
        yield handleSendAllDocumentsXray({ action: 'upload_file', xrayType });
    }
}

function* handleUploaderTotalProgress(action) {
    try {
        const { total, finished, failed, canceled } = action.payload;

        if (total > 0 && total !== finished) {
            return;
        }

        const isPhone = yield select(EnvironmentSelectors.isPhone);
        const storage = yield select(getCurrentStorage);
        const isOwnPublicRO: boolean =
            storage === EStorageType.public ? yield select(isOwnPublic) && !(yield select(isPublicUploadEnabled)) : false;
        const failedCount = failed + canceled;
        const successCount = total - failedCount;

        sendGa(successCount, failedCount - canceled, isOwnPublicRO);

        if (failedCount > 0) {
            if (successCount > 0) {
                yield put(
                    showSnackbarAction({
                        type: SnackbarTypes.failure,
                        id: 'file-upload-err',
                        text: `Успешно загружено ${successCount} ${filesPlural(successCount)} из ${total}`,
                        closable: true,
                    })
                );

                yield allDocumentsGa(successCount, AllDocumentsXrayTypes.onSuccess);
            }
            yield allDocumentsGa(failedCount, AllDocumentsXrayTypes.onError);
        } else {
            yield put(
                showSnackbarAction({
                    type: SnackbarTypes.success,
                    id: 'file-upload',
                    title: isPhone ? 'Файлы загружены' : undefined,
                    text: isPhone ? 'Проверяются антивирусом Касперского' : 'Загрузка успешно завершена',
                    closable: true,
                })
            );
            yield allDocumentsGa(successCount, AllDocumentsXrayTypes.onSuccess);
        }

        yield delay(3000);

        if (storage === EStorageType.start) {
            yield put(clearUploadedQueue);
        }

        yield put(resetProgress());
    } catch (error) {
        logger.error(error);
        yield cancel();
    }
}

function* handleNotAllowedExtensions() {
    const currentStorage = yield select(getCurrentStorage);

    let item;

    if (currentStorage === EStorageType.documents) {
        item = {
            id: 'document-upload',
            closable: true,
            text: 'В личные документы можно загружать только изображения и PDF файлы',
            type: SnackbarTypes.failure,
        };
    }

    if (!item) {
        return;
    }

    yield put(showSnackbarAction(item));
}

export function* watchUploaderRoot() {
    yield takeLatest(uploaderTotalProgress.toString(), handleUploaderTotalProgress);
    yield takeEvery(setNotAllowedExtensionsAction.toString(), handleNotAllowedExtensions);
}
