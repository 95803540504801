import { xray } from 'lib/xray';
import { IAllDocumentsState } from 'reactApp/modules/allDocuments/allDocuments.types';

const radarName = 'alldocuments';

export const allDocumentsHideSpaceRadar = (isHideSpace: boolean) => xray.send(`${radarName}_space_${isHideSpace ? 'hidden' : 'visible'}`);

/**
 * Технические радары страницы всех документов.
 *
 * @param section {IAllDocumentsState['currentDocType']} Тип документа.
 * @param action {string} Экшен для текущего действия.
 */
export const allDocumentsRadars = ({ section, action }: { section: IAllDocumentsState['currentDocType']; action: string }) =>
    ({
        onSuccess: () => xray.send(`${radarName}_${section}_${action}_success`),
        onError: () => xray.send(`${radarName}_${section}_${action}_error`),
    } as const);

/**
 * Преобразовывает все разрешенные расширения для раздела Документы в единый массив
 *
 * @param {T} docExtensions - объект содержащий в себе разрешенные расширения по подразделам для раздела Документы
 * @return {string[]} массив строк, состоящий только из разрешенных расширений для всего раздела Документы
 */
export const getAllDocumentsAlllowedExt = <T>(docExtensions: T): string[] => {
    return Object.values(docExtensions).reduce<string[]>((allowedExt, currentType) => allowedExt.concat(currentType), []);
};
