import { createGaSender, sendXray } from 'reactApp/utils/ga';

export const sendGaAb = createGaSender('pubfile');

export const handleOnMobileApp = () => {
    const deepLink = `?mt_deeplink=${window.location.href}`;
    const link = 'https://trk.mail.ru/c/kw1fh0';

    sendXray(['public-file-view', 'dd-ab-click', 'blu']);
    sendXray(['public-file-view_dl-ab-click_blu'], null, { sendImmediately: true });
    sendGaAb('blu', 'click', null, true);
    setTimeout(() => {
        window.location.href = link + deepLink;
    }, 100);
};
