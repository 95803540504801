import store from 'lib/store';
import { getCreateDocAPICall } from 'reactApp/api/CreateDocAPICall';
import { IS_ONPREMISE, USER_EMAIL } from 'reactApp/appHelpers/configHelpers';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UrlBuilder } from 'reactApp/modules/urlBuilder/UrlBuilder';

import { newDocNamesByExt } from './config';

const urlBuilder = new UrlBuilder();

export const createWindowName = <T extends { id: string; home?: string }>(editorID: string, item: T) => {
    return `${editorID}_${(item.home || item.id).replace(/[^A-Za-z0-9]+/g, (found) => {
        const len = found.length;
        let i = 0,
            res = '';

        for (; i < len; i++) {
            res += found.charCodeAt(i);
        }

        return res;
    })}`;
};

export const joinPath = (...parts: string[]) =>
    parts
        .join('/')
        .replace(/\/{2,}/g, '/')
        .trim();

export const getUrlV2 = <T extends { id: string; storage?: string }>(
    editor: { edit_uri?: string; id: string },
    { item, newExt, token }: { item: T; newExt?: string | null; token?: string | null }
) => {
    const isCreate = !!newExt;
    const itemId = item.id;
    if (isCreate) {
        return joinPath(ROOT_FOLDER_ID, itemId);
    }

    const itemStorage = item.storage === EStorageType.attaches || item.storage === EStorageType.viewerAttaches ? 'attaches' : 'home';

    // TODO: uncomment after fix https://jira.mail.ru/browse/SWA-7454
    // cloudURL += '?token=' + token + '&x-email=' + encodeURIComponent(config.get('user.email'));
    const cloudUrl = `${urlBuilder.encodeId(joinPath(ROOT_FOLDER_ID, itemStorage, itemId))}${token ? `?token=${token}` : ''} `;

    const editUrl = editor.edit_uri || joinPath(editor.id);

    return joinPath(ROOT_FOLDER_ID, editUrl, cloudUrl);
};

export const callApiCreateDoc = (path: string, ext: string) =>
    getCreateDocAPICall().makeRequest({ [IS_ONPREMISE ? 'path' : 'home']: joinPath(path, `${newDocNamesByExt[ext]}.${ext}`) });

/** Функция, которая сокращает имя файла так, чтобы осталось видимым расширение. */
export const shortenFilename = <T extends { ext?: string; name: string }>(item: T, maxLength: number) => {
    const ext = item.ext ? `.${item.ext}` : '';
    const extLength = ext.length;
    let name = String(item.name);
    let nameLength = name.length;

    if (nameLength > maxLength) {
        const index = name.lastIndexOf(ext);

        if (index === nameLength - extLength - 1) {
            name = name.slice(0, index);
            nameLength = name.length;
            name = name.slice(0, nameLength - extLength - maxLength);

            if (nameLength > extLength) {
                return `${name}…\u00A0${ext}`;
            }

            return `${name}…`;
        }

        return `${name.slice(0, maxLength)}…`;
    }

    return name;
};

export const getExt = (item: any | string) => {
    let ext = '';

    if (typeof item === 'object') {
        ext = item.ext;
    } else {
        ext = String(item);
        const index = ext.lastIndexOf('.');
        if (index !== -1) {
            ext = ext.slice(Math.max(0, index + 1));
        }
    }

    return ext && ext.toLowerCase();
};

export const getIsOpenDocumentWarning = (hasSharedEditor: boolean) => {
    if (hasSharedEditor) {
        return false;
    }

    return store.get(`${USER_EMAIL}|openDocumentWarning`);
};

export const setIsOpenDocumentWarning = (hasSharedEditor: boolean) => {
    if (hasSharedEditor) {
        store.remove(`${USER_EMAIL}|openDocumentWarning`);
        return;
    }

    store.set(`${USER_EMAIL}|openDocumentWarning`, true);
};

/** Проверяет не закрыто ли окно или вкладка. */
export const isOpenedTab = (tab: any) => {
    try {
        // В IE9 можно получить exception.
        return !tab.closed && tab.opener;
    } catch (error) {
        return false;
    }
};
