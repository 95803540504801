import { Text } from '@vkontakte/vkui';
import React from 'react';
import { Activity } from 'reactApp/modules/activities/activities.types';
import styles from 'reactApp/ui/FilesFoldersLogViewer/FileFolderChangesLog/FileFolderChangesLog.css';
import { createAvatarUrl } from 'reactApp/utils/avatar';

export const truncateTime = (time: Date | number) => {
    const dateTime = new Date(time);
    const timePortion = (dateTime.getTime() - dateTime.getTimezoneOffset() * 60 * 1000) % (3600 * 1000 * 24);
    return dateTime.getTime() - timePortion;
};

export const extractLocalPart = (email = '') => {
    const AtIndex = email.indexOf('@');
    return (AtIndex > -1 && email.slice(0, email.indexOf('@'))) || email;
};

export const extractDomainPart = (email = '') => {
    const AtIndex = email.indexOf('@');
    return (AtIndex > -1 && email.slice(AtIndex + 1)) || '';
};

const formatByWhom = (activity: Activity, email: string) => {
    const { actor } = activity;
    return actor.email === email ? 'вами' : activity.actor.nick || extractLocalPart(actor.email);
};

const formatWho = (activity: Activity, email: string, replace: Record<string, unknown>) => {
    const { actor } = activity;
    const self = actor.email === email;
    const replaceKey = self ? 'вы' : '';
    const who = self ? (
        <span className={styles.capitalize}>вы</span>
    ) : (
        (activity.actor.nick && <span className={styles.capitalize}>{activity.actor.nick}</span>) || extractLocalPart(actor.email)
    );
    const action = replace[replaceKey];
    return (
        <>
            {who} {action}
        </>
    );
};

const formatAccess = (access = '') => {
    return (
        {
            writable: `«Редактирование»`,
            readonly: `«Просмотр»`,
            downloadable: `«Скачивание»`,
            read_write: `редактирование`,
            read_only: `просмотр`,
        }[access] || `«Неопределенно»`
    );
};

const formatPath = (path = '') => {
    if (path === '/') {
        return '«Личные папки»';
    }
    return `«${path}»`;
};

const formatType = (type = '', declenation = false) => {
    if (type === 'file') {
        return 'файл';
    }
    if (type === 'folder') {
        return declenation ? 'папку' : 'папка';
    }
    return '';
};

function formatInviteActor(activity: Activity) {
    return (
        <div className={styles.list_item}>
            <div className={styles.icon}>
                <img loading="lazy" src={createAvatarUrl(activity.invite_actor?.email || '', activity.invite_actor?.nick || '', 32)} />
            </div>
            {activity.invite_actor?.nick && <Text className={styles.name}>{activity.invite_actor.nick}</Text>}
            <Text className={styles.email}>{activity.invite_actor?.email}</Text>
        </div>
    );
}

const formatDownloadMessage = (activity: Activity) => {
    const entityName = formatType(activity?.type);
    return (
        <>
            <span className={styles.capitalize}>{entityName}</span>
            {` скачан${activity?.type === 'folder' ? 'а' : ''}`}
        </>
    );
};

export const getActionElement = (activity: Activity, email: string) => {
    // eslint-disable-next-line sonarjs/max-switch-cases
    switch (activity.code) {
        case 'webapi_file_publish':
            return <>Открыт доступ {formatByWhom(activity, email)}</>;
        case 'webapi_file_unpublish':
            return <>Закрыт доступ {formatByWhom(activity, email)}</>;
        case 'webapi_file_add':
            return (
                <>
                    {formatWho(activity, email, { ['вы']: 'создали', '': 'создал' })} файл {formatPath(activity?.name)}
                </>
            );
        case 'webapi_folder_add':
            return (
                <>
                    {formatWho(activity, email, { ['вы']: 'создали', '': 'создал' })} папку {formatPath(activity?.name)}
                </>
            );
        case 'webapi_folder_share':
            return (
                <>
                    Открыт доступ на {formatAccess(activity.invite_access)} для
                    {formatInviteActor(activity)}
                </>
            );
        case 'webapi_folder_unshare':
            return (
                <>
                    Закрыт доступ в папку для
                    {formatInviteActor(activity)}
                </>
            );
        case 'webapi_file_move':
            return (
                <>
                    {formatWho(activity, email, { ['вы']: 'перенесли', '': 'перенес' })} {formatPath(activity?.name)} из папки{' '}
                    {formatPath(activity.folder_old)} в папку {formatPath(activity.folder)}
                </>
            );
        case 'webapi_weblinks_file_add':
            return (
                <>
                    {formatWho(activity, email, { ['вы']: 'добавили', '': 'добавил' })} файл {formatPath(activity?.name)}
                </>
            );
        case 'webapi_weblinks_chmod':
            return (
                <>
                    {extractLocalPart(activity?.actor?.nick)} внес изменения в доступе на {formatAccess(activity.access)}
                </>
            );
        case 'webapi_weblinks_expiration_change':
            return <>{extractLocalPart(activity?.actor?.nick)} изменил срок действия доступа</>;
        case 'webapi_weblinks_download_count_change':
            return <>{extractLocalPart(activity?.actor?.nick)} изменил количество скачиваний</>;
        case 'webapi_file_rename':
            return <>Название изменено на {formatPath(activity.new_name)}</>;
        case 'webapi_file_remove':
        case 'webapi_weblinks_file_remove':
            return (
                <>
                    {formatWho(activity, email, { ['вы']: 'удалили', '': 'удалил' })} {formatType(activity?.type, true)}{' '}
                    {formatPath(activity?.name)}
                </>
            );
        case 'webapi_file_restore':
            return (
                <>
                    {extractLocalPart(activity?.actor?.nick)} восстановил {formatPath(activity?.name)}
                </>
            );
        case 'webapi_weblinks_domestic':
            return (
                <>
                    {activity?.enabled === '0'
                        ? 'Открыт доступ по ссылке для всех'
                        : `Доступ по ссылке изменён на «Для пользователей домена ${extractDomainPart(activity.actor.email)}»`}
                </>
            );
        case 'webapi_file_download':
            return <>{formatDownloadMessage(activity)}</>;
        case 'webapi_file_send_email':
            return <>Файл отправлен по почте</>;
        default:
            return activity.code;
    }
};
