import { keys } from 'ramda';
import { getItemById, getStorageItemById } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { createSelector } from 'reselect';

import { IRange, Selected, State } from './selection.types';

const getSelectionsStore = (state: any): State => state.selections;

const getSelected = createSelector(getSelectionsStore, (store): Selected => store.selected);
const getLastSelected = (state: any): string => getSelectionsStore(state).lastSelectedIdx;
const getStorageSelected = (state: any) => getSelectionsStore(state).storage;
const isSelected = createSelector(
    getSelected,
    (state, id): string => id,
    (selected, id): boolean => Boolean(selected[id])
);
const getSelectedIdxs = createSelector(getSelected, keys);

const getSelectedRange = createSelector(getSelectionsStore, (store): IRange | null => store.selectionRange);

const isDragging = createSelector(getSelectionsStore, (store): boolean => Boolean(store.isDragging));

const getSelectedItems = createSelector(
    getSelectedIdxs,
    (state) => state,
    (ids, state) => ids.map((id) => getItemById(state, id))
);

const getSelectedItemsByStorage = createSelector(
    (state) => state,
    (state, storage: EStorageType) => storage,
    getSelectedIdxs,
    (state, storage, ids) => ids.map((id) => getStorageItemById(state, storage, id)).filter(Boolean)
);

const isSelecting = createSelector(getSelectionsStore, (state): boolean => Boolean(state.isSelecting));

const isAnyIndexSelected = createSelector(getSelectedIdxs, (ids) => Boolean(ids.length));

export const getScrollToItemState = createSelector(getSelectionsStore, (state) => state.scrollToItemState);

export const SelectionsSelectors = {
    getSelected,
    getSelectedIdxs,
    getStorageSelected,
    isSelected,
    getLastSelected,
    getSelectedRange,
    isDragging,
    getSelectedItems,
    isSelecting,
    isAnyIndexSelected,
    getScrollToItemState,
    getSelectedItemsByStorage,
};
