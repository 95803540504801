import { ISubscription } from 'reactApp/modules/subscriptions/subscriptions.types';
import { ESubscriptionModalAction } from 'reactApp/sections/SubscriptionsPage/ui/SubscriptionModal/SubscriptionModal.analytics';

export enum BillingBuyTemplates {
    CLOUD_B2C_MOBILE = 'CLOUD_B2C_MOBILE',
    CLOUD_B2C_REG_MOBILE = 'CLOUD_B2C_REG_MOBILE',
    CLOUD_B2C = 'CLOUD_B2C',
    CLOUD_B2C_REG = 'CLOUD_B2C_REG',
    CHECKOUT_VKPAY = 'CHECKOUT_VKPAY',
    CHECKOUT_VKPAY_NOOFFER = 'CHECKOUT_VKPAY_NOOFFER',
}

export enum BillingBuyViewTemplates {
    checkout = 'checkout',
}

export interface RenewApiParams {
    sub_id: string | number;
    skin?: BillingBuyTemplates;
    view?: BillingBuyViewTemplates;
    hide_pay_methods_menu?: boolean;
    pay_method?: string;
}

export enum BuyNotifications {
    renewFailure = 'SUBSCRIPTION_RENEW_FAILURE',
    cancelAutoRenewSuccess = 'SUBSCRIPTIONS_CANCEL_AUTORENEW_SUCCESS',
    cancelAutoRenewFailure = 'SUBSCRIPTIONS_CANCEL_AUTORENEW_FAILURE',
    autoRenewFailure = 'SUBSCRIPTIONS_AUTORENEW_FAILURE',
    autoRenewSuccess = 'SUBSCRIPTIONS_AUTORENEW_SUCCESS',
}

export interface IOpenCancelMailSubsDialogAction {
    subscription: ISubscription;
}

export interface IAddAutoRenewAction {
    subscription: ISubscription;
}

export interface ICancelAutorenewSubscription {
    subscriptionId: string | number;
    captchaId?: number;
    captchaValue?: string;
    sendAnalytics?: (action: ESubscriptionModalAction) => void;
}

export interface IRenewSubscription {
    id: string;
}
