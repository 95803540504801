import classNames from 'clsx';
import React, { memo, ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unPublishHelper } from 'reactApp/appHelpers/publishHelper';
import { sendGa } from 'reactApp/components/SharingWindow/Sharing.helpers';
import { EFrom } from 'reactApp/components/SharingWindow/Sharing.types';
import { SharingNewSection } from 'reactApp/components/SharingWindow/SharingNew/Section/SharingNewSection';
import { AutoDelete } from 'reactApp/components/SharingWindow/SharingNew/Weblink/AutoDelete/AutoDelete';
import styles from 'reactApp/components/SharingWindow/Weblink/SharingWeblink.css';
import { getFeaturePublicAutoDeletePromo, getFeaturePublicDomestic } from 'reactApp/modules/features/features.selectors';
import { publishRequest } from 'reactApp/modules/modifying/modifying.actions';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { isAutoDeleteEnabled } from 'reactApp/modules/sharedAutoDelete/sharedAutoDelete.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { CopyWeblink } from 'reactApp/ui/CopyWeblink/CopyWeblink';
import { getPublicUrl } from 'reactApp/utils/urlHelper';

import { useDomestic } from '../../Weblink/hooks/useDomestic';
import { useExpires } from '../../Weblink/hooks/useExpires';
import { renderUnpublishWeblinkDialog } from '../../Weblink/SharingWeblink.helpers';
import { IProps } from '../../Weblink/SharingWeblink.types';
import { useAccessRights } from './hooks/useAccessRights';

/* eslint-disable complexity */
export const SharingNewWeblink = memo(function SharingNewWeblink(props: IProps): ReactElement | null {
    const { isOwn, item, from, isSharedOrMouted, gaSuffix, noSwitch = false, noExpiresControl = false, isPhone, onClose } = props;
    const dispatch = useDispatch();
    const itemStorage = useSelector(getCurrentStorage);
    const storage = item?.storage || itemStorage;
    const isAlbum = from === EFrom.ALBUMS || storage === EStorageType.albums;
    const isPublic = Boolean(item?.weblink);
    // Исключаем стоки, у которых них нет публичной ссылки
    const publicUrl = (isPublic && getPublicUrl(item, isAlbum)) || '';

    const isFromWeblink = from === EFrom.WEBLINK || isPublic;
    const isStock = itemStorage === EStorageType.stock;

    const [isWeblinkOpened, setWeblinkOpened] = useState<boolean>(isFromWeblink);

    const renderExpires = useExpires(item);
    const renderDomestic = useDomestic(item, { storage });
    const renderAccessRights = useAccessRights(item, isSharedOrMouted, onClose);

    const isBizUser = useSelector(UserSelectors.isBizUser);
    const isCorpUser = useSelector(UserSelectors.isCorpUser);
    const isDomesticFeatureEnabled = useSelector(getFeaturePublicDomestic);
    const showDomesticSettings = (!isAlbum || !isStock) && (isBizUser || isCorpUser) && Boolean(isDomesticFeatureEnabled) && isOwn;
    const isAutodeleteFeatureEnabled = Boolean(useSelector(isAutoDeleteEnabled));
    const isAutodeletePromoFeatureEnabled = Boolean(useSelector(getFeaturePublicAutoDeletePromo));
    const showExpires = isStock || (!isAlbum && !noExpiresControl && isOwn);
    const showAccessRights = !isAlbum && isOwn;
    const showAutoDeleteSettings =
        isOwn &&
        !isAlbum &&
        item?.isFolder &&
        !isSharedOrMouted.isMounted &&
        (isAutodeleteFeatureEnabled || isAutodeletePromoFeatureEnabled);

    const onActionClick = useCallback(() => {
        sendGa('unpublish');
        if (storage === EStorageType.attaches) {
            sendGa('attaches-unpublish');
        }

        if (!item) {
            return;
        }
        unPublishHelper({ items: [item] });
        setWeblinkOpened(false);
    }, [storage, item]);

    const onChangeWeblink = useCallback(() => {
        if (!item) {
            return;
        }

        if (isWeblinkOpened) {
            renderUnpublishWeblinkDialog({ onActionClick, item, isAlbum });
        } else {
            sendGa('publish');

            dispatch(publishRequest({ item }));
            setWeblinkOpened(true);
        }
    }, [isWeblinkOpened, item, onActionClick]);

    useEffect(() => {
        if (!publicUrl) {
            setWeblinkOpened(false);
        }
    }, [publicUrl]);

    return (
        <SharingNewSection
            onChange={onChangeWeblink}
            isOpened={isWeblinkOpened}
            title="По ссылке"
            name="weblink"
            noSwitch={noSwitch}
            isPhone={isPhone}
        >
            <div className={classNames(styles.root, { [styles.root_phone]: isPhone })}>
                <div className={styles.copyWeblink}>
                    <CopyWeblink type="new" publicUrl={publicUrl} autoCopy={!isPhone} isPhone={isPhone} />
                </div>

                {showDomesticSettings && renderDomestic()}
                {showAccessRights && renderAccessRights()}
                {showExpires && renderExpires()}
                {showAutoDeleteSettings && (
                    <AutoDelete
                        item={item}
                        disabled={!isAutodeleteFeatureEnabled && isAutodeletePromoFeatureEnabled && isWeblinkOpened}
                        gaSuffix={gaSuffix}
                    />
                )}
            </div>
        </SharingNewSection>
    );
});
