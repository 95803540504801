import { bytesToNDigits } from '@mail/cross-sizes-utils';
import { equals, filter, path, values } from 'ramda';
import { Tariff } from 'reactApp/types/Billing';
import { parsePeriod, simplifyPeriod } from 'reactApp/utils/Period';
import { pretextSPlural } from 'reactApp/utils/pluralHelpers';

const SPACE_1GB = 1024 ** 3;

export const SINGLE_QUOTA_REGEX = /.*_single_quota*/;

export const TRANSIT_REGEX = /.*_transit_*/;

export const SINGLE_QUOTA_DISKO_REGEX = /.*_single_quota_disko_cloud*/;

export const DEFAULT_PRIMARY_PRODUCT = 137438953472; // 128GB

export const normalizeData = (tariffs) => {
    const list: Record<string, Tariff | undefined> = {};

    Object.keys(tariffs).forEach((id) => {
        const group = tariffs[id];

        const { services, products } = group;
        const space = bytesToNDigits(services.quota, 3);
        const gift = services.gift && {
            space: bytesToNDigits(services.gift.gift_quota, 3),
            period: services.gift.gift_interval,
            id: services.gift.giftible_id,
        };

        list[id] = {
            id,
            turbo: /^pro?\d/.test(products[0].product_id),
            isSingleQuota: Boolean(services.mailru),
            isPromoCode: products.some((product) => product.is_promo),
            available: products.every((product) => product.available),
            space,
            flags: {
                upload: !!services?.flags?.UFLAG_PAID_UPLOAD,
                paidFeatures: !!services?.flags?.UFLAG_PAID_ACCOUNT,
            },
            gift,
            isProTariff: !!services.disko && !!services.pro,
            products: products.map((product) => ({
                price: product.price,
                id: product.product_id,
                period: product.period,
                available: product.available,
                isPromo: product.is_promo,
                hasTrial: product.has_trial_period,
                trialPeriod: product.trial_period,
                isProfessional: !!services.pro,
                isDisko: !!services.disko,
                hasDiskO: !!services.disko && !!services.pro,
                hasDiskOAutoRenewal: !!services.disko,
                // Пока считаем что наличие санты это наличие vkCombo
                isVkCombo: !!services.santa,
                santa: services.santa,
                // Триалы у которых есть еще скидочный период
                isForceDiscountTrial: product.has_trial_period && product.has_discount_period,
                isTransit: TRANSIT_REGEX.test(product.product_id),
                // "предоплаченный" триал, не отнимает квоту при отвязке карты / отключеннии автопродления
                isPrepaid: product.is_prepaid,
                hasDiscount: product.has_discount_period,
                discountPeriod: product.discount_period,
                discountPrice: product.discount_price,
                space,
                turbo: /^pro?\d/.test(product.product_id),
                isSingleQuota: Boolean(services.mailru),
                gift,
            })),
        };
    });

    return list;
};

export const checkProductListEquals =
    (oldData) =>
    ({ data }) =>
        !equals(normalizeData(data), oldData);

export const PROMO_TARIFFS_REGEX = {
    promoTariffs: /.*_PT$/,
    marketingPromo: /.*_blackfriday_2023_discount_single_quota_cloud$/,
};

export const TRIAL_REGEX = /.*(prepaid|trial)+/;

export const isTrial = (productId?: string) => productId?.includes('prepaid') || productId?.includes('trial');

export const YEAR_COST_DECREASE_REGEX = /W\d{3,4}G1y_2021/;

export const YEAR_COST_DECREASE_SINGLE_QUOTA_REGEX = /W\d{1,4}[TG]1y_.*(single_quota_discount50_cloud)/;

export const isNotSpecialProduct = (product) =>
    !product.products.some((product) => product.hasTrial) &&
    !product.products.some((product) => product.isPromo) &&
    !values(PROMO_TARIFFS_REGEX).some((promoRegex) => promoRegex.test(product.id));

export const SPACE_SIZES = {
    _32: 32 * SPACE_1GB,
    _64: 64 * SPACE_1GB,
    _128: 128 * SPACE_1GB,
    _256: 256 * SPACE_1GB,
    _512: 512 * SPACE_1GB,
    _1024: 1024 * SPACE_1GB,
    _2048: 2048 * SPACE_1GB,
    _4096: 4096 * SPACE_1GB,
};

export const getPretextPeriodForProduct = (period: string) => {
    const matchedPeriod = parsePeriod(period);
    if (!matchedPeriod) {
        return '';
    }
    const [count] = simplifyPeriod(Number(matchedPeriod[1]), matchedPeriod[2]);

    return pretextSPlural(count + 1);
};

export const filterTariffsByQuotaAndPeriod = (tariff, quota, period) => {
    if (tariff.space.original !== quota) {
        return false;
    }

    return Boolean(tariff.products.find((product) => product.period === period));
};

export const isTariffForExclude = (tariff: Tariff, quota: number, excludePro?: boolean): boolean => {
    if (tariff.isProTariff && !excludePro) {
        return false;
    }

    return tariff?.space?.original === quota;
};

export const addTwoYearProducts = (tariffList: Tariff[], twoYearTariffsList: Tariff[]): Tariff[] =>
    tariffList.map((tariff) => {
        if (tariff.isProTariff) {
            return tariff;
        }

        const twoYearProduct = twoYearTariffsList.find((product) => tariff.space.original === product.space.original);

        if (twoYearProduct) {
            return {
                ...tariff,
                products: [...tariff.products, twoYearProduct.products[0]],
            };
        }

        return tariff;
    });

export const getTariffsByQuotaHelper = (tariffs, quota) => filter((tariff) => path(['space', 'original'], tariff) === +quota, tariffs);

// tempexp_16338-start
export const replaceProductInTariff = (tariffs: Tariff[], tariffsWithProductToReplace: Tariff[]) => {
    return tariffs.map((tariff) => {
        const tariffWithIncreasedPrice = getTariffsByQuotaHelper(tariffsWithProductToReplace, tariff.space.original)[0] as Tariff;

        if (!tariffWithIncreasedPrice) {
            return tariff;
        }

        const index = tariff.products.findIndex((product) => product.period === tariffWithIncreasedPrice?.products[0].period);

        if (index === -1) {
            return tariff;
        }

        const products = [...tariff.products];
        products[index] = tariffWithIncreasedPrice.products[0];

        return {
            ...tariff,
            products,
        };
    });
};
// tempexp_16338-end

/**
 * tempexp_16510
 * Фильтрует и сортирует массив тарифов на основании массива размеров.
 *
 * @param tariffs - массив тарифов
 * @param quotasInGigabytes - массив размеров в Гигабайтах в нужном порядке
 *
 * @returns
 */
export const filterAndSortTariffsByQuota = (tariffs: Tariff[], quotasInGigabytes: number[]) => {
    const filtered = tariffs.filter((item) => {
        const itemSpaceInGigabytes = item?.space?.original / SPACE_1GB;
        return quotasInGigabytes.includes(itemSpaceInGigabytes);
    });
    filtered.sort((a, b) => {
        const aSpaceInGigabytes = a?.space?.original / SPACE_1GB;
        const bSpaceInGigabytes = b?.space?.original / SPACE_1GB;
        return quotasInGigabytes.indexOf(aSpaceInGigabytes) - quotasInGigabytes.indexOf(bSpaceInGigabytes);
    });

    return filtered;
};
