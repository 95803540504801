import config from 'Cloud/config';
import { xray } from 'lib/xray';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { USER_EMAIL } from 'reactApp/appHelpers/configHelpers';
import { isAttachFile } from 'reactApp/modules/attaches/attaches.helpers';
import { Ovidius } from 'reactApp/modules/ovidius/ovidius';
import { OvidiusAttachesOriginal, OvidiusPart } from 'reactApp/modules/ovidius/ovidius.types';
import { CloudFile, EStorageType } from 'reactApp/modules/storage/storage.types';
import { ATTACHES_DOCS_URL } from 'reactApp/ui/ReactViewer/ReactViewer.helpers';
import { downloadAttach } from 'reactApp/utils/downloadDocumentHelpers';
import { mergePdfs } from 'reactApp/utils/mergePdfParts';

type Error =
    | `api_${string}`
    | 'attach_no_parts_info'
    | 'attach_v2_no_url'
    | 'attach_no_hash'
    | 'no_parts_info'
    | 'no_first_part'
    | 'no_new_part';

export const print = async (content: ArrayBuffer[]) => {
    if (!content.length) {
        return;
    }

    const merged = await mergePdfs(content);
    const blob = new Blob([merged], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);

    const iframe = document.createElement('iframe');

    iframe.src = url;
    iframe.style.display = 'none';

    const removeFrame = () => {
        iframe.remove();
        window.removeEventListener('focus', removeFrame);
    };

    window.addEventListener('focus', removeFrame);

    iframe.onload = function () {
        setTimeout(function () {
            if (iframe.contentWindow) {
                iframe.focus(); // Required for IE
                iframe.contentWindow.print();
            }
        }, 0);
    };

    document.body.appendChild(iframe);
};

const OAUTH_HOST = config.get('OAUTH_HOST');
const CORSAPI_HOST = config.get('CORS_API_HOST');

// eslint-disable-next-line max-lines-per-function
export const useNormalizedOvidius = (params: {
    url: string;
    setContentForPrint?: (id: string, content: ArrayBuffer[] | null) => void;
    file: CloudFile;
    storage: EStorageType;
    attachVersion?: number;
}) => {
    const { url, storage, setContentForPrint, file, attachVersion } = params;

    const isPdfConvertAttachDataSended = useRef(false);
    const isPdfConvertAttachDataReceived = useRef(false);
    const [parts, setParts] = useState<Array<OvidiusPart>>([]);
    const [partsAmount, setPartsAmount] = useState<number>(0);
    const [content, setContent] = useState<ArrayBuffer[]>([]);
    const [error, setError] = useState<Error | null>(null);
    const [attachHash, setAttachHash] = useState<null | string>(null);
    const [fullContent, setFullContent] = useState<ArrayBuffer | null>(null);

    const attachType = useMemo(() => (isAttachFile(file) ? file.attachType : undefined), [file]);
    const isAttachV2 = useMemo(() => attachType && attachVersion === 2, [attachType, attachVersion]);
    const ovidiusUrl = useMemo(() => (isAttachFile(file) ? file.editors?.ovidius?.url : undefined), [file]);

    const resetState = useCallback(() => {
        setContent([]);
        setParts([]);
        setPartsAmount(0);
        setError(null);
        setFullContent(null);
    }, []);

    const sendRadar = useCallback(
        (message: string, isError = false, error?: any) => {
            xray.send(
                `norm_ovidius${attachType ? `_${attachType}` : `_${storage?.replace('/', '-')}`}${file?.ext ? `_${file.ext}` : ''}${
                    isError ? '_err' : ''
                }_${message}`,
                isError
                    ? {
                          rlog: 'cloud_norm_pdf_err',
                          rlog_message: {
                              message: `norm_ovidius_error_${message}`,
                              error: error instanceof Error ? error : error ? { error: JSON.stringify(error) } : undefined,
                              attachType,
                          },
                      }
                    : undefined
            );
        },
        [attachType, isAttachV2]
    );

    useEffect(() => {
        if (content.length === partsAmount && partsAmount > 0) {
            setContentForPrint?.(file.id, content);
        }
    }, [content, partsAmount]);

    // Работа с аттачами V2
    useEffect(() => {
        let cancel = false;

        if (!isAttachV2) {
            return;
        }
        if (isAttachV2 && !ovidiusUrl) {
            sendRadar('no-url', true);
            setError('attach_v2_no_url');
            return;
        }

        // Важно выставить текущее значение при смене урла
        isPdfConvertAttachDataSended.current = false;
        isPdfConvertAttachDataReceived.current = false;
        // Для реализации аттачей V2 нужна схема с получением токена
        // Токен можно получить только через iframe
        const iframe = document.createElement('iframe');
        iframe.src = ATTACHES_DOCS_URL;
        const listener = (event: MessageEvent<{ type: string; docViewData: OvidiusAttachesOriginal }>) => {
            if (cancel) {
                return;
            }

            const isSendingPostMessageAllowed = iframe?.contentWindow === event.source;
            if (isSendingPostMessageAllowed && !isPdfConvertAttachDataSended.current) {
                iframe.contentWindow?.postMessage(
                    {
                        type: 'docsData',
                        docsData: {
                            ovidiusUrl,
                            login: USER_EMAIL,
                            o2_host: OAUTH_HOST,
                            corsapi_host: CORSAPI_HOST,
                        },
                    },
                    `${window.location.origin}${ATTACHES_DOCS_URL}`
                );
                isPdfConvertAttachDataSended.current = true;
                setAttachHash(null);
            }

            if (!isPdfConvertAttachDataReceived.current && event.data.type === 'documentsViewUrl') {
                isPdfConvertAttachDataReceived.current = true;
                if (!event.data.docViewData?.post_params?.parts_info) {
                    sendRadar('no-parts-info', true);
                    setError('attach_no_parts_info');
                    return;
                }

                const { parts_total: partsTotal, hash, parts } = event.data.docViewData.post_params.parts_info || {};
                if (!hash) {
                    sendRadar('no-hash', true);
                    setError('attach_no_hash');
                    return;
                }

                sendRadar('info-success');

                setParts(parts);
                setPartsAmount(partsTotal);
                setAttachHash(hash);
            }
        };

        if (isAttachV2 && ovidiusUrl) {
            document.body.appendChild(iframe);
            window.addEventListener('message', listener);
        }

        return () => {
            cancel = true;
            iframe?.remove();
            window.removeEventListener('message', listener);
            resetState();
        };
        // Для исключения неконсистенных состояни вызываем хук только для url
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url, isAttachV2]);

    // Работа с аттачами V1
    useEffect(() => {
        let cancel = false;
        /* Скачивание файла нужно только для аттачей версии v1 */
        if (!attachType || isAttachV2) {
            return;
        }

        (async () => {
            const { content: data, error } = await downloadAttach(file);
            if (cancel) {
                return;
            }

            if (error || !data) {
                sendRadar('down-err', true, error);

                setError(`api_${error instanceof Error ? error.message : error}`);
            } else {
                sendRadar('full-content');
                setFullContent(data);
            }
        })();

        return () => {
            resetState();
            cancel = true;
        };
        // Для исключения неконсистенных состояни вызываем хук только для url
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url, isAttachV2]);

    // Работа c файлами всех видов за исключением аттачей V2
    useEffect(() => {
        let cancel = false;

        // Пропускаем эту для аттачей 2 видов:
        // - Если не получили контент документа для версии v1
        // - Если версия фичи для аттача v2 (для них работает только функция `loadData`)
        if ((attachType && !fullContent) || isAttachV2) {
            return;
        }
        (async () => {
            const { partsInfo, firstPart } = await Ovidius.getPdfFileParts(url, storage, file, fullContent, !!attachType);

            if (cancel) {
                return;
            }

            if (!partsInfo) {
                sendRadar('no-parts-info', true);
                setError('no_parts_info');
                return;
            }

            if (!firstPart) {
                sendRadar('no-first-part', true);
                setError('no_first_part');
                return;
            }

            const { parts, parts_total: partsTotal } = partsInfo;

            setParts(parts);
            setContent([firstPart]);
            setPartsAmount(partsTotal);

            if (parts.length === 1) {
                sendRadar('one-part-success');
            } else {
                sendRadar('first-part-success');
            }
        })();

        return () => {
            resetState();
            cancel = true;
        };
        // Для исключения неконсистенных состояни вызываем хук только для fullContent
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fullContent, url, isAttachV2]);

    // Работа с pdf для всех видов файлов
    useEffect(() => {
        let cancel = false;
        // Пока у нас нет хэша файла пропускаем загрузку для аттачей V2
        if (isAttachV2 && !attachHash) {
            return;
        }

        // Для второй версии аттачей нет поле firstPart.
        // Поэтому начинаем загрузку с первого чанка
        const initialPart = attachHash && isAttachV2 ? 0 : 1;
        if (parts.length <= initialPart) {
            return;
        }

        const loadData = async () => {
            const getPdfFilePartInfo = Ovidius.getPdfFilePartCallback(
                url,
                storage,
                file,
                fullContent,
                attachType ? (isAttachV2 ? 2 : 1) : undefined,
                attachHash
            );

            let loadSome;
            for (let i = initialPart; i < parts.length; i++) {
                loadSome = true;
                const { id } = parts[i];
                const { content: newPart } = await getPdfFilePartInfo(id);

                if (cancel) {
                    return;
                }
                if (!newPart) {
                    sendRadar('no-new-part', true);
                    setError('no_new_part');
                    return;
                }

                setContent((oldContent) => [...oldContent, newPart]);
            }

            if (loadSome) {
                sendRadar('all-parts-success');
            }
        };

        loadData();

        return () => {
            cancel = true;
        };
        // Вызывает только при смене parts и attachHash, для исключения неконсистентных состояний
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parts, attachHash, isAttachV2]);

    return {
        content,
        partsAmount,
        error,
    };
};
