import { ReactElement } from 'react';

export enum EAsidePromoModalTheme {
    dark = 'dark',
}

export interface IAsidePromoModalProps {
    title: string | ReactElement;
    text: string;
    imageUrl: string;
    buttonText?: string;
    href?: string;
    onShow?: () => void;
    onClick?: () => void;
    onClose?: () => void;
    fixed?: boolean;
    theme?: EAsidePromoModalTheme;
    bottom?: number;
}
