import { MouseEventHandler, ReactElement, RefObject } from 'react';

export enum ETooltipPlacement {
    top = 'top',
    topStart = 'top-start',
    topEnd = 'top-end',
    right = 'right',
    rightStart = 'right-start',
    rightEnd = 'right-end',
    bottom = 'bottom',
    bottomStart = 'bottom-start',
    bottomEnd = 'bottom-end',
    left = 'left',
    leftStart = 'left-start',
    leftEnd = 'left-end',
}

export enum ETooltipSize {
    small = 's',
    medium = 'm',
    large = 'l',
}

export enum ETooltipAppearance {
    white = 'white',
    blue = 'blue',
    green = 'green',
    orange = 'orange',
    purple = 'purple',
    dark = 'dark',
}

export interface ITooltipProps {
    children?: ReactElement;
    className?: string;
    target: RefObject<HTMLElement> | Element | null;
    title?: string;
    text?: string | React.ReactNode;
    img?: string;
    buttonText?: string;
    theme?: 'light' | 'dark';
    isAlternativeButton?: boolean;
    appearance?: ETooltipAppearance;
    size?: ETooltipSize;
    placement: ETooltipPlacement;
    closable?: boolean;
    hideArrow?: boolean;
    closeOnOutsideClick?: boolean;
    closeOnScroll?: boolean;
    withinModal?: boolean;
    qaId?: string;
    overAll?: boolean;
    onClick?: (e?: Event) => void;
    onShow?: () => void;
    onClose?: (e?: Event) => void;
    onTooltipClick?: MouseEventHandler<HTMLDivElement>;
}
