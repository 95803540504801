import { EAllDocumentsType } from 'reactApp/modules/allDocuments/allDocuments.types';
import { ETreeRootIds } from 'reactApp/ui/TreeComponent/TreeComponent.constants';

export const AllDocumentsMeta = {
    [EAllDocumentsType.document]: {
        name: 'Документы',
        id: ETreeRootIds.alldocumentsDocument,
    },
    [EAllDocumentsType.spreadsheet]: {
        name: 'Таблицы',
        id: ETreeRootIds.alldocumentsSpreadsheet,
    },
    [EAllDocumentsType.presentation]: {
        name: 'Презентации',
        id: ETreeRootIds.alldocumentsPresentation,
    },
    [EAllDocumentsType.pdf]: {
        name: 'PDF',
        id: ETreeRootIds.alldocumentsPdf,
    },
    trashbin: {
        name: 'Корзина',
        id: ETreeRootIds.alldocumentsTrashbin,
    },
};
