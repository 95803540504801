import { Text } from '@vkontakte/vkui';
import React, { memo, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardActions } from 'reactApp/modules/creditCard/creditCard.module';
import { CardSelectors } from 'reactApp/modules/creditCard/creditCard.selectors';
import {
    ESubscriptionModalAction,
    TSendAnalytics,
} from 'reactApp/sections/SubscriptionsPage/ui/SubscriptionModal/SubscriptionModal.analytics';
import { ButtonLink } from 'reactApp/ui/ButtonLink/ButtonLink';
import CardNumber from 'reactApp/ui/CardNumber/CardNumber';

import styles from './Card.css';

export const Card = memo(({ sendAnalytics }: { sendAnalytics: TSendAnalytics }): ReactElement | null => {
    const dispatch = useDispatch();

    const { card } = useSelector(CardSelectors.getCard);

    const onSuccessUpdate = () => {
        sendAnalytics(ESubscriptionModalAction.addAnotherCard);
    };

    const onCancelUpdate = () => {
        sendAnalytics(ESubscriptionModalAction.cancelAnotherCard);
    };

    const handleOnChangeCardClick = () => {
        sendAnalytics(ESubscriptionModalAction.changeCard);
        dispatch(CardActions.showModal({ showNotifications: true, onCancel: onCancelUpdate, onSuccess: onSuccessUpdate }));
    };

    if (!card) {
        return null;
    }

    return (
        <div className={styles.root}>
            {card.isSbp ? <Text className={styles.text}>Система быстрых платежей</Text> : <CardNumber number={card.number} />}
            <div className={styles.changeCard}>
                <ButtonLink size="medium" primary onClick={handleOnChangeCardClick}>
                    {card.isSbp ? 'Изменить' : 'Изменить карту'}
                </ButtonLink>
            </div>
        </div>
    );
});

Card.displayName = 'Card';
