import { ISaveSubscriptionProps } from 'reactApp/components/VkBuyModalWithSidebar/SaveSubscriptionSidebar/SaveSubscriptionSidebar';
import { Tariff } from 'reactApp/types/Billing';

export interface IProps {
    productId: string;
    onClose: () => void;
    onSuccess?: () => void;
    onDmrResize?: (height) => void;
    type: EPaymentModalType;
    tariff?: Tariff;
    sidebarProps?: ISaveSubscriptionProps;
    isMarketingPromo?: boolean;
    isFrame?: boolean;
}

export enum EPaymentModalType {
    trial = 'trial',
    fastcheckout = 'fastcheckout',
    saveSubscription = 'saveSubscription',
    attachesTrial = 'attachesTrial',
}
