import { SizeType, Textarea } from '@vkontakte/vkui';
import classNames from 'clsx';
import { ReactComponent as InputArrowRightIcon } from 'img/icons/editor/llm/arrow_right_40_input.svg';
import { ReactComponent as InputArrowRightSubmitIcon } from 'img/icons/editor/llm/arrow_right_40_input_filled.svg';
import { ReactComponent as InputStarsIcon } from 'img/icons/editor/llm/stars_40_input.svg';
import { ReactComponent as InputStarsEditIcon } from 'img/icons/editor/llm/stars_40_input_filled.svg';
import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeInputMessage, getMessageFromLLM } from 'reactApp/modules/editor/editor.module';
import { getLlmDialogState } from 'reactApp/modules/editor/editor.selectors';
import { LLMAnalyticsType, sendTechLLMAnalytics, useGetLLMAnalytics } from 'reactApp/ui/EditorLLM/analytics/EditorLlmAnalytics';
import { SendType } from 'reactApp/ui/EditorLLM/EditorLLMDialog/types/EditorLlmDialog.types';
import { llmFeature } from 'reactApp/ui/EditorLLM/helpers/EditorLlmHelpers';
import { Hint } from 'reactApp/ui/Hint/Hint';

import { EditorLLMDialogLoader } from './components/EditorLLMDialogLoader/EditorLLMDialogLoader';
import styles from './EditorLLMDialogInput.css';

const BeforeIcon = ({ isEdit, isLoading }: { isEdit: boolean; isLoading: boolean }) => {
    if (isLoading) {
        return <EditorLLMDialogLoader />;
    }

    return isEdit ? <InputStarsEditIcon className={styles.icon} /> : <InputStarsIcon className={styles.icon} />;
};

const AfterIcon = ({ value, isLoading, onClick }: { value: string; isLoading: boolean; onClick: () => void }) => {
    const inputLength = value.trim().length;

    switch (true) {
        case isLoading:
            return null;
        case inputLength > 0 && inputLength < 3:
            return (
                <Hint text="Минимум 3 символа">
                    <InputArrowRightIcon className={styles.icon} data-qa-id="LLMDialog_submitButton" />
                </Hint>
            );
        case value.length >= 3:
            return (
                <InputArrowRightSubmitIcon
                    className={classNames(styles.icon, styles.submit_icon)}
                    onClick={onClick}
                    data-qa-id="LLMDialog_submitButton"
                />
            );
        default:
            return null;
    }
};

export const EditorLLMDialogInput: FC = () => {
    const dispatch = useDispatch();
    const [isEdit, setIsEdit] = useState(false);
    const { isLoading, inputMessage, selectType } = useSelector(getLlmDialogState);
    const sendAnalytics = useGetLLMAnalytics();
    const request = inputMessage.trim();

    const onSubmit = (sendType: SendType) => {
        sendAnalytics({ action: LLMAnalyticsType.QUESTION_SEND, dwh: { scenario: selectType.value, question_send_type: sendType } });
        sendTechLLMAnalytics({ action: LLMAnalyticsType.QUESTION_SEND, info: sendType });
        dispatch(getMessageFromLLM({ message: request, isOwn: true }));
    };

    const onEnterPress = (e: KeyboardEvent) => {
        if (e.code === 'Enter') {
            e.preventDefault();

            if (request.length >= 3) {
                onSubmit(SendType.ON_ENTER_PRESS);
            }
        }
    };

    const onButtonClick = () => {
        onSubmit(SendType.ON_BUTTON_CLICK);
    };

    const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        dispatch(changeInputMessage(e.target.value.replace(/^\s*$/g, '')));
    };

    const onClick = () => {
        setIsEdit(true);
    };

    const onFocus = () => {
        setIsEdit(true);
    };

    const onBlur = () => {
        setIsEdit(false);
    };

    return (
        <div
            className={classNames(styles.input_container, { [styles.input_container_disabled]: isLoading })}
            data-qa-id="LLMDialog_inputContainer"
        >
            <BeforeIcon isEdit={isEdit} isLoading={isLoading} />
            <Textarea
                data-qa-id="LLMDialog_input"
                disabled={isLoading}
                className={styles.input}
                sizeY={SizeType.COMPACT}
                placeholder={llmFeature.inputPlaceholder}
                onClick={onClick}
                onFocus={onFocus}
                onBlur={onBlur}
                value={inputMessage}
                onChange={onChange}
                onKeyPress={onEnterPress as any}
                rows={1}
                // Используется как maxRows={3}
                maxHeight={60}
            />
            <AfterIcon value={inputMessage} isLoading={isLoading} onClick={onButtonClick} />
        </div>
    );
};
