// tempexp_15949-full-file
import { Spacing, Text, Title } from '@vkontakte/vkui';
import { ReactComponent as DropImage } from 'img/drop.svg';
import React, { memo, useEffect, useRef } from 'react';
import { useUploadInputHandlers } from 'reactApp/modules/uploading/hooks/useUploadInputHandlers';
import { CloudArrowUpIcon } from 'reactApp/ui/VKUIIcons';

import styles from './WelcomeNewPromo.css';

export const UploaderBlock = memo(() => {
    const inputRef = useRef<HTMLInputElement>(null);

    const { subscribeToInput, unsubscribeOfInput } = useUploadInputHandlers();

    useEffect(() => {
        subscribeToInput(inputRef.current);

        return () => {
            unsubscribeOfInput(inputRef.current);
        };
    }, []);

    return (
        <div className={styles.block}>
            <input type="file" multiple className={styles.input} ref={inputRef} />
            <div className={styles.icon}>
                <CloudArrowUpIcon />
            </div>
            <Spacing size={24} style={{ pointerEvents: 'none' }} />
            <Title className={styles.block_title} level="3">
                Загрузить файлы
            </Title>
            <Spacing size={8} style={{ pointerEvents: 'none' }} />
            <Text className={styles.block_text}>Нажмите или перетащите файлы для загрузки в Облако</Text>
            <Spacing size={24} style={{ pointerEvents: 'none' }} />
            <div className={styles.upload}>
                <DropImage className={styles.drop} />
                <div className={styles.upload_text}>
                    <span className={styles.blue}>Нажмите</span>{' '}
                    <span>
                        или перенесите
                        <br />
                        файлы для загрузки
                    </span>
                </div>
            </div>
        </div>
    );
});

UploaderBlock.displayName = 'UploaderBlock';
