import React, { memo, ReactElement, useRef } from 'react';
import { IProps } from 'reactApp/components/SharingWindow/Sharing.types';
import { SharingContent } from 'reactApp/components/SharingWindow/SharingContent/SharingContent';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { IPropsWithPopup } from 'reactApp/modules/popup/popup.helpers';
import Content from 'reactApp/ui/Content/Content';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';

import styles from './SharingModified.css';

export const SharingDesktop = memo<IPropsWithPopup<IProps>>(function SharingModifiedDesktop(props): ReactElement {
    return (
        <Dialog open id="sharing-modified" onCancel={props.onClose} className={styles.modal}>
            <Content isModal>
                <SharingContent {...props} />
            </Content>
        </Dialog>
    );
});

export const SharingPhone = memo<IPropsWithPopup<IProps>>(function SharingModifiedPhone(props): ReactElement {
    const scrollRef = useRef(null);

    return (
        <MobileDialog id="sharing-modified" closeOnDimmerClick mod="base" topmost onClose={props.onClose} scrollRef={scrollRef}>
            <SharingContent {...props} isPhone ref={scrollRef} />
        </MobileDialog>
    );
});

export const SharingModified = memo<IPropsWithPopup<IProps>>(function SharingModified(props): ReactElement {
    const isPhone = EnvironmentSelectors.isPhone();

    return isPhone ? <SharingPhone {...props} /> : <SharingDesktop {...props} />;
});
