import classNames from 'clsx';
import React, { memo, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { IS_PUBLIC_ALBUM, IS_PUBLIC_FOLDER } from 'reactApp/appHelpers/configHelpers';
import { getFacesListRequestState } from 'reactApp/modules/faces/faces.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { useItems } from 'reactApp/ui/Mobile/ActionsCarousel/useItems';
import { Placeholder } from 'reactApp/ui/Placeholder/Placeholder';

import styles from './ActionsCarousel.css';

export const ActionsCarousel = memo(({ disabled = false }: { disabled?: boolean }): ReactElement => {
    const storage = useSelector(getCurrentStorage);
    const items = useItems({ storage });
    const { isLoading } = useSelector(getFacesListRequestState);

    if (IS_PUBLIC_FOLDER && isLoading && !IS_PUBLIC_ALBUM) {
        return (
            <div className={classNames(styles.root, styles.root_loading)}>
                <Placeholder />
            </div>
        );
    }

    return (
        <div
            className={classNames({
                [styles.root]: true,
                [styles.root_disabled]: disabled,
            })}
        >
            <div className={styles.wrapper}>
                {items.map((item) => (
                    <div
                        className={classNames({
                            [styles.item]: true,
                            [styles.item_active]: item.active,
                            [styles[`item_${item.id}`]]: Boolean(item.id),
                        })}
                        key={item.id}
                        onClick={disabled ? undefined : item.onClick}
                        data-qa-action={item.id}
                    >
                        {item.component}
                        {item.icon && <div className={styles.icon}>{item.icon}</div>}
                        {!item.active && item.text}
                    </div>
                ))}
            </div>
        </div>
    );
});

ActionsCarousel.displayName = 'ActionsCarousel';
