import classNames from 'clsx';
import React, { memo, ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { DOM_ID_PHONE_HEADER_ERRORS_PAGE } from 'reactApp/appHelpers/advertisingConfigHelpers';
import { IS_BLOCKED } from 'reactApp/appHelpers/configHelpers';
import { isDefaultRootContent } from 'reactApp/modules/home/home.selectors';
import { checkUpdateLicenseRequest } from 'reactApp/modules/profile/profile.module';
import { selectStatusPage } from 'reactApp/modules/router/router.selectors';
import { SelectionsSelectors } from 'reactApp/modules/selections/selections.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { useUploadInputHandlers } from 'reactApp/modules/uploading/hooks/useUploadInputHandlers';
import { isUploaderVisible } from 'reactApp/modules/uploadList/uploadList.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { ErrorPage } from 'reactApp/sections/ErrorPage/ErrorPage';
import { useRouteChangeProcessing } from 'reactApp/sections/MainPage/hooks/useRouteChangeProcessing';
import { ActionsBar, EActionsMode } from 'reactApp/ui/Mobile/ActionsBar/ActionsBar';
import { ActionsCarousel } from 'reactApp/ui/Mobile/ActionsCarousel/ActionsCarousel';
import { BreadcrumbsContainer as Breadcrumbs } from 'reactApp/ui/Mobile/Breadcrumbs/BreadcrumbsContainer';
import { Datalist } from 'reactApp/ui/Mobile/Datalist/Datalist';
import { Toolbar } from 'reactApp/ui/Mobile/Toolbar/Toolbar';
import { UploadDropArea } from 'reactApp/ui/UploadDropArea/UploadDropArea';
import { UploadListMobileContainer } from 'reactApp/ui/UploadList/UploadListMobile';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './MobileHomePage.css';

export const MobileHomePage = memo(function MobileHomePage(): ReactElement | null {
    const dispatch = useDispatch();
    const location = useLocation();
    const isSelecting = useSelector(SelectionsSelectors.isSelecting);
    const selectedIds = useSelector(SelectionsSelectors.getSelectedIdxs);
    const isDefaultContent = useSelector(isDefaultRootContent);
    const statusPage = useSelector(selectStatusPage);
    const showUploader = useSelector(isUploaderVisible);
    const showActionsBar = selectedIds.length > 0;
    const showUploadArea = !showUploader && !showActionsBar;
    const isFrozen = useSelector(UserSelectors.isFrozen);

    const { subscribeToInput, unsubscribeOfInput } = useUploadInputHandlers();

    useEffect(() => {
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'touch-home' });
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'page-entered', source: 'home' });
    }, []);

    useEffect(() => {
        if (isDefaultContent) {
            dispatch(checkUpdateLicenseRequest());
        }
    }, [isDefaultContent]);

    useRouteChangeProcessing({ storage: EStorageType.home, path: location.pathname, search: location.search });

    if (IS_BLOCKED || isFrozen) {
        return null;
    }

    if (statusPage) {
        return (
            <div className={classNames(styles.root, styles.root_error)}>
                <div id={DOM_ID_PHONE_HEADER_ERRORS_PAGE} />
                <Toolbar disableActions />
                <ErrorPage status={statusPage} />
            </div>
        );
    }

    return (
        <>
            <div className={styles.root}>
                <Toolbar showMenu logoWithEndorsment={false} />
                <div className={styles.content}>
                    <Breadcrumbs />
                    <ActionsCarousel disabled={isSelecting} />
                    <Datalist />
                </div>
            </div>
            {showActionsBar && <ActionsBar mode={EActionsMode.PRIMARY} />}
            {showUploader && <UploadListMobileContainer />}
            {showUploadArea && (
                <UploadDropArea subscribeToInput={subscribeToInput} unsubscribeOfInput={unsubscribeOfInput} gaCategory={'mobile'} />
            )}
        </>
    );
});
