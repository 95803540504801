import config from 'Cloud/config';
import { logger } from 'lib/logger';
import { path } from 'ramda';
import { itemV4ToV2 } from 'reactApp/api/helpers/apiV4Helpers';
import { EMERGENCY_SWA, IS_BLOCKED } from 'reactApp/appHelpers/configHelpers';
import { getInitialRequestId } from 'reactApp/appHelpers/settingsHelpers';
import { EFrom } from 'reactApp/components/SharingWindow/Sharing.types';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getFolderPath, isFolder } from 'reactApp/modules/file/utils';
import { storagesNeededHomeStore } from 'reactApp/modules/home/home.constants';
import { getHomeItemById } from 'reactApp/modules/home/home.selectors';
import { changeHistorySaga } from 'reactApp/modules/home/sagas/history.saga';
import { checkFolderForUpdate } from 'reactApp/modules/modifying/modifying.actions';
import { openPublishDialogSaga } from 'reactApp/modules/modifying/sagas/publish.saga';
import { applicationStart, fixRouteId, historyPush, routerStart, routeStatusPage } from 'reactApp/modules/router/router.module';
import { getCurrentRouteId, getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import { loadSharedAutoDeleteRequest } from 'reactApp/modules/sharedAutoDelete/sharedAutoDelete.module';
import { getSortById } from 'reactApp/modules/sort/sort.selectors';
import { getPrivateList } from 'reactApp/modules/start/start.saga';
import { getLoadMoreLimit } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { showStory } from 'reactApp/modules/stories/stories.module';
import { setTreeAction } from 'reactApp/modules/tree/tree.actions';
import { openMobileViewer as openMobileViewerAction } from 'reactApp/modules/viewer/viewer.module';
import { EStatus } from 'reactApp/sections/ErrorPage/ErrorPage.types';
import { sendXray } from 'reactApp/utils/ga';
import { getHomeFolderPath, getPathWithoutFile } from 'reactApp/utils/urlHelper';
import { call, cancel, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

import {
    changeHomeHistory,
    loadFolderFail,
    loadFolderStart,
    loadFolderSuccess,
    loadHomeFolderRequest,
    loadMoreHomeRequest,
    loadMoreSuccess,
} from './home.actions';
import { ApiFolderResponse } from './home.types';
import { showEmergencySwaPopup, showPromocodePopup, showRequestPayment } from './sagas/popups.saga';

function* checkAndShowStory() {
    const { action, id: storyId, type, item } = yield select(SettingsSelectors.getQueryParams);

    if (action !== 'show-stories' || !storyId || !type) {
        return;
    }

    sendXray(['story', 'show-by-link']);

    yield put(showStory({ storyId, type, item }));
}

export function* loadHomeFolder(payload) {
    if (IS_BLOCKED) {
        return;
    }

    let { id } = payload;
    const { isFolder, force } = payload;

    try {
        id = decodeURIComponent(id); // при переходе в папку в названии которой есть #, будет ошибка, поэтому игнорируем её и обрабатываем отдельно
    } catch (error) {
        logger.error(error);
    }

    const item = yield select(getHomeItemById, id);

    if ((item?.isLoaded || item?.isLoading) && !force) {
        yield put(checkFolderForUpdate(id));
        return;
    }

    try {
        const path = getFolderPath(id, isFolder);

        yield put(loadFolderStart(payload));

        const sort = yield select((state) => getSortById(state, path));
        const data: ApiFolderResponse = yield call(getPrivateList, path, getLoadMoreLimit(EStorageType.home), 0, sort);

        if (data.home.toLowerCase() === id.toLowerCase()) {
            data.home = id;
        }
        yield put(loadFolderSuccess(data));
    } catch (error) {
        logger.error(error);
        let message = 'Load folder api error';
        if (error instanceof Error) {
            // TODO: use ErrorPage.helpers.getText
            message = (path(['status'], error) as any)?.toString();
        }
        yield put(loadFolderFail({ id: payload.id, error: message }));
        yield cancel();
    }
}

function* initHomeStorage() {
    if (IS_BLOCKED) {
        return;
    }

    const storage = yield select(getCurrentStorage);
    const isHome = /^\/$|^\/home(|\/.*)$/.test(window.location.pathname);
    const id = yield select(getCurrentRouteId);
    const { action: queryParam, id: queryId } =
        ((yield select(SettingsSelectors.getQueryParams)) as ReturnType<typeof SettingsSelectors.getQueryParams>) ?? {};
    const isPhone = yield select(EnvironmentSelectors.isPhone);

    if (isHome) {
        yield put(loadSharedAutoDeleteRequest());
        const item = yield select(getHomeItemById, id);
        if (!item || item.isFolder) {
            yield call(loadFolderOnStart, { payload: { storage, id, __isFolder: true } });
        }
    } else {
        // Для других стораджей предзагружаем дерево папок корня хомяка
        yield call(loadFolderOnStart, { payload: { storage, id: ROOT_FOLDER_ID, __isFolder: true } });
    }

    if (queryParam === 'request-payment' && !isPhone && isHome) {
        yield fork(showRequestPayment);
    }

    if (queryParam === 'emergency-swa' && EMERGENCY_SWA) {
        yield fork(showEmergencySwaPopup);
    }

    if (queryParam === 'promocode-success') {
        yield fork(showPromocodePopup);
    }

    if (isHome && id === ROOT_FOLDER_ID) {
        yield checkAndShowStory();
    }

    const publishIt = queryParam === 'publish-item';
    const publishAction = publishIt ? EFrom.WEBLINK : (queryParam === 'share-item' ? EFrom.SHARING : null);
    if (publishAction) {
        if (queryId && id !== queryId) {
            const folderPath = getPathWithoutFile(queryId);
            yield loadHomeFolder({ id: folderPath, isFolder: true });
            yield put(historyPush({ id: getHomeFolderPath(folderPath) }));
        }
        const item = yield select(getHomeItemById, queryId || id);
        const isItemFolder = isFolder(item);

        if (item && publishAction && item.id !== ROOT_FOLDER_ID && (publishIt || isItemFolder)) {
            if (queryId) {
                sendXray(['publishbylink', publishIt ? 'publish' : 'share', isItemFolder ? 'folder' : 'file']);
            }
            yield openPublishDialogSaga({
                item,
                itemStorage: EStorageType.home,
                from: publishAction,
            });
        }
    }

    yield take(loadFolderSuccess);

    const initialId = getInitialRequestId();

    // TODO: Если файл дальше, чем 501 - то тут надо в фоне доскролливать до него.
    // Когда будет новый бек в хомяке, тогда можно будет брать сам файл из данных бека, и в фоне доскролливать
    // как сделано в паблик саге (можно объединить с ней - сделать общее, чтобы применить в галерее, последних файлах и тп)
    const itemFile = yield select(getHomeItemById, initialId);

    if (isPhone && !itemFile?.isFolder && itemFile?.id) {
        yield put(openMobileViewerAction({ id: itemFile?.id }));
    }
}

function* loadFolderOnStart(action) {
    const { storage, id, __isFolder } = action.payload;

    // __isFolder ставится в старом дереве, надо будет брать напрямую из серверного json, там прилетает "type" : "folder"
    if (!storagesNeededHomeStore.includes(storage)) {
        return;
    }

    yield put(loadHomeFolderRequest({ id, isFolder: __isFolder }));
}

function* loadHomeFolderRequestHandler(action) {
    yield loadHomeFolder(action.payload);
}

function* loadMore({ payload }: ReturnType<typeof loadMoreHomeRequest>) {
    try {
        const { id, offset } = payload;
        const sort = yield select((state) => getSortById(state, getFolderPath(id, true)));
        const folder: ApiFolderResponse = yield call(getPrivateList, id, getLoadMoreLimit(EStorageType.home), offset, sort);
        yield put(loadMoreSuccess(folder));
    } catch (error) {
        logger.error(error);
        yield cancel();
    }
}

export function* setTree() {
    const folders = config.get('serverSideFolders');

    if (!folders) {
        return;
    }

    const { tree, folder } = folders || {};

    if (!tree || !folder) {
        return;
    }

    // @todo выпилить эти условия после переезда бекенда на v2
    if (config.get('API_V2')) {
        const NOT_EXIST = folders?.folder?.home?.error === 'not_exists';

        if (NOT_EXIST) {
            yield put(routeStatusPage({ status: EStatus.NOT_FOUND }));
            return;
        }

        yield put(setTreeAction({ tree: folders.tree }));

        // Если нужен доскролл, то пока не берем папку из серверного дерева, так как там нет флага is_media, а в ответе из апи есть.
        const queryParams: ReturnType<typeof SettingsSelectors.getQueryParams> = yield select(SettingsSelectors.getQueryParams);
        if (folders?.folder && !queryParams?.item) {
            yield put(setTreeAction({ tree: [folders.folder] as any })); // в folders.tree нету size, берем из folders.folder
        }

        return;
    }

    try {
        const treeV4 = tree.map(itemV4ToV2);
        yield put(setTreeAction({ tree: treeV4 }));

        // Если нужен доскролл, то пока не берем папку из серверного дерева, так как там нет флага is_media, а в ответе из апи есть.
        const queryParams: ReturnType<typeof SettingsSelectors.getQueryParams> = yield select(SettingsSelectors.getQueryParams);

        const routeId: ReturnType<typeof getCurrentRouteId> = yield select(getCurrentRouteId);

        if (routeId && routeId !== ROOT_FOLDER_ID) {
            // Может прийти такой путь (в том числе для доскролла), что папки в нем будут в лоу кейсе.
            // А в ответе апи и тут в серверном ответе они в правильном кейсе,
            // потому попробуем сразу поправить кейс в роуте и настройках доскролла
            const newHome = treeV4[treeV4.length - 1]?.home;

            if (newHome && newHome !== routeId) {
                yield put(fixRouteId(newHome));
            }
        }

        if (!queryParams?.item) {
            const folderTree = itemV4ToV2(folder);

            folderTree.list = undefined;
            folderTree.rev = 0;

            yield put(setTreeAction({ tree: [folderTree] as any })); // в folders.tree нету size, берем из folders.folder
        }
    } catch (err) {
        logger.error(err);
    }
}

export function* watchRouteChange() {
    yield takeEvery(loadHomeFolderRequest.toString(), loadHomeFolderRequestHandler);
    yield takeEvery(applicationStart.toString(), initHomeStorage);
    yield takeEvery(routerStart.toString(), loadFolderOnStart);
    yield takeLatest(loadMoreHomeRequest.toString(), loadMore);
    yield takeLatest(changeHomeHistory.toString(), changeHistorySaga);
}
