/* tempexp_15344-full-file */
import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { IS_PUBLIC } from 'reactApp/appHelpers/configHelpers';
import { requiredAuthorizationHelpers } from 'reactApp/modules/requiredAuthorization/helpers';
import { reDownloadController } from 'reactApp/modules/requiredAuthorization/reDownloadController';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { ETooltipAppearance, ETooltipPlacement } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';
import { noopVoid } from 'reactApp/utils/helpers';

interface Props {
    btnText: string;
    isSinglePublic?: boolean;
    isWhite?: boolean;
    downloadRef: HTMLDivElement | null;

    onShow?(): void;

    onClose?(): void;

    onClick?(): void;
}

export const DownloadTooltip: FC<Props> = memo(
    ({ btnText, isSinglePublic, isWhite = false, downloadRef, onShow = noopVoid, onClose = noopVoid, onClick = noopVoid }) => {
        const isNewbie = useSelector(UserSelectors.isNewbie);
        const isSuccessAuthWhenDownloadVariant1 = useSelector(requiredAuthorizationHelpers.isSuccessAuthVariant1);
        const isSuccessAuthWhenDownload = useSelector(requiredAuthorizationHelpers.isSuccessAuthWhenDownload);

        const showTooltip = isSinglePublic
            ? isSuccessAuthWhenDownloadVariant1 && !reDownloadController.isDownloadHappened()
            : isSuccessAuthWhenDownload;

        if (IS_PUBLIC && !isNewbie && showTooltip) {
            return (
                <FloatingTooltip
                    target={downloadRef}
                    title="Файл готов к скачиванию"
                    text={`Нажмите «${btnText}»`}
                    placement={ETooltipPlacement.bottom}
                    closeOnOutsideClick={false}
                    qaId="required-auth-when-download"
                    appearance={isWhite ? ETooltipAppearance.white : ETooltipAppearance.dark}
                    onShow={onShow}
                    onClick={onClick}
                    onClose={onClose}
                />
            );
        }

        return null;
    }
);

DownloadTooltip.displayName = 'DownloadTooltip';
