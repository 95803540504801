import { getUflrStatus, normalizePublicApiInfo } from 'reactApp/modules/file/utils';
import { MalwareStatus } from 'reactApp/modules/start/start.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';

export const itemMapper = (item, storage?: EStorageType) => {
    let itemNew = {
        ...item,
        home: item.path || item.home,
    };

    if (item?.public) {
        itemNew = {
            ...itemNew,
            ...normalizePublicApiInfo(item),
        };
    }

    const uflr = getUflrStatus(item);

    if (uflr) {
        itemNew.uflr = uflr;
    }

    itemNew.favorite = Boolean(item?.flags?.favorite);
    itemNew.readonly = (item.kind === 'mounted' || item.kind === 'domain-folder') && !item.flags?.writable;

    if (item?.malware?.status === MalwareStatus.infected) {
        itemNew.virus_scan = 'fail';
    }

    delete itemNew.path;

    itemNew.rev = item.rev || item.grev;

    if (storage === EStorageType.public) {
        itemNew.weblink = item.weblink;
        itemNew.id = item.weblink;
    }

    return itemNew;
};

export const itemV4ToV2 = (item, storage?: EStorageType, removeGalleryFlag?: boolean) => {
    if (item.list) {
        item.list = item.list.map((el) => {
            if (el?.list) {
                itemV4ToV2(el, storage);
            }

            return itemMapper(el, storage);
        });
    }

    // удаляем признак что это альбом, чтобы при переключении вкладок
    // случайно паблик не стал альбомом
    if (item.flags?.is_gallery && removeGalleryFlag) {
        delete item.flags?.is_gallery;
    }

    return itemMapper(item, storage);
};
