/**
 * Параметры необходимые для инициализации просмотрщика/редактора документов
 */
export type WopiParams = {
    accessParams?: {
        access_token: string;
        access_token_ttl: string;
    };
    postContentType?: string;
    url?: string;
};

/**
 * ID поддерживаемых просмотрщиков/редакторов для документов
 */
export enum EditorID {
    MAILPAD = 'mailpad',
    OFFICE = 'office',
    R7 = 'R7',
    MYOFFICE = 'myoffice',
    DOCS_MAIL = 'docsMail',
    OVIDIUS_V1 = 'ovidiusV1',
    OVIDIUS_V2 = 'ovidiusV2',
    R7_WOPI = 'r7wopi',
    MYOFFICE_ATTACHES = 'myofficeAttaches',
}
