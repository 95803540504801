import { Space } from '@mail/cross-sizes-utils';
import { TOpenTariffBuy } from 'reactApp/sections/MobileSubscriptionsPage/MobileSubscriptionsPage.types';
import { Product } from 'reactApp/types/Billing';

export interface ITariffCardProduct extends Product {
    saving?: number;
    primary?: boolean;
}

export enum MobileTariffCardTheme {
    white = 'white',
    grey = 'grey',
}

export interface IProps {
    space: Space;
    giftSpace?: Space;
    products: ITariffCardProduct[];
    fullPrice?: number;
    oldPrice?: number;
    fullPricePeriod?: string;
    onClick: TOpenTariffBuy;
    economy?: number;
    // tempexp_13600-start
    countSnapshotsBySpace?: number;
    openAbCard?: boolean;
    // tempexp_13600-end
    tariffTypeLabel?: string;
    savingLable?: string;
    hasOldPriceYear?: boolean;
    isOldCard?: boolean;
    infoText?: string;
    theme?: MobileTariffCardTheme;
    isSingleQuota?: boolean;
    alwaysExpand?: boolean;
    showPriceByMonth?: boolean;
    skipBenefitsFeature?: boolean;
    setCarouselLoaded?: (state: boolean) => void;
}
