import classNames from 'clsx';
import React, { createRef, PureComponent, ReactElement, RefObject } from 'react';
import { ENABLE_FULL_RESPONSIVE, IS_BIZ_USER, IS_ONPREMISE } from 'reactApp/appHelpers/configHelpers';
import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import { snackbarController } from 'reactApp/modules/snackbar/snackbar.controller';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { AccessRights } from 'reactApp/types/Tree';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { ShadeRight } from 'reactApp/ui/ShadeRight/ShadeRight';
import { CopyIcon, LinkIcon } from 'reactApp/ui/VKUIIcons';
import { copy as copyApiCall } from 'reactApp/utils/copyToClipboard';
import { noop } from 'reactApp/utils/helpers';

import styles from './CopyWeblink.css';
import { renderPreCopyWeblink } from './PreCopyWeblinkDialog/preCopyWeblink.helpers';

interface CopyProps {
    onCopy: (props?: any) => any;
    onClick?: (props?: any) => any;
    publicUrl?: string;
    autoCopy?: boolean;
    theme?: 'folder' | 'publish';
    type?: 'link' | 'new';
    isPhone?: boolean;
    isDownloadable?: boolean;
    weblinkAccessRights?: AccessRights;
    isFolder?: boolean;
    weblinkDomestic?: boolean;
    fileName?: string;
    isModifiedWindow?: boolean;
    buttonMobile?: string;
}

export class CopyWeblink extends PureComponent<CopyProps> {
    public static defaultProps = {
        onCopy: noop,
        onClick: noop,
        buttonMobile: 'Поделиться',
    };

    private input = createRef<HTMLInputElement>();

    public componentDidMount(): void {
        const { publicUrl, autoCopy } = this.props;

        if (!!publicUrl && autoCopy && !IS_BIZ_USER) {
            this.copy(this.input);
        }
    }

    private copy = (input: RefObject<HTMLInputElement>): void => {
        const { onCopy, isPhone, isDownloadable, weblinkAccessRights, isFolder, isModifiedWindow } = this.props;

        if (!input || !input.current) {
            return;
        }

        if (isPhone) {
            toolbarActions.shareLink({ url: input.current.value });
            onCopy();
            return;
        }

        const processCopy = (notification): void => {
            if (notification) {
                snackbarController.showSnackbar(notification);
            }

            if (input.current) {
                input.current.select();
                input.current.setSelectionRange(0, input.current.value.length);
            }

            onCopy(notification?.type === 'success');
        };

        const copyText = () => {
            const copyTextWithAccessBase = 'Ссылка скопирована с доступом для';

            if (isModifiedWindow) {
                return 'Доступ по ссылке открыт';
            }

            if (!IS_ONPREMISE || isFolder) {
                return 'Ссылка успешно скопирована';
            }

            if (isDownloadable) {
                return `${copyTextWithAccessBase} скачивания`;
            }

            if (weblinkAccessRights && weblinkAccessRights === AccessRights.rw) {
                return `${copyTextWithAccessBase} редактирования`;
            }

            return `${copyTextWithAccessBase} просмотра`;
        };

        copyApiCall(input.current.value)
            .then((resp): void => {
                const hasBeenCopied = resp ? resp.hasBeenCopied : true;
                const text = copyText();
                return processCopy(
                    hasBeenCopied
                        ? {
                              type: SnackbarTypes.success,
                              text,
                              id: 'copyLink',
                              closable: true,
                          }
                        : null
                );
            })
            .catch((): void =>
                processCopy({ type: SnackbarTypes.failure, text: 'Не удалось скопировать ссылку', id: 'copyLink', closable: true })
            );
    };

    handleCopy = (e?: React.MouseEvent<HTMLInputElement | HTMLButtonElement | SVGSVGElement>) => {
        e?.stopPropagation();
        this.copy(this.input);
    };

    handleCopyButtonClick = (e?: React.MouseEvent<HTMLInputElement | HTMLButtonElement | SVGSVGElement>) => {
        const { isFolder, weblinkDomestic } = this.props;

        if (!isFolder && IS_ONPREMISE && !weblinkDomestic) {
            return this.renderPreCopyDialog();
        }

        this.handleCopy(e);
    };

    renderPreCopyDialog = () => {
        const { fileName } = this.props;
        renderPreCopyWeblink({ onSuccess: this.handleCopy, fileName });
    };

    handleClick = () => {
        this.props?.onClick?.();
    };

    handleInputClick = () => {
        if (!this.input?.current) {
            return;
        }
        this.input.current.select();
        this.input.current.setSelectionRange(0, this.input.current.value.length);
    };

    handleCm = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    public render(): ReactElement {
        const { publicUrl, theme, type = 'link', isPhone, buttonMobile } = this.props;

        if (type === 'new') {
            return (
                <div
                    className={classNames({
                        [styles[`root_type_${type}`]]: type,
                        [styles.root_phone]: isPhone,
                        [styles.root_type_new_responsive]: ENABLE_FULL_RESPONSIVE,
                    })}
                    onContextMenu={this.handleCm}
                >
                    <input value={publicUrl} className={styles.input} ref={this.input} readOnly onClick={this.handleInputClick} />

                    <Button
                        theme={isPhone ? 'vk' : 'octavius'}
                        primary
                        middle
                        onClick={this.handleCopyButtonClick}
                        sizeMode={isPhone ? ButtonSizeMode.big : ButtonSizeMode.middle}
                        className={styles.button}
                    >
                        {isPhone ? buttonMobile : 'Скопировать'}
                    </Button>
                </div>
            );
        }

        return (
            <div
                className={classNames(styles.controls, {
                    [styles[`controls_${theme}`]]: !!theme,
                })}
                onContextMenu={this.handleCm}
            >
                <div className={styles.link}>
                    <LinkIcon />
                </div>

                <ShadeRight theme={theme}>
                    <input
                        value={publicUrl}
                        className={styles.input}
                        ref={this.input}
                        title="Скопировать"
                        onClick={this.handleCopy}
                        readOnly
                    />
                </ShadeRight>
                <div className={styles.copy} title="Копировать ссылку" onClick={this.handleClick}>
                    <CopyIcon data-qa-name="copy-link-icon" onClick={this.handleCopy} />
                </div>
            </div>
        );
    }
}
