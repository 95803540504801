import { APICallV3Post } from 'reactApp/api/APICall';

/**
 * Общий тип ответа от API
 */
type CommonAPIResponceField = {
    size: number;
    name: string;
};

/**
 * Ответ от API для паблика
 */
type PublicResponce = CommonAPIResponceField & {
    public: string;
};

/**
 * Ответ от API для хомяка
 */
type HomeResponse = CommonAPIResponceField & {
    home: string;
};

/**
 * Ответ от API для нередактирумых форматов
 */
type APIResponse = PublicResponce | HomeResponse;

// TODO: заготовка для паблика
export const isPublicResponse = (data: APIResponse): data is PublicResponce => 'public' in data;

/**
 * ТypeGuard: проверяет, что конвертируемый файл находится внутри хомяка
 */
export const isHomeResponse = (data: APIResponse): data is HomeResponse => 'home' in data;

/**
 * Класс, конвертирующий документ с определенными расширения в doc-подобный или xls-подобный формат
 *
 * **Пока работает только на хомяке**
 *
 */
export class OvidiusCreateCopyOfNoneditableFileAPICall extends APICallV3Post<APIResponse> {
    _method = 'ovidius/xml/convert';
    _withCredentials = true;
}
