import { captureException } from '@sentry/browser';
import Config from 'Cloud/config';
import classNames from 'clsx';
import debounce from 'lodash.debounce';
import { path } from 'ramda';
import React, { PureComponent, ReactElement } from 'react';
import { connect } from 'react-redux';
import { sendSuccessRadar } from 'reactApp/components/BuyModal/BuyModal.helpers';
import { DmrError } from 'reactApp/errors/serviceErrors/DmrError';
import { BillingActions } from 'reactApp/modules/billing/billing.module';
import { BillingSelectors } from 'reactApp/modules/billing/billing.selectors';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { ButtonLink } from 'reactApp/ui/ButtonLink/ButtonLink';
import Content from 'reactApp/ui/Content/Content';
import DMR from 'reactApp/ui/DMR/DMR';
import { PaymentFooter } from 'reactApp/ui/PaymentFooter/PaymentFooter';
import { createGaSender, sendGa as sendGaCommon } from 'reactApp/utils/ga';
import { sendGaPurchase } from 'reactApp/utils/gaHelpers';
import { noop } from 'reactApp/utils/helpers';
import { getPeriodName } from 'reactApp/utils/Period';
import { Radar } from 'reactApp/utils/Radar';
import { sendYaMetrika } from 'reactApp/utils/yaHelpers';
import { Ref } from 'semantic-ui-react';

import { IMapState, IOwnProps, IProps, IState } from './BuyContent.types';
import styles from './BuyModal.css';
import { ISubscription } from './BuyModal.types';

const sendGa = createGaSender('billing');

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const radar = new Radar({
    logFile: 'buy-dmr',
    groupName: 'buy-dmr',
});

const mapStateToProps = (state, props: IOwnProps): IMapState => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const subscription = ProductsSelectors.getProductById(state, props.id) as ISubscription;

    const { isLoading, hasError } = BillingSelectors.getLoadingState(state);
    const link = BillingSelectors.getPaymentLink(state);

    const { total: userTotalSpace } = UserSelectors.getCloudSpace(state);

    return {
        link: props.link || link,
        hasError: props.hasError || Boolean(hasError),
        isLoading: props.isLoading || isLoading,
        subscription,
        userTotalSpace,
    };
};

const mapDispatchToProps = (dispatch) => ({
    buySubscription: (id: string, source: string): void =>
        dispatch(BillingActions.buySubscription({ id, source, xraySource: 'buy-modal' })),
});

const GA_TYPE = 'BuyContent';

export class BuyContentComponent extends PureComponent<IProps, IState> {
    dmr: any | null = null;

    public static defaultProps = {
        onClose: noop,
        onSuccess: noop,
        retry: null,
        isPromocodesTrial: false,
        renew: false,
        onError: noop,
    };

    public readonly state: IState = {
        node: '',
        dmrLoading: false,
        dmrHeight: 0,
        hasError: false,
        expandFrame: false,
    };

    private height = 0;

    componentDidMount(): void {
        const { retry } = this.props;

        if (!retry) {
            this.callSubscriptionApi();
        }

        if (this.dmr) {
            this.dmr.destroy();
            this.dmr = null;
        }
    }

    componentDidUpdate(prevProps: IProps): void {
        if (prevProps.link !== this.props.link) {
            this.initDmr();
        }
    }

    componentDidCatch(error, errorInfo): void {
        this.setState({
            hasError: true,
        });

        captureException(error, { extra: errorInfo });
    }

    componentWillUnmount(): void {
        if (this.dmr) {
            this.dmr.destroy();
            this.dmr = null;
        }
    }

    callSubscriptionApi = (): void => {
        const { buySubscription, subscription, renew, source = '' } = this.props;

        if (!renew) {
            buySubscription(subscription.id, source);
        }
    };

    retry = (): void => {
        this.setState({
            hasError: false,
        });

        if (this.dmr) {
            this.dmr.destroy();
            this.dmr = null;
        }

        if (this.props.retry) {
            this.props.retry();
        } else {
            this.callSubscriptionApi();
        }
    };

    cameToKebabCase = (str: string): string => str.replace(/[A-Z]/g, (a, i) => (i ? '-' : '') + a.toLowerCase());

    // eslint-disable-next-line unicorn/consistent-function-scoping
    resize = debounce(() => {
        const { dmrLoading, dmrHeight } = this.state;
        const { height } = this;

        if (!dmrLoading && height && height !== dmrHeight) {
            this.setState({ dmrHeight: height });
            this.props.onDmrResizeEvent?.({ height });
        }
    });

    sendPaymentSuccessEvents = () => {
        const { subscription } = this.props;

        sendYaMetrika('pay-button');
        sendGaPurchase({
            tariffId: subscription.id,
            space: subscription.space.value,
            period: subscription.period,
            price: subscription.price.toString(),
            hasTrial: subscription.hasTrial?.toString(),
            eventCategory: GA_TYPE,
        });

        sendSuccessRadar(subscription.id);
    };

    initDmr = (): void => {
        if (this.props.link && !this.dmr) {
            const startTime = new Date().valueOf();
            const errorConf = {
                url: this.props.link,
                source: 'buy',
                pageId: Config.get('x-page-id'),
                user: Config.get('user.email'),
                component: 'buyModal',
                loadTime: 0,
            };

            this.setState({
                dmrLoading: true,
            });
            this.dmr = new DMR({ el: this.state.node });

            this.dmr.on(this.dmr.EVENT_3DS_START, () => {
                this.setState({ expandFrame: true });
            });

            this.dmr.on(this.dmr.EVENT_3DS_FINISH, () => {
                this.setState({ expandFrame: false });
            });

            this.dmr.on(this.dmr.EVENT_FORM_SEND, () => {
                if (!this.props.renew) {
                    sendGa('buySubscriptionFormSend');
                }
                this.props.onBuyClick?.();
            });

            this.dmr.on(this.dmr.EVENT_PAYMENT_SUCCESS, () => {
                if (!this.props.renew) {
                    sendGa(
                        'buySubscriptionFormSuccess',
                        `id-${this.props.subscription.id}-cost-${this.props.subscription.price}-period-${this.props.subscription.period}`,
                        null,
                        { sendGaOnly: true }
                    );
                }

                sendGaCommon(GA_TYPE, 'buy-success');
                radar.addCounter('success');
                radar.send();

                this.sendPaymentSuccessEvents();

                this.props.onSuccess();
            });

            this.dmr.on(this.dmr.EVENT_CLOSE, () => {
                radar.addCounter('close');
                radar.send();
                this.props.onSuccess();
                this.props.onClose();
            });

            this.dmr.on(this.dmr.EVENT_PAYMENT_CANCEL, () => {
                radar.addCounter('close');
                radar.send();
                if (this.props?.onCancel) {
                    this.props.onCancel();
                } else {
                    this.props.onClose();
                }
            });

            this.dmr.on(this.dmr.EVENT_LOADED, () => {
                const time = Date.now() - startTime;
                if (!this.props.renew) {
                    sendGa('buySubscriptionFormShow');
                }

                sendGaCommon(GA_TYPE, 'dmr-loaded', '', { time });
                sendGaCommon(GA_TYPE, 'dmr-loaded', [1, 2, 4, 6, 8, 10, 16].find((t) => time / 1000 <= t)?.toString() ?? '16<');

                radar.addCounter('load-success');
                radar.addCounter('load-time', new Date().valueOf() - startTime);
                radar.send();
                this.setState({ dmrLoading: false });
            });

            this.dmr.on(this.dmr.EVENT_MESSAGE, ({ type, action }) => {
                radar.addCounter(`type_${this.cameToKebabCase(type)}_action_${this.cameToKebabCase(action)}`);
                radar.send();
            });

            this.dmr.on(this.dmr.EVENT_RESIZE, (data) => {
                this.height = path(['height'], data) || 0;
                this.resize();
            });

            this.dmr.on(this.dmr.EVENT_LOAD_ERROR, ({ type }) => {
                const time = Date.now() - startTime;
                errorConf.loadTime = new Date().valueOf() - startTime;
                const error = new DmrError(errorConf);

                sendGaCommon(GA_TYPE, 'dmr-load-error', '', { time });
                sendGaCommon(GA_TYPE, 'dmr-load-error', [1, 2, 4, 6, 8, 10, 16].find((t) => time / 1000 <= t)?.toString() ?? '16<');

                radar.addLogRecord(error);
                radar.addCounter(`error_type_${type}`);
                radar.send();

                this.setState({
                    dmrLoading: false,
                    hasError: true,
                });
            });

            this.dmr.on(this.dmr.EVENT_PAYMENT_FAIL, () => {
                sendGa(GA_TYPE, 'payment-dmr-error');
                this.props.onError?.();
            });

            this.dmr.load(this.props.link);
        }
    };

    handleRef = (node): void => this.setState({ node });

    render(): ReactElement {
        const { subscription, isPromocodesTrial, hasError, isLoading, size, type, qaId, renew } = this.props;
        const { expandFrame, dmrHeight } = this.state;
        const showError = this.state.hasError || hasError;
        const showLoader = this.state.dmrLoading || isLoading;
        const showFooter = subscription && !(subscription.hasTrial && !renew);

        return (
            <div className={styles.root}>
                <Content
                    retry={this.retry}
                    buttonText="Попробовать ещё раз"
                    wrapClass={classNames({
                        [styles.content]: true,
                        [styles[`content_${size}`]]: !!size,
                        [styles[`content_type_${type}`]]: Boolean(type),
                        [styles.content_vkpay]: !isPromocodesTrial,
                        [styles.content_promocodes]: isPromocodesTrial,
                        [styles.content_expandedFrame]: expandFrame,
                    })}
                    description={
                        <span>
                            В&nbsp;случае возникновения проблем с&nbsp;оплатой вы&nbsp;можете обратиться в&nbsp;
                            <ButtonLink
                                inline
                                href="https://help.mail.ru/cloud_web/size/increase#trouble"
                                target="_blank"
                                rel="noopener noreferrer"
                                primary
                                size="big"
                            >
                                службу поддержки
                            </ButtonLink>
                            .
                        </span>
                    }
                    isLoading={showLoader}
                    hasError={showError}
                    isModal
                    prerenderContent
                >
                    {isPromocodesTrial && !expandFrame && (
                        <div className={styles.description}>
                            <div className={styles.header}>
                                {`Получите ${subscription.space.value}`}
                                <br />
                                {`на ${getPeriodName(subscription.trialPeriod || '1m', true, true)} бесплатно`}
                            </div>
                            <div className={styles.info}>Привяжите карту для активации бесплатного периода</div>
                        </div>
                    )}

                    <div className={styles.frame} style={dmrHeight ? { height: dmrHeight } : undefined} data-qa-id={qaId}>
                        <Ref innerRef={this.handleRef}>
                            <div />
                        </Ref>
                    </div>
                </Content>
                {showFooter && (
                    <div className={styles.footer}>
                        <PaymentFooter productId={subscription.id} />
                    </div>
                )}
            </div>
        );
    }
}

export const BuyContent = connect(mapStateToProps, mapDispatchToProps)(BuyContentComponent);
