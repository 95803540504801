import { Spacing } from '@vkontakte/vkui';
import React, { memo, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { familyInviteRequest, familyLoadRequest } from 'reactApp/modules/family/family.actions';
import {
    getFamilyLoadingState,
    getFamilyMemberList,
    isUserFamilyMember,
    isUserFamilyOwner,
    isUserWithFamily,
    userCanCreateFamily,
} from 'reactApp/modules/family/family.selectors';
import { checkUpdateLicenseRequest } from 'reactApp/modules/profile/profile.module';
import { historyPush } from 'reactApp/modules/router/router.module';
import { getIdByStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { openFamilyErrorModal } from 'reactApp/sections/FamilyPage/FamilyErrorDialog/FamilyErrorDialog.helpers';
import { FamilyError } from 'reactApp/sections/FamilyPage/FamilyErrorDialog/FamilyErrorDialog.types';
import { FamilyPromo } from 'reactApp/sections/FamilyPage/FamilyPromo/FamilyPromo';
import { FamilySettings } from 'reactApp/sections/FamilyPage/FamilySettings/FamilySettings';
import { useRouteChangeProcessing } from 'reactApp/sections/MainPage/hooks/useRouteChangeProcessing';
import { Footer } from 'reactApp/sections/MobileFamilyPage/Footer/Footer';
import Content from 'reactApp/ui/Content/Content';
import { Toolbar } from 'reactApp/ui/Mobile/Toolbar/Toolbar';

import styles from './MobileFamilyPage.css';

export const MobileFamilyPage = memo(() => {
    const dispatch = useDispatch();
    const params = useParams();
    const location = useLocation();

    const { isLoading, isLoaded } = useSelector(getFamilyLoadingState);
    const isBizUser = useSelector(UserSelectors.isBizUser);
    const isCorpUser = useSelector(UserSelectors.isCorpUser);
    const canCreateFamily = useSelector(userCanCreateFamily);
    const isFamilyMember = useSelector(isUserFamilyMember);
    const isFamilyOwner = useSelector(isUserFamilyOwner);
    const members = useSelector(getFamilyMemberList);
    const withFamily = useSelector(isUserWithFamily);

    const showFooter = (isFamilyOwner && members.length < 4) || canCreateFamily || !withFamily;

    const content = useMemo(() => {
        // если есть семья или состоишь в семье
        // если есть подписка (не только партнерская)
        // то показываем настройки семьи
        if (isFamilyMember || isFamilyOwner || canCreateFamily) {
            return <FamilySettings />;
        }

        return <FamilyPromo hidePromoButton />;
    }, [canCreateFamily, isFamilyMember, isFamilyOwner]);

    useEffect(() => {
        if (isBizUser || isCorpUser) {
            dispatch(historyPush({ id: getIdByStorage(EStorageType.home) }));
            openFamilyErrorModal({ errorType: FamilyError.wrongAccountEnter, storage: EStorageType.home, isMobile: true });
        }
    }, []);

    useEffect(() => {
        if (params?.inviteLink) {
            dispatch(familyInviteRequest(params?.inviteLink));
        }

        dispatch(checkUpdateLicenseRequest());
        dispatch(familyLoadRequest({ withQuota: true }));
    }, []);

    useRouteChangeProcessing({ storage: EStorageType.family, path: location.pathname, search: location.search });

    return (
        <Content isLoading={isLoading} wrapClass={styles.root}>
            <Toolbar showMenu logoWithEndorsment={false} disableActions />
            <div className={styles.content}>
                <Spacing size={12} />
                <div className={styles.title}>
                    {isFamilyMember || isFamilyOwner || canCreateFamily ? 'Общее хранилище Mail\u00A0Space' : 'Mail Space для близких'}
                </div>
                <Spacing size={16} />
                {content}
            </div>
            {showFooter && isLoaded && <Footer />}
        </Content>
    );
});

MobileFamilyPage.displayName = 'MobileFamilyPage';
