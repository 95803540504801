import { PayloadAction } from '@reduxjs/toolkit';
import app from 'Cloud/Application/app';
import config from 'Cloud/config';
import escapeForHtml from 'lib/escapeForHtml';
import { IS_REACT_PAGE } from 'reactApp/appHelpers/configHelpers';
import { publishHelper } from 'reactApp/appHelpers/publishHelper';
import { renderNewFolderModal } from 'reactApp/components/NewFolderModal/NewFolderModal.helpers';
import { NEW_FOLDER_DEFAULT_NAME } from 'reactApp/constants/magicIdentificators';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getFeatureABCreateFolderNewFlow } from 'reactApp/modules/features/features.selectors';
import { changeHomeHistory } from 'reactApp/modules/home/home.actions';
import { getHomeItemById } from 'reactApp/modules/home/home.selectors';
import { createFolderSuccess } from 'reactApp/modules/modifying/modifying.actions';
import { addFolderHelper } from 'reactApp/modules/modifying/modifying.helpers';
import { EModifyReason, ICreateFolderAction, ICreateFolderSuccess } from 'reactApp/modules/modifying/modifying.types';
import { closePopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { store as reduxStore } from 'reactApp/store';
import { openDisabledFeaturePopupHelper } from 'reactApp/ui/DisabledFeatureDialog/DisabledFeatureDialog.helpers';
import { DisabledFeature } from 'reactApp/ui/DisabledFeatureDialog/DisabledFeatureDialog.types';
import { sendGa } from 'reactApp/utils/ga';
import { noop, noopVoid } from 'reactApp/utils/helpers';
import opener from 'reactApp/utils/opener';
import { call, put, select } from 'redux-saga/effects';

import { createFolderErrors } from '../modifying.constants';

const MAX_NAME_LENGTH = config.get('ITEM_NAME_MAX_LENGTH');

export function createFolderRequest({
    folderName,
    parent,
    skipXhr = undefined,
    conflictMode = undefined,
    storage,
    share,
    gaPrefix,
}): Promise<{ folderName: string }> {
    return new Promise((resolve, reject) => {
        folderName = folderName.trim();

        if (!folderName) {
            return reject(createFolderErrors.getMessage('required'));
        }

        if (folderName.length > MAX_NAME_LENGTH) {
            return reject(createFolderErrors.getMessage('name_too_long'));
        }

        if (app.addFolder) {
            app.addFolder(folderName, parent, skipXhr, conflictMode, storage)
                .done((newFolderName) => {
                    reduxStore.dispatch(
                        createFolderSuccess({
                            storage,
                            share,
                            folderName: newFolderName,
                            gaPrefix,
                        })
                    );
                    resolve({ folderName: newFolderName });
                })
                .fail((error) =>
                    reject(
                        createFolderErrors.getMessage(error, {
                            '{FOLDER_NAME}': escapeForHtml(folderName),
                        })
                    )
                );
        } else {
            addFolderHelper({
                item: folderName,
                parent,
                storage,
                skipXhr,
                conflictMode,
                reason: EModifyReason.add,
            })
                .then((item: any) => {
                    reduxStore.dispatch(
                        createFolderSuccess({
                            storage,
                            share,
                            folderName: item.id,
                            gaPrefix,
                        })
                    );
                    resolve({ folderName: item.id });
                })
                .catch((error) => {
                    reject(
                        createFolderErrors.getMessage((error?.home && error?.home.error) || error, {
                            '{FOLDER_NAME}': escapeForHtml(folderName),
                        })
                    );
                });
        }
    });
}

export function* createNewFolder(action: ICreateFolderAction) {
    const { parentFolderId, showCreateButton, showShareButton = true, storage, gaPrefix, onCreateFolder, onClose = noopVoid } = action;

    const isCorpUser = yield select(UserSelectors.isCorpUser);
    const { isFull: isOverQuota } = yield select(UserSelectors.getCloudSpace);
    // tempexp-14737-start
    const isPhone = yield select(EnvironmentSelectors.isPhone);
    const isNewFlowEnabled = yield select(getFeatureABCreateFolderNewFlow);
    // tempexp-14737-end

    if (isOverQuota && !isCorpUser) {
        openDisabledFeaturePopupHelper({ disabledFeature: DisabledFeature.newFolder });
        return;
    }

    // tempexp-14737-start
    if (isPhone || !isNewFlowEnabled) {
        // Оставляем старый флоу.
        yield renderNewFolderModal(
            {
                createFolder: async (folderName: string, share: boolean) => {
                    const { folderName: newFolder } = await createFolderRequest({
                        folderName,
                        share,
                        parent: parentFolderId,
                        storage,
                        gaPrefix,
                    });

                    if (newFolder) {
                        onCreateFolder?.(newFolder);
                    }
                },
                showShareButton,
                showCreateButton,
            },
            onClose
        );
    } else {
        // На десктопе в контрольном варианте сразу создаем папку.
        const { folderName } = yield call(createFolderRequest, {
            folderName: NEW_FOLDER_DEFAULT_NAME,
            share: false,
            parent: parentFolderId,
            storage,
            gaPrefix,
        });

        onCreateFolder?.(folderName);
    }
    // tempexp-14737-end
}

export function* handleCreateNewFolder(action: PayloadAction<ICreateFolderAction>) {
    yield createNewFolder(action.payload);
}

export function* handleCreateFolderSuccess(action: PayloadAction<ICreateFolderSuccess>) {
    const { folderName, share, storage = '', gaPrefix } = action.payload;
    const currentStorage = yield select(getCurrentStorage);
    const { isHome, isPublic, isStart } = getStorage(storage || currentStorage);
    // tempexp-14737-start
    const isPhone = yield select(EnvironmentSelectors.isPhone);
    const isNewFlowEnabled = yield select(getFeatureABCreateFolderNewFlow);
    // tempexp-14737-end

    yield closePopupHelper(popupNames.CREATE_FOLDER);

    const folder = IS_REACT_PAGE ? yield select(getHomeItemById, folderName) : app.get(folderName, storage);

    if (!folder) {
        return;
    }

    // tempexp-14737-next-line
    // На мобилке или если не включена фича - перекидываем пользователя в созданную папку.
    const destination = isPhone || !isNewFlowEnabled ? folder.id : folder.parent;

    if (IS_REACT_PAGE) {
        // tempexp-14737-start
        if ((!isPublic && !isStart && currentStorage !== EStorageType.alldocuments) || (isStart && !isNewFlowEnabled)) {
            yield put(changeHomeHistory({ id: destination }));
        }
        // tempexp-14737-end

        if (share && folder && folder.id !== '/') {
            publishHelper({
                item: folder,
                itemStorage: EStorageType.home,
                // tempexp-14737-next-line
                onClose: () => (isHome ? noop : opener(`/home/${encodeURIComponent(destination)}`, true)),
            });
        }
    } else {
        sendGa('folderCreate', gaPrefix || 'home');

        let publishItem = '';
        if (share && folderName !== '/') {
            publishItem = '?action=publish-item';
        }

        // tempexp-14737-next-line
        opener(`/home${encodeURIComponent(destination)}${publishItem}`, true);
    }
}
