import { useCallback } from 'react';
import { IS_PHONE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { ISubscription } from 'reactApp/modules/subscriptions/subscriptions.types';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

// https://docs.google.com/spreadsheets/d/1oxESW-0Ne7WtP7gKejV6CJYSv-Q9ssFDC8MY1_chDT0/edit#gid=1290046406

export enum ESubscriptionModalAction {
    open = 'open',
    addSpace = 'add-space',
    openCancelAutopayment = 'cancel-autopay',
    notCancelAutopayment = 'cancel-autopay-false',
    cancelAutopayment = 'cancel-autopay-true',
    turnOnAutopayment = 'turnon-autopay',
    changeCard = 'change-card',
    addAnotherCard = 'change-card-true',
    cancelAnotherCard = 'change-card-false',
    addNewCard = 'add-card-true',
    cancelNewCard = 'add-card-false',
    partnerClick = 'partner-click',
    prolongSubs = 'prolong-sub',
    captchaView = 'cancel-capcha-view',
    captchaSaveSubs = 'cancel-capcha-save',
    captchaCancelSubs = 'cancel-capcha-cancel',
    captchaCancelSubsSuccess = 'cancel-capcha-success',
    captchaCancelSubsFail = 'cancel-capcha-fail',
    cancelAutopaymentRestore = 'cancel-autopay-restore',
}

interface ISubscriptionModalAnalytics {
    action: ESubscriptionModalAction;
    product: string;
    start_date: number;
    expired_date: number;
    source: 'touch' | 'desktop';
    type: string;
    partner_name?: string;
}

export type TSendAnalytics = (action: ESubscriptionModalAction) => void;

export const sendSubscriptionModalAnalytics = ({ action, ...rest }: ISubscriptionModalAnalytics): void => {
    sendDwh({
        eventCategory: ECategoryGa.payment,
        action: `my-tariff-${action}`,
        dwhData: rest,
    });
};

export const useSendSubscriptionModalAnalytics = (subscription: ISubscription): TSendAnalytics => {
    return useCallback(
        (action: ESubscriptionModalAction) => {
            sendSubscriptionModalAnalytics({
                action,
                type: subscription.type,
                product: subscription.productId || subscription.promoId || 'None',
                start_date: subscription?.start || 0,
                expired_date: subscription?.expires || 0,
                partner_name: subscription?.partner || 'None',
                source: IS_PHONE_BROWSER ? 'touch' : 'desktop',
            });
        },
        [subscription]
    );
};
