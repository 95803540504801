import { Buffer } from 'buffer';
import { xray } from 'lib/xray';
import { PdfAttachInfoAPICall } from 'reactApp/api/PdfAttachInfoAPICall';
import { PdfAttachViewPartAPICall } from 'reactApp/api/PdfAttachViewPartAPICall';
import { PdfInfoAPICall } from 'reactApp/api/PdfInfoAPICall';
import { PdfViewAttachPartAPICall } from 'reactApp/api/PdfViewAttachPartAPICall';
import { PdfViewPartAPICall } from 'reactApp/api/PdfViewPartAPICall';
import { isAttachFile } from 'reactApp/modules/attaches/attaches.helpers';
import { OvidiusVersion } from 'reactApp/modules/features/features.types';
import { OvidiusPartsInfo } from 'reactApp/modules/ovidius/ovidius.types';
import { CloudFile, EStorageType } from 'reactApp/modules/storage/storage.types';
import { parse } from 'reactApp/utils/parseMultipartData';

const getRealStorage = (storage: EStorageType): EStorageType => {
    if (storage === EStorageType.public || storage === EStorageType.attaches) {
        return storage;
    } else if (storage === EStorageType.viewerAttaches || storage === EStorageType.stock) {
        return EStorageType.attaches;
    }

    return EStorageType.home;
};

const getPdfFilePartCallback = (
    url: string,
    storage: EStorageType,
    file: CloudFile,
    fullContent: ArrayBuffer | null,
    attachVersion?: OvidiusVersion,
    hash?: string | null
) => {
    return async (partId: string) => {
        const radarPrefix = isAttachFile(file) ? `${file.attachType}_` : '';
        const realStorage = getRealStorage(storage);

        try {
            let data: ArrayBuffer | null = null;
            if (attachVersion === 1) {
                data = (
                    await new PdfAttachViewPartAPICall().makeRequest(fullContent, null, {
                        part_id: partId,
                        filename: file.name,
                    })
                ).data;
            } else if (attachVersion === 2) {
                data = (await new PdfViewAttachPartAPICall().makeRequest({ hash, part_id: partId })).data;
            } else {
                data = (
                    await new PdfViewPartAPICall().makeRequest({
                        [realStorage]: url,
                        part_id: partId,
                    })
                ).data;
            }

            if (!data?.byteLength) {
                throw new Error('no content');
            }

            xray.send(`ovidius_${radarPrefix}get_part_success`);

            return { content: Buffer.from(data) };
        } catch (error: any) {
            xray.send(`ovidius_${radarPrefix}get_part_inf_error`, {
                rlog: 'cloud_norm_pdf_err',
                rlog_message: {
                    message: 'get_part_inf_error',
                    error,
                    partId,
                    storage,
                    isAttach: !!attachVersion,
                },
            });
            return {};
        }
    };
};

const getPdfFileParts = async (
    url: string,
    storage: EStorageType,
    file: CloudFile,
    fullContent: ArrayBuffer | null,
    isAttach: boolean
): Promise<{ partsInfo?: OvidiusPartsInfo; firstPart?: ArrayBuffer }> => {
    const radarPrefix = isAttachFile(file) ? `${file.attachType}_` : '';
    const realStorage = getRealStorage(storage);
    try {
        const { headers, data } = isAttach
            ? await new PdfAttachInfoAPICall().makeRequest(fullContent, null, { filename: file.name })
            : await new PdfInfoAPICall().makeRequest({ [realStorage]: url });

        const contentType = headers['content-type'];
        if (!contentType) {
            xray.send(`ovidius_${radarPrefix}parts_no_content_type`, {
                rlog: 'cloud_norm_pdf_err',
                rlog_message: {
                    error: 'no_content_type',
                    contentType,
                    file: file.name,
                },
            });
            return {};
        }

        const boundary = contentType?.split('boundary=')?.[1];
        const buffer = Buffer.from(data);
        const parts = parse(buffer, boundary);

        if (parts.length !== 2) {
            xray.send(`ovidius_${radarPrefix}parts_format_error`, {
                rlog: 'cloud_norm_pdf_err',
                rlog_message: {
                    error: 'parts_format_error',
                    parts: parts.length,
                    file: file.name,
                },
            });
            return {};
        }

        const partsInfoData = parts[0]?.data;
        const partsString = new TextDecoder().decode(partsInfoData);
        const partsInfo = JSON.parse(partsString);

        const firstPart = parts[1]?.data;

        xray.send(`ovidius_${radarPrefix}parts_success`);

        return { partsInfo, firstPart };
    } catch (error: any) {
        xray.send(`ovidius_${radarPrefix}get_parts_error`, {
            rlog: 'cloud_norm_pdf_err',
            rlog_message: {
                message: 'get_parts_error',
                error,
            },
        });
        return {};
    }
};

export const Ovidius = {
    getPdfFilePartCallback,
    getPdfFileParts,
};
