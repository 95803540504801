// tempexp_14979-full-file
import defaultQuota8CoverImage from 'img/aside-promo-modal/default-quota-8.jpg';
import defaultQuota10CoverImage from 'img/aside-promo-modal/default-quota-10.jpg';
import defaultQuota12CoverImage from 'img/aside-promo-modal/default-quota-12.jpg';
import defaultQuota16CoverImage from 'img/aside-promo-modal/default-quota-16.jpg';
import React from 'react';
import { BASEQUOTA } from 'reactApp/appHelpers/configHelpers';
import { defaultQuotaValueMockForQA, isHideQuotaNewbieFeatureEnabled } from 'reactApp/appHelpers/featuresHelpers';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { store as reduxStore } from 'reactApp/store';
import { openAsidePromoModal } from 'reactApp/ui/AsidePromoModal/AsidePromoModal.helpers';
import { call, put } from 'redux-saga/effects';
import { select } from 'typed-redux-saga';

const PROMO_ID = 'default-quota-promo';
const AVERAGE_IMG_SIZE = 3145728; // 3MB в байтах
const AVERAGE_VIDEO_SIZE = 41943040; // 40MB в байтах

const FormattedTitle = ({ size }) => {
    return (
        <>
            У вас <span>{size} ГБ</span> памяти
        </>
    );
};

function* getContent() {
    const qaMockSize = defaultQuotaValueMockForQA;
    const total = yield* select(UserQuotaSelectors.getTotal);
    const original = total?.original || BASEQUOTA;
    const space = total?.space || 8;

    // QA необходимо будет проверить разную дефолтную квоту до ее включения. поэтому мокаем.
    const sizeInBites = qaMockSize ? Number(qaMockSize) * 1024 * 1024 * 1024 : original;
    const size = qaMockSize || space;

    const imagesCount = Math.round(sizeInBites / AVERAGE_IMG_SIZE);
    const videoCount = Math.round(sizeInBites / AVERAGE_VIDEO_SIZE);

    if (size === 10) {
        return {
            title: <FormattedTitle size={size} />,
            imageUrl: defaultQuota10CoverImage,
            text: `Этого места вам хватит на ${imagesCount} фотографий и ${videoCount} видео`,
        };
    }

    if (size === 12) {
        return {
            title: <FormattedTitle size={size} />,
            imageUrl: defaultQuota12CoverImage,
            text: `Этого места вам хватит на ${imagesCount} фотографий и ${videoCount} видео`,
        };
    }

    if (size === 16) {
        return {
            title: <FormattedTitle size={size} />,
            imageUrl: defaultQuota16CoverImage,
            text: `Это больше чем у всех облачных сервисов, 16 ГБ вам хватит на ${imagesCount} фотографий и ${videoCount} видео`,
        };
    }

    return {
        title: <FormattedTitle size={size} />,
        imageUrl: defaultQuota8CoverImage,
        text: `Этого места вам хватит на ${imagesCount} фотографий и ${videoCount} видео`,
    };
}

export function* initDefaultQuotaAsidePromoModal() {
    const storage = yield* select(getCurrentStorage);
    const isNewbie = yield* select(UserSelectors.isNewbie);
    const alreadyClosed = storeHelper.getValue(PROMO_ID);

    if (!alreadyClosed && storage === EStorageType.home && isHideQuotaNewbieFeatureEnabled && isNewbie) {
        yield put(
            addPromoToShowQueue({
                type: EPromoType.defaultQuotaAsidePromo,
                promoId: PROMO_ID,
                onShow: () => {
                    reduxStore.dispatch(removePromo(EPromoType.defaultQuotaAsidePromo));
                    reduxStore.dispatch(promoShown(EPromoType.defaultQuotaAsidePromo));
                    storeHelper.markAsShown(PROMO_ID);
                },
                onClose: () => {
                    reduxStore.dispatch(promoShown(EPromoType.defaultQuotaAsidePromo));
                    reduxStore.dispatch(removePromo(EPromoType.defaultQuotaAsidePromo));
                    storeHelper.markAsShown(PROMO_ID);
                },
            })
        );
    }
}

export function* showDefaultQuotaAsidePromoModal() {
    const promo = yield* select(PromoSelectors.getPromo(EPromoType.defaultQuotaAsidePromo));
    const isNewbie = yield* select(UserSelectors.isNewbie);

    if (promo && isHideQuotaNewbieFeatureEnabled && !isNewbie) {
        const { onShow, onClose } = promo;
        const { title, text, imageUrl } = yield call(getContent);

        yield openAsidePromoModal({
            title,
            text,
            imageUrl,
            onShow,
            onClose,
            buttonText: 'Хорошо',
        });
    }
}
