import { captureException } from '@sentry/browser';
import { logger } from 'lib/logger';
import { ProfileAgreeLaApiCall } from 'reactApp/api/ProfileAgreeLa';
import { ProfileUserLaApiCall } from 'reactApp/api/ProfileUserLa';
import { UserNewDeviceAPICall } from 'reactApp/api/UserNewDeviceAPICall';
import { IS_MOBILE_BROWSER, IS_PUBLIC, X_PAGE_ID } from 'reactApp/appHelpers/configHelpers';
import { isHideQuotaNewbieFeatureEnabled } from 'reactApp/appHelpers/featuresHelpers';
import { renderUpdateLicenseDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog.helpers';
import { renderCreateDocumentByQueryActionDialog } from 'reactApp/components/CreateDocumentByLinkDialog/createDocumentByLinkDialog.helpers';
import { renameItem } from 'reactApp/modules/modifying/sagas/rename.saga';
import { DEVICE_ID_WEB } from 'reactApp/modules/profile/profile.contants';
import { agreeUpdateLicenseRequest, checkUpdateLicenseCompleted, checkUpdateLicenseRequest } from 'reactApp/modules/profile/profile.module';
import { ProfileApiResponses } from 'reactApp/modules/profile/profile.types';
import { routeChangeSuccess } from 'reactApp/modules/router/router.module';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { updateUser } from 'reactApp/modules/user/user.thunkActions';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { ViewerSelectors } from 'reactApp/modules/viewer/viewer.selectors';
import { getBooleanCookie } from 'reactApp/utils/cookie';
import { sendGa, sendXray } from 'reactApp/utils/ga';
import { channel } from 'redux-saga';
import { call, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

const sentMeAdsCloudCookieName = 'sent_ads_cloud';
const defaultData = { did: DEVICE_ID_WEB };

const newDeviceApiCall = () =>
    new UserNewDeviceAPICall().makeRequest({
        ...defaultData,
        name: DEVICE_ID_WEB,
        plid: DEVICE_ID_WEB,
    });

const agreeLicenseApiCall = ({ initialize_unsubscribed, first_login = '' }) =>
    new ProfileAgreeLaApiCall().makeRequest({
        ...defaultData,
        initialize_unsubscribed,
        first_login,
    });

const checkLicenseApiCall = () => new ProfileUserLaApiCall().makeRequest(defaultData);

const debug = (...args) => {
    logger.verbose('[profile.saga]', ...args);
};

let isCheckUpdateDone = false;

function* handleApiError(error, retry, data = undefined) {
    const message = error?.response?.message;
    if (message === ProfileApiResponses.USER_NOT_FOUND || message === ProfileApiResponses.DEVICE_NOT_FOUND) {
        try {
            const result = yield call(newDeviceApiCall);
            debug('newDeviceApiCall:', result);
            yield call(retry, data);
        } catch (error) {
            captureException(error);
            logger.error(error);
        }
    }
}

function* hideAndUpdateNewUserQuotaActions() {
    if (!isHideQuotaNewbieFeatureEnabled) {
        return;
    }

    try {
        yield renameItem('/Берег.jpg', 'Берег1.jpg');
        yield renameItem('/Берег1.jpg', 'Берег.jpg');
        yield put(updateUser());
        const { userTotalSpace } = yield select(UserQuotaSelectors.getTotal);
        sendXray(['user', 'quota', 'default', userTotalSpace?.value]);
    } catch (error) {
        logger.error(error);
    }
}

function* agreeUpdateLicense(action) {
    try {
        const sentMeAdsCloudCookieValue = getBooleanCookie(sentMeAdsCloudCookieName, X_PAGE_ID.toLowerCase());
        const result = yield call(agreeLicenseApiCall, {
            initialize_unsubscribed: !sentMeAdsCloudCookieValue,
            first_login: action?.payload?.source,
        });
        // tempexp_14979-start
        // CLOUDWEB-14979: После принятия ЛС пользователем нужно один раз произвести действие с файлами в облаке.
        // Для этого переименовываем дефолтный файл и обратно. Под фичей и только один раз
        yield call(hideAndUpdateNewUserQuotaActions);
        // tempexp_14979-end
        debug('agreeLicenseApiCall:', result);
    } catch (error) {
        captureException(error);
        logger.error(error);
        yield call(handleApiError, error, agreeUpdateLicense, action);
    } finally {
        yield put(checkUpdateLicenseCompleted());
    }
}

function* askForUpdateLicense() {
    sendGa('la-update', 'show');

    const chan = channel();

    renderUpdateLicenseDialog({
        onSuccess: () => chan.put(true),
    });

    yield take(chan);
    sendGa('la-update', 'agree');

    yield call(renderCreateDocumentByQueryActionDialog);

    yield call(agreeUpdateLicense, { payload: { source: 'update' } });
}

function* checkUpdateLicense() {
    const isNewbie = yield select(UserSelectors.isNewbie);
    const isBizUser = yield select(UserSelectors.isBizUser);
    const isViewerActive = yield select(ViewerSelectors.isViewerActive);
    const isViewerOpening = yield select(ViewerSelectors.isViewerOpening);

    if (isCheckUpdateDone || isViewerActive || isViewerOpening || isNewbie || isBizUser || IS_PUBLIC || IS_MOBILE_BROWSER) {
        return;
    }

    try {
        isCheckUpdateDone = true;
        const { data } = yield call(checkLicenseApiCall);

        if (data?.status !== 'NOT_ACCEPTED') {
            yield put(checkUpdateLicenseCompleted());

            yield call(renderCreateDocumentByQueryActionDialog);

            return;
        }

        yield call(askForUpdateLicense);
    } catch (error) {
        yield call(handleApiError, error, checkUpdateLicense);
        yield put(checkUpdateLicenseCompleted());
        captureException(error);
        logger.error(error);
    }
}

export function* watchProfile() {
    yield takeEvery(routeChangeSuccess.toString(), checkUpdateLicense);
    yield takeEvery(checkUpdateLicenseRequest.toString(), checkUpdateLicense);
    yield takeLatest(agreeUpdateLicenseRequest.toString(), agreeUpdateLicense);
}
