/* eslint-disable max-lines-per-function */
import { Icon20Chain } from '@vkontakte/icons';
import { Switch } from '@vkontakte/vkui';
import classNames from 'clsx';
import { xray } from 'lib/xray';
import React, { memo, ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isModifiedSharing } from 'reactApp/appHelpers/appHelpers';
import { unPublishHelper } from 'reactApp/appHelpers/publishHelper';
import { sendGa } from 'reactApp/components/SharingWindow/Sharing.helpers';
import { useDomestic } from 'reactApp/components/SharingWindow/Weblink/hooks/useDomestic';
import { useExpires } from 'reactApp/components/SharingWindow/Weblink/hooks/useExpires';
import styles from 'reactApp/components/SharingWindow/Weblink/SharingWeblink.css';
import { renderUnpublishWeblinkDialog } from 'reactApp/components/SharingWindow/Weblink/SharingWeblink.helpers';
import { IProps } from 'reactApp/components/SharingWindow/Weblink/SharingWeblink.types';
import { getFeaturePublicDomestic } from 'reactApp/modules/features/features.selectors';
import { publishRequest } from 'reactApp/modules/modifying/modifying.actions';
import { PublishItem } from 'reactApp/modules/modifying/modifying.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { CopyWeblink } from 'reactApp/ui/CopyWeblink/CopyWeblink';
import { getPublicUrl } from 'reactApp/utils/urlHelper';

import { EFrom } from '../../Sharing.types';
import { useCoEditCondition } from './hooks/useCoEditCondition';

/* eslint-disable complexity */
export const SharingModifiedWeblink = memo<IProps>(function SharingNewWeblink(props): ReactElement | null {
    const { isOwn, item, from, isSharedOrMouted, noSwitch = false, noExpiresControl = false, isPhone, onClose } = props;
    const isModifiedWindow = isModifiedSharing(item);
    const dispatch = useDispatch();
    const itemStorage = useSelector(getCurrentStorage);
    const storage = item?.storage || itemStorage;
    const isAlbum = from === EFrom.ALBUMS || storage === EStorageType.albums;
    const isPublic = Boolean(item?.weblink);
    // Исключаем стоки, у которых них нет публичной ссылки
    const publicUrl = (isPublic && getPublicUrl(item, isAlbum)) || '';

    const isFromWeblink = from === EFrom.WEBLINK || isPublic;
    const isStock = itemStorage === EStorageType.stock;

    const [isWeblinkOpened, setWeblinkOpened] = useState<boolean>(isFromWeblink);

    const renderExpires = useExpires(item);
    const renderDomestic = useDomestic(item, { storage });
    const renderCoEdit = useCoEditCondition(isSharedOrMouted, item as PublishItem, onClose);

    const isBizUser = useSelector(UserSelectors.isBizUser);
    const isCorpUser = useSelector(UserSelectors.isCorpUser);
    const isDomesticFeatureEnabled = useSelector(getFeaturePublicDomestic);
    const showDomesticSettings = (!isAlbum || !isStock) && (isBizUser || isCorpUser) && Boolean(isDomesticFeatureEnabled) && isOwn;
    const showExpires = isStock || (!isAlbum && !noExpiresControl && isOwn);
    const showCoEdit = !isAlbum && isOwn;

    const onActionClick = useCallback(() => {
        sendGa('unpublish');
        xray.send('sharing-modified_unpublish');
        if (storage === EStorageType.attaches) {
            sendGa('attaches-unpublish');
        }

        if (!item) {
            return;
        }

        unPublishHelper({ items: [item] });
    }, [storage, item]);

    const onChangeWeblink = useCallback(() => {
        if (!item) {
            return;
        }

        if (isWeblinkOpened) {
            renderUnpublishWeblinkDialog({ onActionClick, item, isAlbum });
            return;
        }

        sendGa('publish');
        xray.send('sharing-modified_publish');
        dispatch(publishRequest({ item }));
    }, [isWeblinkOpened, item, onActionClick]);

    useEffect(() => {
        setWeblinkOpened(Boolean(publicUrl));
    }, [publicUrl]);

    return (
        <>
            <div
                data-qa-id="contentOpened"
                className={classNames({ [styles.root_opened]: isWeblinkOpened }, { [styles.root_closed]: !isWeblinkOpened })}
            >
                <div className={classNames({ [styles.root]: isWeblinkOpened }, { [styles.root_phone]: isPhone })}>
                    <div className={styles.copyWeblink}>
                        <CopyWeblink
                            type="new"
                            publicUrl={publicUrl}
                            autoCopy={!isPhone}
                            isPhone={isPhone}
                            isModifiedWindow={isModifiedWindow}
                        />
                    </div>
                    {isOwn && (
                        <div className={classNames({ [styles.cellGroup]: isWeblinkOpened })}>
                            {showCoEdit && renderCoEdit()}
                            {showDomesticSettings && renderDomestic()}
                            {showExpires && renderExpires()}
                        </div>
                    )}
                </div>
                <div>
                    {isOwn && (
                        <button data-qa-id="closeAccess" className={styles.closeAccessBottom} onClick={onChangeWeblink}>
                            Закрыть доступ
                        </button>
                    )}
                </div>
            </div>
            <div
                data-qa-id="contentClosed"
                className={classNames({ [styles.root_closed]: isWeblinkOpened }, { [styles.root_opened]: !isWeblinkOpened })}
            >
                <div className={classNames({ [styles.root]: !isWeblinkOpened })} data-qa-section={name}>
                    {!noSwitch && (
                        <div className={classNames({ [styles.cellGroup]: !isWeblinkOpened })}>
                            <div data-qa-id="enableAccessSection" className={classNames(styles.rightItem, styles.rightItemModified)}>
                                <Icon20Chain className={styles.leftIcon} />
                                <span className={styles.rightTitle}>Включить доступ по ссылке</span>
                                <div className={styles.rightAction}>
                                    <Switch data-qa-id="enableLink" name="Имя" onChange={onChangeWeblink} checked={isWeblinkOpened} />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
});
