import React, { useMemo } from 'react';
import { FastCheckoutSidebar } from 'reactApp/components/VkBuyModalWithSidebar/FastСheckoutSidebar/FastCheckoutSidebar';
import { SaveSubscriptionSidebar } from 'reactApp/components/VkBuyModalWithSidebar/SaveSubscriptionSidebar/SaveSubscriptionSidebar';
import { TrialPromoSidebar } from 'reactApp/components/VkBuyModalWithSidebar/TrialPromoSidebar/TrialPromoSidebar';
import styles from 'reactApp/components/VkBuyModalWithSidebar/VkBuyModalWithSidebar.css';
import { EPaymentModalType } from 'reactApp/components/VkBuyModalWithSidebar/VkBuyModalWithSidebar.types';
import { PaymentFooter } from 'reactApp/ui/PaymentFooter/PaymentFooter';

export const useContent = ({ type, product, changeProduct, onClickTariffs, sidebarProps, tariff, isMarketingPromo }) => {
    const sidebar = useMemo(() => {
        if (type === EPaymentModalType.trial || type === EPaymentModalType.attachesTrial) {
            return <TrialPromoSidebar product={product} />;
        }

        if (type === EPaymentModalType.fastcheckout) {
            return (
                <FastCheckoutSidebar
                    product={product}
                    onProductChange={changeProduct}
                    onClickTariffs={onClickTariffs}
                    tariff={tariff}
                    isMarketingPromo={isMarketingPromo}
                />
            );
        }

        if (type === EPaymentModalType.saveSubscription) {
            return <SaveSubscriptionSidebar product={product} {...sidebarProps} />;
        }
    }, [type, product, changeProduct, onClickTariffs]);

    const header = useMemo(() => {
        if (type === EPaymentModalType.fastcheckout) {
            return <div className={styles.header}>Подписка на единый тариф Почты и Облака</div>;
        }
    }, [type]);

    const footer = useMemo(() => {
        if (type === EPaymentModalType.fastcheckout) {
            return <PaymentFooter productId={product?.id} />;
        }
    }, [product?.id, type]);

    return {
        sidebar,
        header,
        footer,
    };
};
