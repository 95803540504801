import {
    defaultPayMethod,
    defaultPayMethodTouch,
    isDmrMidasUrlEnabled,
    isDmrMidasUrlTouchEnabled,
    isHidePayMethodsMenu,
    isHidePayMethodsMenuTouch,
} from 'reactApp/appHelpers/featuresHelpers';
import { getQueryParams, getSourceString } from 'reactApp/appHelpers/settingsHelpers';
import { getPretextPeriodForProduct } from 'reactApp/modules/products/products.helpers';
import { Product } from 'reactApp/types/Billing';
import { getPeriodName, getPeriodNameAsWord } from 'reactApp/utils/Period';
import { formatPrice } from 'reactApp/utils/priceHelpers';

import { BillingBuyTemplates, BillingBuyViewTemplates, BuyAction, CommonBuyApiParams } from './billing.types';

export const getRequestId = (): string => Date.now().toString();

export const getMidasPaymentLink = (url: string, isMobile = false) => {
    const isNewUrlEnabled = (isMobile && isDmrMidasUrlTouchEnabled) || (!isMobile && isDmrMidasUrlEnabled);

    if (!isNewUrlEnabled) {
        return url;
    }

    return url?.replace(/\/\/pw\.money\.mail\.ru\/pw\/[\d-]+\//, '//co.vkpay.io/');
};

export const getPayMethod = (isMobile) => {
    const { pay_method: payMethod } = getQueryParams();

    if (payMethod) {
        return payMethod;
    }

    if (defaultPayMethod && !isMobile) {
        return defaultPayMethod;
    }

    if (defaultPayMethodTouch && isMobile) {
        return defaultPayMethodTouch;
    }
};

export const getBuyApiParams = ({ isMobile, payMethod, source, isQuick }: BuyAction): CommonBuyApiParams => ({
    skin: isMobile || isQuick ? BillingBuyTemplates.CHECKOUT_VKPAY_NOOFFER : BillingBuyTemplates.CHECKOUT_VKPAY,
    view: BillingBuyViewTemplates.checkout,
    source: getSourceString(source, isMobile),
    pay_method: payMethod,
    partner: getQueryParams()?.partner,
    hide_pay_methods_menu: isMobile ? isHidePayMethodsMenuTouch : isHidePayMethodsMenu,
});

export const getPaymentDescription = (product: Product): string => {
    const { space, discountPrice, discountPeriod, period, price } = product;

    const priceInfo = `+ ${space.value} на ${getPeriodName(period)}`;

    let description = `${priceInfo}\nПодписка продлевается автоматически через ${getPeriodName(
        product.period
    )} на аналогичный период. С привязанной карты будет списываться автоплатеж для всех активных подписок, приобретаемых на сайте. Отменить автоплатёж можно в настройках подписки.`;

    if (discountPrice && discountPeriod) {
        description = `${priceInfo}\n${getPretextPeriodForProduct(discountPeriod)} ${getPeriodNameAsWord(
            discountPeriod,
            true
        )} оплата составит ${formatPrice(
            price
        )} ₽. C привязанной карты будет списываться автоплатеж для всех активных подписок, приобретаемых на сайте. Отменить автоплатёж можно в любое время в настройках подписки.`;
    }

    return description.replace(/\u00A0/g, ' ');
};
