// tempexp_15949-full-file
import { Spacing, Text, Title } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { memo, useCallback, useEffect } from 'react';
import { openVkBuyModalWithSidebar } from 'reactApp/components/VkBuyModalWithSidebar/VkBuyModalWithSidebar.helpers';
import { EPaymentModalType } from 'reactApp/components/VkBuyModalWithSidebar/VkBuyModalWithSidebar.types';
import { Button } from 'reactApp/ui/Button/Button';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './WelcomeNewPromo.css';

const BANNER_NAME = 'welcome-trial';

interface Props {
    trialId: string;
    forQuota?: boolean;
}

export const TrialBlock = memo(({ trialId, forQuota = false }: Props) => {
    const onClick = useCallback(() => {
        sendPaymentGa({ eventCategory: ECategoryGa.banner, action: 'click', name_banner: BANNER_NAME });
        openVkBuyModalWithSidebar({ productId: trialId, type: EPaymentModalType.trial });
    }, [trialId]);

    useEffect(() => {
        sendPaymentGa({ eventCategory: ECategoryGa.banner, action: 'view', name_banner: BANNER_NAME });
    }, []);

    return (
        <div className={classNames(styles.block, { [styles.block_quota]: forQuota })}>
            <Title className={styles.tariff_text} level="2">
                Увеличьте хранилище
            </Title>
            <Spacing size={forQuota ? 54 : 38} />
            <div className={styles.tariff}>
                128<span>ГБ</span>
            </div>
            <Text className={styles.tariff_text}>{forQuota ? 'Чтобы места хватило на все' : 'Места хватит на всё'}</Text>
            <Spacing size={forQuota ? 24 : 38} />
            <div>
                <Button data-name="welcome-trial" theme="vk" primary onClick={onClick} fluid>
                    <Text className={styles.tariff_button}>1 ₽ первый месяц</Text>
                </Button>
            </div>
            <Spacing size={forQuota ? 16 : 12} />
            <Text className={styles.tariff_price}>
                Стоимость после 30 дней
                {forQuota ? ', ' : <br />}
                149 ₽ в месяц
            </Text>
        </div>
    );
});

TrialBlock.displayName = 'TrialBlock';
