import { useSelector } from 'react-redux';
import { HIDE_ADS } from 'reactApp/appHelpers/configHelpers';
import { getFeaturePublicDatalistAdv } from 'reactApp/modules/features/features.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { IVirtualList, VirtualListDivider } from 'reactApp/ui/VirtualList/VirtualList.types';

const DATALIST_ADV_DIVIDER_HEIGHT = 95;

const configViewMode = {
    [EViewMode.thumbs]: {
        minItems: 12,
        insertIdxRow: 2,
    },
    [EViewMode.list]: {
        minItems: 8,
        insertIdxRow: 4,
    },
};

export const useInsertAdvDividerToDatalist = (
    list: (string | VirtualListDivider)[],
    viewMode: string,
    listRef: React.RefObject<IVirtualList>
) => {
    const modifiedList = [...list];
    const storage = useSelector(getCurrentStorage);
    const isPublicDatalistAdvFeatureEnabled = useSelector(getFeaturePublicDatalistAdv);
    const { isPublic } = getStorage(storage);

    let dividerHeight: number | undefined;

    if (!isPublic) {
        return {
            modifiedList: list,
            showAdv: false,
            dividerHeight: undefined,
        };
    }

    const columnCount = listRef?.current?.getColumnsCount() || 0;
    // На landscape и portrait разное количество тамбов в строчке.
    const { minItems, insertIdxRow } = configViewMode[viewMode];
    const insertIdx = columnCount * insertIdxRow;
    const showAdv = !HIDE_ADS && list.length >= minItems && isPublicDatalistAdvFeatureEnabled;

    if (showAdv && columnCount) {
        modifiedList.splice(insertIdx, 0, { divider: true, showAdv: true });
        dividerHeight = DATALIST_ADV_DIVIDER_HEIGHT;
    }

    return { modifiedList, showAdv, dividerHeight };
};
