import { EStatus } from 'reactApp/modules/subscriptions/subscriptions.types';
import { formatDefaultDate } from 'reactApp/utils/formatDate';
import { getPeriodName } from 'reactApp/utils/Period';

export const getExpiresTitle = (expires: number, status?: EStatus) =>
    `${status === EStatus.SUSPENDED ? '' : 'до '}${formatDefaultDate(expires)}`;

export const getTariff = <T extends { price: number; period: string }>(value: string, product?: T) => {
    const productPrice = product ? `, ${product.price}₽ в ${getPeriodName(product.period)}` : '';

    return `+ ${value}${productPrice}`;
};

export const getCardNumber = <T extends { number: string }>(card: T | null) => card && `Карта ∙∙${card.number.slice(-4)}`;

export const getNextPayment = (renewAt?: number, expires?: number) => {
    if (renewAt) {
        return formatDefaultDate(renewAt);
    }

    if (expires) {
        return formatDefaultDate(expires);
    }

    return '';
};
