import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { isFamilySubsInTariff } from 'reactApp/appHelpers/featuresHelpers';
import { CardSelectors } from 'reactApp/modules/creditCard/creditCard.selectors';
import { EPromoSubscriptionType } from 'reactApp/modules/promoTariffs/promoTariffs.types';
import { PARTNER_TITLE } from 'reactApp/modules/subscriptions/subscriptions.data';
import { EPartner, ESubscriptionsTypes } from 'reactApp/modules/subscriptions/subscriptions.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { Card } from 'reactApp/sections/SubscriptionsPage/ui/SubscriptionModal/SubscriptionInfo/Card/Card';
import { FeaturesList } from 'reactApp/ui/FeaturesList/FeaturesList';
import { formatFileHistoryDate, formatSubscriptionData } from 'reactApp/utils/formatDate';
import { getPeriodName, getTrialOrDiscountDateEnd, nowIsTrialOrDiscountPeriod } from 'reactApp/utils/Period';

import styles from '../SubscriptionModal.css';

enum EInfoTypes {
    conditions = 'conditions',
    endDate = 'endDate',
    nextDate = 'nextDate',
    options = 'options',
    disko = 'disko',
    card = 'card',
}

enum EInfoTitles {
    conditions = 'Условия подписки',
    endDate = 'Дата окончания подписки',
    nextDate = 'Следующий платёж',
    options = 'Опции подписки',
    disko = 'Лицензия на Диск-О:',
    card = 'Привязанная карта',
}

interface IInfo {
    id: EInfoTypes;
    title: EInfoTitles;
    content: ReactElement;
}

type TSubscriptionsInfos = IInfo[];

export const useInfo = ({ subscription, product, partner, sendAnalytics }): TSubscriptionsInfos => {
    const { card } = useSelector(CardSelectors.getCard);
    const isCorpUser = useSelector(UserSelectors.isCorpUser);

    const infos: TSubscriptionsInfos = [];

    let conditions = '';

    const isTrialPeriod = product?.trialPeriod && nowIsTrialOrDiscountPeriod(product.trialPeriod, subscription.start);

    const autorenewalText = subscription?.autorenewal ? 'с автопродлением' : '';

    if (subscription?.type === ESubscriptionsTypes.ios || subscription?.type === ESubscriptionsTypes.android) {
        conditions = `Мобильная подписка ${subscription?.type === ESubscriptionsTypes.ios ? 'AppStore' : 'Google Play'}`;
    } else if ((subscription?.isTrial || isTrialPeriod) && product?.trialPeriod) {
        const trialDateEnd = getTrialOrDiscountDateEnd(product.trialPeriod, subscription.start);

        const freeCondition = `Бесплатно до ${formatSubscriptionData(trialDateEnd.valueOf())}.`;

        const discountCondition =
            product?.discountPeriod &&
            `Далее ${product.discountPrice} ₽ / ${getPeriodName(product.discountPeriod)}${autorenewalText && ' '}${autorenewalText}.`;

        const defaultCondition = `${product.price} ₽ / ${getPeriodName(product.period)} ${autorenewalText}`;

        conditions = `${freeCondition} Далее ${defaultCondition}`;

        if (discountCondition) {
            const discountDateEnd = getTrialOrDiscountDateEnd(product?.discountPeriod, trialDateEnd);
            conditions = `${freeCondition}  ${discountCondition} С ${formatSubscriptionData(
                discountDateEnd.valueOf()
            )} ${defaultCondition}`;
        }
    } else if (subscription?.type === EPromoSubscriptionType.quota && !partner) {
        conditions = 'Подарок';
    } else if (partner) {
        conditions = `Подписка ${PARTNER_TITLE[partner]}`;
    } else if (!subscription?.base) {
        let discount = '';

        const isDiscountPeriod = product?.discountPeriod && nowIsTrialOrDiscountPeriod(product.discountPeriod, subscription.start);

        if (product?.discountPeriod && product?.discountPrice && isDiscountPeriod) {
            const discountDateEnd = getTrialOrDiscountDateEnd(product.discountPeriod, subscription.start);

            discount = `${product.discountPrice} ₽ / ${getPeriodName(product.discountPeriod)} до ${formatSubscriptionData(
                discountDateEnd.valueOf()
            )}. Далее `;
        }

        conditions = `${discount}${product?.price} ₽ / ${getPeriodName(product?.period)} ${
            subscription?.autorenewal ? 'с автопродлением' : ''
        }`;
    }

    if (conditions) {
        infos.push({
            id: EInfoTypes.conditions,
            title: EInfoTitles.conditions,
            content: <>{conditions}</>,
        });
    }

    if (subscription?.autorenewal && subscription?.renewAt) {
        infos.push({
            id: EInfoTypes.nextDate,
            title: EInfoTitles.nextDate,
            content: <>{formatFileHistoryDate(subscription.renewAt)}</>,
        });
    } else if (subscription?.expires) {
        infos.push({
            id: EInfoTypes.endDate,
            title: EInfoTitles.endDate,
            content: <>{formatFileHistoryDate(subscription.expires)}</>,
        });
    }

    const isSferum = partner === EPartner.sferum;
    const isVkCombo = partner === EPartner.mail;
    const partnerUpload = partner && (isSferum || isVkCombo);

    infos.push({
        id: EInfoTypes.options,
        title: EInfoTitles.options,
        content: (
            <FeaturesList
                partnerUpload={partnerUpload}
                base={subscription?.base}
                upload={subscription?.flags?.upload && !subscription?.isDisko && !subscription?.isProfessional && !subscription?.isMail}
                paidFeatures={
                    !isSferum &&
                    subscription?.flags?.paidFeatures &&
                    !subscription?.isDisko &&
                    !subscription?.isProfessional &&
                    !subscription?.isMail
                }
                autodelete={
                    !isSferum &&
                    subscription?.flags?.paidFeatures &&
                    !subscription?.isDisko &&
                    !subscription?.isProfessional &&
                    !subscription?.isMail
                }
                disko={subscription?.isDisko && !subscription?.isProfessional}
                modalPro={subscription?.isProfessional && !subscription?.isMail}
                isPromoPro={Boolean(subscription?.isProfessional && subscription?.promoId && !subscription?.isMail)}
                isSingleQuota={subscription?.isMail && !subscription?.isProfessional}
                isSingleQuotaPro={subscription?.isMail && subscription?.isProfessional}
                isSingleQuotaFamily={!partner && !subscription?.isProfessional && subscription?.isMail && isFamilySubsInTariff}
                isCorpUser={isCorpUser}
                space={subscription?.space}
                plusSpace
                itemClassName={styles.condition}
                partner={partner}
            />
        ),
    });

    if (subscription?.diskoKey) {
        infos.push({
            id: EInfoTypes.disko,
            title: EInfoTitles.disko,
            content: <>{subscription.diskoKey}</>,
        });
    }

    if (card && subscription?.type === ESubscriptionsTypes.web && subscription?.autorenewal) {
        infos.push({
            id: EInfoTypes.card,
            title: EInfoTitles.card,
            content: <Card sendAnalytics={sendAnalytics} />,
        });
    }

    return infos;
};
