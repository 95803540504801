import { bytesToNDigits } from '@mail/cross-sizes-utils';
import { BASEQUOTA } from 'reactApp/appHelpers/configHelpers';
import { EProductPeriod } from 'reactApp/modules/products/products.types';

const SCREEN_WIDTH_FOR_ONE_CARDS = 600;
const SCREEN_WIDTH_FOR_TWO_CARDS = 800;
const SCREEN_WIDTH_FOR_THREE_CARDS = 1300;

const baseSpace = bytesToNDigits(BASEQUOTA, 3);

export const baseTariffSpace = {
    original: baseSpace?.original || BASEQUOTA,
    units: baseSpace?.units || '_ГБ',
    space: baseSpace?.space || 8,
    value: baseSpace?.value || '8 _  ГБ',
};

export const carouselBreakpoints = [
    {
        maxWidth: SCREEN_WIDTH_FOR_ONE_CARDS,
        showCount: 1,
    },
    {
        maxWidth: SCREEN_WIDTH_FOR_TWO_CARDS,
        showCount: 2,
    },
    {
        maxWidth: SCREEN_WIDTH_FOR_THREE_CARDS,
        showCount: 3,
    },
];

export const baseYearProduct = {
    price: 0,
    id: 'base_year',
    period: EProductPeriod.year,
    available: true,
    isPromo: false,
    hasTrial: false,
    hasDiscount: false,
    turbo: false,
    isBase: true,
};
