import localStore from 'lib/store';
import React, { Component, ReactElement } from 'react';
import { connect } from 'react-redux';
import { IS_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { isAllDocumentsInMenu } from 'reactApp/appHelpers/featuresHelpers';
import { getFeatureAllDocumentsHideSections } from 'reactApp/modules/features/features.selectors';
import { getDomainFoldersFilterActive } from 'reactApp/modules/home/home.selectors';
import { initPromoTariffs } from 'reactApp/modules/promoTariffs/promoTariffs.module';
import { SelectionsSelectors } from 'reactApp/modules/selections/selections.selectors';
import { CloudItem, EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { ViewerSelectors } from 'reactApp/modules/viewer/viewer.selectors';
import { isWelcomePromoShow } from 'reactApp/modules/welcome/welcome.selectors';
import { ETreeNodeMode, ETreeRootIds, MAX_TREE_LEVEL_LM } from 'reactApp/ui/TreeComponent/TreeComponent.constants';
import { treeRootConfigAllDocuments, treeRootConfigLM } from 'reactApp/ui/TreeComponent/TreeComponent.data';
import {
    getAllDocumentsTreeConfig,
    getCurrentTreeState,
    getFoldersTree,
    loadFolder,
} from 'reactApp/ui/TreeComponent/TreeComponent.helpers';
import { TreeNode } from 'reactApp/ui/TreeComponent/TreeNode';
import { ITreeNodeClickData, TreeNodeData } from 'reactApp/ui/TreeComponent/TreeNode.types';
import { sendGa as sendGaFunc } from 'reactApp/utils/ga';

const autoOpenStoragesAtRefresh = [EStorageType.attaches, EStorageType.sharedLinks, EStorageType.sharedIncoming];

interface Props {
    onExpand: ({ id, storage, isOpen, selectedStorage }) => void;
    onClick: (data: ITreeNodeClickData) => void;
    handleScrollIntoViewRef?: (RefObject) => void;
    items: TreeNodeData[];
    selectedId: string;
    treeNodeMod?: ETreeNodeMode;
    gaCategory?: string;
    gaSuffix?: string;
    selectedStorage?: EStorageType;
    userEmail?: ReturnType<typeof UserSelectors.getEmail>;
    initPromoTariffs?();
    needPromo?: boolean;
    isViewerActive?: boolean;
    search?: string;
    maxTreeLevel?: number;
    selectedIds?: string[];
    domainFoldersFilterActive?: boolean;
    isDragging?: boolean;
    itemsToDrop?: (CloudItem | undefined)[];
}

interface State {
    expandAttaches: boolean;
    expandHome: boolean;
}

export class TreeComponent extends Component<Props, State> {
    static defaultProps = {
        gaCategory: 'leftnav',
        gaSuffix: '',
    };

    public readonly state = {
        expandAttaches: false,
        expandHome: false,
    };

    public componentDidMount(): void {
        this.sendGa('menu', 'show');

        const { initPromoTariffs, needPromo } = this.props;

        if (needPromo && initPromoTariffs) {
            initPromoTariffs();
        }

        const { items, selectedStorage = EStorageType.home, onExpand } = this.props;

        items.forEach((item) => {
            const forceOpen = this.checkForceItem(item);

            if (forceOpen) {
                if (!this.state.expandAttaches && item.id === '/attaches' && selectedStorage !== EStorageType.attaches) {
                    // trigger attaches folders load
                    this.setState({ expandAttaches: true }, () => {
                        onExpand({ id: item.id, storage: item.storage, selectedStorage, isOpen: true });
                    });
                }

                if (!this.state.expandHome && item.id === '/' && selectedStorage !== EStorageType.home) {
                    // trigger home folders load
                    this.setState({ expandHome: true }, () => {
                        onExpand({ id: item.id, storage: item.storage, selectedStorage, isOpen: true });
                    });
                }
            }
        });
    }

    private sendGa = (action, label = '', sendImmediately = false) => {
        const { gaCategory, gaSuffix } = this.props;

        sendGaFunc(gaCategory, action, label, gaSuffix ? { [gaSuffix]: 1 } : null, sendImmediately);
    };

    private getLocalStoreKey(id): string {
        return `${this.props.userEmail}|leftnav-expand:${id}`;
    }

    private handleOnExpand = ({ id, gaId, storage, isOpen, level, selectedStorage }) => {
        this.sendGa('expand', gaId || storage);
        const { onExpand, userEmail } = this.props;

        // level == 0 for root nodes
        if (userEmail && !level) {
            localStore.set(this.getLocalStoreKey(id), isOpen);
        }

        if (isOpen) {
            onExpand({ id, storage, isOpen, selectedStorage });
        }

        return Promise.resolve();
    };

    private handleOnClick = ({ id, gaId, level, href, storage, isSharedOrMounted, isThisOrParentPublic }: ITreeNodeClickData) => {
        if (id === ETreeRootIds.promocodes) {
            return;
        }
        this.props.onClick({ id, level, href, storage, isSharedOrMounted, isThisOrParentPublic });
        this.sendGa((level ? 'tree-' : 'menu-') + (gaId || storage), 'click', true);
        this.sendGa('click', '', true);
    };

    private checkForceItem = (item) => {
        const { items, selectedStorage = EStorageType.home, userEmail, treeNodeMod } = this.props;
        let forceOpen = items?.length === 1;

        if (selectedStorage === item.storage && autoOpenStoragesAtRefresh.includes(item.storage)) {
            forceOpen = true;
        }

        if (userEmail) {
            const previouslyExpanded = localStore.get(this.getLocalStoreKey(item.id));
            forceOpen = typeof previouslyExpanded === 'undefined' ? forceOpen : previouslyExpanded;

            if (item.id === ETreeRootIds.promocodes && previouslyExpanded === null) {
                localStore.set(this.getLocalStoreKey(item.id), true);
                forceOpen = true;
            }
        }

        /*
            принудительно раскрываем Личные папки для бизового юзера,
            для корректной работы перехода в папки из раздела Общие
        */
        if (
            (IS_BIZ_USER && item.id === '/' && selectedStorage !== EStorageType.home) ||
            (treeNodeMod === ETreeNodeMode.selectDlg && IS_BIZ_USER)
        ) {
            forceOpen = true;
        }

        return forceOpen;
    };

    public render(): ReactElement | null {
        const {
            items,
            selectedId,
            selectedStorage = EStorageType.home,
            treeNodeMod,
            isViewerActive,
            maxTreeLevel,
            search,
            domainFoldersFilterActive,
            isDragging = false,
            itemsToDrop = [],
        } = this.props;

        if (isViewerActive) {
            return null;
        }

        return (
            <>
                {items.map((item) => (
                    <TreeNode
                        key={item.id}
                        selectedId={domainFoldersFilterActive ? '/domain' : selectedId}
                        selectedStorage={selectedStorage}
                        data={item}
                        mod={treeNodeMod}
                        onExpand={this.handleOnExpand}
                        onClick={this.handleOnClick}
                        forceOpen={this.checkForceItem(item)}
                        handleScrollIntoViewRef={this.props.handleScrollIntoViewRef}
                        maxTreeLevel={maxTreeLevel}
                        search={search}
                        domainFoldersFilterActive={domainFoldersFilterActive}
                        isParentSharedOrMounted={item.isMountedOrShared}
                        isParentPublic={item.isPublic}
                        isDragging={isDragging}
                        itemsToDrop={itemsToDrop}
                    />
                ))}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const currState = getCurrentTreeState(state);
    const hiddenSections = getFeatureAllDocumentsHideSections(state);
    const tree = getFoldersTree(
        state,
        currState?.storage === EStorageType.alldocuments
            ? getAllDocumentsTreeConfig(hiddenSections)
            : isAllDocumentsInMenu
            ? treeRootConfigAllDocuments
            : treeRootConfigLM
    );
    const userEmail = UserSelectors.getEmail(state);
    const showWelcome = isWelcomePromoShow(state);
    const needPromo = !UserSelectors.isBizUser(state);
    const isViewerActive = ViewerSelectors.isViewerActive(state);
    const selectedIds = SelectionsSelectors.getSelectedIdxs(state);
    const domainFoldersFilterActive = getDomainFoldersFilterActive(state);
    const isDragging = SelectionsSelectors.isDragging(state);
    const itemsToDrop = SelectionsSelectors.getSelectedItems(state);

    let selectedId = currState?.id || `${EStorageType.home}/`;
    const newTree = tree;

    if (showWelcome) {
        selectedId = '';
    }

    if (IS_BIZ_USER) {
        newTree.map((item) => {
            if (item.id === '/') {
                item.children = item.children?.filter((item) => item.kind !== 'domain-folder' && item.kind !== 'mounted');
                return item;
            }

            return item;
        });
    }

    return {
        items: newTree,
        selectedId,
        selectedStorage: currState?.storage,
        userEmail,
        needPromo,
        isViewerActive,
        maxTreeLevel: MAX_TREE_LEVEL_LM,
        selectedIds,
        domainFoldersFilterActive,
        itemsToDrop,
        isDragging,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    onExpand: (data) => (ownProps.onExpand ? ownProps.onExpand : loadFolder)({ ...data, dispatch }),
    initPromoTariffs: () => dispatch(initPromoTariffs()),
});

export const TreeConnected = connect(mapStateToProps, mapDispatchToProps)(TreeComponent);
