import { ReactEventHandler, useCallback, useMemo, useState } from 'react';
import { Product } from 'reactApp/types/Billing';
import { SwitchOption } from 'reactApp/ui/TariffCardNew/components/TariffSwitch.types';
import { ITariffCardProps } from 'reactApp/ui/TariffCardNew/TariffCard.types';
import { TariffsByTabProductsType } from 'reactApp/ui/TariffSectionNew/TariffsSection.types';
import { ETabsName } from 'reactApp/ui/TariffsSection/TariffSection.constants';
import { isMonth3Period, isMonth6Period, isMonthPeriod } from 'reactApp/utils/Period';

import { useYearSaving } from './useYearSaving';

interface IUseSwitchProductsReturn extends Pick<ITariffCardProps, 'onClickSwitch' | 'switchChecked' | 'switchOptions'> {
    showSwitch: boolean;
    product: Product;
    yearSavingDiscount?: number;
}

type TSwitchProducts = (props: {
    activeTab?: ETabsName;
    products: TariffsByTabProductsType;
    withSwitch?: boolean;
}) => IUseSwitchProductsReturn;

export const useSwitchProducts: TSwitchProducts = ({ activeTab, products, withSwitch = true }) => {
    // tempexp_15246-start
    const isTwoYearTariffs = Boolean(products.twoYearProduct);
    const [switchChecked, setMonthSwitchChecked] = useState(false);
    const showSwitch = withSwitch && !!products.monthProduct && !isTwoYearTariffs;

    // если свитч не показываем, то основной продукт годовой
    const isMonthProduct =
        (showSwitch && switchChecked && products.monthProduct && (activeTab === ETabsName.pro || ETabsName.usual)) ||
        (activeTab === ETabsName.month && products.monthProduct);

    let product = products.yearProduct;

    if (isMonthProduct && products.monthProduct) {
        product = products.monthProduct;
    } else if (products.twoYearProduct && activeTab === ETabsName.twoYears) {
        product = products.twoYearProduct;
    }

    const [yearSaving, yearSavingDiscount] = useYearSaving({
        monthProduct: products.monthProduct,
        yearProduct: products.twoYearProduct && activeTab === ETabsName.twoYears ? products.twoYearProduct : products.yearProduct,
    });
    // tempexp_15246-end

    const onClickSwitch: ReactEventHandler = useCallback((event) => {
        event.stopPropagation();
        setMonthSwitchChecked((checked) => !checked);
    }, []);

    const switchOptions: [SwitchOption, SwitchOption] = useMemo(() => {
        const mapSwitchTextToPeriodFn = {
            ['На месяц']: isMonthPeriod,
            ['На 3 месяца']: isMonth3Period,
            ['На 6 месяцев']: isMonth6Period,
        };

        return [
            {
                value: products.yearProduct.period,
                text: 'На год',
                note: yearSaving ? `Экономия ${yearSaving} ₽` : '',
            },
            {
                value: products.monthProduct?.period || '',
                text: showSwitch
                    ? Object.keys(mapSwitchTextToPeriodFn).find((text) => mapSwitchTextToPeriodFn[text](products.monthProduct?.period))
                    : '',
            },
        ];
    }, [products, yearSaving, showSwitch]);

    return {
        product,
        showSwitch,
        onClickSwitch,
        switchChecked,
        switchOptions,
        yearSavingDiscount,
    };
};
