import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { isHideQuotaNewbieFeatureEnabled } from 'reactApp/appHelpers/featuresHelpers';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';

import styles from './WelcomeStep.css';

const mapStateToProps = (state) => {
    return {
        userQuota: UserSelectors.getCloudSpace(state).total.value,
    };
};

interface IMapToStateProps {
    userQuota: string;
}

interface IProps extends IMapToStateProps {}

class WelcomeStepComponent extends PureComponent<IProps> {
    renderCommonWelcomeText = (userQuota) => {
        return (
            <div className={styles.root}>
                <div className={styles.title}>
                    Добро пожаловать
                    <br />в Облако!
                </div>
                {/* tempexp_14979-next-line */}
                {!isHideQuotaNewbieFeatureEnabled && (
                    <div className={styles.size}>
                        Вам доступно <b>{userQuota}</b>
                    </div>
                )}
                <div className={styles.text}>Сохраняйте в Облако самое важное.</div>
                <br />
                <div className={styles.text}>
                    Здесь ваши файлы не потеряются,
                    <br />а посторонние не смогут до них добраться.
                    <br />
                </div>
                <br />
                <div className={styles.text}>Файлы в Облаке — значит в безопасности.</div>
            </div>
        );
    };

    render() {
        const { userQuota } = this.props;

        return this.renderCommonWelcomeText(userQuota);
    }
}

export const WelcomeStep = connect(mapStateToProps)(WelcomeStepComponent);
