import { bytesToNDigits } from '@mail/cross-sizes-utils';
import classNames from 'clsx';
import React, { memo, ReactElement, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BuyModal } from 'reactApp/components/BuyModal/BuyModal';
import { CancelAutoRenewConfirmDialog } from 'reactApp/components/CancelAutoRenewConfirmDialog/CancelAutoRenewConfirmDialog';
import { BillingActions } from 'reactApp/modules/billing/billing.module';
import { BillingSelectors } from 'reactApp/modules/billing/billing.selectors';
import { BuySubscriptionActions } from 'reactApp/modules/buySubscription/buySubscription.module';
import { BuyNotifications } from 'reactApp/modules/buySubscription/buySubscription.types';
import { CardActions } from 'reactApp/modules/creditCard/creditCard.module';
import { CardSelectors } from 'reactApp/modules/creditCard/creditCard.selectors';
import { getFeatureAbCancelSubsWithCaptcha } from 'reactApp/modules/features/features.selectors';
import { getLoadingState } from 'reactApp/modules/loading/loading.selectors';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { updateSubscriptionsRequest } from 'reactApp/modules/subscriptions/subscriptions.module';
import { ISubscription } from 'reactApp/modules/subscriptions/subscriptions.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import {
    ESubscriptionModalAction,
    TSendAnalytics,
} from 'reactApp/sections/SubscriptionsPage/ui/SubscriptionModal/SubscriptionModal.analytics';
import { RootState } from 'reactApp/store';
import { Product } from 'reactApp/types/Billing';
import { AddCardModal } from 'reactApp/ui/AddCardModal/AddCardModal';
import { Button } from 'reactApp/ui/Button/Button';
import { ButtonLink } from 'reactApp/ui/ButtonLink/ButtonLink';
import { renderCancelSubscriptionWithCaptchaModal } from 'reactApp/ui/CancelSubscriptionWithCaptchaModal/CancelSubscriptionWithCaptchaModal.helpers';
import { createGaSender } from 'reactApp/utils/ga';
import { isYear2Period } from 'reactApp/utils/Period';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import styles from './SubscriptionFooter.css';

interface IProps {
    subscription: ISubscription;
    product: Product;
    sendAnalytics: TSendAnalytics;
}

const sendBillingGa = createGaSender('billing');

export const SubscriptionFooter = memo(({ subscription, product, sendAnalytics }: IProps): ReactElement | null => {
    const { id, productId, autorenewal, status, isTrial, renewable, space, isPrepaid } = subscription;
    const dispatch: ThunkDispatch<RootState, Record<string, unknown>, AnyAction> = useDispatch();

    const { isLoading: isRenewLoading } = useSelector(BillingSelectors.getLoadingState);
    const renewLink = useSelector(BillingSelectors.getPaymentRenewLink);
    const { total: userTotalSpace } = useSelector(UserSelectors.getCloudSpace);
    const { card, isLoading: isCardLoading } = useSelector(CardSelectors.getCard);
    // tempexp_13984-next-line
    const abWithCaptcha = useSelector(getFeatureAbCancelSubsWithCaptcha);
    const isRenewable = status === 'ACTIVE' && renewable && !isYear2Period(product?.period);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const isSubscriptionsLoading = useSelector((state: RootState) => getLoadingState(state, [updateSubscriptionsRequest.toString()]));

    const [showCardModal, setShowCardModal] = useState<boolean>(false);
    const [showRenewModal, setShowRenewModal] = useState<boolean>(false);
    const [showCancelAutoRenewModal, setShowCancelAutoRenewModal] = useState<boolean>(false);

    const renewSubscriptions = useCallback((): void => {
        dispatch(BillingActions.renewSubscription({ id }));
    }, [dispatch, id]);

    const handleOnRenewClick = useCallback((): void => {
        sendBillingGa('renew-click');
        sendAnalytics(ESubscriptionModalAction.prolongSubs);
        setShowRenewModal(true);
        dispatch(BillingActions.renewSubscription({ id }));
    }, [sendAnalytics, dispatch, id]);

    const handleOnRenewClose = useCallback((): void => {
        sendBillingGa('renew-close');
        dispatch(BillingActions.resetLink());
        setShowRenewModal(false);
    }, [dispatch]);

    const handleOnSuccessRenew = useCallback((): void => {
        sendBillingGa('renew-success', `id-${product.id}-cost-${product.price}-period-${product.period}`, null, { sendGaOnly: true });
        sendBillingGa('renew-success', `id-${product.id}`);

        dispatch(BillingActions.resetLink());
        dispatch(updateSubscriptionsRequest());
        dispatch(CardActions.updateCard({ isSilent: !!card }));
    }, [dispatch, card]);

    const handleOnAutoRenewCancelClick = useCallback((): void => {
        sendBillingGa('autorenew-cancel-click');
        sendAnalytics(ESubscriptionModalAction.openCancelAutopayment);
        setShowCancelAutoRenewModal(true);
    }, [sendAnalytics]);

    const addAutoRenew = useCallback(() => {
        dispatch(BuySubscriptionActions.addAutorenew(id))
            .then(() => {
                sendBillingGa('autorenew-success');
            })
            .catch(() => {
                dispatch(
                    showSnackbarAction({
                        text: 'Не удалось подключить автоплатёж',
                        type: SnackbarTypes.failure,
                        id: BuyNotifications.autoRenewFailure,
                        closable: true,
                    })
                );

                return Promise.reject();
            })
            .then(() => dispatch(updateSubscriptionsRequest()));
    }, [dispatch, id]);

    const handleOnAutoRenewAddClick = useCallback(() => {
        sendBillingGa('autorenew-click');
        sendAnalytics(ESubscriptionModalAction.turnOnAutopayment);

        if (!card) {
            setShowCardModal(true);
            return;
        }

        addAutoRenew();
    }, [sendAnalytics, card, addAutoRenew]);

    const cancelRenewClick = useCallback(() => {
        setShowCancelAutoRenewModal(false);
        sendAnalytics(ESubscriptionModalAction.cancelAutopayment);

        // tempexp_13984-start
        if (abWithCaptcha === 'b') {
            renderCancelSubscriptionWithCaptchaModal({ subscriptionId: id });
            return;
        }
        // tempexp_13984-end

        dispatch(BuySubscriptionActions.cancelAutorenewSubscription({ subscriptionId: id, sendAnalytics }));
    }, [abWithCaptcha, dispatch, id, sendAnalytics]);

    const handleOnCancelAutoRenewClose = useCallback(() => {
        setShowCancelAutoRenewModal(false);
    }, []);

    const handleOnNotCancelAutoRenew = useCallback(() => {
        sendAnalytics(ESubscriptionModalAction.notCancelAutopayment);
        setShowCancelAutoRenewModal(false);
    }, [sendAnalytics]);

    const handleOnChangeCardSuccess = useCallback(() => {
        sendBillingGa('addCardClick', 'subscriptionModal');
        sendAnalytics(ESubscriptionModalAction.addNewCard);
        dispatch(
            CardActions.updateCard({
                showNotifications: true,
                onSuccess: addAutoRenew,
            })
        );
    }, [sendAnalytics, dispatch, addAutoRenew]);

    const handleCancelNewCard = useCallback(() => {
        sendAnalytics(ESubscriptionModalAction.cancelNewCard);
    }, [sendAnalytics]);

    const handleOnChangeCardClose = useCallback(() => {
        setShowCardModal(false);
    }, []);

    return (
        <div
            className={classNames({
                [styles.root]: true,
                [styles.root_centered]: !isRenewable,
            })}
        >
            {showRenewModal && (
                <BuyModal
                    onClose={handleOnRenewClose}
                    onSuccess={handleOnSuccessRenew}
                    id={productId}
                    link={renewLink}
                    retry={renewSubscriptions}
                    renew
                />
            )}
            {showCardModal && (
                <AddCardModal onSuccess={handleOnChangeCardSuccess} onClose={handleOnChangeCardClose} onCancel={handleCancelNewCard} />
            )}
            {showCancelAutoRenewModal && (
                <CancelAutoRenewConfirmDialog
                    isTrial={isTrial && !isPrepaid}
                    leftQuota={bytesToNDigits(userTotalSpace.original - space.original, 3).value}
                    onAction={cancelRenewClick}
                    onClose={handleOnCancelAutoRenewClose}
                    onSuccess={handleOnNotCancelAutoRenew}
                    subscription={subscription}
                />
            )}
            {isRenewable && (
                <Button
                    data-name="renew"
                    theme="octavius"
                    primary
                    middle
                    onClick={handleOnRenewClick}
                    disabled={isRenewLoading || isSubscriptionsLoading || isCardLoading}
                >
                    Продлить подписку
                </Button>
            )}
            <div className={styles.link}>
                <ButtonLink
                    fontInherit
                    className={styles.linkText}
                    onClick={autorenewal ? handleOnAutoRenewCancelClick : handleOnAutoRenewAddClick}
                    loading={isRenewLoading || isSubscriptionsLoading}
                    disabled={isCardLoading}
                >
                    {autorenewal ? 'Отключить автоплатёж' : 'Включить автоплатёж'}
                </ButtonLink>
            </div>
        </div>
    );
});

SubscriptionFooter.displayName = 'SubscriptionFooter';
