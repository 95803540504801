import { PayloadAction } from '@reduxjs/toolkit';
import { captureException, captureMessage } from '@sentry/browser';
import api from 'Cloud/Application/api';
import { confirmActionWithSuspiciousItems } from 'Cloud/Application/Suspicious';
import { user } from 'Cloud/Application/User';
import { logger } from 'lib/logger';
import { CloneAlbumAPICall } from 'reactApp/api/albums/CloneAlbumAPICall';
import { CloneAlbumItemsAPICall } from 'reactApp/api/albums/CloneAlbumItemsAPICall';
import { cloneAttach } from 'reactApp/api/axios.corsapi';
import { ErrorConditionMap } from 'reactApp/api/ErrorConditions';
import { isReadOnlyHttpStatus } from 'reactApp/api/HttpErrorCodes';
import { IS_PUBLIC_ALBUM, IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import { openEditor } from 'reactApp/appHelpers/editorHelpers';
import { ALL_DOCUMENTS_FOLDER_ID, ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { getStockIdOfFile } from 'reactApp/modules/attaches/attaches.helpers';
import { EAttachTypes } from 'reactApp/modules/attaches/attaches.types';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getSelectedFaceId } from 'reactApp/modules/faces/faces.selectors';
import { getWeblinkFromPublicId, isFolder } from 'reactApp/modules/file/utils';
import { loadHomeFolder } from 'reactApp/modules/home/home.saga';
import { getFolderListById } from 'reactApp/modules/home/home.selectors';
import { cloneToCloudFailure, cloneToCloudSuccess } from 'reactApp/modules/modifying/modifying.actions';
import { sendCloneAnalytics } from 'reactApp/modules/modifying/modifying.analytics';
import { authPopup } from 'reactApp/modules/ph/ph.thunkActions';
import { closePopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { getPublicItemId } from 'reactApp/modules/public/public.helpers';
import { getPublicRootWeblink, isOwnPublic } from 'reactApp/modules/public/public.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { CloudItem, EStorageType } from 'reactApp/modules/storage/storage.types';
import { addItemsToStore } from 'reactApp/modules/uploading/helpers/cloudFs/addItemsToStore';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { askLicense } from 'reactApp/modules/welcome/welcome.saga';
import { EConflictMode } from 'reactApp/types/EConflictMode';
import { renderCloneToCloudDialog } from 'reactApp/ui/SelectFolderDialog/SelectFolderDialog.toolkit';
import { ActionName } from 'reactApp/ui/VirusDialog/VirusDialog.types';
import { sendXray } from 'reactApp/utils/ga';
import { promisifyDeferredCall } from 'reactApp/utils/helpers';
import { ECategoryGa, EPaymentGa, sendPaymentGa } from 'reactApp/utils/paymentGa';
import { channel } from 'redux-saga';
import { all, call, put, select, take } from 'redux-saga/effects';

import { cloneErrors } from '../modifying.constants';
import { editPublicCopyNotification, getCloneSnackbarText, getSnackbarButton } from '../modifying.helpers';
import { EditPublicCopyItem, ICloneToCloudRequest } from '../modifying.types';

const UPLOAD_ERROR = 'upload-error';

const cloneAlbumItemsCall = ({ items, weblink, destination }) =>
    new CloneAlbumItemsAPICall().makeRequest({ element_ids: items, weblink_id: weblink, destination });
const cloneAlbumCall = ({ weblink, destination }) => new CloneAlbumAPICall().makeRequest({ weblink_id: weblink, destination });

const getCloneAlbumErrorMessage = (data): string | undefined => {
    const reason = data?.failed?.[0]?.error;
    return reason ? ErrorConditionMap[reason] || 'unknown' : undefined;
};

function* cloneAlbum({ items, destination, source }) {
    const album = items.find((item) => item.type === 'album');

    try {
        if (album) {
            const { data } = yield cloneAlbumCall({ weblink: album.weblink, destination });

            const total = items.length;
            const success = total - (data?.failed?.length || 0);

            return {
                button: getSnackbarButton({ items: [album], destination: data.path }),
                successMessage: getCloneSnackbarText({ total, success }),
                errorMessage: getCloneAlbumErrorMessage(data),
            };
        }

        const currentAlbum = yield select(getPublicRootWeblink);
        const { data } = yield cloneAlbumItemsCall({
            items: items.map((item) => getPublicItemId(item.id)),
            weblink: currentAlbum.weblink,
            destination,
        });

        const total = items.length;
        const success = total - (data?.failed?.length || 0);

        items.forEach((item) => {
            sendCloneAnalytics({
                type_public: item.isFolder ? 'folder' : 'file',
                extension: 'ext' in item ? item.ext : '',
                id_public: 'weblink' in item ? getWeblinkFromPublicId(item.weblink) : undefined,
                place: source,
                source: 'album',
            });
        });

        return {
            successMessage: success > 0 ? getCloneSnackbarText({ total, success }) : '',
            errorMessage: getCloneAlbumErrorMessage(data),
            button: getSnackbarButton({ items, destination: data?.path }),
        };
    } catch ({ response }) {
        return {
            errorMessage: getCloneAlbumErrorMessage(response),
        };
    }
}

const getCloneApi = (item: CloudItem, destination: string, storage: string) => {
    const isCloudStock = 'attachType' in item && item.attachType === EAttachTypes.cloudStock;

    if (storage === EStorageType.stock || isCloudStock) {
        const stockId = isCloudStock ? getStockIdOfFile(item.id, item.name) : item.id;
        return promisifyDeferredCall(api.stock.save, {
            stock: stockId,
            conflict: EConflictMode.RENAME,
            folder: destination,
        });
    }

    if (item.storage === EStorageType.attaches || storage === EStorageType.attaches) {
        return cloneAttach({
            ids: [item.id],
            folder: destination,
            email: user.getEmail(),
        }).catch(({ body, status }) => {
            return Promise.reject([body, status]);
        });
    }

    return promisifyDeferredCall(api.clone, {
        weblink: `/${item.id}`.replace(/\/{2,}/g, '/'),
        conflict: 'rename',
        folder: destination,
    });
};

const getPathFromApiResponse = (data) => {
    let homeIdForScrollTo;
    if (typeof data === 'string') {
        homeIdForScrollTo = data;
    } else if (data && data.files) {
        homeIdForScrollTo = data.files[0];
    } else {
        homeIdForScrollTo = data ? data[0] : '';
    }

    return homeIdForScrollTo;
};

const parseErrorResponse = (error) => {
    const [errors = '', status = '', response = ''] = error;

    let text = '';
    let message = '';

    const weblinkError = typeof errors === 'object' && 'weblink' in errors && errors.weblink?.error;

    if (errors === 'read_only') {
        message = 'READONLY DIR';
        text = cloneErrors.getMessage('read_only');
    } else if (
        // @ts-ignore
        (response && response.isInsufficient()) ||
        isReadOnlyHttpStatus(status)
    ) {
        message = 'OVERQUOTED for CLONE WEBLINK';
        text = cloneErrors.getMessage('overquota');
    } else if (weblinkError === 'own') {
        message = 'CLONE OWN FILE';
        text = cloneErrors.getMessage('clone_own');
    } else {
        const hasViruses = weblinkError === 'virus_scan_fail';
        if (hasViruses) {
            message = 'CLONE VIRUS';
            text = cloneErrors.getMessage('virus_scan_fail');
        } else {
            message = 'CLONE FILE';
            text = cloneErrors.getMessage('unknown');
        }
    }

    return { text, message };
};

function* cloneItem({ firstItem, item, destination, storage, isFaceFilterActive, source, owner, onError, onSuccess }) {
    try {
        const data = yield getCloneApi(item, destination, storage);

        const path = getPathFromApiResponse(data);

        onSuccess(path?.toString());

        yield call(addItemsToStore, {
            files: [
                {
                    cloudPath: path,
                    storage,
                    size: 'size' in firstItem ? firstItem.size : '',
                },
            ],
            email: '',
            currentWorkingDir: ROOT_FOLDER_ID,
        });

        sendPaymentGa({
            eventCategory: ECategoryGa.public,
            action: EPaymentGa.saveInCloud,
            source: source || storage,
            place: isFaceFilterActive ? 'face_list' : 'files',
            owner,
            extension: 'ext' in item ? item.ext : '',
            type_public: item.isFolder ? 'folder' : 'file',
            id_public: 'weblink' in item ? getWeblinkFromPublicId(item.weblink) : '',
        });
    } catch (error) {
        const { message, text } = parseErrorResponse(error);

        captureMessage(message, {
            // @ts-ignore
            extra: message,
        });

        onError();

        yield put(
            showSnackbarAction({
                id: 'clone-error',
                text,
                type: SnackbarTypes.failure,
                closable: true,
            })
        );
    }
}

function* clonePublic({ items, destination, storage, source }) {
    const successes: string[] = [];
    let countErrors = 0;
    let errorMessage;
    let successMessage;
    let homeIdForScrollTo;

    const isFaceFilterActive = yield select(getSelectedFaceId);
    const owner = yield select(isOwnPublic);

    const firstItem = items[0];

    function onError() {
        countErrors++;
    }
    function onSuccess(path) {
        successes.push(path);
    }

    if (storage === EStorageType.stock && items.length === 1 && firstItem.isFolder) {
        destination = `${destination}/${firstItem.name || ''}`;
    }

    yield all(
        items.map((item) =>
            call(cloneItem, { firstItem, item, destination, storage, isFaceFilterActive, source, owner, onError, onSuccess })
        )
    );

    sendXray(['app_clone-weblink_', storage, successes.length > 0 ? 'ok' : 'fail']);

    if (successes.length > 0) {
        homeIdForScrollTo = successes[0];
        successMessage = getCloneSnackbarText({ total: items.length, success: successes.length });
    }

    // Critical for viewer (one item) saga in attaches
    if (items.length === 1) {
        if (countErrors > 0) {
            yield put(cloneToCloudFailure());
        } else {
            yield put(
                cloneToCloudSuccess({
                    storageFrom: storage,
                    idx: [items[0].id],
                    home: [successes[0]],
                })
            );
        }
    }

    return {
        successMessage,
        errorMessage,
        button: getSnackbarButton({
            items,
            destination,
            homeIdForScrollTo,
        }),
        successes,
    };
}

function* processCloneItems({
    items,
    destination = ROOT_FOLDER_ID,
    source,
    noOkSnackbar,
}: {
    items: CloudItem[];
    destination?: string;
    source: string;
    noOkSnackbar?: boolean;
}) {
    const storage = yield select(getCurrentStorage);
    const isWebview = yield select(EnvironmentSelectors.isWebview);

    try {
        const { successMessage, errorMessage, button } = yield call(IS_PUBLIC_ALBUM ? cloneAlbum : clonePublic, {
            items,
            destination,
            storage,
            source,
        });

        if (storage === EStorageType.home && destination === ROOT_FOLDER_ID) {
            button?.onButtonClick?.();
        } else if (successMessage && !noOkSnackbar) {
            yield put(
                showSnackbarAction({
                    // CLOUDWEB-13662 для вебвью не показываем кнопку "Посмотреть в папке" в снекбаре при клонировании
                    ...(isWebview ? {} : button),
                    closable: true,
                    id: UPLOAD_ERROR,
                    type: SnackbarTypes.success,
                    text: successMessage,
                })
            );
        }

        if (errorMessage) {
            yield put(
                showSnackbarAction({
                    closable: true,
                    id: UPLOAD_ERROR,
                    type: SnackbarTypes.failure,
                    text: cloneErrors.getMessage(errorMessage),
                })
            );
        }
    } catch (error) {
        logger.error(error);
        captureException(error);

        yield put(
            showSnackbarAction({
                closable: true,
                id: 'upload-error',
                type: SnackbarTypes.failure,
                // @todo доделать для пабликов, на них могут быть папки
                text: cloneErrors.getMessage('unknown'),
            })
        );
    }
}

function* showDestinationPopup({ items, destination, source }: { items: CloudItem[]; destination?: string; source: string }) {
    yield loadHomeFolder({ id: ROOT_FOLDER_ID, isFolder: true });
    const folders = yield select((state) => getFolderListById(state, ROOT_FOLDER_ID));
    const isFoldersExists = folders.some(isFolder);
    const isMobile = EnvironmentSelectors.isMobile();
    const isPhone = yield select(EnvironmentSelectors.isPhone);

    if (!isFoldersExists) {
        yield processCloneItems({ items, destination: destination || ROOT_FOLDER_ID, source });
        return;
    }

    const destinationChannel = channel();

    renderCloneToCloudDialog({
        items,
        isPhone,
        isMobile,
        selectedId: destination,
        autoCreateSelectedId: true,
        onClose: () => destinationChannel.close(),
        onActionClick: ({ destination }) => destinationChannel.put(destination),
    });

    const selectedDestination = yield take(destinationChannel);

    yield processCloneItems({ items, destination: selectedDestination, source });
    yield destinationChannel.close();

    yield closePopupHelper(popupNames.SELECT_FOLDER_DIALOG);
}

export function* handleCloneItems(action: PayloadAction<ICloneToCloudRequest>) {
    const { items, destination, autoClone = false, source = '', noOkSnackbar } = action.payload;
    const isAnonymous = yield select(UserSelectors.isAnonymous);
    const isNewbie = yield select(UserSelectors.isNewbie);

    if (isAnonymous) {
        try {
            yield put(
                // @ts-ignore
                authPopup({
                    closable: true,
                    loginRequest: true,
                    successPage: `${window.location.href}${window.location.search}`,
                })
            );
        } catch (_) {}

        return;
    }

    if (!IS_WEBVIEW && isNewbie) {
        const isAgreedWithTheLa = yield askLicense({ autoClone });

        if (!isAgreedWithTheLa) {
            return;
        }
    }

    let newItemList;
    try {
        newItemList = yield confirmActionWithSuspiciousItems(items, ActionName.clone);
    } catch (_) {}

    if (!newItemList?.length) {
        return;
    }

    yield loadHomeFolder({ id: ROOT_FOLDER_ID, isFolder: true });

    if (autoClone) {
        yield processCloneItems({ items: newItemList, destination: destination || ROOT_FOLDER_ID, source, noOkSnackbar });
        return;
    }

    yield showDestinationPopup({ items: newItemList, destination, source });
}

export function* handeEditPublicCopy(action: PayloadAction<EditPublicCopyItem>) {
    const { storage, item, source } = action.payload;

    try {
        const { successes, errorMessage } = yield call(clonePublic, {
            items: [item],
            destination: ALL_DOCUMENTS_FOLDER_ID,
            storage,
            source,
        });

        if (errorMessage) {
            yield put(
                showSnackbarAction({
                    closable: true,
                    id: UPLOAD_ERROR,
                    type: SnackbarTypes.failure,
                    text: cloneErrors.getMessage(errorMessage),
                })
            );
        }

        const path = successes.length ? successes[0] : null;

        if (path) {
            openEditor({ id: path, ext: item?.ext } as CloudItem);
            // Прокинуть уведомление о копировании файла в редактор
            yield editPublicCopyNotification.setText('Копия сохранена у вас в Документах');
        }
    } catch (error) {
        logger.error(error);
        captureException(error);
    }
}
