import classNames from 'clsx';
import React, { memo, ReactElement, ReactNode, useCallback, useEffect, useState } from 'react';
import { Button } from 'reactApp/ui/Button/Button';
import Content from 'reactApp/ui/Content/Content';
import { CopyWeblink } from 'reactApp/ui/CopyWeblink/CopyWeblink';
import { Dialog, DialogSize } from 'reactApp/ui/Dialog/Dialog';
import { noop } from 'reactApp/utils/helpers';
import opener from 'reactApp/utils/opener';

import styles from './WhatsNewDialog.css';

export interface IProps {
    primaryButtonText: ReactNode;
    primaryButtonHref?: string;
    secondaryButtonText?: string;
    secondaryButtonHref?: string;
    date?: number;
    title: string;
    text?: string | ReactElement;
    content?: ReactElement;
    imageUrl: string;
    imageBgColor?: string;
    imageFluid?: boolean;
    closeByPrimaryButton?: boolean;
    closable?: boolean;
    link?: string;
    onShow?(): void;
    onClose?(): void;
    onClick?(): void;
    onSecondary?(): void;
    actionButtonText?: string;
    onActionClick?(): void;
    footerText?: ReactElement;
    primaryButtonColor?: 'black' | 'grey';
    onCloseButtonClick?(): void;
    qaId?: string;
    dialogSize?: DialogSize;
    footerTextBelowButtons?: boolean;
}

export const WhatsNewDialog = memo(function WhatsNewDialog({
    primaryButtonText,
    secondaryButtonHref,
    primaryButtonHref,
    onClick = noop,
    onSecondary = noop,
    onClose = noop,
    onShow = noop,
    secondaryButtonText,
    date = 0,
    title,
    text,
    content,
    link,
    closable = true,
    imageUrl,
    imageBgColor,
    imageFluid = true,
    closeByPrimaryButton = false,
    actionButtonText,
    onActionClick = noop,
    footerText,
    primaryButtonColor,
    onCloseButtonClick = noop,
    qaId,
    dialogSize = 'large',
    footerTextBelowButtons = false,
}: IProps): ReactElement {
    const [open, setOpen] = useState(true);
    useEffect(() => onShow(), []);

    const onPrimaryHandler = useCallback(() => {
        onClick();
        if (closeByPrimaryButton) {
            setOpen(false);
        }
        if (primaryButtonHref) {
            opener(primaryButtonHref, true);
        }
    }, [onClick, primaryButtonHref, closeByPrimaryButton]);

    const onSecondaryHandler = useCallback(() => {
        onSecondary();
        if (secondaryButtonHref) {
            opener(secondaryButtonHref, true);
        }
    }, [onSecondary, secondaryButtonHref]);

    const onCloseHandler = useCallback(() => {
        onCloseButtonClick?.();
        onClose?.();
    }, [onClose, onCloseButtonClick]);

    useEffect(() => {
        if (!open) {
            onClose?.();
        }
    }, [
        /** onClose */
        open,
    ]);

    return (
        <Dialog
            open={open}
            header={''}
            closeOnDimmerClick={false}
            id="whatsnew-dlg"
            qaId={qaId}
            onCancel={onCloseHandler}
            size={dialogSize}
            bigCloseIcon
            closable={closable}
            className={styles.root}
        >
            <Content isModal>
                <div className={styles.content}>
                    <div
                        className={styles.imageWrapper}
                        style={{
                            backgroundColor: imageBgColor,
                        }}
                    >
                        <img
                            className={classNames(styles.image, {
                                [styles.image_fluid]: imageFluid,
                            })}
                            src={imageUrl}
                            alt="Cover image"
                        />
                    </div>
                    {Boolean(date) && <div className={styles.date}>{date}</div>}
                    <div className={styles.title}>{title}</div>
                    {text && <div className={styles.text}>{typeof text === 'string' ? text.replace(/\\n/g, '\n') : text}</div>}
                    {Boolean(content) && content}
                    <div
                        className={classNames(styles.footerWrapper, {
                            [styles.footerWrapperColumn]: footerTextBelowButtons,
                        })}
                    >
                        {footerText}
                        <div className={styles.buttonWrapper}>
                            {actionButtonText && (
                                <Button data-name="freeze-renewal" theme="octavius" primary middle onClick={onActionClick}>
                                    {actionButtonText}
                                </Button>
                            )}
                            {primaryButtonText && (
                                <Button
                                    onClick={onPrimaryHandler}
                                    data-name="success"
                                    theme="octavius"
                                    primary={!actionButtonText}
                                    secondary={Boolean(actionButtonText)}
                                    middle
                                    className={classNames({ [styles[`button_${primaryButtonColor}`]]: !!primaryButtonColor })}
                                >
                                    {primaryButtonText}
                                </Button>
                            )}
                            {secondaryButtonText && (
                                <Button onClick={onSecondaryHandler} theme="octavius" data-name="secondary" middle>
                                    {secondaryButtonText}
                                </Button>
                            )}
                            {link && (
                                <div className={styles.copyWeblink}>
                                    <CopyWeblink publicUrl={link} type="new" onCopy={onPrimaryHandler} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Content>
        </Dialog>
    );
});
