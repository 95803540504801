/* eslint-disable max-lines-per-function */
import hotkeys, { KeyHandler } from 'hotkeys-js';
import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import { KeyCodes } from 'reactApp/constants/KeyCodes';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { changeHomeHistory } from 'reactApp/modules/home/home.actions';
import { selectOne, selectSome } from 'reactApp/modules/selections/selections.actions';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { getStorageConfig } from 'reactApp/modules/storage/storage.config';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { getFilterFilesInProgress } from 'reactApp/modules/uploadList/uploadList.getters';
import { gaHotkeyCategory, HOT_KEY_SCOPE_NAME, leaderKey } from 'reactApp/ui/Datalist/DataList.constants';
import { getSelectedCount } from 'reactApp/ui/Datalist/DataList.helpers';
import { EAligment } from 'reactApp/ui/VirtualList/VirtualList.types';
import { sendGa } from 'reactApp/utils/ga';

export const hotkeysHandlers = ({
    folder,
    selectedIdxs,
    storage,
    dispatch,
    items,
    handleOnClick,
    viewMode,
    isPublicUpload,
    selectedItems,
    hasAddedItem,
    lastSelectedId,
    listRef,
    selectedRange,
    filesInProgress,
}) => {
    const filteredItems = getFilterFilesInProgress(filesInProgress, items);
    const isRootOrNoSelected = (folder?.id === ROOT_FOLDER_ID && !selectedIdxs.length) || !selectedIdxs.length;
    const storageConfig = getStorageConfig(storage as EStorageType);

    const onKeySelectAll: KeyHandler = (e) => {
        e.preventDefault();
        sendGa(gaHotkeyCategory, 'select-all');
        toolbarActions.selectAll(storage);
    };

    const onKeyCopy: KeyHandler = (e) => {
        if (isRootOrNoSelected || !storageConfig.copy) {
            return;
        }
        e.preventDefault();
        sendGa(gaHotkeyCategory, 'copy');
        toolbarActions.copy();
    };
    const onKeyMove: KeyHandler = (e) => {
        if (isRootOrNoSelected || !storageConfig.move) {
            return;
        }
        e.preventDefault();
        sendGa(gaHotkeyCategory, 'move');
        toolbarActions.move();
    };
    const onKeyRemove: KeyHandler = (e) => {
        if (isRootOrNoSelected || (!storageConfig.remove && !isPublicUpload)) {
            return;
        }
        e.preventDefault();
        sendGa(gaHotkeyCategory, 'remove');
        toolbarActions.remove();
    };
    const onKeyChangeHomeHistory: KeyHandler = (e) => {
        e.preventDefault();
        sendGa(gaHotkeyCategory, 'back');
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (!folder?.parent) {
            return;
        }

        dispatch(changeHomeHistory({ id: folder.parent }));
    };
    const onKeyClick: KeyHandler = (e) => {
        if (!(selectedItems.length === 1 && selectedItems[0])) {
            return;
        }
        e.preventDefault();
        sendGa(gaHotkeyCategory, 'enter');
        const item = selectedItems[0];
        handleOnClick(item.id, item);
    };
    const onKeySelectOne: KeyHandler = (e) => {
        if (viewMode === EViewMode.list && ['ArrowLeft', 'ArrowRight'].includes(e.key)) {
            return;
        }

        const indexToSelect = hasAddedItem ? 1 : 0;

        const lastSelectedIndex = items.indexOf(lastSelectedId);
        const columnCount = listRef.current?.getColumnsCount() || 0;
        // Надо выделить первый элемент если ничего не выделено
        const index = lastSelectedIndex === -1 ? indexToSelect : lastSelectedIndex + getSelectedCount(e.key, columnCount);

        if (index < indexToSelect || index > items.length - 1) {
            return;
        }

        e.preventDefault();

        const idToSelect = items[index] || lastSelectedId;

        dispatch(selectOne({ selectedIdx: idToSelect, storage: storage as EStorageType }));
        listRef.current?.scrollToItem(index, EAligment.SMART);
        sendGa(gaHotkeyCategory, e.key);
    };
    const onKeySelectSome: KeyHandler = (e) => {
        if (selectedRange === null || (viewMode === 'list' && ['ArrowLeft', 'ArrowRight'].includes(e.key))) {
            return;
        }

        e.preventDefault();
        const indexToSelect = hasAddedItem ? 1 : 0;

        if (!selectedItems.length && filteredItems.length) {
            e.preventDefault();

            dispatch(selectOne({ selectedIdx: filteredItems[indexToSelect], storage: storage as EStorageType }));
            listRef.current?.scrollToItem(indexToSelect, EAligment.SMART);
            return;
        }

        const columnCount = listRef.current?.getColumnsCount() || 0;
        const offset = selectedRange.offset + getSelectedCount(e.key, columnCount);
        const start = filteredItems.indexOf(selectedRange.rangeStartId);
        const end = start + offset;
        const [firstIndex, lastIndex] = start <= end ? [start, end] : [end, start];
        const selectedIdxs = filteredItems.slice(firstIndex, lastIndex + 1);

        if (firstIndex < indexToSelect || lastIndex > filteredItems.length - 1) {
            return;
        }

        dispatch(
            selectSome({
                selectedIdxs,
                storage: storage as EStorageType,
                selectionRange: { offset, rangeStartId: selectedRange.rangeStartId },
            })
        );

        const indexToScroll = ['ArrowUp', 'ArrowLeft'].includes(e.key) ? firstIndex : lastIndex;

        listRef.current?.scrollToItem(indexToScroll, EAligment.SMART);

        sendGa(gaHotkeyCategory, `shift+${e.key}`);
    };

    const onKeyCreate: KeyHandler = (e) => {
        if (storage !== EStorageType.home) {
            return;
        }
        e.preventDefault();
        const actionTypes = {
            [KeyCodes.DOM_VK_F]: 'folder',
            [KeyCodes.DOM_VK_T]: 'docx',
            [KeyCodes.DOM_VK_P]: 'pptx',
            [KeyCodes.DOM_VK_S]: 'xlsx',
        };
        const type = actionTypes[e.which || e.keyCode];
        toolbarActions.create(type, 'datalist', false);
        sendGa(gaHotkeyCategory, `shift+${type}`);
        sendGa('hotkey', `shift+${type}`);
    };

    hotkeys(`${leaderKey}+a`, HOT_KEY_SCOPE_NAME, onKeySelectAll);
    hotkeys(`${leaderKey}+c`, HOT_KEY_SCOPE_NAME, onKeyCopy);
    hotkeys(`${leaderKey}+x`, HOT_KEY_SCOPE_NAME, onKeyMove);
    hotkeys(`delete,${leaderKey}+backspace`, HOT_KEY_SCOPE_NAME, onKeyRemove);
    hotkeys(`backspace`, HOT_KEY_SCOPE_NAME, onKeyChangeHomeHistory);
    hotkeys('enter', HOT_KEY_SCOPE_NAME, onKeyClick);
    hotkeys('down,up,left,right', HOT_KEY_SCOPE_NAME, onKeySelectOne);
    hotkeys('shift+down,shift+up,shift+left,shift+right', HOT_KEY_SCOPE_NAME, onKeySelectSome);
    hotkeys('shift+f,shift+h,shift+t,shift+s,shift+p', HOT_KEY_SCOPE_NAME, onKeyCreate);

    return (): void => {
        hotkeys.unbind(`${leaderKey}+a`, HOT_KEY_SCOPE_NAME, onKeySelectAll);
        hotkeys.unbind(`${leaderKey}+c`, HOT_KEY_SCOPE_NAME, onKeyCopy);
        hotkeys.unbind(`${leaderKey}+x`, HOT_KEY_SCOPE_NAME, onKeyMove);
        hotkeys.unbind(`delete,${leaderKey}+backspace`, HOT_KEY_SCOPE_NAME, onKeyRemove);
        hotkeys.unbind(`backspace`, HOT_KEY_SCOPE_NAME, onKeyChangeHomeHistory);
        hotkeys.unbind('enter', HOT_KEY_SCOPE_NAME, onKeyClick);
        hotkeys.unbind('down,up,left,right', HOT_KEY_SCOPE_NAME, onKeySelectOne);
        hotkeys.unbind('shift+down,shift+up,shift+left,shift+right', HOT_KEY_SCOPE_NAME, onKeySelectSome);
        hotkeys.unbind('shift+f,shift+h,shift+t,shift+s,shift+p', HOT_KEY_SCOPE_NAME, onKeyCreate);
    };
};
