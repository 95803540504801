import { Icon20MailOutline } from '@vkontakte/icons';
import classNames from 'clsx';
import { ReactComponent as DownloadIcon } from 'img/icons/editor/downloadIcon.svg';
import { ReactComponent as AddToFavoriteIcon } from 'img/icons/editor/favoriteIcon.svg';
import { ReactComponent as RemoveFromFavoriteIcon } from 'img/icons/editor/favoriteIconFilled.svg';
import { ReactComponent as SaveToCloud } from 'img/icons/editor/saveToCloudIcon.svg';
import React, { FC, useState } from 'react';
import { downloadItem } from 'reactApp/appHelpers/appDownload';
import { getIsCloudAvailabe, updateItemFavoriteFlag } from 'reactApp/appHelpers/appHelpers';
import { IS_ONPREMISE } from 'reactApp/appHelpers/configHelpers';
import { isWhiteHeaderFavoritesEnabled } from 'reactApp/appHelpers/featuresHelpers';
import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import { EditorItem } from 'reactApp/modules/editor/editor.types';
import { isWhiteEditorHeaderSendByMail } from 'reactApp/modules/features/features.helpers';
import { CloudItem } from 'reactApp/modules/storage/storage.types';
import { HeaderButton } from 'reactApp/ui/EditorHeader/WhiteEditorHeader/components/HeaderButton/HeaderButton';
import { ShareSection } from 'reactApp/ui/EditorHeader/WhiteEditorHeader/components/HeaderRightGroup/components/ShareSection/ShareSection';
import { EditorHeaderButtonPromoContainer as MyOfficePromoHeaderButton } from 'reactApp/ui/MyOfficePromo/EditorHeaderButton/EditorHeaderButtonPromoContainer';

import styles from './HeaderRightGroup.css';

interface Props {
    onBackToCloud: (() => void) | null;
    isPublic: boolean;
    enableFavorites: boolean;
    toggleFavorite: (file: EditorItem) => void;
    gaSender: (action: string, label: string, data?: any) => void;
    sendEditorRadar: (i: string) => void;
    isStock: boolean;
    file: EditorItem | null;
    isCoEditSwitchButtonEnable: boolean;
    isAnonymous: boolean;
    downloadDropdownList?: any[];
    id?: string;
    isWopiFeatureEnabled?: boolean;
    myOfficePromoEditorEnabled?: boolean;
    isViewer?: boolean;
}

export const HeaderRightGroup: FC<Props> = (props) => {
    const {
        id,
        file,
        isPublic,
        isStock,
        downloadDropdownList,
        isWopiFeatureEnabled,
        isViewer,
        isCoEditSwitchButtonEnable,
        gaSender,
        sendEditorRadar,
        myOfficePromoEditorEnabled = false,
        toggleFavorite,
        enableFavorites,
        isAnonymous,
    } = props;
    const [isDownloadOpen, setIsDownloadOpen] = useState(false);
    const isCloudAvailable = IS_ONPREMISE ? !isAnonymous && getIsCloudAvailabe() : getIsCloudAvailabe();
    const showCloneToCloud = !isViewer && !isAnonymous && isPublic && !file?.home && isCloudAvailable;

    if (isStock) {
        return null;
    }

    const handleDownloadDropdown = () => {
        setIsDownloadOpen((prevState) => !prevState);
    };

    const handleDownload = () => {
        gaSender('download', 'click');

        downloadItem({ itemOrId: file });

        sendEditorRadar('download-content');
    };

    const handleSaveToCloud = () => {
        const fileId = file?.id;

        gaSender('savetocloud', 'click');

        if (!fileId) {
            return;
        }

        sendEditorRadar('save-in-cloud');

        toolbarActions.clone({ id: fileId, source: 'editor' });
    };

    const handleToggleFavorite = () => {
        gaSender('favorite', 'click');

        const isInFavoritesPrev = !!file?.isInFavorites;

        if (!file) {
            return;
        }

        toggleFavorite(file);

        gaSender('favorite', 'success');

        sendEditorRadar('favorite');

        updateItemFavoriteFlag(file?.id, !isInFavoritesPrev);
    };

    const handleOnMail = () => {
        const item = props.file as CloudItem;
        sendEditorRadar('mail');
        toolbarActions.forward({ items: [item] });
    };

    return (
        <div
            className={classNames(styles.rightGroup, {
                [styles.myOfficePromoEditorEnabled]: myOfficePromoEditorEnabled,
            })}
        >
            <div className={styles.rightGroup__buttons}>
                {showCloneToCloud && (
                    <HeaderButton hint="Сохранить в Облако" title="Сохранить в Облако" icon={<SaveToCloud />} onClick={handleSaveToCloud} />
                )}
                {isWhiteEditorHeaderSendByMail && !isPublic && (
                    <HeaderButton qaId={'mail-send'} icon={<Icon20MailOutline />} onClick={handleOnMail} hint={'Отправить по почте'} />
                )}
                {enableFavorites && isWhiteHeaderFavoritesEnabled && (
                    <HeaderButton
                        qaId={file?.isInFavorites ? 'favorites-remove' : 'favorites-add'}
                        icon={file?.isInFavorites ? <RemoveFromFavoriteIcon /> : <AddToFavoriteIcon />}
                        onClick={handleToggleFavorite}
                        hint={file?.isInFavorites ? 'Убрать из избранного' : 'Добавить в избранное'}
                    />
                )}
                <HeaderButton
                    qaId="download"
                    icon={<DownloadIcon />}
                    onClick={handleDownload}
                    dropdownList={downloadDropdownList}
                    onDropdownClick={handleDownloadDropdown}
                    isDropdownOpen={isDownloadOpen}
                    isWopiFeatureEnabled={isWopiFeatureEnabled}
                    hint="Скачать"
                />
                <ShareSection
                    gaSender={gaSender}
                    isCoEditSwitchButtonEnable={isCoEditSwitchButtonEnable}
                    file={file}
                    isPublic={isPublic}
                    isStock={isStock}
                    id={id}
                    isViewer={isViewer}
                />
            </div>
            <MyOfficePromoHeaderButton fileExt={file?.ext} />
        </div>
    );
};
