import { Spacing, Title } from '@vkontakte/vkui';
import coverImage from 'img/subscriptions/cancel-autorenew.png';
import React, { memo, ReactElement, useMemo } from 'react';
import { WhatsNewDialog } from 'reactApp/components/WhatsNewDialog/WhatsNewDialog';
import { ISubscription } from 'reactApp/modules/subscriptions/subscriptions.types';

import styles from './CancelAutoRenewConfirmDialog.css';

interface IProps {
    leftQuota: string;
    onClose: () => void;
    onAction: () => void;
    onSuccess: () => void;
    isTrial: boolean;
    subscription: ISubscription;
}

export const CancelAutoRenewConfirmDialog = memo<IProps>(({ leftQuota, onClose, onAction, onSuccess, isTrial }): ReactElement => {
    const content = useMemo(
        (): ReactElement => (
            <div className={styles.content}>
                <Spacing size={12} />
                <Title level="3">После {isTrial ? 'отмены' : 'окончания'} подписки:</Title>
                <Spacing size={12} />
                <ul className={styles.list}>
                    <li>Объём Облака уменьшится до {leftQuota}</li>
                    <li>Вернётся реклама</li>
                    <li>Не получится загружать тяжёлые файлы</li>
                </ul>
                <Spacing size={32} />
            </div>
        ),
        [isTrial, leftQuota]
    );

    return (
        <WhatsNewDialog
            onClose={onClose}
            onClick={onSuccess}
            onSecondary={onAction}
            primaryButtonText={isTrial ? 'Сохранить подписку' : 'Сохранить продление'}
            secondaryButtonText="Отменить"
            title="Вы уверены?"
            content={content}
            imageUrl={coverImage}
            imageBgColor="#ffffff"
        />
    );
});

CancelAutoRenewConfirmDialog.displayName = 'CancelAutoRenewConfirmDialog';
