/* eslint-disable complexity */
import { parse } from 'qs';
import { createAutoDeleteFolder } from 'reactApp/appHelpers/appHelpers';
import { IS_BIZ_USER, IS_PUBLIC_ALBUM, platform } from 'reactApp/appHelpers/configHelpers';
import { isFileForOpenInEditor, openEditor } from 'reactApp/appHelpers/editorHelpers';
import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import { openCreateCopyOfNoneditableFileModal } from 'reactApp/components/CreateCopyOfNoneditableFileModal/helper';
import { EAllDocumentsType } from 'reactApp/modules/allDocuments/allDocuments.types';
import { attachesOpenViewer } from 'reactApp/modules/attaches/attaches.actions';
import { dispatchNewSearchRadar } from 'reactApp/modules/dwh/dwh.module';
import { getEditorsByExtension } from 'reactApp/modules/editor/editor.selectors';
import { getFeatureNoneditableFormats } from 'reactApp/modules/features/features.helpers';
import { getFeatureEditingNoneditableFormats } from 'reactApp/modules/features/features.selectors';
import { isFolder, isPdf } from 'reactApp/modules/file/utils';
import { changeHomeHistory, setDomainFoldersFilterActive } from 'reactApp/modules/home/home.actions';
import { changeHistory, historyPush } from 'reactApp/modules/router/router.module';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { trashbinRestoreRequest } from 'reactApp/modules/trashbin/trashbin.module';
import { openUserQuotaItemView } from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.actions';
import { openViewer } from 'reactApp/modules/viewer/viewer.module';
import { EDocsAction, EDocSubKind } from 'reactApp/sections/AllDocuments/allDocuments.types';
import { store as reduxStore } from 'reactApp/store';
import { createCreateNewMenu } from 'reactApp/ui/ContextMenu/createContextMenuList';
import { renderContextMenu } from 'reactApp/ui/ContextMenu/createContextMenuToolkit';
import { logHandler } from 'reactApp/ui/Datalist/DataList.helpers';
import { DropdownTheme } from 'reactApp/ui/DropdownList/DropdownList';
import { sendDatalistGa } from 'reactApp/utils/datalistGa';
import { sendDwh, sendGa } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';
import { getEncodePath, getHomeFolderPath } from 'reactApp/utils/urlHelper';

const getItemId = (item) => {
    const { isPublic, isAlbums } = getStorage(item.storage);

    if (isAlbums) {
        return item.id;
    }

    if (isPublic) {
        return item.weblink;
    }

    return item.home || item.id;
};

export const onItemClick = ({ id, item, dispatch, storage, viewMode, attachType, attachFolderId, featureFormatsData }) => {
    const isFile = !isFolder(item);
    const {
        isSharedIncoming,
        isTrashBin,
        isHome,
        isSearch,
        isSharedLinks,
        isSharedAutodelete,
        isFavorites,
        isQuotaCleaner,
        isAttaches,
        isAllDocuments,
        isIntegration,
        isPublic,
        isStock,
    } = getStorage(item.storage);
    const reloadPageToFolderHome = (isSearch || isSharedLinks || isSharedIncoming) && item.isFolder;
    const openFolderHome = (isFavorites || isSharedAutodelete) && item.isFolder;
    const openFolderPublicReact = (isStock || isPublic) && item.isFolder;
    const isHomeFolder = isHome && !isFile;
    const isOpenViewer = !item.isFolder;
    const inviteToken = isSharedIncoming && item.inviteToken;
    const { query } = parse(window.location.search, { ignoreQueryPrefix: true });
    const itemStorage = storage ?? item.storage;
    const isPrivate = 'home' in item && itemStorage !== EStorageType.public;
    const editingNoneditableFormats =
        !!item && !!itemStorage && isPrivate
            ? getFeatureNoneditableFormats({ file: item, storage: itemStorage }, featureFormatsData)
            : undefined;

    sendDatalistGa('open', `${item.isFolder ? 'folder' : 'file'}${viewMode}`);

    if ((isSearch || (isAttaches && query)) && item.isFolder) {
        const dwhData = {
            eventCategory: ECategoryGa.entered,
            action: 'open',
        };

        const items = [
            {
                file_name: item.nameWithoutExt,
                type: item.kind,
                pos: item.pos,
                file_id: item.id,
                mtime: item.mtime,
                size: item.size || 0,
                extension: item.isFolder ? 'folder' : item.ext,
            },
        ];

        dispatch(dispatchNewSearchRadar({ dwhData, items }));
    }

    if (isQuotaCleaner) {
        dispatch(openUserQuotaItemView({ id: item.id }));
        return;
    }

    if (inviteToken) {
        toolbarActions.mount();
    } else if (isTrashBin) {
        dispatch(
            trashbinRestoreRequest({
                ids: [item.id],
                from: 'double-click',
            })
        );
    } else if (openFolderHome) {
        dispatch(historyPush({ id: getHomeFolderPath(item.home) }));
    } else if (openFolderPublicReact) {
        dispatch(changeHistory(`/${IS_PUBLIC_ALBUM ? '' : storage}/${getEncodePath(id)}`));
    } else if (reloadPageToFolderHome) {
        // TODO: routing
        location.href = getHomeFolderPath(getEncodePath(item.home));
    } else if (isAllDocuments) {
        const { subKind = '', size = 0, ext: extension = '' } = item || {};
        const path = `home/${getEncodePath(item.home)}`;
        const state = reduxStore.getState();
        const isConvertable = !!item && !!storage ? getFeatureEditingNoneditableFormats(state, { file: item, storage }) : undefined;
        const isEditable = getEditorsByExtension(state, isConvertable?.ext || item.ext).length > 0;

        sendDwh({
            eventCategory: ECategoryGa.docs,
            action: EDocsAction.open,
            dwhData: {
                platform,
                extension,
                size_file: size,
                type_content: EDocSubKind[subKind] ?? '',
                source: EDocSubKind[subKind] ?? '',
                is_edit: isEditable,
                type_open: isPdf(item) || isConvertable ? 'show' : 'edit',
            },
        });

        if (isPdf(item) || isConvertable) {
            window.open(`${window.location.origin}/${path}`);
        } else {
            openEditor(item);
        }
    } else if (IS_BIZ_USER && editingNoneditableFormats) {
        openCreateCopyOfNoneditableFileModal({
            file: item,
            editingNoneEditableFormats: editingNoneditableFormats,
        });
    } else if (isFileForOpenInEditor(item)) {
        openEditor(item);
    } else if (isHomeFolder || isIntegration) {
        dispatch(changeHomeHistory({ id }));
        dispatch(setDomainFoldersFilterActive(false));
    } else if (isOpenViewer) {
        const data = {
            itemId: getItemId(item),
            itemStorage: storage || null,
            gaSuffix: '',
            itemIds: [],
        };
        sendGa('datalist', 'open-viewer', viewMode);

        if (isAttaches) {
            dispatch(attachesOpenViewer({ id: item.id, attachType, folderId: attachFolderId }));
        } else {
            dispatch(openViewer(data));
        }
    }
};

export const onClickCreateNew = (
    e,
    { gaSuffix, storage, currentAllDocsType }: { gaSuffix: string; storage: EStorageType; currentAllDocsType?: EAllDocumentsType }
) => {
    const { isSharedLinks, isSharedAutodelete, isAllDocuments } = getStorage(storage);

    e.preventDefault();
    e.stopPropagation();

    if (isSharedAutodelete) {
        createAutoDeleteFolder();
        return;
    }

    if (isSharedLinks) {
        logHandler({
            radarName: 'createnew_shared_click',
        });
        toolbarActions.create('folder', 'datalist', true, 'shared', true);

        return;
    }

    if (isAllDocuments) {
        logHandler({
            radarName: 'createnew_docs',
        });
        toolbarActions.create(currentAllDocsType === EAllDocumentsType.spreadsheet ? 'xlsx' : 'docx', 'datalist', true, 'docs');

        return;
    }

    logHandler({
        radarName: 'createnew_click',
    });
    renderContextMenu({
        posX: e.pageX,
        posY: e.pageY,
        menuItems: createCreateNewMenu({ createFrom: 'datalist' }),
        theme: DropdownTheme.medium,
        gaSuffix: `ctx_create${gaSuffix ? `_${gaSuffix}` : ''}`,
        storage,
    });
};
