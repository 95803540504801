import { MyOfficeFallbackTimeoutConfig } from 'Cloud/Application/Editor/MyOffice/myOffice.types';
import { getFeature, isFeature } from 'Cloud/Application/FeaturesEs6';
import browser from 'Cloud/browser';
import {
    IS_BIZ_USER,
    IS_IOS_MOBILE,
    IS_MOBILE_BROWSER,
    IS_ONPREMISE,
    IS_PAID_USER,
    IS_PHONE_BROWSER,
    IS_WEBVIEW,
} from 'reactApp/appHelpers/configHelpers';
import { isPromoQuotaInAndroid } from 'reactApp/appHelpers/settingsHelpers';
import { DEFAULT_MYOFFICE_FALLBACK_TIMEOUT, TEN_MINUTES } from 'reactApp/constants';
import { SPACE_LIST } from 'reactApp/constants/spaceList';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { MigrateFileV2ToV4, MigrateFolderV2ToV4, MigrateWeblinksV2ToV4 } from 'reactApp/types/features';
import { SAFE_FAKEDOOR_STORE_ID } from 'reactApp/ui/SafeFakedoorModal/SafeFakedoorModal.constants';

import { TemplateVariantString } from './types';

export const isWopiForSafari = browser.isSafari() ? isFeature('wopi-safari') : true;
export const isWopiEnabled = isWopiForSafari && isFeature('wopi');

export const expFormats = isWopiForSafari && getFeature('myoffice-exp-formats');

export const attachTextFormats = getFeature('attach-text-formats') ?? [];

export const wopiFallbackConfig =
    isWopiForSafari &&
    (getFeature('wopi-fallback-config') || {
        hostReg: '^https:\\/\\/(?:docs\\-mo|docs\\-myoffice)\\.datacloudmail\\.ru',
        enableFallback: false,
        enableFallbackServiceError: false,
        enableTimeoutFallback: false,
        enableFallbackPostMsgError: false,
    });

export const wopiJsErrorsFallbackList = isWopiForSafari && (getFeature('wopi-js-errors-fallback-list') || []);

const myOfficeFallbackTimeoutConfigValue: Partial<MyOfficeFallbackTimeoutConfig> = getFeature('myoffice-fallback-timeout-config');

const isCorrectTimeoutConfig = (config: Partial<MyOfficeFallbackTimeoutConfig>): config is MyOfficeFallbackTimeoutConfig =>
    typeof config?.default === 'number' &&
    typeof config?.override === 'object' &&
    config?.override !== null &&
    Object.entries(config.override).every(([key, value]) => typeof key === 'string' && typeof value === 'number');

/**
 * CLOUDWEB-16114: Загружаем конфигурацию таймаута для фолбека CES -> SEK.
 * Конфигурация содержит дефолтное значение таймаута для всех расшиений файлов и
 * возможность указать переопределенные значения таймаутов для конкретных расширений файлов.
 */
export const myOfficeFallbackTimeoutConfig: MyOfficeFallbackTimeoutConfig = isCorrectTimeoutConfig(myOfficeFallbackTimeoutConfigValue)
    ? myOfficeFallbackTimeoutConfigValue
    : { default: DEFAULT_MYOFFICE_FALLBACK_TIMEOUT, override: {} };

export const isHashCalcLibWasm = getFeature('hash-lib')?.isWasm ?? 0;
export const isHashCalcLibCrypto = (getFeature('hash-lib')?.isCrypto && typeof window?.crypto?.subtle?.digest === 'function') ?? 0;
export const isDmrReactUrlEnabled = isFeature('ab-dmr-react');
export const isDmrReactUrlTouchEnabled = isFeature('ab-dmr-react-touch');
// DMR для реакт окна присылает неверную высоту, пока делаем коррекцию этого у себя
export const abReactDmrHeightFix = getFeature('ab-dmr-react')?.heightFix ?? 0;
// tempexp_SRCH-70037 start
export const isWebSearchContentEnabled = isFeature('new_search_by_content');
// tempexp_SRCH-70037 end
// tempexp_SRCH-70925 start
export const isTouchSearchContentEnabled = isFeature('new_search_by_content_touch');
// tempexp_SRCH-70925 end
// tempexp_SRCH-70994 start
export const isNewWebSearchHistoryEnabled = isFeature('new_search_query_history_web');
// tempexp_SRCH-70994 end
// tempexp_SRCH-70997 start
export const isTouchSearchHistoryEnabled = isFeature('new_search_query_history_touch');
// tempexp_SRCH-70997 end

export const ebookDesktopConfig:
    | {
          exts: string[];
          timeout: number;
      }
    | undefined = getFeature('ebook-desktop-config');
export const ebookTouchConfig: typeof ebookDesktopConfig = getFeature('ebook-touch-config');
// tempexp_14979-start
export const isHideQuotaNewbieFeatureEnabled = isFeature('hide-quota-newbie');
// Нужно тестировать разную дефолную квоту до начала экспа. Поэтому мокаем через фичу.
export const defaultQuotaValueMockForQA = getFeature('default-quota-mock-qa') || false;
// tempexp_14979-end
/** CLOUDWEB-14535: ЕК. Фильтр по годам. Shift лет назад от текущего года для старых групп */
export const quotaCleanerOldGroupYear = getFeature('quota-cleaner-old-group-year') || 0;
/** CLOUDWEB-15461 Аб: Новый лп Единой квоты/страница подписок в вебвью */
export const isTariffsQuotaLandingWebview = isFeature('tariffs-quota-landing-webview');
/** CLOUDWEB-15202 Аб: Лендинг /promocodes */
export const isFeatureAbPromocodesLanding = isFeature('ab-promocodes-landing');
/** CLOUDWEB-13956 МойОфис. Поддержать совместимость функциональности фронтенда Облака по совместному редактированию */
export const isWopiCoEdit = isFeature('wopi-co-edit');
/** CLOUDWEB-16228: Совместное редактирование для R7-Офис */
export const isR7WopiCoEdit = isFeature('r7-wopi-co-edit');
/** CLOUDWEB-15038 Развитие совместного редактирования. Эксперимент с изменением окна шаринга */
export const isModifiedSharingWindow = isFeature('modified-sharing-window');
/** CLOUDWEB-15029: МойОфис. Бизнес метрики. Интервал отправки аткуальности сессии в сек */
export const myOfficeSessionInterval = Number.parseInt(getFeature('myoffice-session-interval'));
/** CLOUDWEB-15029: МойОфис. Бизнес метрики. Включение по типу */
export const myofficeBMetricConfig = getFeature('myoffice-bmetric-config') || {};
/** B2BCLOUD-474: Включение просмотра временных аттачей-документов */
export const isTemporaryAttachView = isFeature('temporary-attach-view');
/** CLOUDWEB-15182 На странице Mail Space отсылать event в биллинг при просмотре тарифов, частота отправки события в мс */
export const sendStartEventTimer: number = getFeature('send-start-tariffs-event') || 0;
/** CLOUDWEB-15280 Поправить преимущества подписок
 * если это промо ек из приложения, то фича рабоать не должна */
export const isOnlyWebAdInTariffs = (!isPromoQuotaInAndroid && isFeature('only-web-ad-in-tariffs')) || false;
/** CLOUDWEB-14763: Конфиг АПИ миграции с v3 -> v4 */
export const apiMigrateV3ToV4 = getFeature('api-migrate-v3-to-v4') || {};
/** B2BCLOUD-1084 Миграция ручек folder/share c APIv2 -> APIv4 для биз юзеров */
export const apiMigrateFolderV2ToV4: MigrateFolderV2ToV4 = getFeature('api-migrate-v2-to-v4-folder') || {};
/** B2BCLOUD-925: Фича АПИ миграции с v2 -> v4 для ручек weblinks/* */
export const apiMigrateWeblinksV2ToV4: MigrateWeblinksV2ToV4 = getFeature('api-migrate-v2-to-v4-weblinks') || {};
/** B2BCLOUD-1004: Фича АПИ миграции с v2 -> v4 для ручки file/* */
export const apiMigrateFileV2ToV4: MigrateFileV2ToV4 = getFeature('api-migrate-v2-to-v4-file') || {};
/** CLOUDWEB-15428 Выключаем бесконечную карусель на /subs и добавляем к ней дотсы  */
export const isNotInfiniteSubsCarousel = isFeature('not-infinite-subs-carousel') || false;
// Квота рекомендованного тарифа в списке тарифов
export const recommendedTariffQuota = getFeature('recommended-tariff-quota') || SPACE_LIST.gb_256;

/** CLOUDWEB-14002-a Включение просмотра файлов из мобильной чистилки */
export const isQuotaCleanerMobViewing = isFeature('quota-cleaner-mob-viewing');

/** CLOUDWEB-15822 Включение просмотра писем из мобильной чистилки */
export const isQuotaCleanerMobLetterViewing = isFeature('quota-cleaner-mob-letter-viewing');

/** CLOUDWEB-15822 Включение просмотра писем из мобильной чистилки */
export const isQuotaCleanerMobLetterUrlToTouch = isFeature('quota-cleaner-mob-letter-url-to-touch');

/** CLOUDWEB-16076 Скрытие кнопки скачать письмо в тач чистилке */
export const isQuotaCleanerMobLetterDownloadAvailable = isFeature('quota-cleaner-mob-letter-download-available');

// Урл и id для каптчи при отмене подписки
export const subscriptionCaptchaConfig: { url: string; captchaId: number } = getFeature('subscription-captcha-config') || {
    url: 'https://c.mail.ru/',
    captchaId: 5,
};
/** CLOUDWEB-15437: МойОфис. Метрики. White list рефереров */
export const myofficeBMetricProjectList = getFeature('myoffice-bmetric-project-list') || [];
/** CLOUDWEB-15488: Переименование файлов/папок в сетке по двойному клику на их название */
export const datalistInlineRenameEnabled = getFeature('datalist-inline-rename') || false;
/** CLOUDWEB-15000 Раздел Документы. Возврат в раздел Документы в шапке */
export const isAllDocumentsReturn = isFeature('all-documents-return');
/** CLOUDWEB-15323 Раздел Документы. Заводная точка в Облаке */
export const isAllDocumentsInMenu = isFeature('all-documents-in-menu');
/** Показывать 2-годовые тарифы в вебвью /promo/quota/tariffs  */
export const isTwoYearsTariffsInWebview = isFeature('2-years-tariff-webview') || false;
/** CLOUDWEB-15647 Раздел "Из Почты". Ограничить число одновременного залития аттачей в Облако. */
export const attachesCloneLimit = getFeature('attaches-clone-limit')?.limit;
// tempexp_15661-start
/** CLOUDWEB-15661: Добавить call to action на /promo/quota */
export const isCallToActionTariffButton = isFeature('call-to-action-tariff-card-button') || false;
export const isCallToActionTariffButtonTouch = isFeature('call-to-action-tariff-card-button-touch') || false;
// tempexp_15661-end
/** CLOUDWEB-15512: 10 лет облаку разработка лендинга / frontend (тариф) */
export const happyBirthdayCloud10Tariff = getFeature('happybirthdaycloud-tariff') || {
    price: 149,
    id: 'W128G1m_single_quota_2022_cloud',
    period: '1m',
    available: true,
    isPromo: false,
    hasTrial: false,
    isProfessional: true,
    isDisko: false,
    hasDiskO: false,
    hasDiskOAutoRenewal: false,
    isVkCombo: false,
    isForceDiscountTrial: false,
    isTransit: false,
    isPrepaid: false,
    hasDiscount: false,
    space: {
        original: 137438953472,
        units: 'ГБ',
        space: 128,
        value: '128 ГБ',
    },
    turbo: false,
    isSingleQuota: true,
};
/** CLOUDWEB-15321 Раздел Документы. Внедрить форму обратной связи */
export const isAllDocumentsHelpButton = isFeature('alldocuments-help-button');

/** CLOUDWEB-15774 Отключаем раздачу призов на лендинге ДР Облака */
export const isHappyBirthdayCloudFinished = isFeature('happybirthdaycloud10-finished') || false;

/** CLOUDWEB-15776 Новая портальная шапка Облака, запускаем через АБ */
export const isNewPortalHeader = isFeature.ssr(
    'new-portal-header',
    () => false,
    (ctx) => {
        if (ctx) {
            return !ctx?.browserInfo?.isMobile && !ctx?.user?.isBizUser && !ctx?.promoLanding;
        }

        return !IS_MOBILE_BROWSER && !IS_BIZ_USER;
    }
);

/** CLOUDWEB-15845 Уведомление о "Рекомендательных технологиях" */
export const isSuggestionsLawEnabled = isFeature('suggestions-law') || false;

/** Общая фича для включения семейно подписки  */
export const isFamilySubsDesktopAvailable = (!IS_ONPREMISE && isFeature('family-subscription')) || false;
export const isFamilySubsTouchAvailable = (!IS_ONPREMISE && isFeature('family-subscription-mobile')) || false;
export const isFamilySubsAvailable = IS_PHONE_BROWSER ? isFamilySubsTouchAvailable : isFamilySubsDesktopAvailable;
/** CLOUDWEB-15532 Семейная подписка. Фичи на карточках тарифов  */
export const isFamilySubsInTariff = (isFamilySubsAvailable && isFeature('family-subs-in-tariffs')) || false;
/** CLOUDWEB-14048: Фича показа aside promo (балуна) семейной подписки (тач) */
export const isFamilyAsidePromoEnabledMobile = isFeature('family-aside-promo-mobile') || false;
/** CLOUDWEB-15528: Фича показа aside promo (балуна) семейной подписки */
export const isFamilyAsidePromoEnabled = IS_PHONE_BROWSER ? isFamilyAsidePromoEnabledMobile : isFeature('family-aside-promo') || false;
// Кол-во возможных членов семьи
export const familyMembersCount = getFeature('family-members-count') || 4;
/** CLOUDWEB-15531: Фича включения семейной подписки на странице /promo/quota */
export const isFamilySubsLandingAvailable = isFeature('family-subscription-landing') || false;
export const isFamilySubsLandingMobileAvailable = isFeature('family-subscription-landing-mobile') || false;
/** CLOUDWEB-15747: Начать принудительный апдейт квоты для тех у кого есть флаг family раз в 10 минут */
export const familyMemberQuotaUpdateInterval = getFeature('family-quota-interval') || TEN_MINUTES;
/** CLOUDWEB-16043:  Фича включения кнопки семейной подписки в блоке квоты на мобилке */
export const isFamilyQuotaButtonMobileEnabled = isFeature('family-quota-button-mobile') || false;
/** CLOUDWEB-15529: Фича включения кнопки семейной подписки в блоке квоты */
export const isFamilyQuotaButtonEnabled = IS_PHONE_BROWSER ? isFamilyQuotaButtonMobileEnabled : isFeature('family-quota-button') || false;
/** CLOUDWEB-15530: Фича включения отображения семьи в блоке квоты  */
export const isFamilyQuotaInSpaceEnabled = isFeature('family-quota-in-space') || false;
/** CLOUDWEB-16043: Фича включения пукта меню Семья в бургер-меню на мобилке */
export const isFamilyMobileMenuItemEnabled = isFeature('family-mobile-menu-item') || false;

/* tempexp_15752-next-line */
/** CLOUDWEB-15752 Доработка окна шаринга / АБ */
export const isAbNewSharingFolder = isFeature('ab-sharing-folder');

/** CLOUDWEB-16015 Белая шапка. Доработки UI */
export const isWhiteHeaderFavoritesEnabled = isFeature('white-header-favorites-enabled');

/** CLOUDWEB-15910 Сброс каунтера Облака  */
export const isStoryUnviewedReset = isFeature('story-unviewed-reset');

// CLOUDWEB-15998 фича включения черной темы на страницах с тарифами
export const isDarkTariffsPages = isFeature('dark-tariffs-pages') || false;

/** CLOUDWEB-14445: Фича, которая оставляет только оплаты картами */
export const isHidePayMethodsMenu = isFeature('hide-pay-methods-menu');
export const isHidePayMethodsMenuTouch = isFeature('hide-pay-methods-menu-mobile');

/** CLOUDWEB-15889: Фичи, через которые задается дефотлный способ оплаты
 *  работает только с включенным левым меню */
export const defaultPayMethod = !isHidePayMethodsMenu && getFeature('default-pay-method');
export const defaultPayMethodTouch = !isHidePayMethodsMenuTouch && getFeature('default-pay-method-mobile');

/** CLOUDWEB-16083: Фича включения оплаты через мидас */
export const isDmrMidasUrlEnabled = isFeature('dmr-midas');
export const isDmrMidasUrlTouchEnabled = isFeature('dmr-midas-touch');

/** b2bcloud-1268 Добавить возможность открывать панель логов для папок там-же, где есть версионирование для файлов  */
/** Не включать в SaaS до готовности бэка B2BCLOUD-1272 */
export const isActivitiesFeatureEnabled = isFeature('activities-and-versions');
export const activitiesFeature = getFeature('activities-and-versions') || {};

/** CLOUDWEB-16194: сжимаем и центрируем баннер worm_nv в аттачах  */
export const isAbAttachesCentered = isFeature('ab-attaches-centered');

/** CLOUDWEB-15875: Новая логика обновления рекламы аттачей почты */
export const isAttachUpdateLogicV2 = isFeature('ab-attach-update-logic-v2');

/** CLOUDWEB-16213: Список триалов для /promo/quota в вебвью  android  */
const androidTrialTariffsList = (IS_WEBVIEW && getFeature('android-trials-list')) || [];
/** CLOUDWEB-16143: Список триалов для /promo/quota в вебвью  ios  */
const iosTrialTariffsList = (IS_WEBVIEW && getFeature('ios-trials-list')) || [];
export const trialTariffsList = IS_IOS_MOBILE ? iosTrialTariffsList : androidTrialTariffsList;

/** CLOUDWEB-16338 АБ Увеличение цен на тарифы 128 и 256 гб на ВЕБ */
// tempexp_16338-next-line
export const abMonthTariffsWithIncreasedPrice = !IS_WEBVIEW ? getFeature('ab-month-tariffs-increased-price') : false;

/** CLOUDWEB-16249 web. Реализация фейкдора сейф */
export const abSafeFakeDoor = getFeature('ab-safe-fakedoor');
export const abSafeFakeDoorSelector = (): TemplateVariantString | boolean => {
    if (storeHelper.getValue(SAFE_FAKEDOOR_STORE_ID) || IS_MOBILE_BROWSER || IS_PAID_USER) {
        return false;
    }

    if (abSafeFakeDoor === 'a') {
        return 'variant1';
    }

    if (abSafeFakeDoor === 'b') {
        return 'variant2';
    }

    return false;
};
/** CLOUDWEB-16438: Кастомизация заголовка и подзаголовка promo/quota через Омикрон */
export const promoQuotaMarketingFallbackText = getFeature('promo-quota-marketing-fallback-text') || {};

/** CLOUDWEB-16215: [WEB] АБ вечная акция */
// tempexp_16215-next-line
export const abEternalPromoYearTariffs: string[] = !IS_WEBVIEW ? getFeature('ab-eternal-promo-year-tariffs') || [] : [];

/** CLOUDWEB-16349: [WEB] АБ-тест 64ГБ */
// tempexp_16349-next-line
export const desktopTariffMinSize = getFeature('desktop-tariff-min-size') || 128;

/** CLOUDWEB-16455: Окна оплаты. Прокидывать заголовок и описание через квери параметры */
export const tariffBuyCustomTitle: Record<number, string> = getFeature('tariff-buy-custom-title') || {};
export const tariffBuyCustomDescription: Record<number, string> = getFeature('tariff-buy-custom-description') || {};

/** CLOUDWEB-16373: [WEB] Подборка выгодных тарифов */
// tempexp_16373-next-line
export const favorableTariffs: string[] = !IS_MOBILE_BROWSER ? getFeature('favorable-tariffs') || [] : [];

/** CLOUDWEB-16510: [TOUCH] расположить карточки тарифов в обратном порядке */
// tempexp_16510-next-line
const iOsMobileTariffsOrderByQuota: number[] = IS_MOBILE_BROWSER || IS_WEBVIEW ? getFeature('tariffs-order-by-quota-mobile-ios') || [] : [];
const androidMobileTariffsOrderByQuota: number[] =
    IS_MOBILE_BROWSER || IS_WEBVIEW ? getFeature('tariffs-order-by-quota-mobile-android') || [] : [];
export const mobileTariffsOrderByQuota = IS_IOS_MOBILE ? iOsMobileTariffsOrderByQuota : androidMobileTariffsOrderByQuota;
