import { stringify } from 'qs';
import type { IntegrationState } from 'reactApp/modules/integration/integration.types';
import type { RootState } from 'reactApp/store';
import { createSelector } from 'reselect';

export const getIntegrationState = (state: RootState): IntegrationState => state.integration;

export const getIntegrationQueryString = createSelector(getIntegrationState, ({ parentOrigin, client, userId }) =>
    stringify(
        {
            parent_origin: parentOrigin,
            integration_client: client,
            integration_uid: userId,
        },
        { addQueryPrefix: true }
    )
);
