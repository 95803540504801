import darkCoverImage from 'img/aside-promo-modal/cloud-dark.jpg';
import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { sendGa } from 'reactApp/utils/ga';

import { EAsidePromoModalTheme, IAsidePromoModalProps } from './AsidePromoModal.types';

export const openAsidePromoModal = ({ onClose, ...data }: IAsidePromoModalProps) =>
    openPopupHelper({ popupName: popupNames.ASIDE_PROMO_MODAL, data, onClose });

// tempexp_15947-start
export const showCloneAsideModal = (id: string, bottom?: number) => {
    if (!id) {
        return;
    }

    openAsidePromoModal({
        title: 'Храните в Облаке',
        text: 'Файл не будет занимать место на устройстве, а бесплатных гигабайтов хватит на сотни фото и видео',
        buttonText: 'Сохранить в Облако',
        imageUrl: darkCoverImage,
        theme: EAsidePromoModalTheme.dark,
        bottom,
        onClick: () => {
            toolbarActions.clone({
                id,
                storage: EStorageType.attaches,
                destination: '',
                source: 'attaches-viewer',
            });

            sendGa('clck-aside-mdl', 'ab-save');
        },
        onShow: () => sendGa('shw-aside-mdl', 'ab-save'),
        onClose: () => sendGa('cls-aside-mdl', 'ab-save'),
    });
};
// tempexp_15947-end
