import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { parseSearchQuery } from 'reactApp/modules/search/search.helpers';
import {
    EViewMode,
    ISetViewModeAction,
    ISetViewModeForStorageAction,
    QueryParams,
    SettingsState,
} from 'reactApp/modules/settings/settings.types';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';

const initialState: SettingsState = {
    viewMode: EViewMode.list,
    prevViewMode: EViewMode.list,
    viewModePhoto: EViewMode.gallery,
    viewModePhotoMobile: EViewMode.galleryGrid3,
    viewModeAttaches: EViewMode.list,
    viewModeQuotaCleaner: EViewMode.list,
    queryParams: {},
    sidebarOpened: false,
};

export const setPhotoViewMode = createAction<ISetViewModeAction>('settings/photoviewmode');
export const setPhotoViewModeMobile = createAction<ISetViewModeAction>('settings/photoviewmodemobile');
export const setAttachesViewMode = createAction<ISetViewModeAction>('settings/attachesviewmode');
export const setFilesViewMode = createAction<ISetViewModeAction>('settings/filesviewmode');
export const setQuotaCleanerViewMode = createAction<ISetViewModeAction>('settings/quotacleanerviewmode');
export const setViewMode = createAction<ISetViewModeForStorageAction>('settings/viewmode');
export const setQueryParams = createAction<QueryParams | undefined>('settings/queryparams');
export const resetQuerySearchParams = createAction('settings/resetQuerySearchParams');
export const loadSettingsRequest = createAction('settings/loadSettingsRequest');
export const sidebarToggle = createAction<boolean | undefined>('settings/sidebarToggle');

export const settingsReducer = createReducer(initialState, {
    [setPhotoViewMode.type]: (state, action: PayloadAction<ISetViewModeAction>) => {
        const { viewMode } = action.payload;
        state.prevViewMode = state.viewModePhoto;
        state.viewModePhoto = viewMode;
    },
    [setPhotoViewModeMobile.type]: (state, action: PayloadAction<ISetViewModeAction>) => {
        const { viewMode } = action.payload;
        state.prevViewMode = state.viewModePhotoMobile;
        state.viewModePhotoMobile = viewMode;
    },
    [setAttachesViewMode.type]: (state, action: PayloadAction<ISetViewModeAction>) => {
        const { viewMode } = action.payload;
        state.prevViewMode = state.viewModeAttaches;
        state.viewModeAttaches = viewMode;
    },
    [setFilesViewMode.type]: (state, action: PayloadAction<ISetViewModeAction>) => {
        const { viewMode } = action.payload;
        state.prevViewMode = state.viewMode;
        state.viewMode = viewMode;
    },
    [setViewMode.type]: (state, action: PayloadAction<ISetViewModeForStorageAction>) => {
        const { viewMode, storage, isMediaFolder } = action.payload;

        const { isGallery, isAttaches, isQuotaCleaner } = getStorage(storage);
        const isPhone = EnvironmentSelectors.isPhone();

        if ((isGallery || isMediaFolder) && !isPhone) {
            state.prevViewMode = state.viewModePhoto;
            state.viewModePhoto = viewMode;
        } else if (isGallery && isPhone) {
            state.prevViewMode = state.viewModePhotoMobile;
            state.viewModePhotoMobile = viewMode;
        } else if (isAttaches) {
            state.prevViewMode = state.viewModeAttaches;
            state.viewModeAttaches = viewMode;
        } else if (isQuotaCleaner) {
            state.prevViewMode = state.viewModeQuotaCleaner;
            state.viewModeQuotaCleaner = viewMode;
        } else {
            state.prevViewMode = state.viewMode;
            state.viewMode = viewMode;
        }
    },
    [setQueryParams.type]: (state, action: PayloadAction<QueryParams>) => {
        const {
            query,
            folderId,
            type,
            fromAuth,
            weblink,
            sort,
            item,
            action: actionParam,
            id,
            searchType,
            searchSource,
            tariffId,
            productId,
            token,
            diskoKey,
            fromCloud,
            from,
            total_size,
            max_size,
            activeFolder,
            activeItem,
            isCloudStock,
            searchSection,
            isFromExternal,
        } = action.payload || {};

        const queryParams = {
            tariffId,
            productId,
            folderId,
            type,
            weblink,
            diskoKey,
            sort,
            item,
            action: actionParam,
            id,
            searchType,
            searchSource,
            token,
            from,
            total_size,
            max_size,
            isCloudStock: Boolean(isCloudStock),
            fromCloud: Boolean(fromCloud),
            activeFolder,
            activeItem,
            searchSection,
            isFromExternal,
        };

        Object.keys(queryParams).forEach((key) => queryParams[key] === undefined && delete queryParams[key]);

        state.queryParams = queryParams;

        if (typeof fromAuth === 'boolean') {
            state.queryParams.fromAuth = Boolean(fromAuth);
        }
        if (typeof query !== 'undefined') {
            state.queryParams.query = parseSearchQuery(query);
        }
    },
    [resetQuerySearchParams.type]: (state) => {
        delete state.queryParams.query;
        delete state.queryParams.fromAuth;
        delete state.queryParams.activeFolder;
        delete state.queryParams.activeItem;
        delete state.queryParams.folderId;
    },
    [sidebarToggle.type]: (state, action: PayloadAction<boolean | undefined>) => {
        state.sidebarOpened = action.payload ?? !state.sidebarOpened;
    },
});
