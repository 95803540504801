import familyCoverImage from 'img/aside-promo-modal/family-cover.jpg';
import familyCoverImageMobile from 'img/aside-promo-modal/family-cover-mobile.jpg';
import { IS_AUTO_TEST_HIDE, IS_FAMILY_USER } from 'reactApp/appHelpers/configHelpers';
import { isFamilyAsidePromoEnabled, isFamilySubsAvailable } from 'reactApp/appHelpers/featuresHelpers';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { initFamilyOnboarding, setFamilyPlan } from 'reactApp/modules/family/family.actions';
import { getFamilyLoadingState, getFamilyQuotaPlan } from 'reactApp/modules/family/family.selectors';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { UserStorageActions } from 'reactApp/modules/user/userStorage';
import { store as reduxStore } from 'reactApp/store';
import { openAsidePromoModal } from 'reactApp/ui/AsidePromoModal/AsidePromoModal.helpers';
import { ESplashScreenAction, sendSplashScreenAnalytics } from 'reactApp/ui/Mobile/SplashScreen/SplashScreen.analytics';
import { put } from 'redux-saga/effects';
import { select, take } from 'typed-redux-saga';

const FAMILY_PROMO_ID = 'family-aside-promo';
const FAMILY_PROMO_SHOW_COUNT = `${FAMILY_PROMO_ID}-count`;

function markPromoShown() {
    reduxStore.dispatch(promoShown(EPromoType.familyAsidePromo));
    storeHelper.markAsShown(FAMILY_PROMO_ID);
    reduxStore.dispatch(removePromo(EPromoType.familyAsidePromo));
}

export function* initFamilyAsidePromoModal() {
    const storage = yield* select(getCurrentStorage);
    const isCorpUser = yield* select(UserSelectors.isCorpUser);
    const isBizUser = yield* select(UserSelectors.isBizUser);
    const isPaidUser = yield* select(UserSelectors.isPaidUser);
    const shownCount = yield put(UserStorageActions.get(FAMILY_PROMO_SHOW_COUNT)) || 0;

    // Показываем промо сначала 1 месяц раз в пять дей, далее 1 раз в 30 дней
    const periodInDays = shownCount < 6 ? 5 : 30;
    const periodPassed = storeHelper.getValue(FAMILY_PROMO_ID) && storeHelper.isPassed(FAMILY_PROMO_ID, { daysCount: periodInDays });

    if (periodPassed) {
        storeHelper.clearStore(FAMILY_PROMO_ID);
    }

    if (
        storeHelper.getValue(FAMILY_PROMO_ID) ||
        IS_AUTO_TEST_HIDE ||
        isCorpUser ||
        isBizUser ||
        !isFamilySubsAvailable ||
        !isFamilyAsidePromoEnabled ||
        storage !== EStorageType.home ||
        IS_FAMILY_USER
    ) {
        return;
    }

    const { isLoaded: isFamilyLoaded } = yield* select(getFamilyLoadingState);
    if (!isFamilyLoaded && isPaidUser) {
        yield take(setFamilyPlan);
    }

    const familyQuota = yield* select(getFamilyQuotaPlan);
    // Если у юзера только партнерская подписка (теле2 / мегафон / и тд) и нет обычной - не показываем промо
    if (!familyQuota && isPaidUser) {
        return false;
    }

    yield put(
        addPromoToShowQueue({
            type: EPromoType.familyAsidePromo,
            promoId: FAMILY_PROMO_ID,
            onClick: () => {
                markPromoShown();
                reduxStore.dispatch(initFamilyOnboarding());
                sendSplashScreenAnalytics({
                    action: ESplashScreenAction.click,
                    name: 'promo_balloon',
                    button_name: 'Попробовать',
                    storage,
                });
            },
            onShow: () => {
                markPromoShown();
                sendSplashScreenAnalytics({
                    action: ESplashScreenAction.show,
                    name: 'promo_balloon',
                    storage,
                });
            },
            onClose: () => {
                markPromoShown();
                sendSplashScreenAnalytics({
                    action: ESplashScreenAction.close,
                    name: 'promo_balloon',
                    button_name: 'Закрыть',
                    storage,
                });
            },
        })
    );
}

export function* showFamilyAsidePromoModal() {
    const promo = yield* select(PromoSelectors.getPromo(EPromoType.familyAsidePromo));
    const shownCount = yield put(UserStorageActions.get(FAMILY_PROMO_SHOW_COUNT)) || 0;
    const isMobile = EnvironmentSelectors.isMobile();

    if (promo) {
        const { onShow, onClick, onClose } = promo;

        yield openAsidePromoModal({
            title: 'Поделитесь гигабайтами \n с близкими',
            text: 'Бесплатно добавьте 3 аккаунта в свою подписку, и вместе пользуйтесь возможностями Mail Space',
            buttonText: 'Попробовать',
            imageUrl: isMobile ? familyCoverImageMobile : familyCoverImage,
            onShow,
            onClick,
            onClose,
        });

        yield put(UserStorageActions.set(FAMILY_PROMO_SHOW_COUNT, shownCount + 1));
    }
}
