import { xray } from 'lib/xray';

const MAX_LOAD_TIME = 30;

export const sendLoadTime = (mountTime: number, radarName: string): void => {
    let loadTime = Math.ceil((performance.now() - mountTime) / 1000);
    loadTime = Math.min(loadTime, MAX_LOAD_TIME);
    xray.send(radarName, {
        i: {
            [loadTime]: 1,
        },
    });
};
