import { AccessRights } from 'reactApp/types/Tree';

import { APICallV2Get } from '../APICall';

export enum EInviteStatus {
    SEND = 'send',
    MOUNTED = 'mounted',
}

export interface IOwner {
    email: string;
    name: string;
}

export interface IInvite {
    access: AccessRights;
    email: string;
    name?: string;
    status: EInviteStatus;
}

export interface ISharedInfo {
    access: AccessRights;
    home: string;
    invited: IInvite[];
    owner: IOwner;
    size: number;
    tree: string;
}

export class SharedInfoApiCall extends APICallV2Get<ISharedInfo> {
    _method = 'folder/shared/info';
}
