import classNames from 'clsx';
import React, { memo, ReactElement, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { SelectionsSelectors } from 'reactApp/modules/selections/selections.selectors';
import { useSendSearchActionBarAnalytics } from 'reactApp/ui/Mobile/ActionsBar/ActionsBar.analytics';
import { useCustomActions } from 'reactApp/ui/Mobile/ActionsBar/customActions/useCustomActions';
import { TToolbarItem } from 'reactApp/ui/Toolbar/Toolbar.types';
import { sendDwh } from 'reactApp/utils/ga';
import { noop } from 'reactApp/utils/helpers';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

import styles from './ActionsBar.css';
import { useItems } from './useItems';

export enum EActionsMode {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
}

export const ActionsBar = memo(({ mode, isPopup }: { mode?: EActionsMode; isPopup?: boolean }): ReactElement => {
    const storage = useSelector(getCurrentStorage);
    const selectedItems = useSelector(SelectionsSelectors.getSelectedItems);
    const actionsBarItems = useItems();
    const { customActions } = useCustomActions({ storage });
    const sendSearchAnalytics = useSendSearchActionBarAnalytics(storage);

    const sendAnalytics = useCallback(
        ({ action, source, ...data }) => {
            sendDwh({
                eventCategory: ECategoryGa.toolbar,
                action,
                dwhData: {
                    ...data,
                    source: `touch-${source}`,
                },
            });

            sendSearchAnalytics(action);
        },
        [sendSearchAnalytics]
    );

    const renderCustomItem = useCallback(({ Item, itemProps }: TToolbarItem, index) => {
        if (Item) {
            return <Item key={index} {...itemProps} />;
        }
    }, []);

    return (
        <div
            className={classNames({
                [styles.root]: true,
                [styles[`root_${mode}`]]: true,
                [styles.rootPopup]: isPopup,
            })}
        >
            <div className={styles.counter}>{selectedItems.length}</div>
            <div className={styles.bar}>
                {actionsBarItems.map((item) => (
                    <div
                        key={item.id}
                        onClick={item.disabled ? noop : () => item.onClick(null, sendAnalytics)}
                        className={classNames({
                            [styles.barItem]: true,
                            [styles[`barItem_${item.id}`]]: Boolean(item.id),
                            [styles.disabled]: item.disabled,
                        })}
                        data-qa-action={item.id}
                    >
                        {item.icon}
                        {item.text}
                    </div>
                ))}
                {Boolean(customActions.length) && customActions.map(renderCustomItem)}
            </div>
        </div>
    );
});

ActionsBar.displayName = 'ActionsBar';
