import React, { PureComponent, ReactElement } from 'react';
import { connect } from 'react-redux';
import { X_PAGE_ID } from 'reactApp/appHelpers/configHelpers';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { loadUser } from 'reactApp/modules/user/user.thunkActions';
import { Button } from 'reactApp/ui/Button/Button';
import Content from 'reactApp/ui/Content/Content';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { createGaSender } from 'reactApp/utils/ga';
import { noop } from 'reactApp/utils/helpers';

import styles from './WelcomeAttaches.css';

const sendGa = createGaSender('welcome-mail');

interface Props {
    onSuccess();
    loadUser();
    isUserLoaded?: boolean;
    isUserError?: boolean;
    publishMode?: boolean;
    onShow?(): void;
}

const mapStateToProps = (state) => {
    const { isLoaded: isUserLoaded, hasError: isUserError } = UserSelectors.getLifeCycleState(state);

    return {
        isUserLoaded,
        isUserError,
    };
};
const mapDispatchToProps = (dispatch) => ({
    loadUser: (): void => dispatch(loadUser()),
});

export class WelcomeAttachesComponent extends PureComponent<Props> {
    public static defaultProps = {
        onSuccess: noop,
        loadUser: noop,
    };

    public componentDidMount() {
        const { isUserLoaded, isUserError, loadUser, onShow } = this.props;

        sendGa('show', 'step_1');

        if (!isUserLoaded && !isUserError) {
            loadUser();
        }

        onShow?.();
    }

    private handleAccept = (): void => {
        sendGa('close', 'step_1');
        this.props.onSuccess();
    };

    private renderDescription = (publishMode) => {
        return publishMode ? (
            <>
                <p>
                    Для получения ссылки на файл из
                    <br />
                    письма мы сохраним его в Облаке.
                </p>
                <p>
                    Вы в любой момент сможете
                    <br />
                    удалить файл или отключить к нему
                    <br />
                    доступ.
                </p>
            </>
        ) : (
            <>
                <p>
                    Это папка, куда мы автоматически добавляем все приложенные файлы
                    <br />
                    из вашей почты.
                </p>
                <p>
                    Они не занимают место в Облаке,
                    <br />
                    так что вам не о чем беспокоиться,
                    <br />а ещё это удобно!
                </p>
            </>
        );
    };

    public render(): ReactElement | null {
        const { isUserLoaded, isUserError, publishMode } = this.props;

        return (
            <Dialog
                open
                size="small"
                closeOnDimmerClick={false}
                closeOnEscape={false}
                id="welcome-dialog"
                className={styles.root}
                closable={false}
            >
                <Content isModal isLoading={!isUserLoaded} hasError={isUserError} scrolling>
                    <div className={styles.content}>
                        <div className={styles.title}>{publishMode ? 'Файл будет сохранен в Облаке' : 'Файлы из почты'}</div>
                        <div className={styles.description}>{this.renderDescription(publishMode)}</div>
                        <Button theme="octavius" primary loading={!isUserLoaded} className={styles.button} onClick={this.handleAccept}>
                            Ясно, спасибо!
                        </Button>
                        <div className={styles.la}>
                            Я соглашаюсь с использованием{' '}
                            <a href="https://help.mail.ru/legal/terms/cloud/recommendation">рекомендательных технологий</a> в Облаке и
                            принимаю{' '}
                            <a
                                href={`https://help.mail.ru/legal/terms/cloud/LA?signupid=${X_PAGE_ID.toLowerCase()}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Лицензионное соглашение
                            </a>{' '}
                            и{' '}
                            <a href="https://help.mail.ru/legal/terms/cloud/privacy" target="_blank" rel="noreferrer">
                                Политику конфиденциальности
                            </a>
                        </div>
                    </div>
                </Content>
            </Dialog>
        );
    }
}

export const WelcomeAttaches = connect(mapStateToProps, mapDispatchToProps)(WelcomeAttachesComponent);
