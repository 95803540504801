import config from 'Cloud/config';
import classNames from 'clsx';
import { xray } from 'lib/xray';
import * as React from 'react';
import { FC, ReactEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { OnMessage, useIframeChannel } from 'reactApp/hooks/useIframeChannel';
import { useNormalizedOvidius } from 'reactApp/hooks/useNormalizedOvidius';
import { CloudFile, EStorageType } from 'reactApp/modules/storage/storage.types';
import { Message } from 'reactApp/ui/ReactViewer/OvidiusV2/OvidiusV2.types';
import { CONTENT_LOAD_TIMEOUT } from 'reactApp/ui/ReactViewer/ReactViewer.constants';
import { Spinner } from 'reactApp/ui/Spinner/Spinner';
import { getAttachType } from 'reactApp/utils/downloadDocumentHelpers';
import { sendLoadTime } from 'reactApp/utils/sendLoadTime';

import styles from './OvidiusV2.css';

interface Props {
    storage: EStorageType;
    file: CloudFile;
    scale?: number;
    setContentForPrint?: (id: string, content: ArrayBuffer[] | null) => void;
    fillParent?: boolean;
    onError: (error: string) => void;
    attachVersion?: number;
}

export const OvidiusV2: FC<Props> = ({ storage, file, scale, setContentForPrint, fillParent, onError, attachVersion }) => {
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const [isFrameLoaded, setIsFrameLoaded] = useState(false);
    const [isRenderError, setIsRenderError] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const mountTime = useRef<number>(0);
    const attachType = useMemo(() => getAttachType(file), [file]);
    const attachVersionState = useMemo(() => attachVersion || 2, [attachVersion]);

    const [channelId] = useState(Date.now().toString());

    const iframeUrl = useMemo(
        () =>
            new URL(
                `${config.get('BUILD_URLS')?.ovidius}?channelId=${channelId}&hostname=${window?.location?.hostname}`,
                window?.location?.origin
            ),
        [channelId]
    );

    const onMessageHandler: OnMessage<Message> = useCallback(
        (data) => {
            if (data.type === 'start') {
                setIsFrameLoaded(true);
            } else if (data.type === 'ready') {
                setIsReady(true);
                xray.send(`norm_ovidius${attachType ? `_${attachType}` : ''}_render_success`, { i: { [file.ext]: 1 } });
                sendLoadTime(mountTime.current, `norm_ovidius${attachType ? `_${attachType}` : ''}_render_time`);
            } else if (data.type === 'error') {
                setIsRenderError(true);
                xray.send('norm_ovidius_view_err', {
                    rlog: 'cloud_norm_pdf_err',
                    rlog_message: { error: data.message },
                });
            } else if (data.type === 'xray') {
                xray.send(data.message);
            }
        },
        [attachType]
    );

    const { postMessage } = useIframeChannel<Message>({
        iframeRef: iframeRef.current,
        isFrameLoaded,
        onMessage: onMessageHandler,
        channelId,
        sendToOrigin: iframeUrl.origin,
        isInsideIframe: false,
    });

    useEffect(() => {
        xray.send(`norm_ovidius${attachType ? `_${attachType}` : ''}_view_open`, { i: { [file.ext]: 1 } });
        mountTime.current = performance.now();
    }, []);

    useEffect(() => {
        setIsReady(false);
        setIsRenderError(false);
    }, [file.id]);

    const { content, error, partsAmount } = useNormalizedOvidius({
        url: file.id,
        setContentForPrint,
        file,
        storage,
        attachVersion: attachVersionState,
    });

    useEffect(() => {
        if (content.length === 1) {
            xray.send(`norm_ovidius${attachType ? `_${attachType}` : ''}_first_content_load`, { i: { [file.ext]: 1 } });
            sendLoadTime(mountTime.current, `norm_ovidius${attachType ? `_${attachType}` : ''}_first_content_load_time`);
        }
    }, [content]);

    useEffect(() => {
        if (content.length && content.length === partsAmount) {
            xray.send(`norm_ovidius${attachType ? `_${attachType}` : ''}_full_content_load`, { i: { [file.ext]: 1 } });
            sendLoadTime(mountTime.current, `norm_ovidius${attachType ? `_${attachType}` : ''}_full_content_load_time`);
        }
    }, [content, partsAmount]);

    useEffect(() => {
        postMessage({ type: 'file', content, id: file.id, partsAmount: partsAmount.toString() });
    }, [content, partsAmount]);

    useEffect(() => {
        if (scale) {
            postMessage({ type: 'scale', scale });
        }
    }, [scale]);

    useEffect(() => {
        if (isReady) {
            return;
        }

        const timeout = window.setTimeout(() => {
            setIsRenderError(true);
            xray.send('norm_ovidius_iframe_timeout_error', {
                rlog: 'cloud_norm_pdf_err',
                rlog_message: { error: 'iframe timeout' },
            });
        }, CONTENT_LOAD_TIMEOUT);

        return () => {
            clearTimeout(timeout);
        };
    }, [isReady]);

    const isLoading = useMemo(() => !isFrameLoaded || content?.length === 0 || !isReady, [isFrameLoaded, isReady]);

    const handleError = useCallback<ReactEventHandler<HTMLIFrameElement>>((error) => {
        setIsRenderError(true);
        xray.send('norm_ovidius_iframe_error', {
            rlog: 'cloud_norm_pdf_err',
            rlog_message: { error: `iframe startup error / ${error.type}` },
        });
    }, []);

    if (error || isRenderError) {
        onError(isRenderError ? 'render-error' : error || '');
        return null;
    }

    return (
        <div className={styles.wrapper}>
            {isLoading && <Spinner />}
            <iframe
                loading="lazy"
                onError={handleError}
                className={classNames(styles.iframe, { [styles.hide]: isLoading, [styles.fillParent]: fillParent })}
                sandbox="allow-same-origin allow-scripts"
                frameBorder={0}
                src={iframeUrl.toString()}
                ref={iframeRef}
            />
        </div>
    );
};
