import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { ReactElement } from 'react';
import { setActionPanelHeader, toggleActionPanel } from 'reactApp/modules/actionpanel/actionpanel.actions';
import { ActionPanelState, ToggleActionPanel, ToggleActionPanelOpened } from 'reactApp/modules/actionpanel/actionpanel.types';

const initialState: ActionPanelState = {
    opened: false,
    component: null,
    caption: '',
};

export const actionPanelReducer = createReducer(initialState, {
    [toggleActionPanel.type]: (state, action: PayloadAction<ToggleActionPanel & ToggleActionPanelOpened>) => {
        state.opened = action.payload.open ?? !state.opened;
        state.component = action.payload.open ? action.payload.component : null;
    },
    [setActionPanelHeader.type]: (state, action: PayloadAction<string | ReactElement>) => {
        state.caption = action.payload;
    },
});
